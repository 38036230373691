import Vue from "vue";
import { sync } from "vuex-router-sync";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import AppSocket from "./plugins/socket";
import moment from "moment";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import Multiselect from "vue-multiselect";
import Vuelidate from "vuelidate";
import VueSanitize from "vue-sanitize";
import VueFormWizard from "vue-form-wizard";
import VueProgressBar from "vue-progressbar";
import { countryList } from "./country.js";
import VueDragscroll from "vue-dragscroll";

const options = {
  color: "#7D7D7D ",
  failedColor: "#874b4b",
  thickness: "0.3em",
  transition: {
    speed: "0.5s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};
const defaultOptions = {
  allowedTags: [],
  allowedAttributes: {},
};

Vue.use(Autocomplete);
Vue.use(BootstrapVue);
Vue.use(Multiselect);
Vue.use(VueDragscroll);
Vue.use(Vuelidate);
Vue.use(VueFormWizard);
Vue.use(VueProgressBar, options);
Vue.use(VueSanitize, defaultOptions);

Vue.config.productionTip = false;
// postponed
//Vue.config.keyCodes.add = 107
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Socket Plugin
Vue.use(AppSocket, {
  connection: process.env.VUE_APP_BACKEND_URL,
});

Vue.component("CustomIcon", () => import("./components/tools/CustomIcon"));
Vue.component("PopoverToSign", () =>
  import("./components/tools/PopoverToSign")
);
Vue.component("TruncateText", () => import("./components/tools/TruncateText"));
Vue.component("PostLoader", () => import("./components/tools/PostLoader"));

Vue.prototype.moment = moment;

Vue.mixin({
  methods: {
    getCountryName(countryCode) {
      const country = countryList;
      let countryText = "";

      country.forEach((el) => {
        if (el.value === countryCode) {
          countryText = el.text;
        }
      });
      return countryText;
    },
    isEmpty(obj) {
      for (var x in obj) {
        return false;
      }
      return true;
    },
    checkToggleMenu(routeName) {
      let showToggelBar = true;
      switch (routeName) {
        case "Signin":
        case "Signup":
        case "ForgotPassword":
        case "MediaNotFound":
        case "PageRestricted":
        case "TermsOfUse":
        case "FriendRequest":
        case "EmailVerification":
        case "PrivacyPolicy":
        case "Notifications":
        case "About":
        case "ProfileEdit":
        case "PasswordEdit":
        case "PrivacySetting":
        case "ContactSetting":
        case "NotificationSetting":
          showToggelBar = !showToggelBar;
          break;
        default:
          showToggelBar = true;
          break;
      }

      return showToggelBar;
    },

    checkLogin() {
      if (
        this.$store.state.auth.status.loggedIn ||
        localStorage.getItem("userToken")
      ) {
        return true;
      } else {
        this.$router.push({
          name: "Signin",
        });
      }
    },
    chechIfExistMe(userIdCon, peopleList) {
      let existMe = false;
      for (let index = 0; index < peopleList.length; index++) {
        if (peopleList[index]._userIdCon === userIdCon) {
          existMe = true;
          break;
        } else {
          existMe = false;
        }
      }

      return existMe;
    },

    showSearchAutocomplete() {
      this.showSearchTag = !this.showSearchTag;
    },
    capitalizeFirstLetter(sentence = null) {
      let result = "";
      for (let i = 0; i < sentence.length; i++) {
        let letter = sentence[i];
        if (i === 0 || sentence[i - 1] === " ") {
          letter = letter.toUpperCase();
        } else {
          letter = letter.toLowerCase();
        }
        result += letter;
      }
      return result;
    },
    validateTooltipState(name) {
      const { $error } = this.$v.form[name];
      return $error;
    },
    searchConnection(input) {
      let payload = {
        type: "all",
        usernameTarget: this.$store.state.auth.user.username,
        startLoad: 0,
        numberOfLoaded: 100,
        keyword: input,
      };
      return new Promise((resolve) => {
        if (input.length < 1) {
          return resolve([]);
        }
        this.$store
          .dispatch("loadConnectionSearch", payload)
          //.then(response => response.json())
          .then((response) => {
            resolve(response.data.connectionData);
          });
      });
    },
    convertAddressFull(data) {
      var setAddress = "";
      const { place, region, country } = data;
      if (place == region) {
        setAddress = place + ", " + country;
      } else {
        setAddress = place + ", " + region + ", " + country;
      }

      return setAddress;
    },
    getResultValueCon() {
      // sebelumnya getResultValueCon(result)
      // const fullname = result.firstname_con + " " + result.lastname_con;
      // return fullname;
      return "";
    },

    hoverIndex(index) {
      this.$set(this.hover, index, true);
    },
    leaveIndex(index) {
      this.$set(this.hover, index, false);
    },
    stringLimit(text, limit = 50) {
      return text.slice(0, limit) + (text.length > limit ? "..." : "");
    },

    async viewsCounterMedia(id) {
      let mediaHistory = sessionStorage.getItem("mediaHistory");
      let expiryTime = 3600000;
      //let expiryTime = 1;
      let nowTime = Date.now();
      let mediaLogData = [];
      let mediaLog = {
        mediaId: id,
        time: nowTime + expiryTime,
      };
      if (mediaHistory === null) {
        mediaLogData.push(mediaLog);
        const payload = {
          id: id,
        };
        this.$store.dispatch("mediaList/mediaViewsCounter", payload).then(
          () => {
            sessionStorage.setItem(
              "mediaHistory",
              JSON.stringify(mediaLogData)
            );
          },
          () => {
            // const err = error.response.data.message;
            // console.log(err);
          }
        );
      } else {
        var mediaLogSess = JSON.parse(mediaHistory);
        let mediaInList = false;
        await mediaLogSess.forEach((obj, idx) => {
          if (obj.mediaId == id) {
            mediaInList = true;
            if (obj.time < nowTime) {
              const payload = {
                id: id,
              };
              this.$store.dispatch("mediaList/mediaViewsCounter", payload).then(
                () => {
                  mediaLogSess.splice(idx, 1);
                  mediaLogSess.push(mediaLog);
                  sessionStorage.removeItem("mediaHistory");
                  sessionStorage.setItem(
                    "mediaHistory",
                    JSON.stringify(mediaLogSess)
                  );
                },
                () => {
                  // const err = error.response.data.message;
                  // console.log(err);
                }
              );
            }
          }
        });
        if (mediaInList == false) {
          mediaLogSess.push(mediaLog);
          const payload = {
            userId: this.userId,
            id: id,
          };
          this.$store.dispatch("mediaList/mediaViewsCounter", payload).then(
            () => {
              sessionStorage.removeItem("mediaHistory");
              sessionStorage.setItem(
                "mediaHistory",
                JSON.stringify(mediaLogSess)
              );
            },
            () => {
              // const err = error.response.data.message;
              // console.log(err);
            }
          );
        }
      }
    },
    handleDetailPhotoCoterie(id) {
      this.$router.push({
        name: "PostPhotoDetail",
        params: {
          id,
        },
      });
    },
    handleDetailPhoto(
      id,
      usernameTarget = null,
      latestState = null,
      postFilter = null,
      isAllPostLoaded = false,
      dataRequest = {}
    ) {
      let recentPage = {
        latestState: latestState,
        usernameTarget: usernameTarget,
        postFilter: postFilter,
        isAllPostLoaded: isAllPostLoaded,
        dataRequest: dataRequest,
      };
      sessionStorage.setItem("recentPage", JSON.stringify(recentPage));
      this.$store.commit("setRecentPage", recentPage);
      // this.$router.push({
      //   name: "PostPhotoDetail",
      //   params: {
      //     id,
      //   },
      // });
    },
    handleDetailVideo(
      id,
      usernameTarget = null,
      latestState = null,
      postFilter = null,
      isAllPostLoaded = false,
      dataRequest = {}
    ) {
      if (id != "private") {
        //save related history to open video related detail
        let recentPage = {
          latestState: latestState,
          usernameTarget: usernameTarget,
          postFilter: postFilter,
          isAllPostLoaded: isAllPostLoaded,
          dataRequest: dataRequest,
        };
        sessionStorage.setItem("recentPage", JSON.stringify(recentPage));
        this.$store.commit("setRecentPage", recentPage);
        // this.$router.push({
        //   name: "VideoDetail",
        //   params: {
        //     id,
        //   },
        // });
      } else {
        return false;
      }
    },
    showPopOverTag() {
      this.showPopOver = !this.showPopOver;
      if (this.showPopOver) {
        this.$root.$emit("bv::show::popover", "popover-connections");
      } else {
        this.$root.$emit("bv::hide::popover", "popover-connections");
      }
    },
    toastAge(toaster, append = false) {
      this.$bvToast.toast(
        `You need to be at least 18 years old to post mature content`,
        {
          title: `Safe for kids Age Restriction`,
          toaster: toaster,
          variant: "danger",
          solid: true,
          appendToast: append,
        }
      );
    },
    toastCast(toaster, username) {
      this.$bvToast.toast(
        `Cast (${username}) cannot be underage users when content is marked "not safe for kids"`,
        {
          title: `Safe for kids Age Restriction  for ${username} `,
          toaster: toaster,
          variant: "danger",
          solid: true,
          appendToast: false,
        }
      );
    },

    timeConvert: (secs) => {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    counterHelper: (num) => {
      //Convert number to K M B -> 1000 to 1K
      if (num == null) {
        return null;
      }
      if (num == 0) {
        return 0;
      }
      if (num < 1000) {
        return num;
      }
      var si = [
        {
          v: 1e3,
          s: "K",
        },
        {
          v: 1e6,
          s: "M",
        },
        {
          v: 1e9,
          s: "B",
        },
        {
          v: 1e12,
          s: "T",
        },
        {
          v: 1e15,
          s: "P",
        },
        {
          v: 1e18,
          s: "E",
        },
      ];
      var i;
      for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].v) {
          break;
        }
      }
      return (
        (parseInt((num / si[i].v) * 10) / 10)
          .toFixed(0)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].s
      );
    },
  },
});
sync(store, router);
// Enable / Disable vue devtools on browser
// true: enable, false: disable
Vue.config.devtools = true;
window.Store = store;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
