export const returNotif = (notifCount, moduleType, notifType, actionType) => {
  // Check Singular or Plural
  let addS = "";
  let othertext = "";
  let notifText = "";
  // hold until upliftApprove & connection Approve done

  if (notifCount == 0) {
    addS = "s";
  } else if (notifCount == 1) {
    othertext = "and " + notifCount + " other";
  } else {
    othertext = "and " + notifCount + " others";
  }

  // Check type notification

  switch (moduleType) {
    //notification for connections
    case "connection":
      switch (notifType) {
        case "connectionApprove":
          switch (actionType) {
            case "1":
              notifText =
                othertext + "has accepted your request as Acquaintance ";
              break;
            case "2":
              notifText = othertext + "has accepted your request as Friend";
              break;
            default:
              notifText =
                othertext + "has accepted your request as Best Friend";
              break;
          }
          break;

        case "connectionUplift":
          switch (actionType) {
            case "2":
              notifText =
                othertext + " is requesting connection uplift to Friend";
              break;
            default:
              notifText =
                othertext + " is requesting connection uplift to Best Friend";
              break;
          }
          break;
        case "approveUplift":
          switch (actionType) {
            case "2":
              notifText =
                othertext + " has approved your uplift request to Friend";
              break;
            default:
              notifText =
                othertext + " has approved your uplift request to Best Friend";
              break;
          }
          break;

        default:
          notifText = othertext + " want" + addS + " to connect with you";
          break;
      }
      break;
    case "follow":
      notifText = othertext + " is now your follower";
      break;
    default:
      //notification for content
      switch (actionType) {
        //for post

        case "createIntouch":
          notifText = othertext + "  wrote " + " on your " + moduleType;
          break;
        case "like":
          notifText = othertext + " like" + addS + " your " + moduleType;
          break;
        case "comment":
          notifText = othertext + " commented " + " on your " + moduleType;
          break;
        case "replyComment":
        case "replyPersonComment":
          notifText = " replied to";
          notifText += " your comment on " + moduleType;
          break;
        case "likeComment":
          notifText =
            othertext + " like" + addS + "  your comment on " + moduleType;
          break;
        case "likeReplyComment":
          notifText =
            othertext + " like" + addS + "  your reply on " + moduleType;
          break;
        case "tags":
          notifText = " tagged you in his " + moduleType;
          break;
      }
      break;
  }
  return notifText;
};
