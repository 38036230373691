import axiosInstance from "@/services/instanceService";

export default {
  state: {
    stateList: {},
    allMessages: [],
    usernameMessageTarget: "",
    loadFilter: "old",
    loadingOldMessage: false,
    loadingNewMessage: false,
    payloadFromSource: {},
    payloadSource: "",
    activeMessage: {
      firstname: "",
      lastname: "",
      messageRoomId: "",
      userId: "",
      imgProfile: "",
      username: "",
    },
    liveMessage: [],
    loadLimitForMessage: 100,
    pingRefetch: false,
    startChatLanding: true,
    noMessage: false,
    messageLoadSource: "",
    scrollBottom: false,
    syncMessageRoom: true,
    topMessageId: "",
    firstOpenNewMessage: true,
    roomChanged: false,
    currentId: "",
  },

  mutations: {
    // used in destroyed lifecycle
    resetMessageState(state) {
      // state.allMessages = []
      state.usernameMessageTarget = "";
      state.loadFilter = "old";
      state.payloadFromSource = {};
      state.payloadSource = "";
      state.activeMessage = {
        firstname: "",
        lastname: "",
        messageRoomId: "",
        userId: "",
        imgProfile: "",
        username: "",
      };
      state.liveMessage = [];
      state.pingRefetch = false;
      state.startChatLanding = true;
      state.messageLoadSource = "";
      state.topMessageId = "";
      state.roomChanged = false;
      state.currentId = "";
    },
    // used when logout
    resetAllMessageState(state) {
      state.stateList = {};
      state.allMessages = [];
      state.usernameMessageTarget = "";
      state.loadFilter = "old";
      state.payloadFromSource = {};
      state.payloadSource = "";
      state.activeMessage = {
        firstname: "",
        lastname: "",
        messageRoomId: "",
        userId: "",
        imgProfile: "",
        username: "",
      };
      state.liveMessage = [];
      state.pingRefetch = false;
      state.startChatLanding = true;
      state.noMessage = false;
      state.messageLoadSource = "";
      state.syncMessageRoom = true;
      state.topMessageId = "";
      state.roomChanged = false;
      state.currentId = "";
    },
    setAllMessages(state, payload) {
      // state.allMessages = payload
      state.allMessages = [...payload, ...state.allMessages];
    },
    resetAllMessages(state) {
      state.allMessages = [];
    },
    setLoadingOldMessage(state, payload) {
      state.loadingOldMessage = payload;
    },
    setLoadingNewMessage(state, payload) {
      state.loadingNewMessage = payload;
    },
    setUsernameMessageTarget(state, payload) {
      state.usernameMessageTarget = payload;
    },
    setLoadFilter(state, payload) {
      state.loadFilter = payload;
    },
    setActiveMessage(state, payload) {
      const {
        _id,
        firstname,
        lastname,
        userId,
        imgProfile,
        username,
        conType,
      } = payload;
      state.activeMessage = {
        firstname,
        lastname,
        messageRoomId: _id,
        userId,
        imgProfile,
        username,
        conType,
      };
      state.activeMessage = { ...state.activeMessage };
    },
    setMessageRoomId(state, payload) {
      state.activeMessage.messageRoomId = payload;
    },
    setDataToStateList(state, data) {
      if (data.dataMessage.old.length) {
        state.stateList[state.usernameMessageTarget].lastCreatedAt =
          data.dataMessage.old[data.dataMessage.old.length - 1].createdAt;
        state.stateList[state.usernameMessageTarget].messages.old = [
          ...state.stateList[state.usernameMessageTarget].messages.old,
          ...data.dataMessage.old,
        ];
      }
      if (data.dataMessage.new.length) {
        state.stateList[state.usernameMessageTarget].messages.new = [
          ...data.dataMessage.new.slice().reverse(),
          ...state.stateList[state.usernameMessageTarget].messages.new,
        ];
      }
      if (state.loadFilter == "old" || state.loadFilter == "all") {
        state.stateList[state.usernameMessageTarget].startLoadOldMessage +=
          state.loadLimitForMessage;
        if (data.dataMessage.new.length) {
          state.stateList[state.usernameMessageTarget].startLoadOldMessage +=
            data.dataMessage.new.length;
        }
      }
      if (data.noMoreDataOld) {
        state.stateList[state.usernameMessageTarget].noMoreData.old =
          data.noMoreDataOld;
      }
      if (data.noMoreDataNew) {
        state.stateList[state.usernameMessageTarget].noMoreData.new =
          data.noMoreDataNew;
      }
      state.stateList = { ...state.stateList };
    },
    setNoMoreNewData(state, data) {
      if (
        state.stateList[data.username] &&
        state.stateList[data.username].noMoreData.new == true
      ) {
        const userIndex = state.allMessages.findIndex(
          (msg) => msg._id === data._id
        );
        if (userIndex > -1) {
          if (state.allMessages[userIndex].countUnread > 0) {
            state.stateList[data.username].noMoreData.new = false;
            state.stateList = { ...state.stateList };
          }
        }
      }
    },
    setAllUnreadToNew(state, data) {
      state.stateList[state.usernameMessageTarget].messages.new = [
        ...data.dataMessage.slice().reverse(),
        ...state.stateList[state.usernameMessageTarget].messages.new,
      ];
      if (data.noMoreDataNew) {
        state.stateList[state.usernameMessageTarget].noMoreData.new =
          data.noMoreDataNew;
      }
      state.stateList = { ...state.stateList };
    },
    deleteAMessageFromState(state, messageId) {
      if (state.stateList[state.usernameMessageTarget]) {
        if (state.stateList[state.usernameMessageTarget].messages.old.length) {
          const indexOld = state.stateList[
            state.usernameMessageTarget
          ].messages.old.findIndex((msg) => msg._id === messageId);
          if (indexOld > -1) {
            state.stateList[state.usernameMessageTarget].messages.old.splice(
              indexOld,
              1
            );
          }
        }

        if (state.stateList[state.usernameMessageTarget].messages.new.length) {
          const indexNew = state.stateList[
            state.usernameMessageTarget
          ].messages.new.findIndex((msg) => msg._id === messageId);
          if (indexNew > -1) {
            state.stateList[state.usernameMessageTarget].messages.new.splice(
              indexNew,
              1
            );
          }
        }

        if (state.liveMessage.length) {
          const indexLive = state.liveMessage.findIndex(
            (msg) => msg._id === messageId
          );
          if (indexLive > -1) {
            state.liveMessage.splice(indexLive, 1);
          }
        }
      }
    },
    changeLastMessage(state, payload) {
      // used for deleting messageRoom too
      const { messageRoomId, messageChange } = payload;
      if (messageChange == " ") {
        this.commit("deleteAMessageRoom", messageRoomId);
      } else {
        const userIndex = state.allMessages.findIndex(
          (msg) => msg._id.toString() == messageRoomId.toString()
        );
        state.allMessages[userIndex]["lastMessage"] = messageChange;
      }
    },
    deleteAMessageRoom(state, messageRoomId) {
      const userIndex = state.allMessages.findIndex(
        (msg) => msg._id.toString() == messageRoomId.toString()
      );
      if (userIndex > -1) state.allMessages.splice(userIndex, 1);
    },
    clearMessagesFromStateList(state) {
      state.stateList[state.usernameMessageTarget].messages.old = [];
      state.stateList[state.usernameMessageTarget].messages.new = [];
      state.stateList[state.usernameMessageTarget].noMoreData.old = false;
      state.stateList[state.usernameMessageTarget].noMoreData.new = false;
      state.liveMessage = [];
    },
    moveNewMessageToOldMessage(state) {
      if (
        state.stateList[state.usernameMessageTarget] &&
        state.stateList[state.usernameMessageTarget].messages.new.length
      ) {
        let messagesToMoveFromNew = [
          ...state.stateList[state.usernameMessageTarget].messages.new,
        ].map(function(element) {
          element.readStatus = 1;
          return element;
        });
        state.stateList[state.usernameMessageTarget].messages.old = [
          ...messagesToMoveFromNew,
          ...state.stateList[state.usernameMessageTarget].messages.old,
        ];
        state.stateList[state.usernameMessageTarget].messages.new = [];
        state.stateList = { ...state.stateList };
      }

      if (state.liveMessage.length) {
        // let messagesToMoveFromLive = [...state.liveMessage].map(function(
        //   element
        // ) {
        //   // element.readStatus = 1;
        //   return element;
        // });
        // state.stateList[state.usernameMessageTarget].messages.old = [
        //   ...messagesToMoveFromLive,
        //   ...state.stateList[state.usernameMessageTarget].messages.old,
        // ];
        state.stateList[state.usernameMessageTarget].messages.old = [
          ...state.liveMessage,
          ...state.stateList[state.usernameMessageTarget].messages.old,
        ];
        state.liveMessage = [];
      }
      state.stateList = { ...state.stateList };
    },
    addNewMessageToUserState(state, data) {
      // nanti bikin if condition untuk toggle tambahin data ke state atau ngga
      state.liveMessage = [...data, ...state.liveMessage];
      state.liveMessage = [...state.liveMessage];
    },
    setPayloadFromSource(state, data) {
      state.payloadFromSource = data;
    },
    setPayloadSource(state, data) {
      state.payloadSource = data;
    },
    changeUnreadMsgCount(state, data) {
      const { messageRoomId, countUnread } = data;
      const userIndex = state.allMessages.findIndex(
        (msg) => msg._id === messageRoomId
      );
      if (userIndex > -1) {
        state.allMessages[userIndex].countUnread = countUnread;
      }
    },
    setLeftUnreadNumber(state, data) {
      const { roomId, readNumber } = data;
      const userIndex = state.allMessages.findIndex(
        (msg) => msg._id === roomId
      );
      if (userIndex > -1) {
        state.allMessages[userIndex].countUnread =
          state.allMessages[userIndex].countUnread - readNumber;
      }
      state.allMessages = [...state.allMessages];
    },
    setLeftUnreadNumberToZero(state) {
      let changedMessageRoom = [...state.allMessages].map(function(element) {
        element.countUnread = 0;
        return element;
      });
      state.allMessages = [...changedMessageRoom];
    },
    setPingRefetch(state, data) {
      state.pingRefetch = data;
    },
    setStartChatLanding(state, data) {
      state.startChatLanding = data;
    },
    setLiveMessageAsRead(state, messageId) {
      const indexLive = state.liveMessage.findIndex(
        (msg) => msg._id === messageId
      );
      if (indexLive > -1) {
        state.liveMessage[indexLive].readStatus = 1;
      }
      state.liveMessage = [...state.liveMessage];
    },
    setNoMessage(state, data) {
      state.noMessage = data;
    },
    setMessageLoadSource(state, payload) {
      state.messageLoadSource = payload;
    },
    setStayingMessageRooms(state, payload) {
      state.allMessages = payload;
    },
    setScrollBottom(state, payload) {
      state.scrollBottom = payload;
    },
    setMessageRoomConnectionStatus(state, payload) {
      const { currentConnectionType, messageRoomId } = payload;
      const messageRoomIndex = state.allMessages.findIndex(
        (messageRoom) => messageRoom._id == messageRoomId
      );
      if (messageRoomIndex > -1) {
        state.allMessages[messageRoomIndex].conType = currentConnectionType;
        if (!currentConnectionType || currentConnectionType == "") {
          state.allMessages[messageRoomIndex].conType = 0;
        }
      }
      state.activeMessage.conType = currentConnectionType;
      state.allMessages = [...state.allMessages];
    },
    setNewReadMessagesToStateList(state, payload) {
      const dataMessage = payload.dataMessage;
      state.stateList[state.usernameMessageTarget].messages.old = [
        ...dataMessage.slice().reverse(),
        ...state.stateList[state.usernameMessageTarget].messages.old,
      ];
      state.stateList = { ...state.stateList };
    },
    setIsNewMessagesReadLoaded(state, payload) {
      state.stateList[
        state.usernameMessageTarget
      ].isNewMessagesReadLoaded = payload;
      state.stateList = { ...state.stateList };
    },

    setTopMessageId(state, payload) {
      state.topMessageId = payload.toString();
    },

    setRoomChanged(state, payload) {
      state.roomChanged = payload;
    },

    setFirstOpenNewMessage(state, payload) {
      state.firstOpenNewMessage = payload;
    },

    setCurrentId(state, payload) {
      state.currentId = payload;
    },

    setLiveMessageReadDecreaseCount(state, payload) {
      const index = state.allMessages.findIndex(
        (msg) => msg.userId.toString() === payload.targetId.toString()
      );
      // console.log("log index: ", index, payload);
      // console.log("log index: ", state.activeMessage.userId, payload.targetId);
      if (
        index > -1 &&
        (!state.activeMessage.userId ||
          state.activeMessage.userId.toString() != payload.targetId.toString())
      ) {
        // console.log("success");
        state.allMessages[index].countUnread -= 1;
      }
    },
  },

  actions: {
    redirectToMessage(context, payload) {
      const { data, source } = payload;
      this.commit("setPayloadSource", source);
      this.commit("setPayloadFromSource", data);
      return true;
    },
    addNewUsernameMessage({ state }, data) {
      const usernameTarget = data.username;
      if (!state.stateList[usernameTarget]) {
        this.commit(
          "setNewPostState",
          {
            resource: "message",
            usernameTarget,
            setState: {
              messages: { old: [], new: [] },
              isNewMessagesReadLoaded: false,
              startLoadOldMessage: 0,
              noMoreData: { old: false, new: false },
              lastCreatedAt: "",
            },
          },
          { root: true }
        );
      }
      return true;
    },
    changeStartChatLanding(context, data) {
      this.commit("setStartChatLanding", data);
      return true;
    },
    updateNewMessageRoom({ state }, payload) {
      const { senderId, userId, messageRoomData, index } = payload;
      if (index > 0) {
        state.allMessages.unshift(state.allMessages.splice(index, 1)[0]);
      }
      if (index > -1) {
        state.allMessages[0].lastMessage = messageRoomData.lastMessageFirstUser;
        state.allMessages[0].countUnread += 1;
        if (
          messageRoomData._id == state.activeMessage.messageRoomId ||
          senderId == userId
        ) {
          state.allMessages[0].countUnread = 0;
        }
      } else {
        // tambah new message room
        state.allMessages.unshift({
          _id: state.activeMessage.messageRoomId,
          firstname: state.activeMessage.firstname,
          lastname: state.activeMessage.lastname,
          userId: state.activeMessage.userId,
          imgProfile: state.activeMessage.imgProfile,
          username: state.activeMessage.username,
          countUnread: 0,
          lastMessage: messageRoomData.lastMessageFirstUser,
          conType: 1,
        });
      }

      state.allMessages = [...state.allMessages];
      return true;
    },
    updateMessageRoomConType({ state, commit }, payload) {
      const { actionTakerId, _userIdCon, connection_type, userId } = payload;
      let userIdToSearch = actionTakerId;
      if (userId == actionTakerId) {
        userIdToSearch = _userIdCon;
      }
      const index = state.allMessages.findIndex(
        (messageRoom) =>
          messageRoom.userId.toString() === userIdToSearch.toString()
      );
      if (index > -1) {
        const payload = {
          currentConnectionType: connection_type,
          messageRoomId: state.allMessages[index]._id,
        };
        commit("setMessageRoomConnectionStatus", payload);
      }
      return true;
    },
    addNewMessageToUserStateAction(context, payload) {
      this.commit("addNewMessageToUserState", payload);
      return true;
    },

    loadOneMessageRoom(context, data) {
      return axiosInstance({
        method: "POST",
        url: `message/loadOneMessageRoom/`,
        data: data,
      });
    },
    loadMessageRoom({ state, commit }) {
      let loadType = "new";
      if (!state.allMessages.length || state.syncMessageRoom) {
        state.syncMessageRoom = false;
        loadType = "all";
      }
      const data = { loadType };
      return axiosInstance({
        method: "POST",
        url: `message/loadMessageRoom`,
        data: data,
      })
        .then((data) => {
          const messageRooms = data.data.messageRoomData;
          if (loadType == "new") {
            // removing messageRoom from state
            const stayingMessageRooms = state.allMessages.filter(
              (sourceArray) =>
                !messageRooms.find(
                  (removeArray) =>
                    removeArray._id.toString() == sourceArray._id.toString()
                )
            );
            commit("setStayingMessageRooms", stayingMessageRooms);
          }
          if (state.allMessages && state.allMessages.length) {
            if (
              state.allMessages[0].countUnread > 0 &&
              state.messageLoadSource == "header"
            ) {
              // for new message indicator
              commit("user/setAllMessageRead", false, { root: true });
              commit("setMessageLoadSource", "");
              commit("setNoMessage", true);
            }
          }

          return messageRooms;
        })
        .then((messageRooms) => {
          commit("setAllMessages", messageRooms);
          state.syncMessageRoom = false;
          return true;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    loadMessage({ state, commit }, data) {
      data.numberOfLoad = state.loadLimitForMessage;
      data.lastCreatedAt =
        state.stateList[state.usernameMessageTarget].lastCreatedAt;
      return axiosInstance({
        method: "POST",
        url: `message/loadMessage/`,
        data: data,
      })
        .then((data) => {
          let messages = state.stateList[state.usernameMessageTarget].messages;
          if (messages.old.length > 0) {
            commit(
              "setTopMessageId",
              messages.old[messages.old.length - 1]._id
            );
          } else {
            if (messages.new.length > 0) {
              commit(
                "setTopMessageId",
                messages.new[messages.new.length - 1]._id
              );
            }
          }
          if (state.loadFilter === "all") {
            if (data.data.dataMessage.new.length) {
              const payload = {
                roomId: data.data.dataMessage.new[0].roomId,
                readNumber: data.data.dataMessage.new.length,
              };
              commit("setLeftUnreadNumber", payload);
            }
            commit("setDataToStateList", data.data);
          } else {
            if (data.data.dataMessage.new.length) {
              const payload = {
                roomId: data.data.dataMessage.new[0].roomId,
                readNumber: data.data.dataMessage.new.length,
              };
              commit("setLeftUnreadNumber", payload);
            }
            commit("setDataToStateList", data.data);
          }
          if (data.data.dataMessage.new[0]) {
            return data.data.dataMessage.new[0].roomId;
          } else {
            return null;
          }
        })
        .then((roomId) => {
          if (!roomId) {
            return null;
          }
          const userIndex = state.allMessages.findIndex(
            (msg) => msg._id === roomId
          );
          if (userIndex > -1) {
            return state.allMessages[userIndex].countUnread;
          } else {
            return null;
          }
        })
        .catch(() => {
          // console.log(err)
        });
    },
    loadNewReadMessages({ state, commit }, data) {
      data.lastCreatedAt =
        state.stateList[state.usernameMessageTarget].messages.old[0].createdAt;
      return axiosInstance({
        method: "POST",
        url: `message/loadNewReadMessages/`,
        data: data,
      })
        .then((data) => {
          commit("setNewReadMessagesToStateList", data.data);
          state.stateList[
            state.usernameMessageTarget
          ].isNewMessagesReadLoaded = true;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    loadAllUnread(context, data) {
      return axiosInstance({
        method: "POST",
        url: `message/loadAllUnread/`,
        data: data,
      })
        .then((data) => {
          this.commit("setAllUnreadToNew", data.data);
          const payload = {
            roomId: data.data.dataMessage[0].roomId,
            readNumber: data.data.dataMessage.length,
          };
          this.commit("setLeftUnreadNumber", payload);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    createNewMessage(context, data) {
      return axiosInstance({
        method: "POST",
        url: "message/createMessage",
        data: data,
      });
    },
    deleteOneMessage(context, data) {
      return axiosInstance({
        method: "POST",
        url: "message/deleteOneDocument",
        data: data,
      });
    },
    deleteAllMessage(context, data) {
      return axiosInstance({
        method: "POST",
        url: "message/deleteAllDocument",
        data: data,
      });
    },
    markOneAsRead(context, data) {
      return axiosInstance({
        method: "POST",
        url: "message/markOneAsRead",
        data: data,
      })
        .then(() => {
          this.commit("addNewMessageToUserState", [data]);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    markAllMessageRoomAsRead(context, data) {
      return axiosInstance({
        method: "POST",
        url: `message/markAllMessageRoomAsRead/`,
        data: data,
      })
        .then(() => {
          this.commit("setLeftUnreadNumberToZero");
        })
        .catch(() => {
          // console.log(error);
        });
    },
    setNoMoreMessageToFalse({ state }) {
      for (const user in state.stateList) {
        state.stateList[user].noMoreData.new = false;
        state.stateList[user].noMoreData.old = false;
        state.stateList[user].isNewMessagesReadLoaded = false;
      }
    },

    checkUnreadMessageIndicator({ commit }, data) {
      return axiosInstance({
        method: "POST",
        url: `message/checkUnreadMessage/`,
        data: data,
      })
        .then((data) => {
          //   console.log("masuk return cek indicator", data);
          commit("user/setAllMessageRead", !data.data.unreadAvailable, {
            root: true,
          });
        })
        .catch(() => {
          // console.log(err);
        });
    },
  },
};
