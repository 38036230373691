<template>
  <b-row>
    <div class="col-12 offset-lg-2 navbar-home">
      <div class="home-tabing">
        <b-nav pills class="d-flex profile-feed-items">
          <!--change to col-sm-3 if show onthe go-->
          <b-nav-item
            @click="loadPages(worldwidePage)"
            nav-link
            pill
            :active="/explore/i.test($route.name)"
            class="col-sm-4 p-0 text-center"
          >
            <custom-icon
              :name="$route.name == 'ExploreVideos' ? 'worldwide' : 'worldwide'"
              class="d-block d-sm-block icon-bottom"
            />
            {{ latestCountry ? latestCountry : "Worldwide" }}</b-nav-item
          >
          <!--change to col-sm-3 if show onthe go-->
          <b-nav-item
            @click="loadPages(hometownPage)"
            nav-link
            pill
            :active="/hometown/i.test($route.path)"
            class="col-sm-4 p-0 text-center"
          >
            <custom-icon
              :name="$route.name == 'HometownVideos' ? 'hometown' : 'hometown'"
              class="d-block d-sm-block icon-bottom"
            />
            {{ hometown }}</b-nav-item
          >
          <!--change to col-sm-3 if show onthe go-->
          <b-nav-item
            @click="loadPages(coteriePage)"
            nav-link
            pill
            :active="/coterie/i.test($route.path)"
            class="col-sm-4 p-0 text-center"
          >
            <custom-icon
              :name="$route.name == 'Coterie' ? 'coterie' : 'coterie'"
              class="d-block d-sm-block icon-bottom"
            />

            Coterie</b-nav-item
          >
          <!--
          <b-nav-item
            @click="loadPages('OnTheGoVideos')"
            nav-link
            pill
            class="col-sm-3 p-0 text-center"
            :active="/onthego/i.test($route.path)"
          >
               <custom-icon
              :name="$route.name == 'OnTheGo' ? 'onthego' : 'onthego-fill'"
              class="d-block d-sm-block icon-bottom"
            />
            On the go</b-nav-item
          >
          -->
        </b-nav>
      </div>
    </div>
  </b-row>
</template>
<script>
export default {
  name: "NavbarTab",
  data() {
    return {
      hometown: "Hometown",
      worldwidePage: "ExploreVideos",
      hometownPage: "HometownVideos",
      coteriePage: "CoterieVideos",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getFilterHome() {
      return this.$store.state.latestFilterHome;
    },
    getSetFilterCoterie() {
      return this.$store.state.latestFilterCoterie;
    },
    latestCountry() {
      return this.$store.state.latestCountry;
    },
  },
  watch: {
    $route(to) {
      let menuGo = this.$route.name;

      this.setMenuNav(menuGo);
      if (to.query.search) {
        this.query = { search: this.$route.query.search };
      }
      this.setHometownNav();
    },
  },
  created() {
    let menuGo = this.$route.name;
    this.setMenuNav(menuGo);
    this.setHometownNav();
  },
  mounted() {
    this.$root.$on("setNavbar", (setTown) => {
      this.setHometownNav(setTown);
    });
  },
  beforeDestroy() {
    this.$root.$off("setNavbar");
  },
  methods: {
    setMenuNav(menuParent) {
      switch (menuParent) {
        case "ExploreVideos":
        case "HometownVideos":
        case "ExploreUsers":
        case "ExplorePhotos":
        case "HometownUser":
          if (this.$store.state.latestFilterHome == "video") {
            this.worldwidePage = "ExploreVideos";
            this.hometownPage = "HometownVideos";
          } else {
            this.worldwidePage = "ExploreUsers";
            this.hometownPage = "HometownUser";
          }
          break;
        default:
          if (this.$store.state.latestFilterCoterie == "video") {
            this.coteriePage = "CoterieVideos";
          } else if (this.$store.state.latestFilterCoterie == "photo") {
            this.coteriePage = "CoteriePhoto";
          } else {
            this.coteriePage = "CoterieIntouch";
          }
          break;
      }
    },
    setHometownNav(setTown) {
      if (this.loggedIn) {
        if (setTown) {
          this.hometown = setTown;
        } else {
          if (this.$route.query.location != "current") {
            const logIn = this.$store.state.auth.user;
            this.hometown = logIn.location.place;
          }
        }
      } else {
        this.hometown = "Hometown";
      }
    },
    loadPages(pages) {
      if (
        (pages == "HometownVideos" && !this.loggedIn) ||
        (pages == "HometownUser" && !this.loggedIn) ||
        (pages == "CoterieVideos" && !this.loggedIn)
      ) {
        this.$router.push({ name: "Signin" });
      } else {
        let query = {};
        var keywordValue = document.querySelector("#header-search").value;
        if (keywordValue != "") {
          {
            query = { search: decodeURI(keywordValue) };
          }
        } else {
          query = {};
        }
        setTimeout(() => {
          this.$router
            .replace({
              name: pages,
              query: query,
            })
            .catch(() => {});
        }, 300);
      }
    },
  },
};
</script>
