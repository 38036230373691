import axiosInstance from "@/services/instanceService";

export default {
  namespaced: true,
  state: {
    statusReport: false,
  },
  actions: {
    /*post Reports*/
    sendReport({ commit }, { dataReport }) {
      return axiosInstance
        .post(`report/sendReport`, dataReport, { timeout: 1000 })
        .then((response) => {
          try {
            commit("statusReport", { status: true });

            return response.data;
            //return Promise.resolve(response.data);
          } catch (error) {
            commit("statusReport", { status: false });
            return Promise.reject(error);
          }
        });
    },
    reportUser({ commit }, { dataReport }) {
      return axiosInstance
        .post("report/reportUser", dataReport, { timeout: 1000 })
        .then((response) => {
          try {
            commit("statusReport", { status: true });
            return response.data;
          } catch (error) {
            commit("statusReport", { status: false });
            return Promise.reject(error);
          }
        });
    },
    /*end post Reports*/
    checkAlreadyReported(context, dataReport) {
      return axiosInstance
        .post("report/checkAlreadyReported", dataReport)
        .then((response) => {
          try {
            return response.data;
          } catch (error) {
            return Promise.reject(error);
          }
        });
    },
    sendReportMail(context, payload) {
      return axiosInstance({
        method: "POST",
        url: "report/sendReportMail/",
        data: payload,
      });
    },
  },

  mutations: {
    statusReport(state, { status }) {
      state.statusReport = status;
    },
  },
};
