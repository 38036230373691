import axiosInstance from "@/services/instanceService";

export default {
  namespaced: true,

  state: {
    reaction: false,
  },
  /*
    getters: {
      getComments: state => {
        return state.items
      },
      isAllCommentsLoaded: state => {
        return state.isAllCommentsLoaded
      }
    },
    */

  actions: {
    doReaction(context, { dataPost }) {
      return axiosInstance
        .post(`reactionPost/doReaction`, dataPost)
        .then((resp) => {
          const response = resp.data;
          return response;
        });
    },
  },
  mutations: {
    doReaction(state) {
      state.reaction = true;
    },
  },
};
