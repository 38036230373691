<template>
  <div :class="rootClass" class="load-image col-12">
    <ul :class="`${rootClass}__wrapper`" id="infinite-photo">
      <li
        class="col-lg-3 col-sm-3 text-center"
        v-for="(photos, index) in dataMediaPhotoLocal"
        :key="index"
        :class="`${rootClass}__item`"
      >
        <div
          :class="classThumbnail(singleSelected.id, photos.id, photos.disabled)"
          @click="onSelectImage(photos)"
          v-if="!isMultiple"
        >
          <img
            thumbnail
            fluid
            :src="urlMedia + thumbSize + photos.mediaThumbnail"
            :alt="'Image' + index"
            :id="photos.id"
            :height="h"
            :width="w"
            :class="`${rootClass}__img`"
          />
          <label
            :for="photos.id"
            v-if="useLabel"
            :class="`${rootClass}__lbl`"
            >{{ photos.id }}</label
          >
        </div>
        <div
          :class="classThumbnailMultiple(photos.id, photos.disabled)"
          @click="onSelectMultipleImage(photos)"
          v-if="isMultiple"
        >
          <b-img
            thumbnail
            fluid
            :src="urlMedia + thumbSize + photos.mediaThumbnail"
            :alt="'Image' + index"
            :height="h"
            :width="w"
            :class="`${rootClass}__img`"
          ></b-img>

          <label
            :for="photos.id"
            v-if="useLabel"
            :class="`${rootClass}__lbl`"
            >{{ "Image" + index }}</label
          >
        </div>
      </li>
    </ul>

    <div
      class="d-xl-none loading text-center small"
      v-if="!isAllPhotoLoaded && !loading"
    >
      <b-button variant="none" @click="loadMediaPhotos">Load more</b-button>
    </div>

    <transition name="fade">
      <div class="loading text-center" v-if="loading">
        <span class="fa fa-spinner fa-spin"></span> Loading
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "SelectMediaPhoto",
  props: {
    dataMediaPhoto: {
      type: Array,
      default: () => [],
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    urlMedia: {
      type: String,
    },
    thumbSize: {
      type: String,
    },
    isAllPhotoLoaded: {
      type: Boolean,
      default: false,
    },
    selectedImages: {
      type: Array,
      default: () => [],
    },
    h: {
      type: String,
      default: "auto",
    },
    w: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {
      singleSelected: {
        id: "",
      },
      loading: false,
      multipleSelected: [],
      limit: 0,
      activeClass: "--selected",
      rootClass: "vue-select-image",
      useLabel: false,
    };
  },
  computed: {
    dataMediaPhotoLocal: function () {
      return this.dataMediaPhoto || [];
    },
  },
  mounted() {
    this.setInitialSelection();
    // mounting for loadmore
    if (this.isAllPhotoLoaded == false) {
      const listElm = document.querySelector("#infinite-photo");
      listElm.addEventListener("scroll", () => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (!this.isAllPhotoLoaded) this.loadMediaPhotos();
        }
      });
    }
  },

  methods: {
    loadMediaPhotos() {
      this.loading = true;
      setTimeout(() => {
        this.$emit("emitLoadMediaPhotos");
        this.loading = false;
      }, 200);
    },

    classThumbnail(selectedId, imageId, isDisabled) {
      const baseClass = `${this.rootClass}__thumbnail`;
      if (isDisabled) {
        return `${baseClass} ${baseClass}--disabled`;
      }
      if (selectedId === imageId) {
        return `${baseClass} ${baseClass}${this.activeClass}`;
      }
      return `${baseClass}`;
    },
    classThumbnailMultiple(id, isDisabled) {
      const baseClass = `${this.rootClass}__thumbnail`;
      const baseMultipleClass = `${baseClass} is--multiple`;
      if (isDisabled) {
        return `${baseMultipleClass} ${baseClass}--disabled`;
      }
      if (this.isExistInArray(id)) {
        return `${baseMultipleClass} ${baseClass}${this.activeClass}`;
      }
      return `${baseMultipleClass}`;
    },
    onSelectImage(objectImage) {
      if (!objectImage.disabled) {
        this.singleSelected = Object.assign(
          {},
          this.singleSelected,
          objectImage
        );
        this.$emit("onselectimage", objectImage);
      }
    },
    isExistInArray(id) {
      return this.multipleSelected.find((item) => {
        return id === item.id;
      });
    },
    removeFromSingleSelected() {
      this.singleSelected = {};
      this.$emit("onselectimage", {});
    },

    removeFromMultipleSelected(id, dontFireEmit) {
      this.multipleSelected = this.multipleSelected.filter((item) => {
        return id !== item.id;
      });
      if (!dontFireEmit) {
        this.$emit("onselectmultipleimage", this.multipleSelected);
      }
    },
    resetMultipleSelection() {
      this.multipleSelected = [];
    },
    onSelectMultipleImage(objectImage) {
      if (!objectImage.disabled) {
        if (!this.isExistInArray(objectImage.id)) {
          if (this.limit > 0) {
            if (this.multipleSelected.length < this.limit) {
              this.multipleSelected.push(objectImage);
              this.$emit(
                "onselectmultipleimage",
                this.multipleSelected,
                "select",
                objectImage.id
              );
            }
          } else {
            this.multipleSelected.push(objectImage);
            this.$emit(
              "onselectmultipleimage",
              this.multipleSelected,
              "select",
              objectImage.id
            );
          }
        } else {
          this.removeFromMultipleSelected(objectImage.id, true);
          this.$emit(
            "onselectmultipleimage",
            this.multipleSelected,
            "remove",
            objectImage.id
          );
          this.$emit("removemultiple", objectImage.id);
        }
      }
    },
    setInitialSelection() {
      if (this.selectedImages) {
        if (!this.isMultiple && this.selectedImages.length === 1) {
          this.singleSelected = Object.assign({}, this.selectedImages[0]);
        } else {
          this.multipleSelected = [].concat(this.selectedImages);
        }
      }
    },

    /* Disable
 //for reset  if item already selected 
  onSelectImage(objectImage) {
      if (!objectImage.disabled) {
      
      if(this.singleSelected.id===objectImage.id){
       this.removeFromSingleSelected();
       this.classThumbnail(this.singleSelected.id, objectImage.id, false);
      }
      else{
      this.singleSelected = Object.assign(
          {},
          this.singleSelected,
          objectImage
        );
        this.$emit("onselectimage", objectImage);
      }      
      }
    },
 
    removeFromSingleSelected() {
      const baseClass = `${this.rootClass}__thumbnail`;
      this.singleSelected = {};
      this.$emit("onselectimage", {});
      return `${baseClass}`;
    },
*/
  },
};
</script>

<style src="../../public/css/vue-select-image.css"></style>
