import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./authModule";
import commentList from "./comments";
import reactionPost from "./reactionPost";
import notifModule from "./notification";
import intouchList from "./intouch";
import mediaList from "./media";
import showcaseList from "./showcase";
import connection from "./connection";
import photoList from "./photo";
import videoList from "./video";
import albumList from "./album";
import publicAccess from "./public";
import report from "./report";
import user from "./user";
import follow from "./follow";
import message from "./message";
import tags from "./tags";
import interest from "./interest";
import group from "./group";
import { uniqBy } from "lodash";
// ========== vuex-persistedstate ==========
// import createPersistedState from "vuex-persistedstate";
// const statePersist = createPersistedState({
//   paths: ['photoList','showcaseList','albumList','videoList','exploreLocation','currentLocation','message']
// })
// ========== vuex-persistedstate ==========

// ========== vuex-persist + localForage (for indexedDB) ==========
import createMultiTabState from "vuex-multi-tab-state";
import VuexPersistence from "vuex-persist";
import localforage from "localforage";

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "Mindcaster",
  version: 1.0,
  storeName: "vuex-key", // Should be alphanumeric, with underscores.
  description: "Initialization",
});
const vuexLocal = new VuexPersistence({
  storage: localforage,
  asyncStorage: true,
  reducer: (state) => ({
    //albumList: state.albumList,
    //photoList: state.photoList,
    // showcaseList: state.showcaseList,
    //videoList: state.videoList,

    // currentLocation: state.currentLocation,
    // currentPlace: state.currentPlace,
    exploreLocation: state.exploreLocation,
    exploreLocationForSearch: state.exploreLocationForSearch,
    exploreLocationLast: state.exploreLocationLast,
    message: {
      allMessages: state.message.allMessages,
      stateList: state.message.stateList,
      usernameMessageTarget: state.message.usernameMessageTarget,
      liveMessage: state.message.liveMessage,
    },
  }),
});
// ========== vuex-persist + localForage (for indexedDB) ==========

Vue.use(Vuex);
let initialState = {
  auth: auth.state,
  commentList: commentList,
  notifModule: notifModule.state,
  reactionPost: reactionPost.state,
  mediaList: mediaList.state,
  showcaseList: showcaseList.state,
  photoList: photoList.state,
  videoList: videoList.state,
  albumList: albumList.state,
};
export default new Vuex.Store({
  state: {
    latestUrl: "/",
    latestFilterHome: "video",
    latestFilterCoterie: "video",
    latestKeyword: "",
    latestCountry: "",
    isSpecificCountrySearch: false,
    latestIsSpecificCountrySearch: null,
    exploreLocation: "",
    exploreLocationForSearch: "",
    exploreLocationLast: "",
    exploreVideo: {
      Search: {},
    },
    exploreUser: {
      Search: {},
    },
    explorePhoto: {
      Search: {},
    },
    hometownVideo: {
      stateLocation: "",
      stateCurrentType: "",
      stateCurrent: "",
      stateFilterCurrent: "",
      current: { default: { dataList: {} }, search: { dataList: {} } },
      origin: { default: { dataList: {} }, search: { dataList: {} } },
    },
    hometownPhoto: {
      stateLocation: "",
      stateCurrentType: "",
      stateCurrent: "",
      stateFilterCurrent: "",
      current: { default: { dataList: {} }, search: { dataList: {} } },
      origin: { default: { dataList: {} }, search: { dataList: {} } },
    },
    hometownUser: {
      stateLocation: "",
      stateCurrentType: "",
      stateCurrent: "",
      stateFilterCurrent: "",
      current: { default: { dataList: {} }, search: { dataList: {} } },
      origin: { default: { dataList: {} }, search: { dataList: {} } },
    },
    isAdvanceSearchOrigin: false,
    isAdvanceSearchCurrent: false,
    coterieVideo: {
      stateCurrentType: "",
      stateCurrent: "",
      default: {
        stateList: {},
      },
      search: {
        stateList: {},
      },
    },
    coteriePhoto: {
      stateCurrentType: "",
      stateCurrent: "",
      default: {
        stateList: {},
      },
      search: {
        stateList: {},
      },
    },
    coterieIntouch: {
      stateCurrentType: "",
      stateCurrent: "",
      default: {
        stateList: {},
      },
      search: {
        stateList: {},
      },
    },
    currentLocation: [],
    currentPlace: "",
    onthegoVideo: {
      stateCurrentType: "",
      stateCurrent: "",
      stateFilterCurrent: "",
      default: {
        videoList: {},
      },
      search: {
        videoList: {},
      },
    },
    onthegoUser: {
      stateCurrentType: "",
      stateCurrent: "",
      stateFilterCurrent: "",
      default: {
        userList: {},
      },
      search: {
        userList: {},
      },
    },
    captionChange: false,
    countryChange: false,
    recentPage: {},
    relatedVideos: {
      items: [],
      startLoad: 1,
      isAllPostLoaded: false,
    },
    relatedPhotos: {
      items: [],
      startLoad: 1,
      isAllPostLoaded: false,
    },
    counterReactionLimit: {},
    counterLoveLimit: {},
    reactionCommentLimit: {},
    reactionReplyLimit: {},
    isUploadingVideo: false,
    isUploadingPhoto: false,
  },
  getters: {
    //getter explore Video
    explorerList: (state) => {
      return uniqBy(state.exploreVideo[state.exploreLocation].items, "_id");
      // return state.exploreVideo[state.exploreLocation].items;
    },
    explorerStatusLoad: (state) => {
      return state.exploreVideo[state.exploreLocation].isAllPostLoaded;
    },
    //getter explore User
    explorerUserList: (state) => {
      return uniqBy(state.exploreUser[state.exploreLocation].items, "_id");
    },
    explorerUserStatusLoad: (state) => {
      return state.exploreUser[state.exploreLocation].isAllUserLoaded;
    },
    //getter explore Photo
    explorerPhotoList: (state) => {
      return uniqBy(state.explorePhoto[state.exploreLocation].items, "_id");
    },
    explorerPhotoStatusLoad: (state) => {
      return state.explorePhoto[state.exploreLocation].isAllPhotoLoaded;
    },
    //getter hometown video
    hometownList: (state) => {
      return uniqBy(
        state.hometownVideo[state.hometownVideo.stateLocation][
          state.hometownVideo.stateCurrentType
        ].dataList[state.hometownVideo.stateCurrent].items[
          state.hometownVideo.stateFilterCurrent
        ],
        "_id"
      );
    },
    // get isAllPostLoaded true or false
    hometownStatusLoad: (state) => {
      return state.hometownVideo[state.hometownVideo.stateLocation][
        state.hometownVideo.stateCurrentType
      ].dataList[state.hometownVideo.stateCurrent].isAllDataLoaded[
        state.hometownVideo.stateFilterCurrent
      ];
      //return state.hometownVideo[state.hometownVideo].isAllPostLoaded
    },
    // getter hometown photo
    hometownPhotoList: (state) => {
      return uniqBy(
        state.hometownPhoto[state.hometownPhoto.stateLocation][
          state.hometownPhoto.stateCurrentType
        ].dataList[state.hometownPhoto.stateCurrent].items[
          state.hometownPhoto.stateFilterCurrent
        ],
        "_id"
      );
    },
    // get isAllPostLoaded true or false
    hometownPhotoStatusLoad: (state) => {
      return state.hometownPhoto[state.hometownPhoto.stateLocation][
        state.hometownPhoto.stateCurrentType
      ].dataList[state.hometownPhoto.stateCurrent].isAllDataLoaded[
        state.hometownPhoto.stateFilterCurrent
      ];
    },
    //getter hometown user
    hometownUserList: (state) => {
      return uniqBy(
        state.hometownUser[state.hometownUser.stateLocation][
          state.hometownUser.stateCurrentType
        ].dataList[state.hometownUser.stateCurrent].items[
          state.hometownUser.stateFilterCurrent
        ],
        "_id"
      );
    },
    // get isAllUserLoaded true or false
    hometownUserStatusLoad: (state) => {
      return state.hometownUser[state.hometownUser.stateLocation][
        state.hometownUser.stateCurrentType
      ].dataList[state.hometownUser.stateCurrent].isAllDataLoaded[
        state.hometownUser.stateFilterCurrent
      ];
    },
    //getter coterie video
    coterieList: (state) => {
      return uniqBy(
        state.coterieVideo[state.coterieVideo.stateCurrentType].stateList[
          state.coterieVideo.stateCurrent
        ].items,
        "_id"
      );
    },
    coterieStatusLoad: (state) => {
      return state.coterieVideo[state.coterieVideo.stateCurrentType].stateList[
        state.coterieVideo.stateCurrent
      ].isAllPostLoaded;
      //return state.hometownVideo[state.hometownVideo].isAllPostLoaded
    },
    //getter coterie Photo
    coteriePhotoList: (state) => {
      return uniqBy(
        state.coteriePhoto[state.coteriePhoto.stateCurrentType].stateList[
          state.coteriePhoto.stateCurrent
        ].items,
        "_id"
      );
    },
    coteriePhotoStatusLoad: (state) => {
      return state.coteriePhoto[state.coteriePhoto.stateCurrentType].stateList[
        state.coteriePhoto.stateCurrent
      ].isAllPostLoaded;
    },
    //getter coterie Intouch
    coterieIntouchList: (state) => {
      return uniqBy(
        state.coterieIntouch[state.coterieIntouch.stateCurrentType].stateList[
          state.coterieIntouch.stateCurrent
        ].items,
        "_id"
      );
    },
    coterieIntouchStatusLoad: (state) => {
      return state.coterieIntouch[state.coterieIntouch.stateCurrentType]
        .stateList[state.coterieIntouch.stateCurrent].isAllPostLoaded;
    },
    //getter onthego video
    onthegoList: (state) => {
      return state.onthegoVideo[state.onthegoVideo.stateCurrentType].videoList[
        state.onthegoVideo.stateCurrent
      ].items[state.onthegoVideo.stateFilterCurrent];
    },
    onthegoStatusLoad: (state) => {
      return state.onthegoVideo[state.onthegoVideo.stateCurrentType].videoList[
        state.onthegoVideo.stateCurrent
      ].isAllPostLoaded[state.onthegoVideo.stateFilterCurrent];
    },
    //getter onthego user
    onthegoUserList: (state) => {
      return state.onthegoUser[state.onthegoUser.stateCurrentType].userList[
        state.onthegoUser.stateCurrent
      ].items[state.onthegoUser.stateFilterCurrent];
    },
    // get isAllUserLoaded true or false
    onthegoUserStatusLoad: (state) => {
      return state.onthegoUser[state.onthegoUser.stateCurrentType].userList[
        state.onthegoUser.stateCurrent
      ].isAllUserLoaded[state.onthegoUser.stateFilterCurrent];
    },
    relatedAllList: (state) => {
      return state.relatedVideos.items;
    },
    relatedAllStatusLoad: (state) => {
      return state.relatedVideos.isAllPostLoaded;
    },
    relatedAllPhotoList: (state) => {
      return state.relatedPhotos.items;
    },
    relatedAllPhotoStatusLoad: (state) => {
      return state.relatedPhotos.isAllPostLoaded;
    },
  },
  mutations: {
    setIsUploadingVideo(state, payload) {
      state.isUploadingVideo = payload;
    },
    setIsUploadingPhoto(state, payload) {
      state.isUploadingPhoto = payload;
    },
    setLatestUrl(state, { payload }) {
      state.latestUrl = payload;
    },
    setIsAdvanceSearchOrigin(state, payload) {
      state.isAdvanceSearchOrigin = payload;
    },
    setIsAdvanceSearchCurrent(state, payload) {
      state.isAdvanceSearchCurrent = payload;
    },
    setCounterReaction(state, postId) {
      if (!state.counterReactionLimit[postId]) {
        Vue.set(state.counterReactionLimit, [postId], 1);
      } else {
        if (state.counterReactionLimit[postId] <= 6)
          state.counterReactionLimit[postId]++;
      }
    },
    setCounterLove(state, postId) {
      if (!state.counterLoveLimit[postId]) {
        Vue.set(state.counterLoveLimit, [postId], 1);
      } else {
        if (state.counterLoveLimit[postId] <= 6)
          state.counterLoveLimit[postId]++;
      }
    },
    setCounterReactionComment(state, commentId) {
      if (!state.reactionCommentLimit[commentId]) {
        Vue.set(state.reactionCommentLimit, [commentId], 1);
      } else {
        if (state.reactionCommentLimit[commentId] <= 6)
          state.reactionCommentLimit[commentId]++;
      }
    },
    setCounterReactionReply(state, replyId) {
      if (!state.reactionReplyLimit[replyId]) {
        Vue.set(state.reactionReplyLimit, [replyId], 1);
      } else {
        if (state.reactionReplyLimit[replyId] <= 6)
          state.reactionReplyLimit[replyId]++;
      }
    },
    setLatestFilterHome(state, { rootState, payload }) {
      state[rootState] = payload;
    },
    setCurrentLocation(state, { payload }) {
      state.currentLocation = [...payload];
    },
    setCurrentPlace(state, { payload }) {
      state.currentPlace = payload;
    },
    setCoterieState(
      state,
      { rootName, listType, contentType, filter, setState }
    ) {
      Vue.set(state[rootName][listType][contentType], [filter], setState);
    },
    resetCoterieSearch(state, { resource, remove }) {
      if (state[resource][remove].stateList)
        state[resource][remove].stateList = {};
    },
    //this mutation video home
    mergeCoterietState(
      state,
      {
        rootName,
        listType,
        contentType,
        filter,
        newgetPosts,
        isAllPostLoaded,
        tag,
      }
    ) {
      state[rootName][listType][contentType][
        filter
      ].isAllPostLoaded = isAllPostLoaded;
      state[rootName][listType][contentType][filter].items = [
        ...state[rootName][listType][contentType][filter].items,
        ...newgetPosts,
      ];
      state[rootName][listType][contentType][filter].tag = tag;
      if (!isAllPostLoaded) {
        state[rootName][listType][contentType][filter].startLoad++;
      }
    },
    // mutation all list coterie filter
    mutationCoterieReaction(
      state,
      { stateCurrent, postId, reaction, existReaction }
    ) {
      let stateType = ["default", "search"];
      let filter = ["followed", "all", "acquaintance", "friend", "bestfriend"];
      //looping stateType
      stateType.forEach((listType) => {
        if (Object.keys(state[stateCurrent][listType].stateList).length) {
          // Object.keys(state[stateCurrent][listType].stateList).length
          // );
          // default or search
          let groupState = state[stateCurrent][listType].stateList;
          //looping filter after looping default
          filter.forEach((element) => {
            if (groupState[element]) {
              //var coterieId = postId;
              let itemList = groupState[element].items;
              /*   const coterieIndex = groupState[element].items.findIndex(
                (coterie) => coterie._id === coterieId
              ); */
              itemList.forEach((list) => {
                if (list._id == postId) {
                  switch (reaction) {
                    //for like
                    case 1:
                      switch (existReaction) {
                        //check if haslike
                        case 1:
                          list.like--;
                          list.hasLike = false;
                          break;
                        case 0:
                          //check if hasDislike
                          list.disLike--;
                          list.hasDislike = false;
                          list.like++;
                          list.hasLike = true;
                          break;
                        case null:
                        default:
                          list.like++;
                          list.hasLike = true;
                          break;
                      }
                      break;
                    default:
                      //for dislike
                      switch (existReaction) {
                        //check if hasDislike
                        case 0:
                          list.disLike--;
                          list.hasDislike = false;
                          break;
                        //check if haslike
                        case 1:
                          list.like--;
                          list.hasLike = false;
                          list.disLike++;
                          list.hasDislike = true;
                          break;
                        case null:
                        default:
                          list.disLike++;
                          list.hasDislike = true;
                          break;
                      }
                      break;
                  }
                }
              });
            }
          });
        }
      });
    },
    mutationCoterieVideoLove(state, { postId, statusLove, counter }) {
      let stateType = ["default", "search"];
      let filter = ["followed", "all", "acquaintance", "friend", "bestfriend"];
      //looping stateType
      stateType.forEach((listType) => {
        if (Object.keys(state.coterieVideo[listType].stateList).length) {
          let groupState = state.coterieVideo[listType].stateList;
          //looping filter after looping default
          filter.forEach((element) => {
            if (groupState[element]) {
              //var coterieId = postId;
              let itemList = groupState[element].items;
              /*   const coterieIndex = groupState[element].items.findIndex(
                (coterie) => coterie._id === coterieId
              ); */
              itemList.forEach((list) => {
                if (list._id == postId) {
                  list.hasFavorite = statusLove;
                  list.countFavorite = list.countFavorite + counter;
                }
              });
            }
          });
        }
      });
    },
    setNewRootState(
      state,
      { rootName, listType, contentType, filter, setState }
    ) {
      Vue.set(state[rootName][listType][contentType], [filter], setState);
    },
    setNewRootStateUser(state, { rootName, listType, filter, setState }) {
      Vue.set(state[rootName][listType].userList, [filter], setState);
    },
    //this mutation video home
    mergeRootState(
      state,
      {
        rootName,
        listType,
        contentType,
        filter,
        interest,
        newgetPosts,
        isAllPostLoaded,
      }
    ) {
      state[rootName][listType][contentType][filter].isAllPostLoaded[
        interest
      ] = isAllPostLoaded;
      state[rootName][listType][contentType][filter].items[interest] = [
        ...state[rootName][listType][contentType][filter].items[interest],
        ...newgetPosts,
      ];
      //  state[rootName][listType].videoList[filter].items[interest]=[...state[rootName][listType].videoList[filter].items[interest]]
      if (!isAllPostLoaded) {
        state[rootName][listType][contentType][filter].startLoad[interest]++;
      }
    },
    //this mutation user home
    mergeRootStateUser(
      state,
      { rootName, listType, filter, interest, newgetUser, isAllUserLoaded }
    ) {
      state[rootName][listType].userList[filter].isAllUserLoaded[
        interest
      ] = isAllUserLoaded;
      state[rootName][listType].userList[filter].items[interest] = [
        ...state[rootName][listType].userList[filter].items[interest],
        ...newgetUser,
      ];
      //  state[rootName][listType].userList[filter].items[interest]=[...state[rootName][listType].userList[filter].items[interest]]
      if (!isAllUserLoaded) {
        state[rootName][listType].userList[filter].startLoad[interest]++;
      }
    },

    //this mutation photo home
    mergeRootStatePhoto(
      state,
      { rootName, listType, filter, interest, newgetPhoto, isAllPhotoLoaded }
    ) {
      state[rootName][listType].photoList[filter].isAllPhotoLoaded[
        interest
      ] = isAllPhotoLoaded;
      state[rootName][listType].photoList[filter].items[interest] = [
        ...state[rootName][listType].photoList[filter].items[interest],
        ...newgetPhoto,
      ];
      //  state[rootName][listType].photoList[filter].items[interest]=[...state[rootName][listType].photoList[filter].items[interest]]
      if (!isAllPhotoLoaded) {
        state[rootName][listType].photoList[filter].startLoad[interest]++;
      }
    },
    /*
      rootName : Video/User, stateLocation:origin/current,
      listType:search/default,
      dataList:videoList/userList,
      range:town/range,
      interest, setState
      */
    setNewHometownState(
      state,
      { rootName, stateLocation, listType, filter, setState }
    ) {
      Vue.set(
        state[rootName][stateLocation][listType].dataList,
        [filter],
        setState
      );
    },
    //reset origin/current, search,videoList/userList
    resetSearchHometown(
      state,
      { resource, stateLocation, removeType, listRemove }
    ) {
      if (state[resource][stateLocation][removeType][listRemove])
        state[resource][stateLocation][removeType][listRemove] = {};
    },
    //this mutation video home
    mergeHometownState(
      state,
      {
        rootName,
        stateLocation,
        listType,
        filter,
        interest,
        mergeState,
        isAllDataLoaded,
      }
    ) {
      state[rootName][stateLocation][listType].dataList[filter].isAllDataLoaded[
        interest
      ] = isAllDataLoaded;
      state[rootName][stateLocation][listType].dataList[filter].items[
        interest
      ] = [
        ...state[rootName][stateLocation][listType].dataList[filter].items[
          interest
        ],
        ...mergeState,
      ];
      //  state[rootName][listType].videoList[filter].items[interest]=[...state[rootName][listType].videoList[filter].items[interest]]
      if (!isAllDataLoaded) {
        state[rootName][stateLocation][listType].dataList[filter].startLoad[
          interest
        ]++;
      }
    },
    setLatestKeyword(state, { payload }) {
      state.latestKeyword = payload;
    },
    setLatestCountry(state, { payload }) {
      state.latestCountry = payload;
    },
    setIsSpecificCountrySearch(state, { payload }) {
      state.isSpecificCountrySearch = payload;
    },
    setLatestIsSpecificCountrySearch(state, payload) {
      state.latestIsSpecificCountrySearch = payload;
    },
    setExplorerLocation(state, { payload }) {
      state.exploreLocation = payload;
    },
    setExplorerLocationForSearch(state, { payload }) {
      state.exploreLocationForSearch = payload;
    },
    setLastExplorerLocation(state, { payload }) {
      state.exploreLocationLast = payload;
    },
    //set Video & User Explore state items
    setExplorerState(state, { payload, routeName }) {
      if (routeName == "ExploreUsers") {
        if (!state.exploreUser[payload] || !state.exploreUser[payload].items) {
          var setStateUser = {
            isAllUserLoaded: false,
            startLoad: 1,
            items: [],
          };
          Vue.set(state.exploreUser, [payload], setStateUser);
        }
      } else if (routeName == "ExplorePhotos") {
        if (
          !state.explorePhoto[payload] ||
          !state.explorePhoto[payload].items
        ) {
          var setStatePhoto = {
            isAllPhotoLoaded: false,
            startLoad: 1,
            items: [],
          };
          Vue.set(state.explorePhoto, [payload], setStatePhoto);
        }
      } else {
        if (
          !state.exploreVideo[payload] ||
          !state.exploreVideo[payload].items
        ) {
          var setState = {
            isAllPostLoaded: false,
            startLoad: 1,
            items: [],
          };
          Vue.set(state.exploreVideo, [payload], setState);
        }
      }
    },
    //set Video & User Explore Search state
    setExplorerStateSearch(state, { payload, routeName }) {
      if (routeName == "ExploreUsers") {
        if (state.exploreUser.Search || state.exploreUser.Search.items) {
          const setStateUserSearch = {
            isAllUserLoaded: false,
            startLoad: 1,
            items: [],
          };
          Vue.set(state.exploreUser, [payload], setStateUserSearch);
        }
      } else if (routeName == "ExplorePhotos") {
        if (state.explorePhoto.Search || state.explorePhoto.Search.items) {
          const setStatePhotoSearch = {
            isAllPhotoLoaded: false,
            startLoad: 1,
            items: [],
          };
          Vue.set(state.explorePhoto, [payload], setStatePhotoSearch);
        }
      } else {
        if (state.exploreVideo.Search || state.exploreVideo.Search.items) {
          const setState = {
            isAllPostLoaded: false,
            startLoad: 1,
            items: [],
          };
          Vue.set(state.exploreVideo, [payload], setState);
        }
      }
    },
    //mutation User Explore
    mergeUserExplore(state, { newgetUser, isAllUserLoaded }) {
      state.exploreUser[
        state.exploreLocation
      ].isAllUserLoaded = isAllUserLoaded;
      state.exploreUser[state.exploreLocation].items = [
        ...state.exploreUser[state.exploreLocation].items,
        ...newgetUser,
      ];
      state.exploreUser[state.exploreLocation].items = [
        ...state.exploreUser[state.exploreLocation].items,
      ];
      if (!isAllUserLoaded) {
        state.exploreUser[state.exploreLocation].startLoad++;
      }
    },
    mergePhotoExplore(state, { newgetPhoto, isAllPhotoLoaded }) {
      state.explorePhoto[
        state.exploreLocation
      ].isAllPhotoLoaded = isAllPhotoLoaded;
      state.explorePhoto[state.exploreLocation].items = [
        ...state.explorePhoto[state.exploreLocation].items,
        ...newgetPhoto,
      ];
      state.explorePhoto[state.exploreLocation].items = [
        ...state.explorePhoto[state.exploreLocation].items,
      ];
      if (!isAllPhotoLoaded) {
        state.explorePhoto[state.exploreLocation].startLoad++;
      }
    },
    mergeVideoExplore(state, { newgetPosts, isAllPostLoaded }) {
      state.exploreVideo[
        state.exploreLocation
      ].isAllPostLoaded = isAllPostLoaded;
      state.exploreVideo[state.exploreLocation].items = [
        ...state.exploreVideo[state.exploreLocation].items,
        ...newgetPosts,
      ];
      state.exploreVideo[state.exploreLocation].items = [
        ...state.exploreVideo[state.exploreLocation].items,
      ];
      if (!isAllPostLoaded) {
        state.exploreVideo[state.exploreLocation].startLoad++;
      }
    },
    renewStateUser(state, { resource, newuser }) {
      state[resource].user = newuser;
    },
    renewProfilePictUser(state, { resource, imgProfile }) {
      state[resource].user.imgProfile = imgProfile;
    },
    renewInterestUser(state, { resource, interest }) {
      state[resource].user.interest = interest;
    },
    renewPrivacyUser(state, { resource, userData }) {
      state[resource].user.firstname = userData.newFirstname;
      state[resource].user.firstname = userData.newLastname;
      state[resource].user.privacy_setting = userData.privacySettingData;
    },
    resetSearcExplore(state, { rootState, payload }) {
      if (state[rootState][payload]) state[rootState][payload] = {};
    },
    resetSearchRoot(state, { resource, remove }) {
      if (state[resource][remove].videoList)
        state[resource][remove].videoList = {};
    },
    resetSearchRootUser(state, { resource, remove }) {
      if (state[resource][remove].userList)
        state[resource][remove].userList = {};
    },
    setItemsNotif(state, { resource, notification }) {
      state[resource].notification = notification;
    },
    setItemsIntouch(state, { resource, usernameTarget, setState }) {
      //state[resource][usernameTarget] = setState
      Vue.set(state[resource], [usernameTarget], setState);
    },
    setItems(state, { resource, items }) {
      state[resource].items = items;
    },
    setNewPostState(
      state,
      { resource, usernameTarget, setState, refetchVideoDetail }
    ) {
      if (
        !state[resource].stateList[usernameTarget] ||
        refetchVideoDetail == true
      ) {
        Vue.set(state[resource].stateList, [usernameTarget], setState);
      }
      //state[resource][usernameTarget].stateList = setState
    },
    setConfigCoterieState(state, { resource, stateCurrentType, stateCurrent }) {
      state[resource].stateCurrentType = stateCurrentType;
      if (state[resource].stateCurrent != stateCurrent)
        state[resource].stateCurrent = stateCurrent;
    },
    hometownConfigState(
      state,
      { resource, stateLocation, listType, currentRage, setFilter }
    ) {
      state[resource].stateLocation = stateLocation;
      state[resource].stateCurrentType = listType;
      if (state[resource].stateCurrent != currentRage)
        state[resource].stateCurrent = currentRage;
      if (state[resource].stateFilterCurrent != setFilter)
        state[resource].stateFilterCurrent = setFilter;
    },
    setStateType(state, { resource, listType }) {
      state[resource].stateCurrentType = listType;
    },
    setStateLocation(state, { resource, stateLocation }) {
      state[resource].stateLocation = stateLocation;
    },
    setCurrentState(state, { resource, usernameTarget }) {
      if (state[resource].stateCurrent != usernameTarget)
        state[resource].stateCurrent = usernameTarget;
    },
    stateFilterCurrent(state, { resource, setFilter }) {
      if (state[resource].stateFilterCurrent != setFilter)
        state[resource].stateFilterCurrent = setFilter;
    },
    setFilterPost(state, { resource, setFilter, usernameTarget }) {
      state[resource].stateList[usernameTarget].filter = setFilter;
      state[resource].stateList[usernameTarget].owner = usernameTarget;
    },
    //mutation from video gallery, photo gallery, tag photo, love video, tag video & showcase
    setConfigPostState(state, { resource, usernameTarget, setFilter }) {
      if (state[resource].stateCurrent != usernameTarget)
        state[resource].stateCurrent = usernameTarget;
      if (state[resource].stateFilterCurrent != setFilter)
        state[resource].stateFilterCurrent = setFilter;
      state[resource].stateList[usernameTarget].owner = usernameTarget;
      state[resource].stateList[usernameTarget].filter = setFilter;
    },
    setConfigIntouchState(state, { resource, usernameTarget }) {
      state[resource].intouchUser = usernameTarget;
    },
    setConfigAlbumId(state, { resource, getAlbumId }) {
      state[resource].albumId = getAlbumId;
    },
    //this mutation merge photo ,video & showcase gallery
    reducePost(state, { resource, postId, stateCurrent, filter }) {
      filter.forEach((element) => {
        if (
          Object.keys(state[resource].stateList[stateCurrent].items[element])
            .length
        ) {
          let index = state[resource].stateList[stateCurrent].items[
            element
          ].findIndex((album) => album._id === postId);
          if (index >= 0) {
            state[resource].stateList[stateCurrent].items[element].splice(
              index,
              1
            );
          }
        }
      });
    },
    pushToPostState(state, { resource, filter, usernameTarget, dataPost }) {
      // prev codes 6/1/2023
      // filter.forEach((element) => {
      //   if (element == "newest") {
      //     //state[resource].stateList[usernameTarget].items[element].unshift(dataPost);
      //     state[resource].stateList[usernameTarget].items[element] = [
      //       ...dataPost,
      //       ...state[resource].stateList[usernameTarget].items[element],
      //     ];
      //   } else {
      //     state[resource].stateList[usernameTarget].items[element] = [
      //       ...state[resource].stateList[usernameTarget].items[element],
      //       ...dataPost,
      //     ];
      //     //state[resource].stateList[usernameTarget].items[element].push(dataPost);
      //   }
      // });

      // // curr codes
      if (filter == "newest") {
        //state[resource].stateList[usernameTarget].items[filter].unshift(dataPost);
        state[resource].stateList[usernameTarget].items[filter] = [
          ...dataPost,
          ...state[resource].stateList[usernameTarget].items[filter],
        ];
      } else {
        state[resource].stateList[usernameTarget].items[filter] = [
          ...state[resource].stateList[usernameTarget].items[filter],
          ...dataPost,
        ];
      }

      /*   if(resource=="videoList"){
      dispatch('generateStateShowcase', {usernameTarget,dataPost})
    } */
    },
    /*  generateStateShowcase({state,commit,dispatch},{usernameTarget,dataPost}){
      if (!state.showcaseList.stateList[usernameTarget]) {
        commit(
          "setNewPostState", {
            resource: "showcaseList",
            usernameTarget: usernameTarget,
            setState: {
              isAllPostLoaded: {
                newest: false,
                oldest: false,
                mostview: false,
                mostcomment: false,
              },
              startLoad: {
                newest: 1,
                oldest: 1,
                mostview: 1,
                mostcomment: 1
              },
              filter: "",
              owner: "",
              items: {
                newest: [],
                oldest: [],
                mostview: [],
                mostcomment: []
              },
            },
          }
        );
      }
    }, */
    mergePosts(
      state,
      { resource, newgetPosts, isAllPostLoaded, usernameTarget }
    ) {
      var setFilter = state[resource].stateList[usernameTarget].filter;
      state[resource].stateList[usernameTarget].isAllPostLoaded[
        setFilter
      ] = isAllPostLoaded;
      state[resource].stateList[usernameTarget].items[setFilter] = [
        ...state[resource].stateList[usernameTarget].items[setFilter],
        ...newgetPosts,
      ];
      state[resource].stateList[usernameTarget].items[setFilter] = [
        ...state[resource].stateList[usernameTarget].items[setFilter],
      ];
      if (!isAllPostLoaded) {
        state[resource].stateList[usernameTarget].startLoad[setFilter]++;
      }
    },
    mergeRelatedState(state, { newgetPosts, isAllPostLoaded, latestId }) {
      state.relatedVideos.isAllPostLoaded = isAllPostLoaded;
      state.relatedVideos.latestId = latestId;
      state.relatedVideos.items = [
        ...state.relatedVideos.items,
        ...newgetPosts,
      ];
      //  state[rootName][listType].videoList[filter].items[interest]=[...state[rootName][listType].videoList[filter].items[interest]]
      if (!isAllPostLoaded) {
        state.relatedVideos.startLoad++;
      }
    },
    mergeRelatedPhotoState(state, { newgetPosts, isAllPostLoaded, latestId }) {
      state.relatedPhotos.isAllPostLoaded = isAllPostLoaded;
      state.relatedPhotos.latestId = latestId;
      state.relatedPhotos.items = [
        ...state.relatedPhotos.items,
        ...newgetPosts,
      ];

      if (!isAllPostLoaded) {
        state.relatedPhotos.startLoad++;
      }
    },
    addItemToArray(state, { item, index, resource }) {
      Vue.set(state[resource].items, index, item);
    },
    addIntouchToArray(state, { item, index, resource, username }) {
      Vue.set(state[resource][username].items, index, item);
    },
    setItemsComments(state, { index, resource, items }) {
      state[resource].items[index].comments = items;
    },
    setItemsReply(state, { resource, items, index }) {
      state[resource].items[index].commentReply = items;
    },
    addReplyItemToArray(state, { item, indexReply, index, resource }) {
      state[resource].items[indexReply].commentReply[index] = item;
    },
    setIsCaptionChange(state, payload) {
      state.captionChange = payload;
    },
    setIsCountryChange(state, payload) {
      // if(state.countryChange != payload)
      state.countryChange = payload;
    },
    setRecentPage(state, payload) {
      state.recentPage = payload;
    },
    resetPersistState(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
      this.commit("resetConnectionStore");
      this.commit("resetFollowState");
    },
    resetAllHomeState(state) {
      state.exploreLocation = "WW";
      state.exploreLocationForSearch = "WW";
      state.exploreLocationLast = "WW";
      state.exploreVideo = {
        Search: {},
      };
      state.explorePhoto = {
        Search: {},
      };
      state.exploreUser = {
        Search: {},
      };
      state.hometownVideo = {
        stateLocation: "",
        stateCurrentType: "",
        stateCurrent: "",
        stateFilterCurrent: "",
        current: { default: { dataList: {} }, search: { dataList: {} } },
        origin: { default: { dataList: {} }, search: { dataList: {} } },
      };
      state.hometownPhoto = {
        stateLocation: "",
        stateCurrentType: "",
        stateCurrent: "",
        stateFilterCurrent: "",
        current: { default: { dataList: {} }, search: { dataList: {} } },
        origin: { default: { dataList: {} }, search: { dataList: {} } },
      };
      state.hometownUser = {
        stateLocation: "",
        stateCurrentType: "",
        stateCurrent: "",
        stateFilterCurrent: "",
        current: { default: { dataList: {} }, search: { dataList: {} } },
        origin: { default: { dataList: {} }, search: { dataList: {} } },
      };
      state.coterieVideo = {
        stateCurrentType: "",
        stateCurrent: "",
        default: {
          stateList: {},
        },
        search: {
          stateList: {},
        },
      };
      state.coteriePhoto = {
        stateCurrentType: "",
        stateCurrent: "",
        default: {
          stateList: {},
        },
        search: {
          stateList: {},
        },
      };
      state.coterieIntouch = {
        stateCurrentType: "",
        stateCurrent: "",
        default: {
          stateList: {},
        },
        search: {
          stateList: {},
        },
      };
      state.onthegoVideo = {};
      // state.recentPage = {};
      state.relatedVideos = {
        items: [],
        startLoad: 1,
        isAllPostLoaded: false,
      };
      state["photoList"] = {
        stateCurrent: "",
        stateFilterCurrent: "",
        stateList: {},
      };
      state["videoList"] = {
        stateCurrent: "",
        stateFilterCurrent: "",
        stateList: {},
      };
      state["intouchList"] = {
        intouchUser: "",
        latestId: null,
      };
    },
  },
  modules: {
    auth,
    commentList,
    reactionPost,
    notifModule,
    intouchList,
    showcaseList,
    connection,
    photoList,
    videoList,
    mediaList,
    publicAccess,
    report,
    user,
    follow,
    albumList,
    message,
    tags,
    interest,
    group,
  },
  plugins: [
    vuexLocal.plugin,
    createMultiTabState({
      statesPaths: [
        "state.exploreLocation",
        "state.exploreLocationLast",
        "state.message.allMessages,",
        "state.message.stateList",
        "state.message.usernameMessageTarget",
        "state.message.liveMessage",
        "recentPage",
        "auth.user",
        "auth.status",
        "auth.userToken",
      ],
    }),
  ],
  // ========== vuex-persistedstate ==========
  // plugins: [statePersist],
  // ========== vuex-persist + localForage (for indexedDB) ==========
});
