<template>
  <div class="d-flex">
    <b-modal
      centered
      size="md"
      class="col-12"
      id="upload-photo"
      dialog-class="modalUploadMedia"
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-variant="none"
      ok-title="Cancel"
    >
      <template #modal-header-close>
        <b-icon
          icon="x"
          animation="none"
          font-scale="1"
          class="ml-2 mt-n2 d-flex justify-content-start"
        ></b-icon>
      </template>
      <template #modal-title>
        <h5>Photo Upload</h5>
      </template>
      <vue-dropzone
        class="col-12"
        id="dropzone"
        ref="myVueDropzone"
        :options="dropzoneOptionsPhoto"
        :useCustomSlot="true"
        @vdropzone-sending="beforeSendPhoto"
        @vdropzone-file-added="beforeUploadPhoto"
        @vdropzone-success="afterCompletePhoto"
        @vdropzone-error="afterErrorPhoto"
      >
        <!-- @vdropzone-canceled="uploadCanceledPhoto" -->
        <div class="dropzone-custom-content">
          <b-icon
            icon="cloud-arrow-down"
            animation="none"
            font-scale="10"
            class="mr-2"
          ></b-icon>
          <h6 class="dropzone-custom-title">Drag and drop your photo here</h6>
          <div class="subtitle">or click to browse your computer</div>
        </div>
      </vue-dropzone>
      <template #modal-footer>
        <b-button v-if="file.length > 0" @click="handlePhotoDetail"
          >Back to photo</b-button
        >
      </template>
    </b-modal>
    <b-modal
      lazy
      size="sm"
      class="col-12"
      id="detail-photo"
      dialog-class="modalUploadMedia"
      no-close-on-backdrop
      no-close-on-esc
      title="Photo Upload"
    >
      <template #modal-header-close>
        <b-button size="sm" variant="none" @click="deleteAll()"
          ><b-icon
            icon="x"
            animation="none"
            font-scale="2"
            class="ml-2"
          ></b-icon>
        </b-button>
      </template>
      <b-tabs v-model="tabIndex">
        <b-tab
          v-for="(dataPhoto, i) in dataForm"
          :set="(v = $v.dataForm.$each[i])"
          :key="'dyn-tab-' + i"
        >
          <template #title>
            <div>
              <!-- if image name less than 15 characters -->
              {{ "Photo -" + (i + 1) }} ({{ stringLimit(dataPhoto.name, 10) }})
              <b-icon
                icon="x-circle"
                class="tabDelIcon"
                v-if="dataForm.length > 1"
                v-b-modal="'del-form-photo-' + i"
              ></b-icon>
            </div>
          </template>

          <b-row>
            <b-col sm="12" md="12" lg="12">
              <form-wizard
                stepSize="xs"
                title=""
                :subtitle="null"
                color="#31a3e0"
                :ref="'wizard' + i"
              >
                <template slot="footer" slot-scope="props">
                  <div class="wizard-footer-left">
                    <wizard-button
                      v-if="props.activeTabIndex > 0"
                      @click.native="props.prevTab()"
                      :style="props.fillButtonStyle"
                      >Previous</wizard-button
                    >
                  </div>
                  <div class="wizard-footer-right">
                    <wizard-button
                      v-if="!props.isLastStep"
                      @click.native="props.nextTab()"
                      class="wizard-footer-right"
                      :style="props.fillButtonStyle"
                      >Next</wizard-button
                    >
                  </div>
                </template>
                <tab-content
                  title="Photo Description"
                  :before-change="() => validateCaption(i)"
                >
                  <b-row class="d-flex justify-content-between">
                    <b-col
                      sm="12"
                      md="5"
                      class="text-center pt-4 upload-thumb-img"
                    >
                      <transition
                        v-if="showSkeletonImg && dataPhoto.mediaThumbnail"
                        name="fade"
                      >
                        <b-skeleton-img
                          no-aspect
                          height="400px"
                        ></b-skeleton-img>
                      </transition>
                      <b-img
                        v-else
                        thumbnail
                        :src="
                          dataPhoto.mediaThumbnail
                            ? filepath + '/thumbLg/' + dataPhoto.mediaThumbnail
                            : ''
                        "
                      ></b-img>
                    </b-col>
                    <b-col sm="12" md="7" class="pt-3 pl-3">
                      <b-form>
                        <b-form-group>
                          <label for="description">Caption :</label>
                          <b-form-textarea
                            :class="{
                              'is-invalid': v.mediaDescription.$error,
                            }"
                            v-model="dataPhoto.mediaDescription"
                            :value="dataPhoto.mediaDescription"
                            @change="(v) => (dataForm[i].mediaDescription = v)"
                            id="description"
                            placeholder="Your Caption"
                            rows="2"
                            max-rows="4"
                            class="borderPeopleInPicture"
                          ></b-form-textarea>
                          <div
                            class=""
                            v-if="
                              $v.dataForm.$each.$iter[i].mediaDescription.$error
                            "
                          >
                            <div
                              class="error text-danger"
                              v-if="
                                !$v.dataForm.$each.$iter[i].mediaDescription
                                  .maxLength
                              "
                            >
                              Caption cannot exceed
                              {{
                                $v.dataForm.$each.$iter[i].mediaDescription
                                  .$params.maxLength.max
                              }}
                              characters.
                            </div>
                          </div>
                        </b-form-group>
                        <!-- <hr class="mt-4" /> -->
                        <b-form-group>
                          <div class="row col mt-3">
                            <label>Who is in this Picture ?</label>
                            <b-form-checkbox
                              v-model="dataPhoto.tagMe"
                              @change="checkedMePopup($event)"
                              name="tag-me"
                              role="button"
                              class="ml-5"
                              switch
                            >
                              Include myself
                            </b-form-checkbox>
                          </div>
                          <div
                            :class="'iq-card ' + connectionBoxClass"
                            class="borderPeopleInPicture"
                          >
                            <div class="iq-card-body">
                              <div class="row">
                                <div class="col-sm-6 ml-n1">
                                  <autocomplete
                                    aria-label="Search Connections"
                                    placeholder="Search Connections"
                                    :search="searchConnection"
                                    :get-result-value="getResultValueCon"
                                    @submit="handleSubmitCast"
                                    :debounce-time="500"
                                  >
                                    <template #result="{ result, props }">
                                      <li
                                        v-bind="props"
                                        class="
                                          autocomplete-result
                                          search-result
                                          pt-2
                                          pb-2
                                        "
                                      >
                                        <b-avatar
                                          variant="primary"
                                          size="30px"
                                          class="ml-3"
                                          :text="
                                            result.firstname_con.charAt(0) +
                                            result.lastname_con.charAt(0)
                                          "
                                          :src="
                                            result.imgProfile
                                              ? `${file_path_media}${result._userIdCon}${thumbPP}${result.imgProfile}`
                                              : ''
                                          "
                                          alt="profilePicture"
                                        ></b-avatar>

                                        {{
                                          result.firstname_con +
                                          " " +
                                          result.lastname_con
                                        }}
                                      </li>
                                    </template>
                                  </autocomplete>
                                </div>
                              </div>
                              <div class="mt-4">
                                <pim-slider
                                  v-bind:key="i"
                                  :peopleInMedia="dataPhoto.cast"
                                  :filePath="file_path_media"
                                  :thumbPP="thumbPP"
                                  :allowRemove="true"
                                  @removeFromMedia="removePeopeInPicture"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="text-left">
                            <div v-if="limitCast" class="error text-danger">
                              You can't tag more than 25 people
                            </div>
                          </div>
                        </b-form-group>
                      </b-form>
                    </b-col>
                  </b-row>
                </tab-content>
                <tab-content title="Details">
                  <b-row>
                    <b-col
                      sm="12"
                      md="5"
                      class="text-center pt-4 upload-thumb-img"
                    >
                      <transition
                        v-if="showSkeletonImg && dataPhoto.mediaThumbnail"
                        name="fade"
                      >
                        <b-skeleton-img
                          no-aspect
                          height="400px"
                        ></b-skeleton-img>
                      </transition>
                      <b-img
                        v-else
                        thumbnail
                        :src="
                          dataPhoto.mediaThumbnail
                            ? filepath + '/thumbLg/' + dataPhoto.mediaThumbnail
                            : ''
                        "
                      ></b-img>
                    </b-col>
                    <b-col sm="12" md="7" class="pt-3 pl-3">
                      <b-form>
                        <b-form-group
                          class="
                            location-label
                            col-12 col-md-10 col-lg-10
                            mb-3
                            pl-0
                          "
                          label=" Where was this photo taken?"
                          label-for="location"
                        >
                          <MapBoxComponent
                            mapboxPlaceholder="Enter photo location"
                            :i="i"
                            @resulted="loadDataMapPhoto"
                            @resetMap="resetDataMap"
                          />
                        </b-form-group>

                        <b-form-group>
                          <b-form-checkbox
                            v-model="dataPhoto.showLocation"
                            :disabled="dataPhoto.enableShowLoc ? true : false"
                            name="showLocation"
                            value="1"
                            unchecked-value="0"
                          >
                            Show location
                          </b-form-checkbox>
                        </b-form-group>
                        <b-form-group>
                          <b-form-checkbox
                            id="album"
                            v-model="set_as_album"
                            name="album"
                            :value="1"
                            :unchecked-value="0"
                          >
                            Make as an Album
                          </b-form-checkbox>
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            v-if="set_as_album == 1"
                            :value="$v.album_title.$model"
                            @change="(v) => ($v.album_title.$model = v)"
                            placeholder="Enter your album title"
                          />
                          <div
                            class="error text-danger"
                            v-if="$v.album_title.$invalid"
                          >
                            Album title is required
                          </div>
                          <div
                            class="error text-danger"
                            v-if="!$v.album_title.maxLength"
                          >
                            Title cannot exceed
                            {{ $v.album_title.$params.maxLength.max }}
                            characters.
                          </div>
                        </b-form-group>
                        <b-form-group v-if="set_as_album == 1">
                          <label for="photo"> Who can see my album? </label>
                          <b-row>
                            <b-col cols="6">
                              <b-form-select
                                v-model="album_access"
                                class="mb-3 borderPeopleInPicture"
                              >
                                <template #first>
                                  <b-form-select-option
                                    selected
                                    :value="0"
                                    default
                                    >Everyone</b-form-select-option
                                  ></template
                                >
                                <b-form-select-option :value="1"
                                  >Acquaintances</b-form-select-option
                                >
                                <b-form-select-option :value="2"
                                  >Friend</b-form-select-option
                                >
                                <b-form-select-option :value="3"
                                  >Best Friend</b-form-select-option
                                >
                              </b-form-select>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-form-group>
                          <b-row>
                            <b-col class="col-sm-12 col-md-10 tags-input">
                              <label class="typo__label"
                                >Tag what's this photo about:</label
                              >
                              <div class="borderPeopleInPicture rounded">
                                <multiselect
                                  :id="'tags-editphoto-' + i"
                                  :max="7"
                                  v-model="dataTag[i].tagToEdit"
                                  tag-placeholder="Add this as new tag"
                                  placeholder="Search or add a tag here..."
                                  :hideSelected="true"
                                  :allow-empty="true"
                                  label="tagTitle"
                                  track-by="tagTitle"
                                  :options="dataTag[i].listTags"
                                  :multiple="true"
                                  :taggable="true"
                                  @tag="onCloseTags"
                                  @close="onCloseTags"
                                  :close-on-select="false"
                                  @search-change="searchTags"
                                  :max-height="0"
                                >
                                </multiselect>
                              </div>
                            </b-col>
                          </b-row>
                          <label v-if="tagLimit" class="error text-danger">
                            Tags can't be more than 7
                          </label>
                        </b-form-group>

                        <b-form-group>
                          <label for="photo"> Who can see my photo? </label>
                          <b-row>
                            <b-col cols="6">
                              <b-form-select
                                v-model="dataForm[i].mediaAccess"
                                class="mb-3 borderPeopleInPicture"
                              >
                                <template #first>
                                  <b-form-select-option
                                    selected
                                    :value="0"
                                    default
                                    >Everyone</b-form-select-option
                                  ></template
                                >
                                <b-form-select-option :value="1"
                                  >Acquaintances</b-form-select-option
                                >
                                <b-form-select-option :value="2"
                                  >Friend</b-form-select-option
                                >
                                <b-form-select-option :value="3"
                                  >Best Friend</b-form-select-option
                                >
                              </b-form-select>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <b-form-group>
                          <b-row>
                            <b-col class="col-sm-12 col-md-10 pb-1">
                              <b-form-group
                                label="Is this photo safe for kids ?"
                                v-slot="{ ariaDescribedby }"
                              >
                                <b-form-radio
                                  :class="{
                                    'is-invalid': v.safeForKids.$error,
                                  }"
                                  v-model="dataPhoto.safeForKids"
                                  :aria-describedby="ariaDescribedby"
                                  name="savekids"
                                  :value="1"
                                  >Yes, it is</b-form-radio
                                >
                                <b-form-radio
                                  :class="{
                                    'is-invalid': v.safeForKids.$error,
                                  }"
                                  v-model="dataPhoto.safeForKids"
                                  :aria-describedby="ariaDescribedby"
                                  name="savekids"
                                  :value="0"
                                  >No, it is for young adults over
                                  18</b-form-radio
                                >
                                <div
                                  class="invalid-feedback mb-3"
                                  v-if="
                                    !$v.dataForm.$each.$iter[i].safeForKids
                                      .required
                                  "
                                >
                                  Please verify if this photo is safe for
                                  younger audience
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-form>
                    </b-col>
                  </b-row>
                </tab-content>
              </form-wizard>
            </b-col>
          </b-row>

          <b-modal
            :id="'del-form-photo-' + i"
            size="sm"
            title="Delete Form"
            ok-only
            lazy
          >
            <p>Are you sure you want to delete this post ?</p>
            <template #modal-footer>
              <b-button size="sm" variant="none" @click="deleteThisTab(i)">
                Delete
              </b-button>
            </template>
          </b-modal>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <!-- <b-button size="sm" variant="none" @click="handleDelete()">
        Cancel
        </b-button> -->
        <b-button
          v-if="dataForm.length < limit"
          size="sm"
          variant="primary"
          @click.prevent="addMore"
          ><b-icon
            icon="plus-square"
            animation="none"
            font-scale="1"
            class="mr-2"
          ></b-icon>
          Add More
        </b-button>
        <b-button
          size="sm"
          :disabled="disableSubmitPhoto"
          :variant="!disableSubmitPhoto ? 'primary' : 'secondary'"
          @click.prevent="checkSubmit"
        >
          <i
            class="fa"
            :class="
              !disableSubmitPhoto ? 'fa-inbox mr-2' : 'fa-spinner fa-spin mr-2'
            "
            aria-hidden="true"
            font-scale="1"
          ></i>

          Save All
        </b-button>
      </template>
    </b-modal>
    <b-modal
      centered
      id="del-form-photo-all"
      size="sm"
      title="Delete Form"
      lazy
      ok-only
      class="m-1"
    >
      <p>Are you sure you want to delete all post ?</p>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="none" @click="cancel()"> Cancel </b-button>
        <b-button size="sm" variant="primary" @click="deleteAll()">
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PimSlider from "@/components/tools/PimSlider.vue";
import Multiselect from "vue-multiselect";
import vue2Dropzone from "vue2-dropzone";
import MapBoxComponent from "@/components/tools/MapBoxComponent.vue";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { validationMixin } from "vuelidate";
import { uniqBy, debounce } from "lodash";
import { rejectDirty, disallowSpecialChar } from "@/helpers/sanitizeString";
import {
  requiredIf,
  required,
  between,
  maxLength,
} from "vuelidate/lib/validators";
import {
  FILE_PATH,
  PHOTO_UPLOAD_LIMIT,
  EIGHTTEEN_TIMESTAMP,
  CURRENT_DATE,
  TITLE_LIMIT,
  CAPTION_LIMIT,
  WORDING_DISALLOW_SPECIAL_CHAR_MODAL_TITLE,
  WORDING_DISALLOW_SPECIAL_CHAR_MODAL_MESSAGE,
} from "../../variables";

export default {
  name: "UploadPhotoModal",
  components: {
    VueDropzone: vue2Dropzone,
    PimSlider,
    MapBoxComponent,
    Multiselect,
  },
  mixins: [validationMixin],
  data() {
    return {
      file_path_media: FILE_PATH,
      showMapError: false,
      uploadComplete: false,
      showSkeletonImg: false,
      disableSubmitPhoto: false,
      triggerIntouchPhoto: false,
      limit: 3,
      tabIndex: 0,
      set_as_album: 0,
      album_access: 0,
      file: [],
      dataTag: [],
      dataForm: [],
      connections: [],
      ageValidation: [],
      checkLocation: [],
      form: {},
      photoUser: {},
      dropzoneOptionsPhoto: {},
      thumbPP: "/80x80/",
      filepath: "",
      album_title: "",
      mapErrorText: "",
      currentFilename: "",
      currentProcessId: "",
      connectionBoxClass: "conHeightMin",
      tagLimit: false,
      limitCast: false,
    };
  },
  validations: {
    dataForm: {
      $each: {
        safeForKids: {
          required,
          between: between(0, 1),
        },
        mediaDescription: {
          maxLength: maxLength(CAPTION_LIMIT),
        },
      },
    },

    album_title: {
      maxLength: maxLength(TITLE_LIMIT),
      required: requiredIf(function (form) {
        return form.set_as_album == 1;
      }),
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    dataLogin() {
      return this.$store.state.auth.user;
    },
    userToken() {
      return this.$store.state.auth.userToken;
    },
    rejectAge() {
      const userBirthday = this.dataLogin.birthday;
      return CURRENT_DATE - userBirthday < EIGHTTEEN_TIMESTAMP ? true : false;
    },
    isUploadingPhoto() {
      return this.$store.state.isUploadingPhoto;
    },
  },

  created() {
    this.filepath = FILE_PATH + this.dataLogin.id;
    this.dropzoneOptionsPhoto = {
      url: process.env.VUE_APP_STORAGE_API + "photo/uploadPhoto",
      timeout: null,
      addRemoveLinks: true,
      uploadMultiple: false,
      thumbnailWidth: 400,
      thumbnailHeight: 400,
      maxFiles: 1,
      maxFilesize: PHOTO_UPLOAD_LIMIT,
      acceptedFiles: "image/*",
      headers: {
        Authorization: "Bearer " + this.userToken,
      },
    };
  },
  mounted() {
    window.addEventListener("unload", this.handleConfirmClosePhoto);
    window.addEventListener("beforeunload", this.handleClosePhoto);
    // this.$bvModal.show("detail-photo");
    this.$root.$on("bv::modal::shown", (modalId) => {
      if (modalId.componentId == "intouch-gallery-select") {
        this.triggerIntouchPhoto = true;
      }
    });
    this.$root.$on("bv::modal::shown", (modalId) => {
      if (modalId.componentId == "intouch-gallery-edit") {
        this.triggerIntouchPhoto = true;
      }
    });
  },
  beforeDestroy() {
    this.$route.$off("bv::modal::shown");
  },
  methods: {
    handleClosePhoto(e) {
      if (this.isUploadingPhoto) {
        e.preventDefault();
        e.returnValue = "Upload is on progress. Cancel upload?";
        return "Upload is on progress. Cancel upload?";
      }
      localStorage.setItem("isUploading", 0);
    },

    handleConfirmClosePhoto() {
      var token = JSON.parse(localStorage.getItem("userToken"));
      const payload = this.file;
      if (this.isUploadingPhoto && this.uploadComplete) {
        // uploadCanceledPhoto;
        fetch(`${process.env.VUE_APP_STORAGE_API}photo/uploadPhotoCancel`, {
          keepalive: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            photoData: payload,
          }),
        });
        localStorage.setItem("isUploading", 0);
      }
      // else if (this.isUploadingPhoto && !this.uploadComplete) {
      //   fetch(
      //     `${process.env.VUE_APP_STORAGE_API}video/deleteCanceledUploadVideo`,
      //     {
      //       keepalive: true,
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: `Bearer ${token}`,
      //       },
      //       body: JSON.stringify({
      //         videoName: this.currentFilename,
      //         processid: this.currentProcessId,
      //       }),
      //     }
      //   );
      // }
      return;
    },

    checkedMePopup: function (event) {
      if (event) {
        const dataMe = {
          _userIdCon: this.dataLogin.id,
          firstname_con: this.dataLogin.firstname,
          lastname_con: this.dataLogin.lastname,
          username_con: this.dataLogin.username,
          imgProfile: this.dataLogin.imgProfile,
        };
        this.handleSubmitCast(dataMe);
      } else {
        this.removePeopeInPicture(this.dataLogin.id);
      }
    },
    handleSubmitCast(result) {
      const currentIndex = this.tabIndex;
      const userIdCon = result._userIdCon;
      if (
        !this.dataForm[currentIndex].cast ||
        this.dataForm[currentIndex].cast.length == 0
      ) {
        this.dataForm[currentIndex].cast = [];
        this.dataForm[currentIndex].cast.push(result);
        this.limitCast = false;
      } else if (this.dataForm[currentIndex].cast.length < 25) {
        this.limitCast = false;
        this.dataForm[currentIndex].cast.forEach((el) => {
          if (el._userIdCon != userIdCon) {
            this.dataForm[currentIndex].cast.push(result);
          } else {
            const peopleIndex = this.dataForm[currentIndex].cast.findIndex(
              (people) => people._userIdCon === userIdCon
            );
            this.dataForm[currentIndex].cast[peopleIndex] = result;
          }
        });
      } else if (this.dataForm[currentIndex].cast.length >= 25) {
        this.limitCast = true;
      }
      this.dataForm[currentIndex].cast = uniqBy(
        this.dataForm[currentIndex].cast,
        "_userIdCon"
      );
      this.$forceUpdate();
    },
    removePeopeInPicture(userIdCon) {
      const index = this.tabIndex;
      if (userIdCon == this.dataLogin.id) {
        this.dataForm[index].tagMe = false;
      }
      let newPeopleInPicture = [];
      this.dataForm[index].cast.forEach((el) => {
        if (el._userIdCon != userIdCon) {
          newPeopleInPicture.push(el);
        }
      });
      this.dataForm[index].cast = newPeopleInPicture;
      this.$forceUpdate();
    },
    loadDataMapPhoto(data, mapIndex) {
      this.dataForm[mapIndex].location = {
        type: "Point",
        country: data.country,
        locality: data.locality,
        place: data.place,
        region: data.region,
        coordinates: [data.lng, data.lat],
      };
      if (this.dataForm[mapIndex].location)
        this.dataForm[mapIndex].enableShowLoc = false;
    },
    resetDataMap(data, mapIndex) {
      this.dataForm[mapIndex].showLocation = 0;
      this.dataForm[mapIndex].location = data;
      this.dataForm[mapIndex].enableShowLoc = true;
    },

    // ==================== dropzone photo related events ====================
    // eslint-disable-next-line
    beforeSendPhoto(file, xhr, formData) {
      // let splitFilename = file.name.split(".");
      // let fileExt = splitFilename[splitFilename.length - 1];
      // let filename = `${Date.now()}_${this.dataLogin.id}.${fileExt}`;
      // let processid = `${Date.now()}_${this.dataLogin.id}`;
      // xhr.setRequestHeader("filename", filename);
      // xhr.setRequestHeader("processid", processid);
      // this.currentFilename = filename;
      // this.currentProcessId = processid;
    },

    beforeUploadPhoto() {
      this.$store.commit("setIsUploadingPhoto", true);
      localStorage.setItem("isUploading", 0);
    },

    // uploadCanceledPhoto() {
    //   // this.$refs.vidDropzone.removeAllFiles(true);
    //   // setTimeout(() => {
    //   this.$store
    //     .dispatch("videoList/deleteCanceledUploadVideo", {
    //       videoName: this.currentFilename,
    //       processid: this.currentProcessId,
    //     })
    //     .then(() => {
    //       this.currentFilename = "";
    //       this.currentProcessId = "";
    //       this.showConvertLoading = false;
    //       this.$store.commit("setIsUploadingPhoto", false);
    //       this.uploadComplete = false;
    //     });
    //   // }, 2000);
    // },

    afterCompletePhoto(_, response) {
      this.uploadComplete = true;

      this.file.push({
        name: response.data.name ? response.data.name : "---",
        mediaThumbnail: response.data.filenameUpload,
      });
      this.dataForm.push({
        enableShowLoc: true,
        mediaAccess: this.$store.state.auth.user.privacy_setting.posts_show,
        mediaThumbnail: response.data.filenameUpload,
        mediaType: 2,
        showLocation: 0,
        safeForKids: "",
        tag: [],
        name: response.data.name,
      });
      this.dataTag.push({
        tagToEdit: [],
        listTags: [],
      });
      var tabLength = this.dataForm.length;
      if (tabLength > 0) {
        this.tabIndex = tabLength - 1;
      }
      this.showSkeletonImg = true;
      this.$bvModal.show("detail-photo");
      localStorage.setItem("isUploading", 1);
      setTimeout(() => {
        if (response.data.filenameUpload) {
          this.showSkeletonImg = false;
        }
      }, 500);
    },

    afterErrorPhoto(_file) {
      let textError = "";
      var fileSize = _file.size;
      var fileType = _file.type;
      var checkLImit = PHOTO_UPLOAD_LIMIT * 1024 * 1024;
      if (!fileType.startsWith("image")) {
        textError = `Upload Error - No image file found`;
      } else if (checkLImit < fileSize) {
        textError = `File size exceeds ${PHOTO_UPLOAD_LIMIT} MB limit`;
      } else {
        textError = `There’s a problem with the connection`;
      }
      _file.previewElement.querySelectorAll(
        "#upload-photo .dz-error-message span"
      )[0].textContent = textError;
      localStorage.setItem("isUploading", 0);
    },
    // ==================== dropzone photo related events ====================

    changeConnectionClass() {
      this.connectionBoxClass = "conHeightMax";
    },
    async deleteThisTab(idx) {
      const payload = [];
      await payload.push(this.file[idx]);
      this.file = this.file.filter((el) => el.name !== this.dataForm[idx].name);
      this.$store
        .dispatch("photoList/cancelUploadPhoto", payload)
        .then(() => {
          this.dataForm.splice(idx, 1);
          //  / this.file.splice(idx, 1);
          var tabLength = this.dataForm.length;
          if (tabLength > 0) {
            this.tabIndex = tabLength - 1;
          }

          const idDelForm = "del-form-photo-" + idx;
          this.$refs.myVueDropzone.removeAllFiles();
          this.$bvModal.hide(idDelForm);
        })
        .catch(() => {
          // console.log(err)
        });
    },
    addMore() {
      if (this.dataForm.length <= this.limit) {
        this.$refs.myVueDropzone.removeAllFiles();
        this.$bvModal.hide("detail-photo");
      }
    },

    searchTags: debounce(function () {
      this.dataTag[this.tabIndex].listTags = [
        ...this.dataForm[this.tabIndex].tag,
      ];
    }, 500),

    /*     searchTags: debounce(function (text) {
      if (text.length > 0) {
        this.$store.dispatch("tags/searchTags", text).then((response) => {
          this.dataTag[this.tabIndex].listTags = [...response.data.result];
        });
      } else {
        this.dataTag[this.tabIndex].listTags = [
          ...this.dataForm[this.tabIndex].tag,
        ];
      }
    }, 500), */
    onCloseTags() {
      // var tagEl = this.$el.querySelector("#tags-input");
      var tagEl = document.getElementById("tags-editphoto-" + this.tabIndex);
      if (
        this.dataTag[this.tabIndex].tagToEdit.length +
          tagEl.value.split(" ").length >
          7 &&
        tagEl.value.trim() != ""
      ) {
        // console.log(tagEl.value.split(" ").length + this.dataForm.tags.length);
        this.tagLimit = true;
      } else {
        this.tagLimit = false;
      }
      if (tagEl.value !== "") {
        this.addTags(tagEl.value);
      }
    },
    addTags(inputedTag) {
      if (disallowSpecialChar(inputedTag)) {
        return this.handleModal();
      }

      const currentIndex = this.tabIndex;
      //to split inputed tag into array, to remove 'space' characters
      var splitTags = inputedTag.toLowerCase().split(" ");
      if (splitTags.length + this.dataTag[currentIndex].tagToEdit.length > 7) {
        let num = 7 - this.currentTags.length;
        splitTags = splitTags.slice(0, num);
      }
      for (var index = 0; index < splitTags.length; index++) {
        //to capitalize the first character in array of strings
        splitTags[index] =
          splitTags[index].charAt(0).toUpperCase() + splitTags[index].substr(1);
      }
      for (var i = 0; i < this.dataTag[currentIndex].tagToEdit.length; i++) {
        //to filter the same string
        splitTags = splitTags.filter(
          (item) =>
            item.toLowerCase() !==
              this.dataTag[currentIndex].tagToEdit[i].tagTitle.toLowerCase() &&
            item !== ""
        );
      }
      splitTags = [...new Set(splitTags)];
      for (var j = 0; j < splitTags.length; j++) {
        //to filter null string
        splitTags = splitTags.filter((item) => item !== "");
      }

      splitTags.forEach(async (tagI) => {
        const response = await rejectDirty(tagI);
        if (response) {
          var newTag = {
            _id: null,
            _tagId: null,
            tagTitle: tagI,
          };
          this.dataTag[this.tabIndex].tagToEdit.push(newTag);
          this.dataTag[this.tabIndex].listTags.push(newTag);

          /*  this.dataForm[this.tabIndex].tag = [
            ...this.dataTag[this.tabIndex].tagToEdit,
          ]; */
          const tagToEdit = this.dataTag[this.tabIndex].tagToEdit;
          this.dataForm[this.tabIndex].tag = [...tagToEdit];
        }
      });
    },
    handleModal() {
      this.$bvModal
        .msgBoxOk(WORDING_DISALLOW_SPECIAL_CHAR_MODAL_MESSAGE, {
          title: WORDING_DISALLOW_SPECIAL_CHAR_MODAL_TITLE,
          okTitle: "OK",
          cancelDisabled: true,
          buttonSize: "sm",
          bodyClass: "ml-4 py-4",
          hideHeaderClose: false,
          centered: true,
        })
        .then(() => {
          return;
        })
        .catch(() => {
          // console.log(err);
        });
    },

    handleDelete() {
      this.$bvModal.show("del-form-photo-all");
    },
    deleteAll() {
      const payload = this.file;
      this.$store
        .dispatch("photoList/cancelUploadPhoto", payload)
        .then(() => {
          this.$refs.myVueDropzone.removeAllFiles();
          this.$bvModal.hide("detail-photo");
          this.$bvModal.hide("upload-photo");
          this.$bvModal.hide("del-form-photo-all");
          this.stateReset();
          this.uploadComplete = false;
          this.$store.commit("setIsUploadingPhoto", false);
          localStorage.setItem("isUploading", 0);
        })
        .catch(() => {
          // console.log(err)
        });
    },
    validateCaption(i) {
      this.$v.dataForm.$each.$iter[i].mediaDescription.$touch();
      const isValid =
        !this.$v.dataForm.$each.$iter[i].mediaDescription.$invalid;
      return isValid;
    },

    checkSubmit() {
      //  console.log(e);
      this.$v.$touch();
      // check age validation by looping

      for (let i = 0; i < this.dataForm.length; i++) {
        this.tabIndex = i;
        const ref = "wizard" + i;
        if (this.$v.dataForm.$each.$iter[i].mediaDescription.$anyError) {
          if (this.$refs[ref][0].activeTabIndex > 0) {
            this.$refs[ref][0].prevTab();
          }
          return;
        }

        if (this.$v.dataForm.$each.$iter[i].safeForKids.$anyError) {
          this.$refs[ref][0].nextTab();
          return;
        }
        //check age
        if (this.dataForm[i].safeForKids === 0 && this.rejectAge) {
          this.$refs[ref][0].nextTab();
          this.toastAge("b-toaster-bottom-right");
          return;
        }
        //check Age caster
        if ("cast" in this.dataForm[i]) {
          for (let j = 0; j < this.dataForm[i].cast.length; j++) {
            if (
              this.dataForm[i].safeForKids === 0 &&
              CURRENT_DATE - this.dataForm[i].cast[j].birthday_con <
                EIGHTTEEN_TIMESTAMP
            ) {
              this.$refs[ref][0].nextTab();
              this.toastCast(
                "b-toaster-bottom-right",
                this.dataForm[i].cast[j].username_con
              );
              return;
            }
          }
        }
      }
      // stop here if form is invalid
      if (this.$v.dataForm.$anyError) {
        return;
      }
      this.doSubmitForm();
    },
    doSubmitForm() {
      this.$v.$touch();
      this.$v.album_title.$touch();
      if (this.$v.album_title.$anyError) {
        return;
      }
      this.photoUser = {
        username: this.dataLogin.username,
        userId: this.dataLogin.id,
      };

      this.disableSubmitPhoto = true;
      let data = {
        payload: this.dataForm,
        photoUser: this.photoUser,
      };
      if (this.$route.params.filter) {
        data.filter = this.$route.params.filter;
      }
      if (this.set_as_album === 0) {
        this.$store
          .dispatch("photoList/submitPhoto", data)
          .then((response) => {
            this.disableSubmitPhoto = false;
            sessionStorage.clear();
            this.$refs.myVueDropzone.removeAllFiles();
            this.$bvModal.hide("detail-photo");
            this.$bvModal.hide("upload-photo");
            this.stateReset();
            this.uploadComplete = false;
            this.$store.commit("setIsUploadingPhoto", false);

            //return data to intouch form
            if (this.triggerIntouchPhoto) {
              const photos = response;
              // emit to intouch wall if upload from create / edit intouch
              this.$root.$emit("pushToPhoto", photos);
            }
            localStorage.setItem("isUploading", 0);
          })
          .catch(() => {
            // console.log(err);
            this.disableSubmitPhoto = false;
            this.stateReset();
          });
      } else {
        const payload = {
          album_title: this.album_title,
          album_access: this.album_access,
          dataForm: this.dataForm,
        };
        this.$store
          .dispatch("photoList/submitPhotoAlbum", payload)
          .then(() => {
            this.disableSubmitPhoto = false;
            this.$refs.myVueDropzone.removeAllFiles();
            this.$bvModal.hide("detail-photo");
            this.$bvModal.hide("upload-photo");
            this.stateReset();
            this.uploadComplete = false;
            this.$store.commit("setIsUploadingPhoto", false);
            localStorage.setItem("isUploading", 0);
          })
          .catch(() => {
            // console.log(err);
            this.disableSubmitPhoto = false;
            this.stateReset();
          });
      }
    },
    handlePhotoDetail() {
      this.$bvModal.show("detail-photo");
    },
    stateReset() {
      this.tabIndex = 0;
      this.dataTag = [];
      this.dataForm = [];
      this.file = [];
      this.album_access = 0;
      this.album_title = "";
      this.set_as_album = 0;
    },
  },
};
</script>

<style>
.borderPeopleInPicture {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(114, 113, 113);
}
.vue-dropzone > .dz-preview .dz-details {
  bottom: 0;
  top: 0;
  color: #fff;
  background-color: var(--iq-secondary);
  transition: opacity 0.2s linear;
  text-align: left;
}
.vue-dropzone > .dz-preview .dz-image,
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
}
.dropzone .dz-preview {
  position: relative;
  display: contents;
  vertical-align: baseline;
  margin: 16px;
  min-height: 300px;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
  width: 90%;
}
.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 10em;
  height: 10em;
}
.vue-dropzone {
  font-family: "Libre Franklin", sans-serif !important;
}
</style>
