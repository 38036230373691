import axiosInstance, { axiosDefault } from "@/services/instanceService";

export default {
  state: {
    currentTargetUsername: "",
    connectionFilter: "",
    isSelf: false,
    // For connections list of other user
    otherConnectionList: {},
    // For connections list of logged in user
    connectionsList: [],
    acquaintanceList: [],
    friendList: [],
    bestFriendList: [],
    searchedList: [],
    // No more data to fetch
    noMoreConnection: false,
    noMoreAcquaintance: false,
    noMoreFriend: false,
    noMoreBestFriend: false,
    noMoreSearched: false,
    // Start load
    startLoadConnection: 0,
    startLoadAcquaintance: 0,
    startLoadFriend: 0,
    startLoadBestFriend: 0,
    startLoadSearched: 0,
    // For manipulate skip and limit when connection deleted
    // Load limit, must same as numberOfLoadedConnection
    loadLimitAll: 24,
    loadLimitAcquaintance: 24,
    loadLimitFriend: 24,
    loadLimitBestFriend: 24,
    loadLimitSearched: 24,
    // Skip / Limit changes
    allSkipLimitChanges: 0,
    acquaintaceSkipLimitChanges: 0,
    friendSkipLimitChanges: 0,
    bestFriendSkipLimitChanges: 0,
    searchedSkipLimitChanges: 0,
    // For conditional in friend page
    numberOfLoadedConnection: 24,
    loadMoreConnection: false,
    connectionsIsLoading: false,
    loadMoreIsLoading: false,
    userSearched: "",
    refetch: false,
    // No Connection found
    noConnectionFound: false,
    noAllTypeFound: false,
    noAcquaintanceFound: false,
    noFriendFound: false,
    noBestFriendFound: false,
    searchNotFound: false,
    // for friend request page
    currentConnectionPage: "",
    // for incoming connection request
    connectionRequestList: [],
    connectionRequestIsLoading: false,
    startLoadRequest: 0,
    numberOfLoadedConReq: 24,
    loadLimitConRequest: 24,
    conReqSkipLimitChanges: 0,
    noMoreConnectionRequest: false,
    noConnectionRequest: false,
    checkConnectionRequest: false,
    connectionRequestSearch: "",
    requestSearchNotFound: false,
    // for manipulating state when connection data changed
    removedConnectionsIndexListAll: [], // to store all removed all connection index, used in unfriend and block
    removedConnectionsIndexListAcquaintance: [],
    removedConnectionsIndexListFriend: [],
    removedConnectionsIndexListBestFriend: [],
    deletedConnectionSum: 0,
    // for block page
    blockState: {
      blockList: [],
      noMoreBlockedUsers: false,
      startLoadBlock: 0,
      numberOfLoadedBlock: 24,
    },
    isBlockLoading: {
      content: false,
      footer: false,
    },
  },

  mutations: {
    // for reseting connection store when logout
    resetConnectionStore(state) {
      state.connectionFilter = "";

      state.otherConnectionList = {};

      state.connectionsList = [];
      state.acquaintanceList = [];
      state.friendList = [];
      state.bestFriendList = [];
      state.searchedList = [];

      state.noMoreConnection = false;
      state.noMoreAcquaintance = false;
      state.noMoreFriend = false;
      state.noMoreBestFriend = false;
      state.noMoreSearched = false;

      state.startLoadConnection = 0;
      state.startLoadAcquaintance = 0;
      state.startLoadFriend = 0;
      state.startLoadBestFriend = 0;
      state.startLoadSearched = 0;

      state.loadLimitAll = 24;
      state.loadLimitAcquaintance = 24;
      state.loadLimitFriend = 24;
      state.loadLimitBestFriend = 24;
      state.loadLimitSearched = 24;

      state.allSkipLimitChanges = 0;
      state.acquaintaceSkipLimitChanges = 0;
      state.friendSkipLimitChanges = 0;
      state.bestFriendSkipLimitChanges = 0;
      state.searchedSkipLimitChanges = 0;

      state.loadMoreConnection = false;
      state.connectionsIsLoading = false;
      state.loadMoreIsLoading = false;
      state.userSearched = "";
      state.refetch = false;

      state.noConnectionFound = false;
      state.noAllTypeFound = false;
      state.noAcquaintanceFound = false;
      state.noFriendFound = false;
      state.noBestFriendFound = false;
      state.searchNotFound = false;

      state.currentConnectionPage = "";

      // for incoming connection request
      state.connectionRequestList = [];
      state.connectionRequestIsLoading = false;
      state.startLoadRequest = 0;
      state.numberOfLoadedConReq = 24;
      state.loadLimitConRequest = 24;
      state.conReqSkipLimitChanges = 0;
      state.noMoreConnectionRequest = false;
      state.noConnectionRequest = false;
      state.checkConnectionRequest = false;
      state.connectionRequestSearch = "";
      state.requestSearchNotFound = false;
    },
    setCurrentTargetUsername(state, payload) {
      state.currentTargetUsername = payload;
    },
    setConnectionFilter(state, payload) {
      state.connectionFilter = payload;
    },
    setIsSelf(state, payload) {
      state.isSelf = payload;
    },
    // for connections list of other user
    addNewUsernameOtherConnection(state, username) {
      state.otherConnectionList[username] = {
        ...{
          list: [],
          // for loadmore following and follower
          startLoad: 0,
          loadLimit: 24,
          noMoreData: false,
        },
      };
    },

    setOtherConnectionList(state, data) {
      state.otherConnectionList[state.currentTargetUsername].list = [
        ...state.otherConnectionList[state.currentTargetUsername].list,
        ...data.connectionData,
      ];
      state.otherConnectionList[state.currentTargetUsername].startLoad =
        state.otherConnectionList[state.currentTargetUsername].startLoad +
        state.otherConnectionList[state.currentTargetUsername].loadLimit;
      state.otherConnectionList[state.currentTargetUsername].noMoreData =
        data.noMoreData;

      state.otherConnectionList = { ...state.otherConnectionList };
    },

    // for connections list of logged in user
    setConnectionList(state, payload) {
      state.connectionsList = [...state.connectionsList, ...payload];
    },
    setAcquaintanceList(state, payload) {
      state.acquaintanceList = [...state.acquaintanceList, ...payload];
    },
    setFriendList(state, payload) {
      state.friendList = [...state.friendList, ...payload];
    },
    setBestFriendList(state, payload) {
      state.bestFriendList = [...state.bestFriendList, ...payload];
    },
    setSearchedList(state, payload) {
      state.searchedList = [...state.searchedList, ...payload];
    },
    clearConnectionAllList(state) {
      state.connectionsList = [];
    },
    clearAcquaintanceList(state) {
      state.acquaintanceList = [];
    },
    clearFriendList(state) {
      state.friendList = [];
    },
    clearBestFriendList(state) {
      state.bestFriendList = [];
    },

    updateAnElementInConnectionList(state, payload) {
      const { index, type, data } = payload;
      switch (type) {
        case 0:
          state.connectionsList.splice(index, 1, data);
          break;
        case 1:
          state.acquaintanceList.splice(index, 1, data);
          break;
        case 2:
          state.friendList.splice(index, 1, data);
          break;
        case 3:
          state.bestFriendList.splice(index, 1, data);
          break;
        default:
          break;
      }
    },
    removeAnElementInConnectionList(state, payload) {
      const { index, type } = payload;
      switch (type) {
        case 0:
          state.connectionsList.splice(index, 1);
          break;
        case 1:
          state.acquaintanceList.splice(index, 1);
          break;
        case 2:
          state.friendList.splice(index, 1);
          break;
        case 3:
          state.bestFriendList.splice(index, 1);
          break;
        default:
          break;
      }
    },
    clearConnectionsList(state) {
      state.connectionsList = [];
      state.acquaintanceList = [];
      state.friendList = [];
      state.bestFriendList = [];
    },
    clearSearchedList(state) {
      state.searchedList = [];
    },

    setNoMoreConnection(state, payload) {
      state.noMoreConnection = payload;
    },
    setNoMoreAcquaintance(state, payload) {
      state.noMoreAcquaintance = payload;
    },
    setNoMoreFriend(state, payload) {
      state.noMoreFriend = payload;
    },
    setNoMoreBestFriend(state, payload) {
      state.noMoreBestFriend = payload;
    },
    setNoMoreSearched(state, payload) {
      state.noMoreSearched = payload;
    },
    clearNoMoreConnection(state) {
      state.noMoreConnection = false;
      state.noMoreAcquaintance = false;
      state.noMoreFriend = false;
      state.noMoreBestFriend = false;
      state.noMoreSearched = false;
    },

    setStartLoadConnection(state, payload) {
      state.startLoadConnection = payload;
    },
    setStartLoadAcquaintance(state, payload) {
      state.startLoadAcquaintance = payload;
    },
    setStartLoadFriend(state, payload) {
      state.startLoadFriend = payload;
    },
    setStartLoadBestFriend(state, payload) {
      state.startLoadBestFriend = payload;
    },
    setStartLoadSearched(state, payload) {
      state.startLoadSearched = payload;
    },
    clearStartLoad(state) {
      state.startLoadConnection = 0;
      state.startLoadAcquaintance = 0;
      state.startLoadFriend = 0;
      state.startLoadBestFriend = 0;
    },

    setLoadLimitAll(state, payload) {
      state.loadLimitAll = payload;
    },
    setLoadLimitAcquaintance(state, payload) {
      state.loadLimitAcquaintance = payload;
    },
    setLoadLimitFriend(state, payload) {
      state.loadLimitFriend = payload;
    },
    setLoadLimitBestFriend(state, payload) {
      state.loadLimitBestFriend = payload;
    },
    setLoadLimitSearched(state, payload) {
      state.loadLimitSearched = payload;
    },

    setAllSkipLimitChanges(state, payload) {
      state.allSkipLimitChanges = payload;
    },
    setAcquaintaceSkipLimitChanges(state, payload) {
      state.acquaintaceSkipLimitChanges = payload;
    },
    setFriendSkipLimitChanges(state, payload) {
      state.friendSkipLimitChanges = payload;
    },
    setBestFriendSkipLimitChanges(state, payload) {
      state.bestFriendSkipLimitChanges = payload;
    },
    setSearchedSkipLimitChanges(state, payload) {
      state.searchedSkipLimitChanges = payload;
    },

    // for conditional in friend page
    setConnectionsIsLoading(state, payload) {
      state.connectionsIsLoading = payload;
    },
    setLoadMoreConnection(state, payload) {
      state.loadMoreConnection = payload;
    },
    setLoadMoreIsLoading(state, payload) {
      state.loadMoreIsLoading = payload;
    },
    setNoConnectionFound(state, payload) {
      state.noConnectionFound = payload;
    },
    setNoAllTypeFound(state, payload) {
      state.noAllTypeFound = payload;
    },
    setNoAcquaintanceFound(state, payload) {
      state.noAcquaintanceFound = payload;
    },
    setNoFriendFound(state, payload) {
      state.noFriendFound = payload;
    },
    setNoBestFriendFound(state, payload) {
      state.noBestFriendFound = payload;
    },
    setSearchNotFound(state, payload) {
      state.searchNotFound = payload;
    },

    setUserSearched(state, payload) {
      state.userSearched = payload;
    },
    setRefetch(state, payload) {
      state.refetch = payload;
    },

    resetAllConnectionStatesToInitial(state) {
      state.connectionsList = [];
      state.acquaintanceList = [];
      state.friendList = [];
      state.bestFriendList = [];
      state.connectionFilter = "";
      state.connectionsIsLoading = false;
      state.startLoadConnection = 0;
      state.startLoadAcquaintance = 0;
      state.startLoadFriend = 0;
      state.startLoadBestFriend = 0;
      state.noMoreConnection = false;
      state.noMoreAcquaintance = false;
      state.noMoreFriend = false;
      state.noMoreBestFriend = false;
      state.loadMoreConnection = true;
      state.noConnectionFound = false;
      state.userSearched = "";
      state.searchNotFound = false;
      state.refetch = true;
    },

    resetConnectionStatesToInitial(state) {
      state.connectionsList = [];
      state.connectionsIsLoading = false;
      state.startLoadConnection = 0;
      state.noMoreConnection = false;
      state.loadMoreConnection = true;
      state.noConnectionFound = false;
      state.userSearched = "";
      state.searchNotFound = false;
      state.refetch = true;
    },
    resetAcquaintanceStatesToInitial(state) {
      state.acquaintanceList = [];
      state.connectionsIsLoading = false;
      state.startLoadAcquaintance = 0;
      state.noMoreAcquaintance = false;
      state.loadMoreConnection = true;
      state.noConnectionFound = false;
      state.userSearched = "";
      state.searchNotFound = false;
      state.refetch = true;
    },
    resetFriendStatesToInitial(state) {
      state.friendList = [];
      state.connectionsIsLoading = false;
      state.startLoadFriend = 0;
      state.noMoreFriend = false;
      state.loadMoreConnection = true;
      state.noConnectionFound = false;
      state.userSearched = "";
      state.searchNotFound = false;
      state.refetch = true;
    },
    resetBestFriendStatesToInitial(state) {
      state.bestFriendList = [];
      state.connectionsIsLoading = false;
      state.startLoadBestFriend = 0;
      state.noMoreBestFriend = false;
      state.loadMoreConnection = true;
      state.noConnectionFound = false;
      state.userSearched = "";
      state.searchNotFound = false;
      state.refetch = true;
    },

    // for incoming connection request
    // list = connection list page, request = connection request page
    setCurrentConnectionPage(state, payload) {
      state.currentConnectionPage = payload;
    },
    setConnectionRequestList(state, payload) {
      state.connectionRequestList = [
        ...state.connectionRequestList,
        ...payload,
      ];
    },
    clearConnectionRequestList(state) {
      state.connectionRequestList = [];
    },
    deleteOneConnectionRequest(state, index) {
      state.connectionRequestList.splice(index, 1);
    },
    setConnectionRequestIsLoading(state, payload) {
      state.connectionRequestIsLoading = payload;
    },
    setStartLoadRequest(state, payload) {
      state.startLoadRequest = payload;
    },
    setLoadLimitConRequest(state, payload) {
      state.loadLimitConRequest = payload;
    },
    setConReqSkipLimitChanges(state, payload) {
      state.conReqSkipLimitChanges = payload;
    },
    setNoMoreConnectionRequest(state, payload) {
      state.noMoreConnectionRequest = payload;
    },
    setNoConnectionRequest(state, payload) {
      state.noConnectionRequest = payload;
    },
    setCheckConnectionRequest(state, payload) {
      state.checkConnectionRequest = payload;
    },
    resetConReqStatesToInitial(state) {
      state.startLoadRequest = 0;
      state.noMoreConnectionRequest = false;
      state.noConnectionRequest = false;
      state.connectionRequestSearch = "";
      state.requestSearchNotFound = false;
    },

    // For manipulating state when connection data changed
    // Saving the index of deleted connection
    setRemovedConnectionsIndexListAll(state, payload) {
      state.removedConnectionsIndexListAll = [
        ...state.removedConnectionsIndexListAll,
        ...payload,
      ];
    },
    setRemovedConnectionsIndexListAcquaintance(state, payload) {
      state.removedConnectionsIndexListAcquaintance = [
        ...state.removedConnectionsIndexListAcquaintance,
        ...payload,
      ];
    },
    setRemovedConnectionsIndexListFriend(state, payload) {
      state.removedConnectionsIndexListFriend = [
        ...state.removedConnectionsIndexListFriend,
        ...payload,
      ];
    },
    setRemovedConnectionsIndexListBestFriend(state, payload) {
      state.removedConnectionsIndexListBestFriend = [
        ...state.removedConnectionsIndexListBestFriend,
        ...payload,
      ];
    },
    // Deleting data in state
    deleteDataInList(state, payload) {
      let connectionTypeList;
      let indexArray;
      switch (payload) {
        case 0:
          connectionTypeList = state.connectionsList;
          indexArray = state.removedConnectionsIndexListAll;
          break;
        case 1:
          connectionTypeList = state.acquaintanceList;
          indexArray = state.removedConnectionsIndexListAcquaintance;
          break;
        case 2:
          connectionTypeList = state.friendList;
          indexArray = state.removedConnectionsIndexListFriend;
          break;
        case 3:
          connectionTypeList = state.bestFriendList;
          indexArray = state.removedConnectionsIndexListBestFriend;
          break;
        default:
          break;
      }
      indexArray.sort(function(a, b) {
        return b - a;
      });

      for (let i = 0; i < indexArray.length; i++) {
        const index = indexArray[i];
        connectionTypeList.splice(index, 1);
      }
    },
    setDeletedConnectionSum(state) {
      state.deletedConnectionSum += 1;
    },
    resetDeletedConnectionSum(state) {
      state.deletedConnectionSum = 0;
    },

    // For block related
    setBlockList(state, payload) {
      if (!payload.blockList.length) {
        state.blockState.noMoreBlockedUsers = true;
      } else {
        state.blockState.blockList = [
          ...state.blockState.blockList,
          ...payload.blockList,
        ];
        state.blockState.noMoreBlockedUsers = payload.noMoreData;
        state.blockState.startLoadBlock += payload.blockList.length;
      }
      state.blockState = { ...state.blockState };
    },
    resetBlockData(state) {
      state.blockState.blockList = [];
      state.blockState.noMoreBlockedUsers = false;
      state.blockState.startLoadBlock = 0;
    },
    removeFromBlockListState(state, index) {
      state.blockState.blockList.splice(index, 1),
        (state.blockState = { ...state.blockState });
    },
    setIsBlockLoadingContent(state, payload) {
      state.isBlockLoading.content = payload;
    },
    setIsBlockLoadingFooter(state, payload) {
      state.isBlockLoading.footer = payload;
    },
  },

  actions: {
    reqConnectionLoad({ state }, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionRequestLoad`,
        data: data,
      })
        .then((data) => {
          // this.commit('setConnectionRequestList', data.data.connectionData)
          if (
            !data.data.connectionData.length &&
            data.data.message === "Connection request not found."
          ) {
            this.commit("setNoConnectionRequest", true);
            this.commit("setNoMoreConnectionRequest", data.data.noMoreData);
          }
          this.commit("setNoMoreConnectionRequest", data.data.noMoreData);
          this.commit("setConReqSkipLimitChanges", 0);
          this.commit("setLoadLimitConRequest", state.numberOfLoadedConReq);

          setTimeout(() => {
            this.commit("setConnectionRequestIsLoading", false);
            this.commit("setConnectionRequestList", data.data.connectionData);
          }, 400);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    reqConnection(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionRequest`,
        data: data,
      });
    },
    connectionSet(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionSet`,
        data: {
          _userIdCon: data._userIdCon,
          conNumber: data.conNumber,
          upReq: data.upReq,
        },
      });
    },
    requestConnectionChange(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/requestConnectionChange`,
        data: data,
      });
    },
    connectionUplift(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionUplift`,
        data: data,
      });
    },
    deleteConnection(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionDelete`,
        data: data,
      });
    },
    async loadConnectionSearch(context, data) {
      return await axiosDefault({
        method: "POST",
        url: `public/connectionDataLoadByLimit`,
        data: data,
      });
    },
    loadConnectionOne(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionUsername`,
        data: { data },
      });
    },
    connectionDataLoadByLimit({ state, rootState }, payload) {
      let hitAxios;
      if (!rootState.auth.status.loggedIn) {
        hitAxios = axiosDefault({
          method: "POST",
          url: `public/connectionDataLoadByLimit`,
          data: payload,
        });
      } else if (rootState.auth.status.loggedIn) {
        hitAxios = axiosInstance({
          method: "POST",
          url: `connection/connectionDataLoadByLimit`,
          data: payload,
        });
      }
      return hitAxios
        .then((data) => {
          let setConnectionAction = "";
          let noMoreConnections = "";
          let connectionDataFetched = data.data.connectionData;
          let noMoreConnectionStatus = data.data.noMoreData;

          if (data.data.connectionData.length) {
            this.commit("setNoConnectionFound", false);
            this.commit("setSearchNotFound", false);
            this.commit("setRefetch", false);
            // If user searching connection
            if (state.userSearched !== "") {
              setConnectionAction = "setSearchedList";
              noMoreConnections = "setNoMoreSearched";
              this.commit("setSearchedSkipLimitChanges", 0);
              this.commit(
                "setLoadLimitSearched",
                state.numberOfLoadedConnection
              );
            } else {
              // If user not searching, only fetching connections
              // if isSelf, save data to own connection state
              // else save to other connection state, push if different other user
              if (state.isSelf) {
                if (payload.type == "all") {
                  setConnectionAction = "setConnectionList";
                  noMoreConnections = "setNoMoreConnection";
                  this.commit("setAllSkipLimitChanges", 0);
                  this.commit(
                    "setLoadLimitAll",
                    state.numberOfLoadedConnection
                  );
                } else if (payload.type == "acquaintance") {
                  setConnectionAction = "setAcquaintanceList";
                  noMoreConnections = "setNoMoreAcquaintance";
                  this.commit("setAcquaintaceSkipLimitChanges", 0);
                  this.commit(
                    "setLoadLimitAcquaintance",
                    state.numberOfLoadedConnection
                  );
                } else if (payload.type == "friend") {
                  setConnectionAction = "setFriendList";
                  noMoreConnections = "setNoMoreFriend";
                  this.commit("setFriendSkipLimitChanges", 0);
                  this.commit(
                    "setLoadLimitFriend",
                    state.numberOfLoadedConnection
                  );
                } else if (payload.type == "bestfriend") {
                  setConnectionAction = "setBestFriendList";
                  noMoreConnections = "setNoMoreBestFriend";
                  this.commit("setBestFriendSkipLimitChanges", 0);
                  this.commit(
                    "setLoadLimitBestFriend",
                    state.numberOfLoadedConnection
                  );
                }
              }
            }

            setTimeout(() => {
              // console.log("connection hit backend API");
              if (state.isSelf) {
                this.commit(setConnectionAction, connectionDataFetched);
                this.commit(noMoreConnections, noMoreConnectionStatus);
              } else {
                this.commit("setOtherConnectionList", data.data);
              }
              if (state.loadMoreConnection) {
                // this.commit('setLoadMoreIsLoading', false)
                this.commit("setLoadMoreConnection", false);
              } else {
                this.commit("setConnectionsIsLoading", false);
              }
            }, 500);
          } else {
            // If message = connection not found, it means user dont have connection at all, or dont have connection
            // in particular connection type, or no connection same as keyword
            if (data.data.message === "Connection not found !") {
              this.commit("setLoadMoreConnection", false);
              // set load more to false
              if (
                payload.type == "all" &&
                state.userSearched === "" &&
                !state.connectionsList.length
              ) {
                this.commit("setNoConnectionFound", true);
                this.commit("setNoAllTypeFound", true);
              } else if (
                payload.type == "acquaintance" &&
                state.userSearched === "" &&
                !state.acquaintanceList.length
              ) {
                this.commit("setNoConnectionFound", true);
                this.commit("setNoAcquaintanceFound", true);
              } else if (
                payload.type == "friend" &&
                state.userSearched === "" &&
                !state.friendList.length
              ) {
                this.commit("setNoConnectionFound", true);
                this.commit("setNoFriendFound", true);
              } else if (
                payload.type == "bestfriend" &&
                state.userSearched === "" &&
                !state.bestFriendList.length
              ) {
                this.commit("setNoConnectionFound", true);
                this.commit("setNoBestFriendFound", true);
              } else if (
                state.userSearched !== "" &&
                !state.searchedList.length
              ) {
                this.commit("setNoConnectionFound", true);
                this.commit("setSearchNotFound", true);
              }
            }
            // this.commit('setNoConnectionFound', true) // tambahin di bagian pindah pindah filter
          }
        })
        .catch((error) => {
          // console.log(error);
          const errCode = error.response.status;
          const errMsg = error.response.data.message;
          this.commit("setRefetch", false);

          if (errCode == 403) {
            this.$router.push({ name: "PageRestricted" });
          }

          if (errCode == 404) {
            if (state.startLoadConnection == 0) {
              this.commit("clearConnectionsList");
              // this.commit('setNoConnectionFound', true)
            }

            if (state.startLoadConnection >= 0) {
              this.commit("setLoadMoreConnection", false);
            }

            if (errMsg == "Connection not found !") {
              this.commit("setLoadMoreConnection", false);

              // set search user not found
              if (state.userSearched !== "") {
                if (state.startLoadConnection > 0) {
                  this.commit("setNoConnectionFound", true);
                } else {
                  this.commit("setSearchNotFound", true);
                }
              }
            }
          }
          setTimeout(() => {
            this.commit("setConnectionsIsLoading", false);
          }, 500);
        });
    },
    blockConnection(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionBlock`,
        data: data,
      });
    },
    unblockConnection(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionUnblock`,
        data: data,
      });
    },
    loadBlocks(context, data) {
      return axiosInstance({
        method: "POST",
        url: `connection/loadBlocks`,
        data: data,
      })
        .then((data) => {
          setTimeout(() => {
            this.commit("setIsBlockLoadingContent", false);
            this.commit("setIsBlockLoadingFooter", false);
            this.commit("setBlockList", data.data);
          }, 1400);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    loadConnectionInterest(context, payload) {
      const { startNum, numberOfLoad } = payload;
      return axiosInstance({
        method: "GET",
        url: `connection/loadConnectionInterest/${startNum}/${numberOfLoad}`,
      });
    },
    connectionCheckById(context, payload) {
      return axiosInstance({
        method: "POST",
        url: `connection/connectionCheckById`,
        data: { payload },
      });
    },

    // Check current connection status
    checkUserIsConnected(context, payload) {
      return axiosInstance({
        method: "POST",
        url: `connection/checkUserIsConnected`,
        data: payload,
      });
    },
    checkIncomingUpliftRequest(context, payload) {
      return axiosInstance({
        method: "POST",
        url: `connection/checkIncomingUpliftRequest`,
        data: payload,
      });
    },
  },
};
