<template>
  <div>
    <vueper-slides
      class="pt-1 people-post-slider no-shadow"
      :visible-slides="5"
      slide-multiple
      :alwaysRefreshClones="true"
      :bullets="bullets"
      :arrowsOutside="true"
      :gap="2"
      :slide-ratio="1 / 3"
      :dragging-distance="100"
      fixedHeight="100px"
    >
      <template v-slot:arrow-left>
        <b-icon
          icon="chevron-compact-left"
          variant="black"
          font-scale="2"
        ></b-icon>
      </template>

      <template v-slot:arrow-right>
        <b-icon
          icon="chevron-compact-right"
          variant="black"
          font-scale="2"
        ></b-icon>
      </template>
      <vueper-slide
        class="people-in-post"
        v-for="(inMedia, index) of peopleInMedia"
        :key="index"
      >
        <template v-slot:content>
          <div class="content-overlay"></div>
          <b-avatar
            class="rounded"
            variant="primary"
            size="100px"
            :text="
              inMedia.firstname_con.charAt(0) + inMedia.lastname_con.charAt(0)
            "
            :src="
              inMedia.imgProfile
                ? `${filePath}${inMedia._userIdCon}${thumbPP}${inMedia.imgProfile}`
                : ''
            "
            v-b-tooltip.hover.bottom="
              inMedia.firstname_con + ' ' + inMedia.lastname_con
            "
          ></b-avatar>

          <div class="people-in-post-name text-center">
            {{
              stringLimit(inMedia.firstname_con + " " + inMedia.lastname_con, 8)
            }}
          </div>
          <b-button
            @click.prevent="removePeople(inMedia._userIdCon)"
            variant="dark"
            toggle-class="text-decoration-none"
            no-caret
            class="people-in-post-btn"
            v-b-tooltip.hover.left="'Remove'"
          >
            <b-icon icon="x" variant="white"></b-icon>
          </b-button>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  name: "PimSlider",
  components: { VueperSlides, VueperSlide },
  props: [
    "peopleInMedia",
    "filePath",
    "thumbPP",
    "removeFromMedia",
    "allowRemove",
    "bullets",
  ],

  mounted() {},
  methods: {
    removePeople(_userIdCon) {
      this.$emit("removeFromMedia", _userIdCon);
    },
  },
};
</script>
>
