import axiosInstance from "@/services/instanceService";
import { returNotif } from "@/helpers/notification.js";

/*
const today = new Date();
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
const dateTime = date + ' ' + time;
*/
export default {
  namespaced: true,

  state: {
    notifHeader: [],
    countNotif: 0,
    allNotifHeaderLoaded: false,
    isAllNotifLoaded: false,
    notifAll: [],
  },
  /*
    getters: {
      getComments: state => {
        return state.items
      },
      isAllCommentsLoaded: state => {
        return state.isAllCommentsLoaded
      }
    },
    */
  /*Load notif*/
  actions: {
    async fetchNotifications({ commit, state }, { userNotif }) {
      if (!state.allNotifHeaderLoaded)
        return axiosInstance
          .post(`notification/getnotification`, userNotif)
          .then((res) => {
            var notification = res.data;
            const notifcount = notification.countNotif;
            const notifItems = notification.notif;
            const allNotifHeaderLoaded = notification.allNotifHeaderLoaded;
            for (let i = 0; i < notifItems.length; i++) {
              var notifCountItem = notifItems[i].count_notif - 1;
              var moduleType = notifItems[i].moduleType;
              var notifType = notifItems[i].notification_type;
              var actionType = notifItems[i].action_type;
              const text = returNotif(
                notifCountItem,
                moduleType,
                notifType,
                actionType
              );
              notifItems[i].text = text;
            }
            commit("counterNotif", notifcount);
            commit("mergeNotif", notifItems, allNotifHeaderLoaded);
            return notifItems;
          })
          .catch((err) => {
            // console.log(err)
            return err;
          });
    },

    async fetchAllNotif({ commit }, { filter = {}, init }) {
      if (init) {
        commit(
          "setItems",
          { resource: "notifModule", notifAll: [] },
          { root: true }
        );
      }

      if (filter.notifPageNum == 1) {
        commit("removeStateHeader");
      }
      return axiosInstance
        .post(`notification/getAllNotif`, filter)
        .then((response) => {
          const { notif, isAllDataLoaded } = response.data;
          
          const notifItems = notif;
          for (let i = 0; i < notifItems.length; i++) {
            var notifCount = notifItems[i].count_notif - 1;
            var moduleType = notifItems[i].moduleType;
            var notifType = notifItems[i].notification_type;
            var actionType = notifItems[i].action_type;
            const text = returNotif(
              notifCount,
              moduleType,
              notifType,
              actionType
            );
            notifItems[i].text = text;
          }

          commit("setAllNotifLoaded", isAllDataLoaded);
          commit("mergeNotifAll", notifItems);
          return notifItems;
        })
        .catch((err) => {
          // console.log(err)
          return err;
        });
    },
    readNotificationHead({ commit, state }, { notifId, dataNotif }) {
      // const currentUrl = dataNotif.currentUrl;
      //  const slug = dataNotif.slug; const getCountNotif = dataNotif.countNotif;
      return axiosInstance
        .post(`notification/editnotification`, dataNotif)
        .then((resp) => {
          if (state.notifHeader.length > 0) {
            commit("reduceNotifHeader", notifId);
          }
          return resp;
        });
    },

    async marksAllReadNotif({ commit }) {
      return axiosInstance.post(`notification/readAllRecentNotif`).then(() => {
        commit("removeStateHeader");
      });
    },

    async deleteNotif({ state }, { notifData }) {
      const notifId = notifData.notifId;
      return axiosInstance
        .post(`notification/deleteNotif`, notifData)
        .then((resp) => {
          // console.log(resp);
          var index = state.notifAll
            .map(function(x) {
              return x._id;
            })
            .indexOf(notifId);
          state.notifAll.splice(index, 1);
          return resp;
        });
    },
    async deleteAllNotifList({ state, commit }) {
      const notifIdAll = state.notifAll.map((item) => {
        return {
          _id :item._id
        }
      });
      return axiosInstance
        .post(`notification/deleteAllNotif`, {notifIdAll})
        .then((resp) => {
          commit(
            "removeNotifList",
          );
          return resp;
        });
    },
  },

  mutations: {
    setNotifHeaderLoaded(state) {
      state.allNotifHeaderLoaded = false;
    },
    removeStateHeader(state) {
      state.notifHeader = "";
      state.countNotif = 0;
      state.allNotifHeaderLoaded = false;
    },

    counterNotif(state, notifcount) {
      state.countNotif = notifcount;
    },
    reduceNotifHeader(state, notifId) {
      var indexNotif = state.notifHeader.findIndex(
        (notif) => notif._id === notifId
      );
      state.countNotif =
        state.countNotif - state.notifHeader[indexNotif].count_notif;
      if (state.countNotif <= 0) {
        state.allNotifHeaderLoaded = true;
      }
      if (indexNotif >= 0 && state.notifHeader.length > 0) {
        state.notifHeader.splice(indexNotif, 1);
      }
    },
    mergeNotif(state, notifItem, allNotifHeaderLoaded) {
      // state.notifHeader = notifItem
      state.notifHeader = [...state.notifHeader, ...notifItem];
      state.allNotifHeaderLoaded = allNotifHeaderLoaded;
    },
    mergeNotifAll(state, notifItem) {
      // state.notifAll = notifItem
      state.notifAll = [...state.notifAll, ...notifItem];
    },
    setAllNotifLoaded(state, isAllNotifLoaded) {
      state.isAllNotifLoaded = isAllNotifLoaded;
    },
    removeNotifList(state){
      state.notifAll = []
    },

  },
};
