<template>
  <b-sidebar
    id="sidebar-right"
    aria-labelledby="sidebar-no-header-title"
    backdrop-variant="transparent"
    no-header
    backdrop
    bg-variant="white"
    lazy
    right
  >
    <template v-slot:default="{ hide }">
      <b-button
        class="btn-sider-bar-close close-button"
        variant="none"
        @click="hide"
      >
        <b-icon icon="x" font-scale="2"></b-icon>
      </b-button>
      <div class="side-profile-pict mt-2 mb-3 pt-xl-5 pt-2">
        <EditProfilePicture
          :notOwnProfile="notOwnProfile"
          :urlMedia="urlMedia"
          :userId="userId"
          :firstname="firstname"
          :lastname="lastname"
          :username="username"
          :imgProfile="profilePicture"
          avatarSize="140px"
          classThumb="rounded avatar-140 "
        />
        <router-link :to="'/' + username + '/about'" class="iq-waves-effect">
          <h4 class="text-capitalize text-center mt-lg-2">
            {{ stringLimit(firstname + " " + lastname, 15) }}
          </h4>
        </router-link>
      </div>
      <hr class="m-0" />
      <div class="social-info p-2">
        <ul
          class="
            d-flex
            align-items-center
            justify-content-between
            list-inline
            p-0
            m-0
          "
        >
          <li class="text-center pl-3">
            <h5 class="text-title-right-sidebar">
              {{ counterHelper(authUser.videos_number) }}
            </h5>
            <p class="text-sub-title-right-sidebar mb-0">Videos</p>
          </li>
          <li class="text-center p-0">
            <h5 class="text-title-right-sidebar">
              {{ counterHelper(authUser.connections_number) }}
            </h5>
            <p class="text-sub-title-right-sidebar mb-0">Connections</p>
          </li>
          <li class="text-center pr-3">
            <h5 class="text-title-right-sidebar">
              {{ counterHelper(authUser.followers_number) }}
            </h5>
            <p class="text-sub-title-right-sidebar mb-0">Followers</p>
          </li>
        </ul>
      </div>
      <hr class="m-0" />
      <nav class="iq-sidebar-menu mt-4">
        <ul id="iq-sidebar-toggle" class="ul-sidebar-right iq-menu">
          <li>
            <router-link
              :class="activeRight == 'intouch' ? 'right-active' : ''"
              :to="'/' + username + '/intouch'"
              class="iq-waves-effect"
            >
              <custom-icon name="intouch" margin="ml-2" size="medium" />
              <span class="ml-3">My In Touch</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/' + username + '/showcase'"
              class="iq-waves-effect"
            >
              <custom-icon name="showcase" margin="ml-2" size="medium" />
              <span class="ml-3">My Showcase</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/' + username + '/photos'"
              class="iq-waves-effect"
            >
              <custom-icon name="photo" margin="ml-2" size="medium" />
              <span class="ml-3">My Photos</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/' + username + '/videos'"
              class="iq-waves-effect"
            >
              <custom-icon name="video" margin="ml-2" size="medium" />
              <span class="ml-3">My Videos</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/' + username + '/friends'"
              class="iq-waves-effect"
            >
              <custom-icon name="allconnection" margin="ml-2" size="medium" />

              <span class="ml-3">My Connections</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/' + username + '/about'"
              class="iq-waves-effect"
            >
              <custom-icon name="profile" margin="ml-2" size="medium" />
              <span class="ml-3">About Me</span>
            </router-link>
          </li>
          <li>
            <router-link :to="'/privacy_setting'" class="iq-waves-effect">
              <b-icon icon="gear" font-scale="1.5"></b-icon>
              <span class="ml-3">Settings</span>
            </router-link>
          </li>
          <li>
            <a href @click.prevent="doLogOut" class="iq-waves-effect">
              <b-icon icon="power" font-scale="1.5"></b-icon>
              <span class="ml-3">Log Out</span>
            </a>
          </li>
        </ul>
      </nav>
      <div
        :class="
          fixedFooterToggle
            ? 'footer-sidebar fixed-bottom py-3 px-1'
            : 'footer-sidebar py-3 px-1'
        "
      >
        <b-row align-v="center" class="ml-2">
          <b-container>
            <b-row class="row-lg-12 text-left small mt-3">
              <b-col
                ><router-link :to="'/terms_of_use'"
                  >Terms of Use</router-link
                ></b-col
              >
              <b-col
                ><router-link :to="'/privacy_policy'"
                  >Privacy Policy</router-link
                ></b-col
              >
              <b-col>
                <a @click.prevent="showReportModal" href="#">Contact Us</a>
              </b-col>
            </b-row>
          </b-container>
        </b-row>
        <div class="row-lg-6 text-left small d-block mt-3 ml-3">
          © Mindcaster All Rights Reserved
        </div>
      </div>
      <!-- START POPUP CONTACT US-->
      <b-modal
        id="modal-header-contact-us"
        centered
        no-close-on-backdrop
        title="CONTACT US"
        hide-footer
      >
        <div class="px-3 lato400 fontSize16">
          <b-form
            class="mb-3"
            @submit.prevent="sendReportMail"
            @reset="onReset"
          >
            <b-form-group
              class="pt-2 pb-0"
              id="input-group-3"
              label="Title:"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="formData.title"
                placeholder="Title (required)"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Name:" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="formData.name"
                placeholder="Your name (required)"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Email:"
              label-for="input-1"
              description="We'll never share your email with anyone else."
            >
              <b-form-input
                id="input-1"
                v-model="formData.email"
                type="email"
                placeholder="Your contact email (required)"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-4"
              label="Details:"
              label-for="input-4"
            >
              <b-form-textarea
                id="input-group-4"
                v-model="formData.detail"
                placeholder="Tell us what you think..."
                rows="6"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <button
              type="submit"
              class="btn btn-primary d-flex align-items-center px-2 float-right"
              :disabled="buttonIsDisabled"
            >
              <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
              <span class="d-none d-lg-block ml-1">Send</span>
            </button>
            <b-button
              class="float-right mr-2"
              type="reset"
              variant="secondary"
              :disabled="buttonIsDisabled"
              >Reset</b-button
            >
          </b-form>
          <br />
          <br />
        </div>
      </b-modal>
      <!-- END POPUP CONTACT US-->

      <b-modal
        id="thank-you-modal"
        size="sm"
        centered
        no-close-on-backdrop
        hide-header
        hide-footer
      >
        <div class="row align-items-center justify-content-center py-3">
          <span class="col-12 text-center fontSize16 lato400">
            Thank you for contacting us
          </span>
          <span class="col-12 text-center fontSize14 lato400">
            We'll reach you immediately
          </span>
        </div>
      </b-modal>

      <b-modal
        id="failed-mail-modal"
        size="sm"
        centered
        no-close-on-backdrop
        hide-header
        hide-footer
      >
        <div class="row align-items-center justify-content-center py-3">
          <span class="col-12 text-center fontSize16 lato400">
            Error Occured
          </span>
          <span class="col-12 text-center fontSize14 lato400">
            Please check your connection or try again later
          </span>
        </div>
      </b-modal>
      <b-modal id="upload-notification-modal" size="md" centered hide-footer>
        <template #modal-header
          ><div class="upload-notification-title-style">
            Notification
          </div></template
        >
        <div class="row align-items-center justify-content-center py-3 mt-2">
          <span class="col-12 text-center upload-notification-style">
            You are currently uploading a content... Before logging out, please
            cancel your ongoing upload or complete the upload process
          </span>
          <b-button
            class="mt-4 modalBtnConfirm"
            @click.prevent="closeModalUploadNotification"
            >Confirm</b-button
          >
        </div>
      </b-modal>
    </template>
  </b-sidebar>
</template>
<script>
import EditProfilePicture from "@/components/user-setting/EditProfilePicture.vue";
export default {
  name: "RightSidebar",
  components: {
    EditProfilePicture,
  },
  props: [
    "userId",
    "profilePicture",
    "profilePictureMedium",
    "firstname",
    "lastname",
    "username",
    "urlMedia",
  ],
  data() {
    return {
      notOwnProfile: false,
      activeRight: true,
      formData: {
        email: "",
        name: "",
        title: "",
        detail: "",
      },
      buttonIsDisabled: false,
      fixedFooterToggle: true,
    };
  },
  computed: {
    authUser() {
      return this.$store.state.auth.user;
    },
  },

  created() {
    window.addEventListener("resize", this.footerToggler);
  },
  destroyed() {
    window.removeEventListener("resize", this.footerToggler);
  },

  methods: {
    footerToggler() {
      if (window.innerHeight <= 810 && this.fixedFooterToggle == true) {
        this.fixedFooterToggle = false;
      } else if (window.innerHeight > 810 && this.fixedFooterToggle == false) {
        this.fixedFooterToggle = true;
      }
    },

    doLogOut() {
      if (localStorage.getItem("isUploading") == "1") {
        this.$bvModal.show("upload-notification-modal");
      } else {
        this.$root.$emit("triggerLogout");
      }
    },

    closeModalUploadNotification() {
      this.$bvModal.hide("upload-notification-modal");
    },

    showReportModal() {
      this.buttonIsDisabled = false;
      this.formData.email = this.$store.state.auth.user.email;
      this.formData.name = this.$store.state.auth.user.firstname;
      this.$bvModal.show("modal-header-contact-us");
    },

    sendReportMail(event) {
      event.preventDefault();
      this.buttonIsDisabled = true;
      // console.log(JSON.stringify(this.formData));
      // alert(JSON.stringify(this.formData));
      // codes for sending email here
      let emailFrom = this.formData.email;
      let emailTo = process.env.VUE_APP_CONTACT_US_EMAIL_RECEIVER;
      let emailSubject = this.formData.title;
      let emailText = this.formData.name;
      let emailHtml = this.formData.detail;
      let payload = {
        emailFrom,
        emailTo,
        emailSubject,
        emailText,
        emailHtml,
      };
      this.$store
        .dispatch("report/sendReportMail", payload)
        .then(() => {
          this.$bvModal.show("thank-you-modal");
          this.$bvModal.hide("modal-header-contact-us");
          this.formData.email = "";
          this.formData.name = "";
          this.formData.title = "";
          this.formData.detail = "";
          setTimeout(() => {
            this.$bvModal.hide("thank-you-modal");
          }, 2000);
        })
        .catch(() => {
          this.formData.email = "";
          this.formData.name = "";
          this.formData.title = "";
          this.formData.detail = "";
          // console.log(error);
          this.$bvModal.show("failed-mail-modal");
          this.$bvModal.hide("modal-header-contact-us");
          setTimeout(() => {
            this.$bvModal.hide("failed-mail-modal");
          }, 2000);
        });
    },

    onReset(event) {
      event.preventDefault();
      // Reset our formData values
      this.formData.email = "";
      this.formData.name = "";
      this.formData.title = "";
      this.formData.detail = "";
    },
  },
};
</script>
