import axiosInstance, { axiosDefault } from "@/services/instanceService";
import { sortBy, uniqBy } from "lodash";

export default {
  namespaced: true,
  state: {
    isAllCommentsLoaded: false,
    postId: "",
    items: [],
  },
  mutations: {
    /* unused temporary
    saveReplyToComment(state, { reply, commentIndex }) {
      state.items[commentIndex].commentReply = [...state.items[commentIndex].commentReply, ...reply]
    },
    */
    //add reply comment to stage
    addReplyToComment(state, { reply, commentId, index }) {
      const commentIndex = state.items.findIndex(
        (commentList) => commentList._id === commentId
      );
      //count reply lenght
      state.items[commentIndex].commentReply.push(reply);

      state.items[index].countReply++;
    },
    //end reply comment to stage
    setAllDataLoaded(state, isAllDataLoaded) {
      state.isAllCommentsLoaded = isAllDataLoaded;
    },
    mergeComments(state, comments) {
      state.items = [...state.items, ...comments];
    },
    setAllReplyLoaded(state, { isAllReplyLoaded, index }) {
      state.items[index].setAllReplyLoaded = isAllReplyLoaded;
    },
    mergeReplys(state, { replied, index }) {
      //state.items[index].commentReply = replied
      state.items[index].commentReply = sortBy(
        uniqBy([...state.items[index].commentReply, ...replied], "_id"),
        "createdAt"
      );
    },
    /* has reaction Comment mutation*/
    countReactionComment(state, { index, reaction, existReaction }) {
      switch (reaction) {
        case 1:
          switch (existReaction) {
            //check if haslike
            case 1:
              state.items[index].like--;
              state.items[index].hasLike = false;
              break;
            case 0:
              //check if hasDislike
              state.items[index].disLike--;
              state.items[index].hasDislike = false;
              state.items[index].like++;
              state.items[index].hasLike = true;
              break;
            default:
              state.items[index].like++;
              state.items[index].hasLike = true;
              break;
          }
          break;

        default:
          //for dislike
          switch (existReaction) {
            //check if hasDislike
            case 0:
              state.items[index].disLike--;
              state.items[index].hasDislike = false;
              break;
            //check if haslike
            case 1:
              state.items[index].like--;
              state.items[index].hasLike = false;
              state.items[index].disLike++;
              state.items[index].hasDislike = true;
              break;
            default:
              state.items[index].disLike++;
              state.items[index].hasDislike = true;
              break;
          }
          break;
      }
    },

    countReactionReply(state, { index, indexReply, reaction, existReaction }) {
      switch (reaction) {
        case 1:
          switch (existReaction) {
            //check if haslike
            case 1:
              state.items[index].commentReply[indexReply].replyLike--;
              state.items[index].commentReply[indexReply].hasLikeReply = false;
              break;
            case 0:
              //check if hasDislike
              state.items[index].commentReply[indexReply].replyDislike--;
              state.items[index].commentReply[
                indexReply
              ].hasDislikeReply = false;
              state.items[index].commentReply[indexReply].replyLike++;
              state.items[index].commentReply[indexReply].hasLikeReply = true;
              break;
            default:
              state.items[index].commentReply[indexReply].replyLike++;
              state.items[index].commentReply[indexReply].hasLikeReply = true;
              break;
          }
          break;

        default:
          //for dislike
          switch (existReaction) {
            //check if hasDislike
            case 0:
              state.items[index].commentReply[indexReply].replyDislike--;
              state.items[index].commentReply[
                indexReply
              ].hasDislikeReply = false;
              break;
            //check if haslike
            case 1:
              state.items[index].commentReply[indexReply].replyLike--;
              state.items[index].commentReply[indexReply].hasLikeReply = false;
              state.items[index].commentReply[indexReply].replyDislike++;
              state.items[index].commentReply[
                indexReply
              ].hasDislikeReply = true;
              break;
            default:
              state.items[index].commentReply[indexReply].replyDislike++;
              state.items[index].commentReply[
                indexReply
              ].hasDislikeReply = true;
              break;
          }
          break;
      }
    },
  },
  /*
  getters: {
    getComments: state => {
      return state.items
    },
    isAllCommentsLoaded: state => {
      return state.isAllCommentsLoaded
    }
  },
  */
  actions: {
    async fetchComments({ state, commit }, { filter = {}, init }) {
      if (init) {
        commit(
          "setItems",
          { resource: "commentList", items: [] },
          { root: true }
        );
      }
      let response = "";
      if (!filter.userId) {
        response = await axiosDefault.post(`public/getCommentsPublic`, filter);
      } else {
        response = await axiosInstance.post(`authComments`, filter);
      }
      const { comments, isAllDataLoaded } = response.data;
      commit("setAllDataLoaded", isAllDataLoaded);
      commit("mergeComments", comments);
      return state.items;
    },
    /*End load */

    /*post commment*/
    postComment({ state, commit }, { comments }) {
      return axiosInstance
        .post(`authComments/updateComments`, comments)
        .then((res) => {
          const comment = res.data.comment[0];
          var commentText = comment.commentText;
          var idComment = comment._id;
          var createdAt = comment.createdAt;
          var updatedAt = comment.updatedAt;
          const index = state.items.length;
          const returnComment = {
            like: 0,
            disLike: 0,
            countReply: 0,
            commentReply: [],
            createBy: [
              {
                _id: comments.createBy,
                username: comments.username,
                firstname: comments.firstname,
                lastname: comments.lastname,
                imgProfile: comments.imgProfile,
              },
            ],
            commentText: commentText,
            updatedAt: updatedAt,
            createdAt: createdAt,
            _id: idComment,
          };
          const status = res.status;
          if (index >= 1) {
            state.items.unshift(returnComment);
          } else {
            commit(
              "addItemToArray",
              { item: returnComment, index, resource: "commentList" },
              { root: true }
            );
          }
          return status;
        });
    },
    /*end post commment*/

    /*Edit Comment*/
    doEditComment({ state }, { newComment }) {
      return axiosInstance
        .post(`authComments/updateComments`, newComment)
        .then(() => {
          return state.items;
        });
    },

    /*Delete Comment*/
    doDeleteComment({ state }, { deleteComment, index }) {
      //Remove Dom Before remove DB
      state.items.splice(index, 1);
      return axiosInstance
        .post(`authComments/delete`, deleteComment)
        .then(() => {
          //state.items.splice(index, 1)
          return state.items;
        });
    },

    //Do Post reply comment
    postReply({ commit }, { replyComments, index }) {
      return axiosInstance
        .post(`authComments/updateReply`, replyComments)
        .then((res) => {
          var replyData = res.data.reply[0];
          const commentReply = {
            replyBy: [
              {
                _id: replyData.replyBy,
                username: replyComments.username,
                firstname: replyComments.firstname,
                lastname: replyComments.lastname,
                imgProfile: replyComments.imgProfile,
              },
            ],
            replyTo: [
              {
                _id: replyComments.replyTo,
                firstname: replyComments.replyFirstnameTo,
                lastname: replyComments.replyLastnameTo,
                username: replyComments.replyUsernameTo,
              },
            ],
            replyLike: 0,
            replyDislike: 0,
            _id: replyData._id,
            replyText: replyData.replyText,
            updatedAt: replyData.updatedAt,
            createdAt: replyData.createdAt,
          };
          commit("addReplyToComment", {
            reply: commentReply,
            commentId: replyComments.commentId,
            index: index,
          });
          return commentReply;
        });
    },
    //end Post reply comment

    /** EDIT REPLY */
    doEditReply({ state }, { newReply }) {
      return axiosInstance
        .post(`authComments/updateReply`, newReply)
        .then(() => {
          return state.items;
        });
    },
    /** DELETE REPLY */
    doDeleteReply({ state }, { deleteReply, index, indexReply }) {
      //Remove Reply DOM befor remove DB
      state.items[index].commentReply.splice(indexReply, 1);
      if (state.items[index].countReply > 0) {
        state.items[index].countReply--;
      }
      return axiosInstance
        .post(`authComments/deletereply`, deleteReply)
        .then(() => {
          return state.items[index];
        });
    },
    //Load reply comment
    async fetchReplyComments({ state, commit }, { filter = {}, index }) {
      let response = "";
      if (!filter.userId) {
        response = await axiosDefault.post(`public/getReplyPublic`, filter);
      } else {
        response = await axiosInstance.post(`authComments/getreply`, filter);
      }

      const { replied, isAllReplyLoaded } = response.data;
      commit("setAllReplyLoaded", { isAllReplyLoaded, index });
      commit("mergeReplys", { replied, index });
      return state.items[index].setAllReplyLoaded;
    },
    //End Load reply comment

    //do reaction like/dislike
    doReactionComment({ commit, rootState }, { index, reactionComments }) {
      const commentId = reactionComments.commentId;
      const hasLike = reactionComments.hasLike;
      const hasDislike = reactionComments.hasDislike;
      const reaction = reactionComments.reactionValue;
      let existReaction = null;

      commit("setCounterReactionComment", commentId, {
        root: true,
      });
      switch (reaction) {
        case 1:
          // this check like
          if (hasLike && !hasDislike) {
            existReaction = 1;
            break;
          } else if (!hasLike && hasDislike) {
            existReaction = 0;
          }
          break;
        default:
          // this check hasdlike
          if (!hasLike && hasDislike) {
            existReaction = 0;
            break;
          } else if (hasLike && !hasDislike) {
            existReaction = 1;
          }
          break;
      }

      commit("countReactionComment", {
        index: index,
        reaction: reaction,
        existReaction: existReaction,
      });
      if (rootState.reactionCommentLimit[commentId] > 5) {
        return;
      }
      return axiosInstance
        .post(`reactionComment/doReactionComment`, reactionComments)
        .then((resp) => {
          const response = resp.data;
          const reaction = response.reaction;
          return reaction;
        });
    },
    //do reaction Reply comment like
    doReactionReply(
      { commit, rootState },
      { index, indexReply, reactionReplyComments }
    ) {
      const replyId = reactionReplyComments.replyId;
      const hasLike = reactionReplyComments.hasLike;
      const hasDislike = reactionReplyComments.hasDislike;
      const reaction = reactionReplyComments.reactionValue;
      let existReaction = null;
      commit("setCounterReactionReply", replyId, {
        root: true,
      });

      switch (reaction) {
        case 1:
          // this check like
          if (hasLike && !hasDislike) {
            existReaction = 1;
            break;
          } else if (!hasLike && hasDislike) {
            existReaction = 0;
          }
          break;
        default:
          // this check hasdlike
          if (!hasLike && hasDislike) {
            existReaction = 0;
            break;
          } else if (hasLike && !hasDislike) {
            existReaction = 1;
          }
          break;
      }

      commit("countReactionReply", {
        index: index,
        indexReply: indexReply,
        reaction: reaction,
        existReaction: existReaction,
      });

      if (rootState.reactionReplyLimit[replyId] > 5) {
        return;
      }

      return axiosInstance
        .post(`reactionReply/doReactionReply`, reactionReplyComments)
        .then((resp) => {
          const response = resp.data;
          const reaction = response.reaction;
          return reaction;
        });
    },
    //do reset state before sort
    fetchResetComments({ commit }) {
      commit(
        "setItems",
        { resource: "commentList", items: [] },
        { root: true }
      );
    },
  },
};
