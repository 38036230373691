<template>
  <div>
    <b-col @click="showCropper" class="image-profile text-center">
      <div class="content-overlay"></div>
      <b-avatar
        v-if="!loaderAvatar"
        v-model="profilePictureMedium"
        variant="primary"
        class="hover-image"
        :class="classThumb"
        :size="avatarSize"
        :text="firstname.charAt(0) + lastname.charAt(0)"
        :alt="firstname"
        :src="profilePicture && !loaderAvatar ? profilePictureMedium : ''"
      ></b-avatar>
      <transition name="fade" v-if="loaderAvatar">
        <div class="loading text-center">
          <b-spinner label="Spinning"></b-spinner>
        </div>
      </transition>
    </b-col>
    <!-- START POPUP Cropper-->
    <b-modal
      v-if="!notOwnProfile"
      centered
      id="modal-lg"
      size="lg"
      no-close-on-backdrop
      v-model="showCropTools"
      title="Edit profile picture"
      @cancel="cancelCrop"
      @hidden="cancelCrop"
    >
      <b-container fluid class="pr-0">
        <b-col lg="12" md="12" sm="12" class="content-cropper row">
          <b-col lg="12" sm="12" class="cropper-area p-0">
            <input
              hidden
              id="input-image"
              ref="inputImage"
              type="file"
              name="image"
              accept="image/*"
              @change="setImage"
            />
            <div class="img-cropper">
              <vue-cropper
                ref="cropper"
                :aspect-ratio="1 / 1"
                :src="imgSrc"
                :guides="true"
                :img-style="{ width: '600px', height: '600px' }"
                alt="Profile picture cropper"
              ></vue-cropper>
            </div>
            <b-button-toolbar>
              <b-button-group vertical size="sm" class="toolbar-cropper">
                <b-button
                  variant=""
                  @click.prevent="zoom(0.2)"
                  v-b-tooltip.hover.right="'Zoom In'"
                >
                  <b-icon icon="zoom-in"></b-icon>
                </b-button>
                <b-button
                  variant=""
                  @click.prevent="zoom(-0.2)"
                  v-b-tooltip
                  v-b-tooltip.hover.right="'Zoom Out'"
                >
                  <b-icon icon="zoom-out"></b-icon>
                </b-button>
                <b-button
                  variant=""
                  ref="flipX"
                  @click.prevent="flipX"
                  v-b-tooltip.hover.right="'Flip X'"
                >
                  <b-icon icon="arrow-left-right"></b-icon>
                </b-button>
                <b-button
                  variant=""
                  ref="flipY"
                  @click.prevent="flipY"
                  v-b-tooltip.hover.right="'Flip Y'"
                >
                  <b-icon icon="arrow-down-up"></b-icon>
                </b-button>
                <b-button
                  variant=""
                  @click.prevent="rotate(90)"
                  v-b-tooltip
                  v-b-tooltip.hover.right="'Rotate 90'"
                >
                  <b-icon icon="arrow90deg-right"></b-icon>
                </b-button>
                <b-button
                  variant=""
                  @click.prevent="rotate(-90)"
                  v-b-tooltip
                  v-b-tooltip.hover.right="'Rotate -90'"
                >
                  <b-icon icon="arrow90deg-left"></b-icon>
                </b-button>
                <b-button
                  variant=""
                  @click.prevent="reset"
                  v-b-tooltip.hover.right="'Reset'"
                >
                  <b-icon icon="arrow-repeat"></b-icon>
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
          <b-col lg="12" sm="12" class="p-0">
            <b-button-toolbar class="mt-2 float-left w-100">
              <b-button-group size="md" class="w-100 toolbar-edit">
                <b-button
                  @click="showGallery = true"
                  size="md"
                  variant="none"
                  v-b-tooltip
                  v-b-tooltip.hover.bottom="'Gallery'"
                >
                  <b-icon icon="images"></b-icon>
                </b-button>
                <b-button
                  size="md"
                  variant="none"
                  @click.prevent="showFileChooser"
                  v-b-tooltip.hover.bottom="'Upload Image'"
                >
                  <b-icon icon="upload"></b-icon>
                </b-button>
                <b-button
                  size="md"
                  variant="none"
                  @click.prevent="deleteProfilePicture"
                  v-b-tooltip.hover.bottom="'Delete profile picture'"
                >
                  <b-icon icon="trash"></b-icon>
                </b-button>
                <b-button
                  size="md"
                  variant="none"
                  @click.prevent="cropImage"
                  v-b-tooltip.hover.bottom="'Crop'"
                >
                  <b-icon icon="crop"></b-icon>
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-col>
      </b-container>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="none" @click="cancel()"> Cancel </b-button>
      </template>
    </b-modal>
    <!-- END POPUP Cropper-->
    <!-- START POPUP Cropped-->
    <b-modal
      centered
      size="md"
      no-close-on-backdrop
      v-model="showCropped"
      title="Preview"
      @cancel="cancelCrop"
      @ok="pushToProfile"
    >
      <b-col>
        <div class="cropped-image text-center">
          <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
          <div v-else class="crop-placeholder">Preview</div>
        </div>
      </b-col>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="none" @click="cancel()"> Retake </b-button>
        <b-button
          v-if="cropImg != ''"
          size="sm"
          variant="primary"
          @click="ok()"
        >
          Save
        </b-button>
      </template>
    </b-modal>
    <!-- END POPUP Cropped-->
    <!-- START POPUP gallery-->
    <b-modal
      id="modal-gallery-pp"
      centered
      size="lg"
      no-close-on-backdrop
      v-model="showGallery"
      title="Select From Gallery"
      @cancel="resetMedia"
      @ok="pushToCrop(imageSelected.mediaThumbnail)"
    >
      <b-container>
        <b-row>
          <b-col lg="12" sm="12" class="load-photo py-2">
            <SelectMediaPhoto
              key="edit-pp"
              :dataMediaPhoto="photos"
              :urlMedia="urlMedia"
              :thumbSize="thumbSize"
              :isAllPhotoLoaded="isAllPhotoLoaded"
              @onselectimage="onSelectImage"
              @emitLoadMediaPhotos="loadMediaPhoto"
              h="120"
              w="120"
              ref="single-select-image"
            />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="none" @click="cancel()"> Cancel </b-button>
        <b-button
          v-if="imageSelected != ''"
          size="sm"
          variant="primary"
          @click="ok()"
        >
          Select
        </b-button>
      </template>
    </b-modal>
    <!-- END POPUP gallery-->
  </div>
</template>
<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import { mapActions, mapGetters } from "vuex";
import SelectMediaPhoto from "@/components/SelectMediaPhoto.vue";
import { FILE_PATH, THUMB_MEDIUM } from "../../variables.js";

import { axiosDefault } from "@/services/instanceService";
export default {
  name: "EditProfilePicture",
  components: {
    VueCropper,
    SelectMediaPhoto,
  },

  props: {
    notOwnProfile: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
    },
    firstname: {
      required: true,
      type: String,
    },
    lastname: {
      required: true,
      type: String,
    },
    username: {
      required: true,
      type: String,
    },

    isLogin: Boolean,
    urlMedia: {
      required: true,
      type: String,
    },
    avatarSize: {
      default: "6em",
    },
    classThumb: {
      default: "rounded-circle avatar-100 ",
    },
  },

  data() {
    return {
      loaderAvatar: false,
      imgProfile: "",
      imgSrc: "",
      cropImg: "",
      data: null,
      showCropTools: false,
      showCropped: false,
      showGallery: false,
      imageSelected: [],
      photoPageNum: 1,
      numberOfLoad: 24,
      isLoadingPhoto: true,

      popupLimitGal: 0,
      thumbSize: "/thumbMd/",
      thumbMd: THUMB_MEDIUM,
    };
  },
  computed: {
    ...mapGetters({
      photos: "mediaList/photosData",
      isAllPhotoLoaded: "mediaList/isAllPhotoLoaded",
    }),

    profilePicture() {
      if (
        this.$store.state.auth.user &&
        Object.keys(this.$store.state.auth.user).length > 0
      ) {
        return this.$store.state.auth.user.imgProfile;
      } else {
        return "";
      }
    },
    profilePictureMedium() {
      return FILE_PATH + this.userId + THUMB_MEDIUM + this.profilePicture;
    },
    authId() {
      if (
        this.$store.state.auth.user &&
        Object.keys(this.$store.state.auth.user).length > 0
      ) {
        return this.$store.state.auth.user.id;
      } else {
        return "";
      }
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  watch: {},

  created() {},

  mounted() {
    //this for trigger to loadmore photo
    this.$root.$on("bv::modal::show", (modalId) => {
      if (modalId.componentId == "modal-gallery-pp") {
        if (this.popupLimitGal == 0) this.loadMediaPhoto();
        this.popupLimitGal = 1;
      }
    });
    /*    this.$root.$on("loadMediaPhotos", () => {
      console.log("hit dari edit pp");
      this.loadMediaPhoto();
    }); */
  },

  beforeDestroy() {
    this.$root.$off("bv::modal::show");
  },

  methods: {
    ...mapActions("mediaList", ["getMediaPhoto"]),
    showCropper() {
      this.pushToCrop(this.profilePicture);
      this.showCropTools = true;
    },
    cropImage() {
      this.showCropped = true;
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper
        .getCroppedCanvas(600, 600)
        .toDataURL("image/jpeg");
    },

    async deleteProfilePicture() {
      this.loaderAvatar = true;
      const imgProfileDel = this.profilePicture;
      this.$store
        .dispatch("user/deletePictureProfile", {
          imgProfile: imgProfileDel,
        })
        .then((response) => {
          const newImgProfile = response.data.imgProfile;
          // this.$store.state.auth.user.imgProfile = "";
          // this.profilePictureMedium = "";
          this.$root.$emit("newImgProfile", newImgProfile);
          this.imgSrc = "";
          this.$refs.cropper.replace("");
          this.showCropTools = false;
          this.$bvToast.toast(`Success Delete Picture`, {
            title: `Profile picture has been delete`,
            toaster: `b-toaster-bottom-right`,
            solid: true,
            variant: "success",
            appendToast: true,
          });
          this.loaderAvatar = false;
        })
        .catch(function () {
          // console.log(error);
        });
    },

    async pushToProfile() {
      this.loaderAvatar = true;
      const _this = this;
      this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {
        let formData = new FormData();
        formData.append("name", "image-name-" + new Date().getTime()) +
          formData.append("file", blob, ".webp");
        _this.$store
          .dispatch("user/userEditProfilePicture", formData)
          .then((response) => {
            const newImgProfile = response.imgProfile;

            _this.$nextTick(function () {
              _this.imgProfile = newImgProfile;
              this.imgSrc = this.urlMedia + "/" + newImgProfile;
              _this.showCropTools = false;
              _this.$refs.cropper.reset();

              setTimeout(() => {
                this.loaderAvatar = false;
                this.$root.$emit("newImgProfile", newImgProfile);
              }, 500);
            });
          })
          .catch(function () {
            // console.log(error);
          });
      });
    },

    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },

    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.cropImg = "";
      if (this.imgProfile) {
        this.pushToCrop(this.imgProfile);
      } else {
        this.$refs.cropper.reset();
      }
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;

          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        // alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    showFileChooser() {
      this.$refs.inputImage.click();
    },

    onUnselectSingleImage: function () {
      this.$refs["single-select-image"].removeFromSingleSelected();
    },
    cancelCrop() {
      this.imgSrc = this.urlMedia + "/" + this.imgProfile;
      if (this.$refs.cropper) {
        this.$refs.cropper.reset("");
      }
    },
    onSelectImage(data) {
      this.imageSelected = data;
    },
    async pushToCrop(imageSelected) {
      const _this = this;

      await axiosDefault({
        method: "POST",
        url: FILE_PATH + "media",
        data: {
          imgPath: this.userId + "/" + imageSelected,
        },
        responseType: "blob",
      })
        .then((response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = function () {
            var imageDataUrl = reader.result;
            _this.imgSrc = imageDataUrl;
            _this.$refs.cropper.replace(imageDataUrl);
          };
        })
        .catch((ex) => {
          console.error(ex);
        });
    },

    resetMedia() {
      this.imageSelected = [];
    },
    // //load photo
    loadMediaPhoto() {
      if (this.isAllPhotoLoaded != true) this.isLoadingPhoto = true;
      const filter = {
        numberOfLoad: this.numberOfLoad,
        userId: this.userId,
      };
      setTimeout(() => {
        this.getMediaPhoto({
          filter,
        }).then(() => {
          this.isLoadingPhoto = false;
        });
      }, 1000);
    },
  },
  destroyed() {},
};
</script>

<style>
.modal-body {
  padding: 0;
}
</style>
