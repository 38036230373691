import axiosInstance, { axiosDefault } from "@/services/instanceService";
import { uniqBy } from "lodash";
export default {
  namespaced: true,
  state: {
    usernameOwner: "",
    albumId: "",
    albumTitle: "",
    albumDesc: "",
    startLoad: 1,
    isAllPostLoaded: false,
    stateCurrent: "",
    stateFilterCurrent: "",
    numberOfLoad: 8,
    // quickfix
    albumDB: {
      album_contents: [],
      _id: "",
      album_title: "",
      album_description: "",
      album_access: "",
    },
    loadedAllMedia: false,

    usernameTarget: "",
    filter: "newest",
    isLoading: false,
    stateList: {},
    items: {},

    editedAlbumId: "",
    currentMediaId: "",
    currentMediaSource: "",
    albumListForMedia: [],
    albumListForMediaAllLoaded: false,
  },
  getters: {
    currentPostState: (state) => {
      return uniqBy(
        state.stateList[state.stateCurrent].items[state.stateFilterCurrent],
        "_id"
      );
    },
    currentPostStatusLoad: (state) => {
      return state.stateList[state.stateCurrent].isAllPostLoaded[
        state.stateFilterCurrent
      ];
    },
    currentAlbumTitle: (state) => {
      return state.albumTitle;
    },
    currentAlbumId: (state) => {
      return state.albumId;
    },
    currentAlbumDesc: (state) => {
      return state.albumDesc;
    },
    currentAlbumState: (state) => {
      return state.items;
    },
    currentAlbumStatusLoad: (state) => {
      return state.isAllPostLoaded;
    },
  },
  mutations: {
    resetAlbum(state) {
      state.albumId = "";
      state.albumTitle = "";
      state.albumDesc = "";
      state.items = {};
      state.isAllPostLoaded = false;
      state.startLoad = 1;
      state.filter = "newest";
      state.isLoading = false;
      state.stateList = {};
    },
    resetAlbumContent(state) {
      state.items = {};
    },

    resetAlbumEdit(state) {
      state.albumId = "";
      state.albumTitle = "";
      state.albumDesc = "";
      state.items = [];
    },
    albumsUpdate(state, { albumId, stateCurrent, dataEdit, filter }) {
      filter.forEach((element) => {
        if (Object.keys(state.stateList[stateCurrent].items[element]).length) {
          let index = state.stateList[stateCurrent].items[element].findIndex(
            (album) => album._id === albumId
          );
          if (index >= 0) {
            state.stateList[stateCurrent].items[element][index].album_title =
              dataEdit.album_title;
          }
        }
      });
    },
    pushtoMediaAlbum(state, data) {
      let albumId = data.albumId;
      let pushToDetail = data.pushToDetail;
      state.items[albumId].contents = [
        ...state.items[albumId].contents,
        ...pushToDetail,
      ];
      state.items = { ...state.items };
    },

    setAlbumDB(state, payload) {
      state.albumDB.album_contents = [
        ...state.albumDB.album_contents,
        ...payload.album_contents,
      ];
      state._id = payload._id;
      state.album_title = payload.album_title;
      state.album_description = payload.album_description;
      state.album_access = payload.album_access;
    },
    setLoadedAllMedia(state, payload) {
      state.loadedAllMedia = payload;
    },

    addNewStateList(state, usernameTarget) {
      state.stateList[usernameTarget] = {
        loadedAllAlbum: {
          newest: false,
          oldest: false,
        },
        startLoad: {
          newest: 1,
          oldest: 1,
        },
        items: {
          newest: [],
          oldest: [],
        },
      };
    },
    setStateList(state, payload) {
      state.stateList[state.usernameTarget].startLoad[state.filter] += 1;
      state.stateList[state.usernameTarget].loadedAllAlbum[state.filter] =
        payload.loadedAllAlbum;
      state.stateList[state.usernameTarget].items[state.filter] = [
        ...state.stateList[state.usernameTarget].items[state.filter],
        ...payload.albumsData,
      ];
      state.stateList = { ...state.stateList };
    },
    setFilter(state, payload) {
      state.filter = payload;
    },
    setUsernameTarget(state, payload) {
      state.usernameTarget = payload;
    },
    setPhotoVideoContent(state, { data, albumId }) {
      let videoContents = data.filter((i) => {
        return i.mediaType == 1;
      });
      state.items[albumId].contentVideos = [
        ...state.items[albumId].contentVideos,
        ...videoContents,
      ];
      let photoContents = data.filter((i) => {
        return i.mediaType == 2;
      });
      state.items[albumId].contentPhotos = [
        ...state.items[albumId].contentPhotos,
        ...photoContents,
      ];
      state.items = { ...state.items };
    },
    setItems(state, { albumId, getData, loadedAllMedia }) {
      state.items[albumId].contents = [
        ...state.items[albumId].contents,
        ...getData.album_contents,
      ];
      state.items[albumId].access = getData.album_access;
      state.items[albumId].description = getData.album_description;
      state.items[albumId].title = getData.album_title;
      state.items[albumId].startLoad += 1;
      state.items[albumId].loadedAllMedia = loadedAllMedia;

      state.items = { ...state.items };
    },

    setEditedAlbumId(state, payload) {
      state.editedAlbumId = payload;
    },
    setCurrentMediaId(state, payload) {
      state.currentMediaId = payload;
    },
    setAlbumListForMedia(state, payload) {
      if (payload.length) {
        state.albumListForMedia = [...state.albumListForMedia, ...payload];
      } else {
        state.albumListForMedia = [];
      }
    },
    setAlbumListForMediaAllLoaded(state, payload) {
      state.albumListForMediaAllLoaded = payload;
    },
    setCurrentMediaSource(state, payload) {
      state.currentMediaSource = payload;
    },
    resetAlbumListForMedia(state) {
      state.currentMediaId = "";
      state.currentMediaSource = "";
      state.albumListForMedia = [];
      state.albumListForMediaAllLoaded = false;
    },
  },
  actions: {
    loadAllAlbum({ state, commit }, data) {
      const usernameTarget = data.usernameTarget;
      const filter = data.filter;
      let axiosCall;
      commit("setUsernameTarget", usernameTarget);
      commit("setFilter", filter);
      let getStartNum;
      let loadedAllAlbum;

      if (!state.stateList[usernameTarget]) {
        commit(
          "setNewPostState",
          {
            resource: "albumList",
            usernameTarget: usernameTarget,
            setState: {
              loadedAllAlbum: { newest: false, oldest: false },
              startLoad: { newest: 1, oldest: 1 },
              filter: "",
              owner: "",
              items: { newest: [], oldest: [] },
            },
          },
          { root: true }
        );
        commit("addNewStateList", usernameTarget);
      }
      commit(
        "setConfigPostState",
        {
          resource: "albumList",
          usernameTarget: usernameTarget,
          setFilter: filter,
        },
        { root: true }
      );

      getStartNum = state.stateList[usernameTarget].startLoad[filter];
      loadedAllAlbum = state.stateList[usernameTarget].loadedAllAlbum[filter];
      const payload = {
        usernameTarget: usernameTarget,
        startLoad: getStartNum,
        numberOfLoad: state.numberOfLoad,
        filter: filter,
      };
      payload["numberOfLoad"] = global.albumLN;
      if (localStorage.getItem("userToken")) {
        axiosCall = axiosInstance({
          method: "POST",
          url: "album/albumLoadAll",
          data: payload,
        });
      } else {
        axiosCall = axiosDefault({
          method: "POST",
          url: "public/albumsLoadAllPublic",
          data: payload,
        });
      }
      if (!loadedAllAlbum) {
        return axiosCall
          .then((response) => {
            commit("setStateList", response.data);
            return response.data;
          })
          .catch((error) => {
            // console.log(error);
            return error;
          });
      } else {
        return;
      }
    },

    //for detail album page
    loadAlbumDetail({ state, commit }, data) {
      const { userId, albumId, postType, usernameTarget } = data;
      let axiosCall;
      // if (state.albumId != albumId) {
      //   commit("resetAlbum");
      // }

      // create new parent object (albumId) when not exist
      if (state.items[albumId] == null) {
        state.items[albumId] = {
          contents: [],
          contentPhotos: [],
          contentVideos: [],
          access: 0,
          description: "",
          title: "",
          startLoad: 1,
          loadedAllMedia: false,
        };
      }

      const payload = {
        startNum: state.items[albumId].startLoad,
        numberOfLoad: state.numberOfLoad,
        userId,
        albumId,
        postType,
        usernameTarget,
      };
      if (state.items[albumId].loadedAllMedia == false) {
        if (
          // (data.userId && data.usernameTarget) ||
          localStorage.getItem("userToken")
        ) {
          axiosCall = axiosInstance({
            method: "POST",
            url: "album/getAlbumId",
            data: payload,
          });
        } else {
          axiosCall = axiosDefault({
            method: "POST",
            url: "public/getAlbumId",
            data: payload,
          });
        }

        return axiosCall
          .then((response) => {
            const getData = response.data.albumDB;
            // const getAlbumId = getData._id;
            // const albumTitle = getData.album_title;
            // const albumDesc = getData.album_description;
            // const albumData = getData.album_contents;
            const loadedAllMedia = response.data.loadedAllMedia;
            // commit("mergeAlbum", {
            //   getAlbumId: getAlbumId,
            //   albumTitle: albumTitle,
            //   albumDesc: albumDesc,
            //   albumData: albumData,
            //   loadedAllAlbum: loadedAllMedia,
            // });
            commit("setPhotoVideoContent", {
              data: getData.album_contents,
              albumId,
            });
            commit("setItems", { albumId, getData, loadedAllMedia });
            return response.data;
          })
          .catch((error) => {
            // console.log(error);
            return error;
          });
      } else {
        return {
          albumDB: {
            album_contents: state.items[albumId].contents,
            _id: albumId,
            album_title: state.items[albumId].title,
            album_description: state.items[albumId].description,
            album_access: state.items[albumId].access,
          },
          loadedAllMedia: state.items[albumId].loadedAllMedia,
        };
      }
    },

    loadAlbumForMedia({ state, commit }, payload) {
      const numberOfLoad = state.numberOfLoad;
      const source = payload.source;
      let _idPost = payload._idPost;
      let startLoad = state.albumListForMedia.length;
      let allLoaded = state.albumListForMediaAllLoaded;
      if (_idPost == "same") {
        // for load more
        _idPost = state.currentMediaId;
      } else if (_idPost != state.currentMediaId) {
        // for first load, or change load
        commit("setCurrentMediaId", payload._idPost);
        commit("setAlbumListForMedia", []);
        commit("setAlbumListForMediaAllLoaded", false);
        startLoad = 0;
        allLoaded = false;
      }

      if (source != "same") commit("setCurrentMediaSource", source);
      if (!allLoaded) {
        const data = {
          _idPost,
          startLoad,
          numberOfLoad,
        };
        return axiosInstance({
          method: "POST",
          url: "album/loadAlbumForMedia",
          data,
        })
          .then((response) => {
            commit("setAlbumListForMedia", response.data.albumList);
            commit("setAlbumListForMediaAllLoaded", response.data.noMoreData);
          })
          .catch((error) => {
            // console.log(error);
            return error;
          });
      } else {
        return;
      }
    },

    addPhotoVideoToAlbum({ state, commit }, payload) {
      const usernameTarget = payload.username;
      if (!state.stateList[usernameTarget]) {
        commit(
          "setNewPostState",
          {
            resource: "albumList",
            usernameTarget: usernameTarget,
            setState: {
              loadedAllAlbum: { newest: false, oldest: false },
              startLoad: { newest: 1, oldest: 1 },
              filter: "",
              owner: "",
              items: { newest: [], oldest: [] },
            },
          },
          { root: true }
        );
      }

      return axiosInstance({
        method: "POST",
        url: `album/addManyPhotoVideoToAlbum/`,
        data: {
          album: payload,
        },
      })
        .then((response) => {
          const dataPost = response.data.dataAlbum;
          let filter = ["newest", "oldest"];
          let resource = "albumList";
          commit(
            "pushToPostState",
            { resource, filter, usernameTarget, dataPost },
            { root: true }
          );
          return response;
        })
        .catch((err) => {
          return err;
        });
    },

    photoToAlbum(context, data) {
      return axiosInstance
        .post("album/photoToAlbum", {
          idPhoto: data.idPhoto,
          inlistAlbum: data.inlistAlbum,
          newAlbumName: data.newAlbumName,
        })
        .then((response) => {
          this.commit("albumList/resetAlbumListForMedia");
          return response.data;
        })
        .catch((err) => {
          return err;
        });
    },

    videoToAlbum(context, data) {
      return axiosInstance
        .post("album/videoToAlbum", {
          idVideo: data.idVideo,
          inlistAlbum: data.inlistAlbum,
          newAlbumName: data.newAlbumName,
        })
        .then((response) => {
          this.commit("albumList/resetAlbumListForMedia");
          return response.data;
        })
        .catch((err) => {
          return err;
        });
    },

    updateAlbum({ commit, state }, payload) {
      const { album, deletedAlbum } = payload;
      return axiosInstance({
        method: "POST",
        url: `album/updateAlbum/`,
        data: {
          album,
          deletedAlbum,
        },
      })
        .then((response) => {
          commit("resetAlbumEdit");
          let filter = ["newest", "oldest"];
          commit("albumsUpdate", {
            albumId: album._id,
            stateCurrent: state.stateCurrent,
            dataEdit: response.data.album,
            filter: filter,
          });
          return response.data.album;
        })
        .catch((err) => {
          return err;
        });
    },

    deleteAlbum({ commit, state }, id) {
      return axiosInstance({
        method: "POST",
        url: `album/delete`,
        data: { id: id },
      })
        .then(() => {
          let filter = ["newest", "oldest"];
          commit(
            "reducePost",
            {
              resource: "albumList",
              postId: id,
              stateCurrent: state.stateCurrent,
              filter: filter,
            },
            { root: true }
          );

          return true;
        })
        .catch((err) => {
          return err;
        });
    },

    deleteAlbumItem(context, payload) {
      const { albumId, mediaId, type } = payload;
      return axiosInstance({
        method: "POST",
        url: `album/deleteAlbumItem/`,
        data: {
          albumId,
          mediaId,
          type,
        },
      });
    },

    addMediaToAlbum({ commit }, data) {
      commit("pushtoMediaAlbum", data);
      return axiosInstance
        .post("album/addMediaToAlbum", {
          albumId: data.albumId,
          mediaAlbum: data.mediaAlbum,
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
