<template>
  <div id="app">
    <vue-progress-bar v-if="!isMobileAccess"></vue-progress-bar>
    <Header v-if="!isMobileAccess" />
    <b-col v-if="showToggleApp && !isMobileAccess" class="col-12 d-lg-none">
      <b-nav class="navbar-nav ml-auto ul-app-bar">
        <b-nav-item
          class="d-block d-sm-block d-md-block d-lg-none icon-header-bar mt-1"
          v-b-toggle.sidebar-left-menu
        >
          <b-icon icon="list" animation="none" font-scale="2"></b-icon>
        </b-nav-item>
      </b-nav>
    </b-col>

    <div
      class="center-all-axis"
      align="center"
      v-if="!authLoaded && !isMobileAccess"
    >
      <img src="/images/rainbowSpinner.svg" class="loadingSpinnerSmall" />
      <br />
      <span>Loading...</span>
    </div>
    <transition>
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
import Header from "@/components/navigation/Header.vue";
import { isMobile } from "mobile-device-detect";
// import { mapMutations } from "vuex";
export default {
  name: "App",
  metaInfo: {
    title: "Mindcaster",
    titleTemplate: "%s | Mindcaster",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "Mindcaster webpage" },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      showToggleApp: true,
      isMobileAccess: isMobile,
    };
  },
  computed: {
    authLoaded() {
      return this.$store.state.auth.status.authLoaded;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    $route() {
      //  console.log(this.$route.name);
      if (!this.loggedIn) {
        /*     this.showToggleApp = this.checkToggleMenu(this.$route.name);
        if (this.showToggleApp && this.$route.name == "Intouch") {
          this.showToggleApp = !this.showToggleApp;
        }
        if (this.showToggleApp && this.$route.name == "Friend") {
          this.showToggleApp = !this.showToggleApp;
        }
        if (this.showToggleApp && this.$route.name == "Follow") {
          this.showToggleApp = !this.showToggleApp;
        } */
      }
    },
  },
  created() {
    if (!this.loggedIn) {
      this.showToggleApp = this.checkToggleMenu(this.$route.name);
    }

    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });

    this.$router.afterEach((to, from) => {
      this.$Progress.finish(to, from);
    });

    // window.addEventListener("beforeunload", this.removePersistState);
  },
  mounted() {
    this.$Progress.finish();
  },
  // methods: {
  //   ...mapMutations(["resetPersistState"]),
  //   removePersistState() {
  //     this.$store.dispatch("setNoMoreMessageToFalse");
  //     // this.resetPersistState();
  //   },
  // },
};
</script>
