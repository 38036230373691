import { uniqBy } from "lodash";
import axiosInstance, {
  axiosMediaApi,
  axiosDefault,
} from "@/services/instanceService";

export default {
  namespaced: true,
  state: {
    stateCurrent: "",
    stateFilterCurrent: "",
    stateList: {},
    toVideoDetailFromHometown: false,
  },
  getters: {
    currentPostState: (state) => {
      return uniqBy(
        state.stateList[state.stateCurrent].items[state.stateFilterCurrent],
        "_id"
      );
    },
    currentPostStatusLoad: (state) => {
      return state.stateList[state.stateCurrent].isAllPostLoaded[
        state.stateFilterCurrent
      ];
    },
  },
  actions: {
    //All videos gallery (video grid, all filter, loved & featured)
    async videosLoadAll({ state, commit, dispatch }, data) {
      const usernameTarget = data.usernameTarget;
      const counterDelete = data.counterDelete;
      const numberOfLoaded = data.numberOfLoaded;
      const filter = data.videoFilter;
      dispatch("generateState", { resource: "videoList", usernameTarget });
      commit(
        "setConfigPostState",
        {
          resource: "videoList",
          usernameTarget: usernameTarget,
          setFilter: filter,
        },
        {
          root: true,
        }
      );

      //this desition for define load API feature, loved or videoList
      let publicAPI = "";
      let privateAPI = "";
      switch (filter) {
        case "featured":
          privateAPI = "featured";
          publicAPI = "public/featuredVideoPublic";
          break;
        case "loved":
          privateAPI = "favoritedContent";
          break;
        default:
          privateAPI = "video/videosLoadAll";
          publicAPI = "public/videosLoadAllPublic";
          break;
      }
      var getStartNum = state.stateList[usernameTarget].startLoad[filter];
      var oldStatusLoad =
        state.stateList[usernameTarget].isAllPostLoaded[filter];

      if (oldStatusLoad !== true) {
        const payload = {
          usernameTarget: usernameTarget,
          startNum: getStartNum,
          numberOfLoad: numberOfLoaded,
          filter: filter,
          counterDelete: counterDelete,
        };

        let getPosts = "";
        if (localStorage.getItem("userToken")) {
          getPosts = await axiosInstance.post(`${privateAPI}`, payload);
        } else {
          getPosts = await axiosDefault.post(`${publicAPI}`, payload);
        }
        // if (data.userId) {
        //   getPosts = await axiosInstance.post(`${privateAPI}`, payload);
        // } else {
        //   getPosts = await axiosDefault.post(`${publicAPI}`, payload);
        // }
        if (getPosts) {
          const datavid = getPosts.data.data;
          var isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = datavid.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaTitle: item.mediaTitle,
              duration: item.duration,
              mediaType: 1,
              like: item.like,
              location: item.location,
              countComment: item.countComment,
              countViews: item.countViews,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              slugUrl: "video/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              tags: item.tag,
              createdAt: item.createdAt,
              mediaOwner: item.videoOwner,
              allowAccessAge: item.allowAccessAge ? item.allowAccessAge : false,
              allowAccessLevel: item.allowAccessLevel,
            };
          });
          commit(
            "mergePosts",
            {
              resource: "videoList",
              newgetPosts: newgetPosts,
              isAllPostLoaded: isAllPostLoaded,
              usernameTarget: usernameTarget,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts.length);
        } else {
          return Promise.reject("error");
        }
      }
    },
    async videoLoadDetail(context, data) {
      var userId = data.userId;
      var videoId = data.videoId;
      var isLogin = data.isLogin;
      if ((userId && isLogin) || localStorage.getItem("userToken")) {
        return await axiosInstance({
          url: "video/videoLoad/",
          method: "POST",
          data: {
            videoId: videoId,
          },
        }).then((response) => {
          return response.data;
        });
      } else {
        return await axiosDefault({
          url: "public/videoLoadPublic/",
          method: "POST",
          data: {
            videoId: videoId,
          },
        }).then((response) => {
          return response.data;
        });
      }
    },
    videoCountShare(id) {
      return axiosDefault
        .post("public/videoCountSharePublic/", {
          id: id,
        })
        .then((response) => {
          return response.data;
        });
    },
    videoViewsCounter(context, payload) {
      return axiosDefault
        .post("public/videoCounterPublic", {
          id: payload.id,
          mediaType: payload.mediaType,
        })
        .then((response) => {
          return response.data;
        });
    },
    deleteFeatured({ commit }, payload) {
      commit("removeFeatured", {
        payload,
      });
      const videoId = payload.postId;
      return axiosInstance({
        method: "POST",
        url: "featured/deleteFeatured",
        data: {
          videoId,
        },
      });
    },
    addFavorite(context, { videoId, ownerId }) {
      return axiosInstance({
        method: "POST",
        url: "favoritedContent/addFavorite",
        data: {
          videoId,
          ownerId,
        },
      });
    },
    deleteFavorite({ commit }, payload) {
      const videoId = payload.postId;
      const ownerId = payload.ownerId;
      commit("removeFavorite", {
        payload,
      });
      return axiosInstance({
        method: "POST",
        url: "favoritedContent/deleteFavorite",
        data: {
          videoId,
          ownerId,
        },
      });
    },
    cancelUploadVideo(context, payload) {
      return axiosMediaApi({
        method: "POST",
        url: "video/uploadVideoCancel",
        data: {
          videoFile: payload.mediaFile,
          imgThumbFile: payload.mediaThumbnail,
        },
      });
    },
    deleteCanceledUploadVideo(context, data) {
      return axiosMediaApi({
        method: "POST",
        url: "video/deleteCanceledUploadVideo",
        data,
      });
    },
    cancelUploadVidThumbnail(context, payload) {
      return axiosMediaApi({
        method: "POST",
        url: "video/uploadThumbVideoCancel",
        data: {
          photoData: payload,
        },
      });
    },
    submitVideo({ commit, dispatch }, payload) {
      const usernameTarget = payload.username;
      dispatch("generateState", { resource: "videoList", usernameTarget });
      return axiosInstance({
        method: "POST",
        url: "video/uploadVideoData",
        data: {
          mediaTitle: payload.mediaTitle,
          mediaDescription: payload.mediaDescription,
          safeForKids: payload.safeForKids,
          mediaAccess: payload.mediaAccess,
          mediaFile: payload.mediaFile,
          duration: payload.duration,
          qualityOption: payload.qualityOption,
          mediaThumbnail: payload.mediaThumbnail,
          cast: payload.cast,
          tag: payload.tags,
          location: payload.location,
          showLocation: payload.showLocation,
        },
      }).then((resp) => {
        let dataPost = [];
        let filter = payload.filter;
        // let filter = ["newest", "oldest", "mostview", "mostcomment"];
        let dataVid = resp.data.videos;
        dataVid.slugUrl = "video/" + dataVid._id;
        (dataVid.contentAccess = dataVid.mediaAccess), dataPost.push(dataVid);
        if (filter) {
          commit(
            "pushToPostState",
            { resource: "videoList", filter, usernameTarget, dataPost },
            { root: true }
          );

          if (payload.mediaAccess == 0) {
            dispatch("generateState", {
              resource: "showcaseList",
              usernameTarget,
            });
            commit(
              "pushToPostState",
              { resource: "showcaseList", filter, usernameTarget, dataPost },
              { root: true }
            );
          }
        }

        return dataPost;
      });
    },
    updateVideoData({ commit }, payload) {
      return axiosInstance({
        method: "POST",
        url: "video/updateVideoData",
        data: payload,
      })
        .then((result) => {
          commit("setUpdateVideo", {
            postId: payload.idVideo,
            mediaThumbnail: payload.mediaThumbnail,
            mediaTitle: payload.mediaTitle,
          });
          return result.data;
        })
        .catch((err) => {
          return err;
        });
    },
    videoDelete({ commit, state }, id) {
      return axiosMediaApi
        .post("video/deleteVideoData", {
          id,
        })
        .then((response) => {
          var filter = [
            "newest",
            "oldest",
            "mostview",
            "mostcomment",
            "loved",
            "featured",
          ];
          commit(
            "reducePost",
            {
              resource: "videoList",
              postId: id,
              stateCurrent: state.stateCurrent,
              filter: filter,
            },
            {
              root: true,
            }
          );

          var filterShowcase = ["newest", "oldest", "mostview", "mostcomment"];
          commit(
            "reducePost",
            {
              resource: "showcaseList",
              postId: id,
              stateCurrent: state.stateCurrent,
              filter: filterShowcase,
            },
            {
              root: true,
            }
          );
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    },
    editInlineVideo(contex, payload) {
      return axiosInstance({
        method: "POST",
        url: "video/updateVideoInline",
        data: {
          postId: payload.postId,
          data: payload.dataEdit,
        },
      });
    },

    //Store loadVideosLanding for load, filter, search (Worldwide)
    async loadVideosLanding({ commit, rootState }, data) {
      const { numberOfLoad, countryCode, keyword, countryCodeForSearch } = data;
      //get latest pagin state
      var getStartNum = rootState.exploreLocation
        ? rootState.exploreVideo[rootState.exploreLocation].startLoad
        : 1;
      //get latest status  load state
      var oldStatusLoad = rootState.exploreLocation
        ? rootState.exploreVideo[rootState.exploreLocation].isAllPostLoaded
        : false;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        countryCode: countryCode,
        keyword,
        countryCodeForSearch,
      };
      let getPosts = "";
      if (oldStatusLoad != true) {
        if (localStorage.getItem("userToken")) {
          getPosts = await axiosInstance.post(
            `${"video/videosLanding"}`,
            payload
          );
        } else {
          getPosts = await axiosDefault.post(
            `${"public/videosLandingPublic"}`,
            payload
          );
        }
        if (getPosts) {
          const datavid = getPosts.data.data;
          var isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = datavid.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaTitle: item.mediaTitle,
              duration: item.duration,
              mediaType: 1,
              like: item.like,
              countComment: item.countComment,
              countViews: item.countViews,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              slugUrl: "video/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.videoOwner,
              location: item.location,
              showLocation: item.showLocation,
              tags: item.tag,
            };
          });
          commit(
            "mergeVideoExplore",
            {
              newgetPosts: newgetPosts,
              isAllPostLoaded: isAllPostLoaded,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts.length);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },
    //Store loadVideosAllHometown for load, filter, search
    async loadVideosAllHometown({ commit, rootState }, data) {
      const {
        stateLocation,
        numberOfLoad,
        range,
        checkhometown,
        keyword,
        interest,
        coorLocation,
        currentPlace,
      } = data;
      let listType = keyword ? "search" : "default";
      //initilize new item state

      if (
        //   Object.keys(rootState.hometownVideo[stateLocation]).length === 0 ||
        !rootState.hometownVideo[stateLocation][listType].dataList ||
        !rootState.hometownVideo[stateLocation][listType].dataList[range] ||
        !rootState.hometownVideo[stateLocation][listType].dataList[range]
          .items ||
        !rootState.hometownVideo[stateLocation][listType].dataList[range].items[
          interest
        ]
      ) {
        commit(
          "setNewHometownState",
          {
            rootName: "hometownVideo",
            stateLocation: stateLocation,
            listType: listType,
            filter: range,
            setState: {
              isAllDataLoaded: {
                [interest]: false,
              },
              startLoad: {
                [interest]: 1,
              },
              items: {
                [interest]: [],
              },
            },
          },
          {
            root: true,
          }
        );
      }
      commit(
        "hometownConfigState",
        {
          resource: "hometownVideo",
          stateLocation: stateLocation,
          listType: listType,
          currentRage: range,
          setFilter: interest,
        },
        {
          root: true,
        }
      );

      //get latest pagin state
      var getStartNum =
        rootState.hometownVideo[stateLocation][listType].dataList[range]
          .startLoad[interest];
      //get latest status  load state
      var oldStatusLoad =
        rootState.hometownVideo[stateLocation][listType].dataList[range]
          .isAllDataLoaded[interest];
      // const oldStatusLoad =false;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        checkhometown: checkhometown,
        range,
        keyword,
        interest,
        stateLocation,
        coorLocation,
        currentPlace,
      };

      let getPosts = "";
      if (oldStatusLoad != true) {
        getPosts = await axiosInstance.post(
          `${"video/videosLoadAllHometown"}`,
          payload
        );

        if (getPosts) {
          const datavid = getPosts.data.data;
          var isAllDataLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = datavid.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaTitle: item.mediaTitle,
              duration: item.duration,
              mediaType: 1,
              like: item.like,
              countComment: item.countComment,
              countViews: item.countViews,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              slugUrl: "video/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.videoOwner,
              location: item.location,
              showLocation: item.showLocation,
              tags: item.tag,
            };
          });
          commit(
            "mergeHometownState",
            {
              rootName: "hometownVideo",
              stateLocation: stateLocation,
              listType: listType,
              filter: range,
              interest: interest,
              mergeState: newgetPosts,
              isAllDataLoaded: isAllDataLoaded,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts.length);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },
    //Store loadVideosAllCoterie for load, filter, search
    async loadVideosAllCoterie({ commit, rootState }, data) {
      const { numberOfLoad, filter, keyword } = data;
      let listType = keyword ? "search" : "default";

      if (
        !rootState.coterieVideo[listType].stateList[filter] ||
        !rootState.coterieVideo[listType].stateList[filter].items
      ) {
        commit(
          "setCoterieState",
          {
            rootName: "coterieVideo",
            listType: listType,
            contentType: "stateList",
            filter: filter,
            setState: {
              isAllPostLoaded: false,
              startLoad: 1,
              items: [],
            },
          },
          {
            root: true,
          }
        );
      }

      commit(
        "setStateType",
        {
          resource: "coterieVideo",
          listType: listType,
        },
        {
          root: true,
        }
      );
      commit(
        "setCurrentState",
        {
          resource: "coterieVideo",
          usernameTarget: filter,
        },
        {
          root: true,
        }
      );

      //get latest pagin state
      var getStartNum =
        rootState.coterieVideo[listType].stateList[filter].startLoad;
      //get latest status  load state
      let isAllPostLoaded =
        rootState.coterieVideo[listType].stateList[filter].isAllPostLoaded;

      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        filter,
        keyword,
      };

      let getPosts = "";
      if (isAllPostLoaded != true) {
        getPosts = await axiosInstance.post(
          `${"video/videosLoadAllCoterie"}`,
          payload
        );

        if (getPosts) {
          const datavid = getPosts.data.data;
          isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = datavid.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaTitle: item.mediaTitle,
              duration: item.duration,
              mediaType: 1,
              like: item.like,
              disLike: item.disLike,
              hasLike: item.hasLike,
              hasDislike: item.hasDislike,
              hasFavorite: item.hasFavorite ? item.hasFavorite : false,
              countFavorite: item.mediaFavoritedCounter
                ? item.mediaFavoritedCounter
                : item.countFavorite,
              countComment: item.countComment,
              countViews: item.countViews,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              slugUrl: "video/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.videoOwner,
              location: item.location,
              showLocation: item.showLocation,
              allowAccessPost: item.allowAccessPost,
              tags: item.tag,
            };
          });
        }
        commit(
          "mergeCoterietState",
          {
            rootName: "coterieVideo",
            listType: listType,
            contentType: "stateList",
            filter: filter,
            newgetPosts: newgetPosts,
            isAllPostLoaded: isAllPostLoaded,
          },
          {
            root: true,
          }
        );
        return Promise.resolve(newgetPosts.length);
      } else {
        return Promise.reject("error");
      }
    },
    //Store loadVideosAllOnTheGo for load, filter, search
    async loadVideosAllOnTheGo({ commit, rootState }, data) {
      const { numberOfLoad, location, range, keyword, interest } = data;
      let listType = keyword ? "search" : "default";
      //initilalize new item state
      if (
        !rootState.onthegoVideo[listType].videoList[range] ||
        !rootState.onthegoVideo[listType].videoList[range].items[interest]
      ) {
        commit(
          "setNewRootState",
          {
            rootName: "onthegoVideo",
            listType: listType,
            contentType: "videoList",
            filter: range,
            setState: {
              isAllPostLoaded: {
                [interest]: false,
              },
              startLoad: {
                [interest]: 1,
              },
              items: {
                [interest]: [],
              },
            },
          },
          {
            root: true,
          }
        );
      }
      commit(
        "setStateType",
        {
          resource: "onthegoVideo",
          listType: listType,
        },
        {
          root: true,
        }
      );
      commit(
        "setCurrentState",
        {
          resource: "onthegoVideo",
          usernameTarget: range,
        },
        {
          root: true,
        }
      );
      commit(
        "stateFilterCurrent",
        {
          resource: "onthegoVideo",
          setFilter: interest,
        },
        {
          root: true,
        }
      );
      //get latest pagin state
      var getStartNum =
        rootState.onthegoVideo[listType].videoList[range].startLoad[interest];
      //get latest status  load state
      var oldStatusLoad =
        rootState.onthegoVideo[listType].videoList[range].isAllPostLoaded[
          interest
        ];
      // const oldStatusLoad =false;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        range,
        keyword,
        interest,
        location,
      };
      let getPosts = "";
      if (oldStatusLoad != true) {
        getPosts = await axiosInstance.post(
          `${"video/videosLoadAllOnTheGo"}`,
          payload
        );

        if (getPosts) {
          const datavid = getPosts.data.data;
          var isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = datavid.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaTitle: item.mediaTitle,
              duration: item.duration,
              mediaType: 1,
              like: item.like,
              countComment: item.countComment,
              countViews: item.countViews,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              slugUrl: "video/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.videoOwner,
              location: item.location,
              showLocation: item.showLocation,
            };
          });
          commit(
            "mergeRootState",
            {
              rootName: "onthegoVideo",
              listType: listType,
              contentType: "videoList",
              filter: range,
              interest: interest,
              newgetPosts: newgetPosts,
              isAllPostLoaded: isAllPostLoaded,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },
    //Store relatedVideos for load, filter, search
    async loadRelatedVideos({ commit, rootState }, data) {
      const {
        numberOfLoad,
        tagVideos,
        keyword,
        recentVideos,
        userId,
        checkhometown,
      } = data;
      //get latest pagin state
      var getStartNum = rootState.relatedVideos.startLoad;
      //get latest status  load state
      var oldStatusLoad = rootState.relatedVideos.isAllPostLoaded;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        keyword,
        tagVideos,
        recentVideos,
        checkhometown,
      };
      let getPosts = "";
      if (oldStatusLoad != true) {
        if (userId) {
          getPosts = await axiosInstance.post(
            `${"video/loadRelatedVideos"}`,
            payload
          );
        } else {
          getPosts = await axiosDefault.post(
            `${"public/relatedVideosPublic"}`,
            payload
          );
        }

        if (getPosts) {
          const datavid = getPosts.data.data;
          var isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = datavid.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaTitle: item.mediaTitle,
              duration: item.duration,
              mediaType: 1,
              like: item.like,
              countComment: item.countComment,
              countViews: item.countViews,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              slugUrl: "video/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.videoOwner,
              tags: item.tag,
              location: item.location,
              showLocation: item.showLocation,
            };
          });
          commit(
            "mergeRelatedState",
            {
              newgetPosts: newgetPosts,
              isAllPostLoaded: isAllPostLoaded,
              latestId: recentVideos,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts.length);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },

    generateState({ commit }, { usernameTarget, resource }) {
      commit(
        "setNewPostState",
        {
          resource: resource,
          usernameTarget: usernameTarget,
          setState: {
            isAllPostLoaded: {
              newest: false,
              oldest: false,
              mostview: false,
              mostcomment: false,
              featured: false,
              loved: false,
            },
            startLoad: {
              newest: 1,
              oldest: 1,
              mostview: 1,
              mostcomment: 1,
              featured: 1,
              loved: 1,
            },
            filter: "",
            owner: "",
            items: {
              newest: [],
              oldest: [],
              mostview: [],
              mostcomment: [],
              featured: [],
              loved: [],
            },
          },
        },
        {
          root: true,
        }
      );
    },
  },
  mutations: {
    resetStateVideo(state) {
      state.stateCurrent = "";
      state.stateFilterCurrent = "";
      state.stateList = {};
      state.toVideoDetailFromHometown = false;
    },
    setToVideoDetailFromHometown(state, payload) {
      state.toVideoDetailFromHometown = payload;
    },
    removeFavorite(state, { payload }) {
      const postId = payload.postId;
      const usernameTarget = payload.usernameTarget;
      if (
        state.stateList[usernameTarget] &&
        state.stateList[usernameTarget].items.loved
      ) {
        const indexLoved = state.stateList[
          usernameTarget
        ].items.loved.findIndex((vid) => vid._id === postId);
        if (indexLoved >= 0) {
          state.stateList[usernameTarget].items.loved.splice(indexLoved, 1);
        }
      }
    },
    removeFeatured(state, { payload }) {
      const postId = payload.postId;
      const usernameTarget = payload.usernameTarget;
      if (
        state.stateList[usernameTarget] &&
        state.stateList[usernameTarget].items.featured
      ) {
        const indexFeatured = state.stateList[
          usernameTarget
        ].items.featured.findIndex((vid) => vid._id === postId);
        if (indexFeatured >= 0) {
          state.stateList[usernameTarget].items.featured.splice(
            indexFeatured,
            1
          );
        }
      }
    },
    setUpdateVideo(state, { postId, mediaThumbnail, mediaTitle }) {
      const indexNew = state.stateList[
        state.stateCurrent
      ].items.newest.findIndex((vid) => vid._id === postId);
      if (indexNew >= 0) {
        state.stateList[state.stateCurrent].items.newest[
          indexNew
        ].mediaThumbnail = mediaThumbnail;
        state.stateList[state.stateCurrent].items.newest[
          indexNew
        ].mediaTitle = mediaTitle;
      }
      const indexOld = state.stateList[
        state.stateCurrent
      ].items.oldest.findIndex((vid) => vid._id === postId);
      if (indexOld >= 0) {
        state.stateList[state.stateCurrent].items.oldest[
          indexOld
        ].mediaThumbnail = mediaThumbnail;
        state.stateList[state.stateCurrent].items.oldest[
          indexOld
        ].mediaTitle = mediaTitle;
      }
      const indexView = state.stateList[
        state.stateCurrent
      ].items.mostview.findIndex((vid) => vid._id === postId);
      if (indexView >= 0) {
        state.stateList[state.stateCurrent].items.mostview[
          indexView
        ].mediaThumbnail = mediaThumbnail;
        state.stateList[state.stateCurrent].items.mostview[
          indexView
        ].mediaTitle = mediaTitle;
      }
      const indexComment = state.stateList[
        state.stateCurrent
      ].items.mostcomment.findIndex((vid) => vid._id === postId);
      if (indexComment >= 0) {
        state.stateList[state.stateCurrent].items.mostcomment[
          indexComment
        ].mediaThumbnail = mediaThumbnail;
        state.stateList[state.stateCurrent].items.mostcomment[
          indexComment
        ].mediaTitle = mediaTitle;
      }
    },
  },
};
