import axiosInstance, { axiosDefault } from "@/services/instanceService";
import { sortBy, uniqBy } from "lodash";
/*
const today = new Date();
const date =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
const time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
const dateTime = date + " " + time;
*/
export default {
  namespaced: true,
  state: {
    intouchUser: "",
    latestId: null,
  },

  getters: {
    currentUserState: (state) => {
      return state.intouchUser;
    },
    currentIntouchState: (state) => {
      return state[state.intouchUser].items;
    },
    currentIntouchStatusLoad: (state) => {
      return state[state.intouchUser].isAllIntouchLoaded;
    },
    currentConnetionLevel: (state) => {
      return state[state.intouchUser].connectionLevel;
    },
    currentIsAdult: (state) => {
      return state[state.intouchUser].isAdult;
    },
    currentAllowCreate: (state) => {
      return state[state.intouchUser].allowCreateIntouch;
    },
  },
  actions: {
    async fetchIntouch({ commit, state }, { filter }) {
      var usernameTarget = filter.intouchUser;
      if (state.intouchUser != usernameTarget) {
        commit("resetUserTarget", usernameTarget);
      }
      var intouchId = filter.intouchId;
      if (intouchId) {
        commit("resetLatestId", intouchId);
      }

      //  if (!state[usernameTarget] || state.intouchUser != null) {
      if (!state[usernameTarget] && state.intouchUser != null) {
        // var isAllDataLoaded= false;
        commit(
          "setItemsIntouch",
          {
            resource: "intouchList",
            usernameTarget,
            setState: {
              connectionLevel: 0,
              isAdult: 1,
              allowCreateIntouch: false,
              isAllIntouchLoaded: false,
              startLoad: 1,
              items: [],
            },
          },
          {
            root: true,
          }
        );

        // commit("setAllIntouchLoaded", {usernameTarget,isAllDataLoaded});
      }
      const getStartNum = state[usernameTarget].startLoad;
      var oldStatusLoad = state[usernameTarget].isAllIntouchLoaded;

      const payload = {
        intouchId: filter.intouchId,
        intouchPageNum: getStartNum,
        intouchPageShow: filter.intouchPageShow,
        intouchUser: filter.intouchUser,
        userId: filter.userId,
      };
      let response = "";
      if (
        oldStatusLoad != true ||
        state[usernameTarget].items[0]._id != filter.intouchId
      ) {
        if (!localStorage.getItem("userToken")) {
          response = await axiosDefault.post(
            `public/getIntouchPublic`,
            payload
          );
        } else {
          response = await axiosInstance.post(`authIntouch`, payload);
        }
        if (response) {
          const {
            intouch,
            isAllDataLoaded,
            allowCreateIntouch,
            defaultPrivacy,
            isAdult,
            connectionLevel,
          } = response.data;

          commit("mergeIntouch", {
            usernameTarget,
            intouch,
            isAllDataLoaded,
          });
          commit("setIntouchAccess", {
            usernameTarget,
            allowCreateIntouch,
            connectionLevel,
            isAdult,
          });
          const res = {
            intouchLength: intouch.length,
            allowCreateIntouch: allowCreateIntouch,
            defaultPrivacy: defaultPrivacy,
            isAdult: isAdult,
            connectionLevel: connectionLevel,
          };
          return Promise.resolve(res);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },

    //Store loadIntouchAllCoterie for load, filter, search
    async loadIntouchAllCoterie({ commit, rootState }, data) {
      const { numberOfLoad, filter, keyword, interest } = data;
      let listType = keyword ? "search" : "default";
      if (interest) {
        listType = "search";
      }

      if (
        !rootState.coterieIntouch[listType].stateList[filter] ||
        !rootState.coterieIntouch[listType].stateList[filter].items
      ) {
        commit(
          "setCoterieState",
          {
            rootName: "coterieIntouch",
            listType: listType,
            contentType: "stateList",
            filter: filter,
            setState: {
              isAllPostLoaded: false,
              startLoad: 1,
              items: [],
            },
          },
          {
            root: true,
          }
        );
      }

      commit(
        "setStateType",
        {
          resource: "coterieIntouch",
          listType: listType,
        },
        {
          root: true,
        }
      );
      commit(
        "setCurrentState",
        {
          resource: "coterieIntouch",
          usernameTarget: filter,
        },
        {
          root: true,
        }
      );

      //get latest pagin state
      var getStartNum =
        rootState.coterieIntouch[listType].stateList[filter].startLoad;
      //get latest status  load state
      let isAllPostLoaded =
        rootState.coterieIntouch[listType].stateList[filter].isAllPostLoaded;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        filter,
        keyword,
        interest,
      };

      let getPosts = "";
      if (isAllPostLoaded != true) {
        getPosts = await axiosInstance.post(
          `authIntouch/intouchLoadAllCoterie`,
          payload
        );

        if (getPosts) {
          const dataIntouch = getPosts.data.intouch;
          isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = dataIntouch.map((item) => {
            return {
              _id: item._id,
              createBy: item.createBy,
              createTo: item.createTo,
              intouchText: item.intouchText ? item.intouchText : " ",
              like: item.like,
              disLike: item.disLike,
              hasLike: item.hasLike,
              hasDislike: item.hasDislike,
              countComment: item.countComment,
              intouchAccess: item.intouchAccess,
              slugUrl: "intouch/" + item._id,
              mediaList: item.mediaList,
              createdAt: item.createdAt,
              connectionLevel: item.connectionLevel,
              isAdult: item.isAdult,
              allowAccessPost: item.allowAccessPost,
              tag: item.tag,
            };
          });
        }
        commit(
          "mergeCoterietState",
          {
            rootName: "coterieIntouch",
            listType: listType,
            contentType: "stateList",
            filter: filter,
            newgetPosts: newgetPosts,
            isAllPostLoaded: isAllPostLoaded,
          },
          {
            root: true,
          }
        );
        return Promise.resolve(newgetPosts.length);
      } else {
        return Promise.reject("error");
      }
    },

    doDeleteIntouch({ state, commit }, { deleteIntouch }) {
      var intouchId = deleteIntouch.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      //remove dom before remove DB
      commit("reduceIntouch", {
        intouchIndex,
      });
      return axiosInstance
        .post(`authIntouch/delete`, deleteIntouch)
        .then(() => {
          return state[state.intouchUser].items;
        });
    },

    /*post inTouch*/
    postIntouch({ state, commit }, { intouch }) {
      return axiosInstance.post(`authIntouch/create`, intouch).then((res) => {
        const { newIntouch } = res.data;
        const index = state[state.intouchUser].items.length;
        const dataIntouch = newIntouch[0];

        if (index >= 1) {
          commit("unshiftIntouch", dataIntouch);
        } else {
          commit(
            "addIntouchToArray",
            {
              item: dataIntouch,
              index,
              resource: "intouchList",
              username: state.intouchUser,
            },
            {
              root: true,
            }
          );
        }
        return state[state.intouchUser].items;
      });
    },
    /*end post inTouch*/

    /*Edit intouch*/
    async editIntouch({ state, commit }, { intouch }) {
      var intouchId = intouch.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      return axiosInstance.post(`authIntouch/edit`, intouch).then((res) => {
        const intouchMedia = res.data.intouchMedia;
        const countUpdate = res.data.countUpdate;
        const intouchText = res.data.intouchText;
        const intouchAccess = res.data.intouchAccess;
        const tag = res.data.tag;

        commit("editIntouchState", {
          intouchIndex,
          intouchText,
          countUpdate,
          intouchMedia,
          intouchAccess,
          tag,
        });
        return intouchIndex;
      });
    },
    //this for like & dislike
    async reactionIntouch({ state, rootState, commit }, { dataPost }) {
      //return axiosInstance.put(`${API_REACTION_LIKE_POST}?content=${content}&postId=${postId}`, dataPost)
      var intouchId = dataPost.postId;
      const hasLike = dataPost.hasLike;
      const hasDislike = dataPost.hasDislike;
      const reaction = dataPost.reactionValue;
      let existReaction = null;

      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      commit("setCounterReaction", intouchId, {
        root: true,
      });
      switch (reaction) {
        case 1:
          // this check like
          if (hasLike && !hasDislike) {
            existReaction = 1;
            break;
          } else if (!hasLike && hasDislike) {
            existReaction = 0;
          }
          break;
        default:
          // this check hasdlike
          if (!hasLike && hasDislike) {
            existReaction = 0;
            break;
          } else if (hasLike && !hasDislike) {
            existReaction = 1;
          }
          break;
      }
      commit("countReaction", {
        intouchIndex: intouchIndex,
        reaction: reaction,
        existReaction: existReaction,
      });
      if (rootState.counterReactionLimit[intouchId] > 5) {
        return;
      }
      return axiosInstance
        .post(`reactionpost/doReaction`, dataPost)
        .then(() => {
          return true;
        });
    },
    /*load Comment Intouch*/
    async fetchComments({ state, commit }, { filter = {}, init }) {
      var intouchId = filter.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      if (init) {
        commit(
          "setItemsComments",
          {
            intouchIndex: intouchIndex,
            resource: "intouchList",
            items: [],
          },
          {
            root: true,
          }
        );
      }
      let response = "";
      if (!filter.userId) {
        response = await axiosDefault.post(`public/getCommentsPublic`, filter);
      } else {
        response = await axiosInstance.post(`authComments`, filter);
      }

      const { comments, isAllDataLoaded } = response.data;
      commit("setAllCommentsLoaded", {
        intouchIndex,
        isAllDataLoaded,
      });
      commit("mergeComments", {
        intouchIndex,
        comments,
      });
      return state[state.intouchUser].items;
    },

    /*Edit Comment*/
    async doEditComment({ state }, { newComment, index }) {
      var intouchId = newComment.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      return axiosInstance
        .post(`authComments/updateComments`, newComment)
        .then((res) => {
          const updatedAt = res.data.comment.updatedAt;
          state[state.intouchUser].items[intouchIndex].comments[
            index
          ].edited = 1;
          return updatedAt;
        });
    },

    /*Delete Comment*/
    async doDeleteComment({ state, commit }, { deleteComment, index }) {
      var intouchId = deleteComment.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      //Remove Dom before Remove DB
      state[state.intouchUser].items[intouchIndex].comments.splice(index, 1);
      return axiosInstance
        .post(`authComments/delete`, deleteComment)
        .then((res) => {
          const reduceIntouch = res.data.countComments;
          commit("reduceComments", {
            intouchIndex,
            reduceIntouch,
          });
          return state[state.intouchUser].items;
        });
    },

    /*post commment*/
    async postComment({ state }, { comments }) {
      return axiosInstance
        .post(`authComments/updateComments`, comments)
        .then((res) => {
          const comment = res.data.comment[0];
          var commentText = comment.commentText;
          var idComment = comment._id;
          var createdAt = comment.createdAt;
          var updatedAt = comment.updatedAt;
          var intouchId = comments.postId;
          const intouchIndex = state[state.intouchUser].items.findIndex(
            (intouchList) => intouchList._id === intouchId
          );
          let index = 0;
          if (state[state.intouchUser].items[intouchIndex].comments == null) {
            index = 0;
          } else {
            index =
              state[state.intouchUser].items[intouchIndex].comments.length;
          }
          const returnComment = {
            like: 0,
            disLike: 0,
            countReply: 0,
            commentReply: [],
            createBy: [
              {
                _id: comments.createBy,
                username: comments.username,
                firstname: comments.firstname,
                lastname: comments.lastname,
                imgProfile: comments.imgProfile,
              },
            ],
            commentText: commentText,
            updatedAt: updatedAt,
            createdAt: createdAt,
            _id: idComment,
          };

          if (index >= 1) {
            state[state.intouchUser].items[intouchIndex].comments.unshift(
              returnComment
            );
          } else {
            state[state.intouchUser].items[intouchIndex].comments.push(
              returnComment
            );
          }
          state[state.intouchUser].items[intouchIndex].countComment + 1;
          //
          state[state.intouchUser].items[intouchIndex].countComment =
            state[state.intouchUser].items[intouchIndex].countComment + 1;
        })
        .catch(() => {
          // console.log(err);
        });
    },
    /*end post commment*/
    //Load reply comment
    async fetchReplyComments({ state, commit }, { filter = {}, index }) {
      var intouchId = filter.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      let response = "";
      if (!filter.userId) {
        response = await axiosDefault.post(`public/getReplyPublic`, filter);
      } else {
        response = await axiosInstance.post(`authComments/getreply`, filter);
      }
      const { replied, isAllReplyLoaded } = response.data;

      commit("setAllReplyLoaded", {
        isAllReplyLoaded,
        intouchIndex,
        index,
      });
      commit("mergeReplys", {
        replied,
        intouchIndex,
        index,
      });
      return true;
      //   return state[state.intouchUser].items[intouchIndex].setAllReplyLoaded;
    },
    //End Load reply comment

    //Do Post reply comment
    async postReply({ commit, state }, { replyComments, index }) {
      var intouchId = replyComments.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      return axiosInstance
        .post(`authComments/updateReply`, replyComments)
        .then((res) => {
          var replyData = res.data.reply[0];
          const commentReply = {
            replyBy: [
              {
                _id: replyData.replyBy,
                username: replyComments.username,
                firstname: replyComments.firstname,
                lastname: replyComments.lastname,
                imgProfile: replyComments.imgProfile,
              },
            ],
            replyTo: [
              {
                _id: replyData.replyTo,
                firstname: replyComments.replyFirstnameTo,
                lastname: replyComments.replyLastnameTo,
                username: replyComments.replyUsernameTo,
              },
            ],
            replyLike: 0,
            replyDislike: 0,
            _id: replyData._id,
            replyText: replyData.replyText,
            updatedAt: replyData.updatedAt,
            createdAt: replyData.createdAt,
          };
          commit("addReplyToComment", {
            reply: commentReply,
            commentId: replyComments.commentId,
            intouchIndex: intouchIndex,
            index: index,
          });
          return commentReply;
        });
    },
    //end Post reply comment

    /** EDIT REPLY */
    doEditReply({ state }, { index, indexReply, newReply }) {
      var intouchId = newReply.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      return axiosInstance
        .post(`authComments/updateReply`, newReply)
        .then(() => {
          state[state.intouchUser].items[intouchIndex].comments[
            index
          ].commentReply[indexReply].edited = 1;
          return state[state.intouchUser].items;
        });
    },

    /** DELETE REPLY */
    doDeleteReply({ state }, { deleteReply, index, indexReply }) {
      var intouchId = deleteReply.postId;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );

      //Remove DOM Reply Comment before Remove DB
      state[state.intouchUser].items[intouchIndex].comments[
        index
      ].commentReply.splice(indexReply, 1);
      if (
        state[state.intouchUser].items[intouchIndex].comments[index]
          .countReply > 0
      ) {
        state[state.intouchUser].items[intouchIndex].comments[index]
          .countReply--;
      }
      if (state[state.intouchUser].items[intouchIndex].countComment > 0) {
        state[state.intouchUser].items[intouchIndex].countComment--;
      }

      return axiosInstance
        .post(`authComments/deletereply`, deleteReply)
        .then(() => {
          return state[state.intouchUser].items[index];
        });
    },

    //do reaction
    doReactionComment(
      { state, rootState, commit },
      { index, reactionComments }
    ) {
      var intouchId = reactionComments.postId;
      const commentId = reactionComments.commentId;
      const hasLike = reactionComments.hasLike;
      const hasDislike = reactionComments.hasDislike;
      const reaction = reactionComments.reactionValue;
      let existReaction = null;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      commit("setCounterReactionComment", commentId, {
        root: true,
      });
      switch (reaction) {
        case 1:
          // this check like
          if (hasLike && !hasDislike) {
            existReaction = 1;
            break;
          } else if (!hasLike && hasDislike) {
            existReaction = 0;
          }
          break;
        default:
          // this check hasdlike
          if (!hasLike && hasDislike) {
            existReaction = 0;
            break;
          } else if (hasLike && !hasDislike) {
            existReaction = 1;
          }
          break;
      }
      commit("countReactionComment", {
        intouchIndex: intouchIndex,
        commentIndex: index,
        reaction: reaction,
        existReaction: existReaction,
      });
      if (rootState.reactionCommentLimit[commentId] > 5) {
        return;
      }
      return axiosInstance
        .post(`reactionComment/doReactionComment`, reactionComments)
        .then((resp) => {
          const response = resp.data;
          const reaction = response.reaction;

          return reaction;
        });
    },

    //do reaction Reply
    doReactionReply(
      { state, rootState, commit },
      { index, indexReply, reactionReplyComments }
    ) {
      var intouchId = reactionReplyComments.postId;
      const replyId = reactionReplyComments.replyId;
      const hasLike = reactionReplyComments.hasLike;
      const hasDislike = reactionReplyComments.hasDislike;
      const reaction = reactionReplyComments.reactionValue;
      let existReaction = null;
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === intouchId
      );
      commit("setCounterReactionReply", replyId, {
        root: true,
      });
      switch (reaction) {
        case 1:
          // this check like
          if (hasLike && !hasDislike) {
            existReaction = 1;
            break;
          } else if (!hasLike && hasDislike) {
            existReaction = 0;
          }
          break;
        default:
          // this check hasdlike
          if (!hasLike && hasDislike) {
            existReaction = 0;
            break;
          } else if (hasLike && !hasDislike) {
            existReaction = 1;
          }
          break;
      }
      commit("countReactionReply", {
        intouchIndex: intouchIndex,
        indexC: index,
        indexReply: indexReply,
        reaction: reaction,
        existReaction: existReaction,
      });

      if (rootState.reactionReplyLimit[replyId] > 5) {
        return;
      }
      return axiosInstance
        .post(`reactionReply/doReactionReply`, reactionReplyComments)
        .then((resp) => {
          const response = resp.data;
          const reaction = response.reaction;
          return reaction;
        });
    },

    //do reset state before sort
    fetchResetComments({ state, commit }, { postId }) {
      const intouchIndex = state[state.intouchUser].items.findIndex(
        (intouchList) => intouchList._id === postId
      );
      commit("resetComents", {
        intouchIndex,
      });
      return true;
    },
  },

  mutations: {
    afterEditVideo(state, payloadBack) {
      // this mutation  thumbnail intouch
      if (state[state.intouchUser].items.length)
        state[state.intouchUser].items.forEach((item) => {
          if (item.mediaList.length)
            item.mediaList.forEach((media) => {
              if (media.mediaId == payloadBack.idVideo) {
                media.mediaThumbnail = payloadBack.mediaThumbnail;
              }
            });
        });
    },

    resetIntouchUserTarget(state, { usernameTarget }) {
      delete state[usernameTarget];
    },

    resetUserTarget(state, usernameTarget) {
      state.intouchUser = usernameTarget;
    },
    resetLatestId(state, latestId) {
      state.latestId = latestId;
    },
    unshiftIntouch(state, dataIntouch) {
      state[state.intouchUser].items.unshift(dataIntouch);
    },
    //add reply comment to stage
    addReplyToComment(state, { reply, commentId, intouchIndex }) {
      const commentIndex = state[state.intouchUser].items[
        intouchIndex
      ].comments.findIndex((comments) => comments._id === commentId);

      state[state.intouchUser].items[intouchIndex].comments[
        commentIndex
      ].commentReply.push(reply);
      // }
      state[state.intouchUser].items[intouchIndex].countComment++;
      state[state.intouchUser].items[intouchIndex].comments[commentIndex]
        .countReply++;
      return reply;
    },
    //end reply comment to stage
    countReaction(state, { intouchIndex, reaction, existReaction }) {
      switch (reaction) {
        //for like
        case 1:
          switch (existReaction) {
            //check if haslike
            case 1:
              state[state.intouchUser].items[intouchIndex].like--;
              state[state.intouchUser].items[intouchIndex].hasLike = false;
              break;
            case 0:
              //check if hasDislike
              state[state.intouchUser].items[intouchIndex].disLike--;
              state[state.intouchUser].items[intouchIndex].hasDislike = false;
              state[state.intouchUser].items[intouchIndex].like++;
              state[state.intouchUser].items[intouchIndex].hasLike = true;
              break;
            default:
              state[state.intouchUser].items[intouchIndex].like++;
              state[state.intouchUser].items[intouchIndex].hasLike = true;
              break;
          }
          break;
        default:
          //for dislike
          switch (existReaction) {
            //check if hasDislike
            case 0:
              state[state.intouchUser].items[intouchIndex].disLike--;
              state[state.intouchUser].items[intouchIndex].hasDislike = false;
              break;
            //check if haslike
            case 1:
              state[state.intouchUser].items[intouchIndex].like--;
              state[state.intouchUser].items[intouchIndex].hasLike = false;
              state[state.intouchUser].items[intouchIndex].disLike++;
              state[state.intouchUser].items[intouchIndex].hasDislike = true;
              break;
            default:
              state[state.intouchUser].items[intouchIndex].disLike++;
              state[state.intouchUser].items[intouchIndex].hasDislike = true;
              break;
          }
          break;
      }
    },
    /* has reaction Comment mutation*/
    countReactionComment(
      state,
      { intouchIndex, commentIndex, reaction, existReaction }
    ) {
      switch (reaction) {
        case 1:
          switch (existReaction) {
            //check if haslike
            case 1:
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].like--;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].hasLike = false;
              break;
            case 0:
              //check if hasDislike
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].disLike--;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].hasDislike = false;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].like++;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].hasLike = true;
              break;
            default:
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].like++;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].hasLike = true;
              break;
          }
          break;

        default:
          //for dislike
          switch (existReaction) {
            //check if hasDislike
            case 0:
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].disLike--;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].hasDislike = false;
              break;
            //check if haslike
            case 1:
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].like--;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].hasLike = false;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].disLike++;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].hasDislike = true;
              break;
            default:
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].disLike++;
              state[state.intouchUser].items[intouchIndex].comments[
                commentIndex
              ].hasDislike = true;
              break;
          }
          break;
      }
    },
    /* has reaction reply mutation*/
    countReactionReply(
      state,
      { intouchIndex, indexC, indexReply, reaction, existReaction }
    ) {
      switch (reaction) {
        case 1:
          switch (existReaction) {
            //check if haslike
            case 1:
              state[state.intouchUser].items[intouchIndex].comments[indexC]
                .commentReply[indexReply].replyLike--;
              state[state.intouchUser].items[intouchIndex].comments[
                indexC
              ].commentReply[indexReply].hasLikeReply = false;
              break;
            case 0:
              //check if hasDislike
              state[state.intouchUser].items[intouchIndex].comments[indexC]
                .commentReply[indexReply].replyDislike--;
              state[state.intouchUser].items[intouchIndex].comments[
                indexC
              ].commentReply[indexReply].hasDislikeReply = false;
              state[state.intouchUser].items[intouchIndex].comments[indexC]
                .commentReply[indexReply].replyLike++;
              state[state.intouchUser].items[intouchIndex].comments[
                indexC
              ].commentReply[indexReply].hasLikeReply = true;
              break;
            default:
              state[state.intouchUser].items[intouchIndex].comments[indexC]
                .commentReply[indexReply].replyLike++;
              state[state.intouchUser].items[intouchIndex].comments[
                indexC
              ].commentReply[indexReply].hasLikeReply = true;
              break;
          }
          break;

        default:
          //for dislike
          switch (existReaction) {
            //check if hasDislike
            case 0:
              state[state.intouchUser].items[intouchIndex].comments[indexC]
                .commentReply[indexReply].replyDislike--;
              state[state.intouchUser].items[intouchIndex].comments[
                indexC
              ].commentReply[indexReply].hasDislikeReply = false;
              break;
            //check if haslike
            case 1:
              state[state.intouchUser].items[intouchIndex].comments[indexC]
                .commentReply[indexReply].replyLike--;
              state[state.intouchUser].items[intouchIndex].comments[
                indexC
              ].commentReply[indexReply].hasLikeReply = false;
              state[state.intouchUser].items[intouchIndex].comments[indexC]
                .commentReply[indexReply].replyDislike++;
              state[state.intouchUser].items[intouchIndex].comments[
                indexC
              ].commentReply[indexReply].hasDislikeReply = true;
              break;
            default:
              state[state.intouchUser].items[intouchIndex].comments[indexC]
                .commentReply[indexReply].replyDislike++;
              state[state.intouchUser].items[intouchIndex].comments[
                indexC
              ].commentReply[indexReply].hasDislikeReply = true;
              break;
          }
          break;
      }
    },

    mergeIntouch(state, { usernameTarget, intouch, isAllDataLoaded }) {
      state[usernameTarget].isAllIntouchLoaded = isAllDataLoaded;
      state[usernameTarget].items = [
        ...state[usernameTarget].items,
        ...intouch,
      ];
      if (!isAllDataLoaded) {
        state[usernameTarget].startLoad++;
      }
    },
    setIntouchAccess(
      state,
      { usernameTarget, allowCreateIntouch, connectionLevel, isAdult }
    ) {
      state[usernameTarget].allowCreateIntouch = allowCreateIntouch;
      state[usernameTarget].connectionLevel = connectionLevel;
      state[usernameTarget].isAdult = isAdult;
    },
    editIntouchState(
      state,
      {
        intouchIndex,
        intouchText,
        countUpdate,
        intouchMedia,
        intouchAccess,
        tag,
      }
    ) {
      state[state.intouchUser].items[intouchIndex].intouchText = intouchText;
      state[state.intouchUser].items[intouchIndex].countUpdate = countUpdate;
      state[state.intouchUser].items[intouchIndex].mediaList = intouchMedia;
      state[state.intouchUser].items[
        intouchIndex
      ].intouchAccess = intouchAccess;
      state[state.intouchUser].items[intouchIndex].tag = tag;
    },
    reduceIntouch(state, intouch) {
      state[state.intouchUser].items.splice(intouch, 1);
    },

    setAllCommentsLoaded(state, { intouchIndex, isAllDataLoaded }) {
      state[state.intouchUser].items[
        intouchIndex
      ].isAllCommentsLoaded = isAllDataLoaded;
    },
    mergeComments(state, { intouchIndex, comments }) {
      state[state.intouchUser].items[intouchIndex].comments = [
        ...state[state.intouchUser].items[intouchIndex].comments,
        ...comments,
      ];
      //  console.log( state[state.intouchUser].items[intouchIndex])
    },
    reduceComments(state, { intouchIndex, reduceIntouch }) {
      if (state[state.intouchUser].items[intouchIndex].countComment > 0) {
        state[state.intouchUser].items[intouchIndex].countComment =
          state[state.intouchUser].items[intouchIndex].countComment -
          reduceIntouch;
      }
      // state[state.intouchUser].items[intouchIndex].comments.splice(index, 1);
    },

    resetComents(state, { intouchIndex }) {
      state[state.intouchUser].items[intouchIndex].comments = [];
      state[state.intouchUser].items[intouchIndex].isAllCommentsLoaded = true;
    },

    updateReply(state, { intouchIndex, index, indexReply, updatedAt }) {
      state[state.intouchUser].items[intouchIndex].comments[index].commentReply[
        indexReply
      ].updatedAt = updatedAt;
    },
    setAllReplyLoaded(state, { isAllReplyLoaded, intouchIndex, index }) {
      state[state.intouchUser].items[intouchIndex].comments[
        index
      ].setAllReplyLoaded = isAllReplyLoaded;
    },
    mergeReplys(state, { replied, intouchIndex, index }) {
      state[state.intouchUser].items[intouchIndex].comments[
        index
      ].commentReply = sortBy(
        uniqBy(
          [
            ...state[state.intouchUser].items[intouchIndex].comments[index]
              .commentReply,
            ...replied,
          ],
          "_id"
        ),
        "createdAt"
      );
    },
  },
};
