import axiosInstance, { axiosDefault } from "@/services/instanceService";
let user = "";
const initialState = user
  ? {
      status: { loggedIn: true, authLoaded: false },
      user,
      userToken: JSON.parse(localStorage.getItem("userToken")) || null,
      logoutTrigger: false,
    }
  : {
      status: { loggedIn: false, authLoaded: true },
      user: null,
      userToken: null,
      logoutTrigger: false,
    };
export const auth = {
  namespaced: true,
  state: initialState,
  mutations: {
    loginSuccess(state, { userData, token }) {
      state.status.loggedIn = true;
      state.status = { ...state.status };
      state.user = userData;
      state.userToken = token;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.userToken = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.userToken = null;
    },
    changePhoto(state, { newuser }) {
      state.user = newuser;
    },
    setAuthLoaded(state, payload) {
      state.status.authLoaded = payload;
    },
    setLogoutTrigger(state, payload) {
      state.logoutTrigger = payload;
    },
  },
  actions: {
    login({ commit }, user) {
      return axiosDefault
        .post(`auth/login`, {
          email: user.email,
          password: user.password,
        })
        .then((response) => {
          let userData = {};
          let setuserToken = "";
          if (response.data.token) {
            userData = response.data.user;
            setuserToken = response.data.token;
            localStorage.setItem("userToken", JSON.stringify(setuserToken));

            /*      commit(
              "setExplorerLocation",
              {
                payload: userData.location.country_code,
                routeName: "exploreLocation",
              },
              { root: true }
            ); */
            commit("loginSuccess", { userData: userData, token: setuserToken });
            commit("setAuthLoaded", true);
            return userData;
          }
        })
        .catch((err) => {
          commit("loginFailure");
          commit("setAuthLoaded", true);
          return Promise.reject(err);
        });
    },
    logout({ commit }) {
      localStorage.removeItem("userToken");
      localStorage.removeItem("location");
      // localStorage.removeItem('user');
      this.commit("resetFollowState");
      commit("logout");
      return Promise.resolve("");
    },
    getdataLogin({ commit, state }) {
      let userToken = JSON.parse(localStorage.getItem("userToken")) || null;
      if (userToken && userToken != null && userToken != "") {
        return axiosInstance
          .post("user/getUserLogin")
          .then((response) => {
            let userData = {};
            if (response.data.user) {
              userData = response.data.user;
              /*   commit(
                "setExplorerLocation",
                {
                  payload: userData.location.country_code,
                  routeName: "exploreLocation",
                },
                { root: true }
              ); */
              commit("loginSuccess", {
                userData: userData,
                token: userToken,
              });
              commit("setAuthLoaded", true);
              if (
                userData.account_status >= 3 &&
                userData.account_status <= 5 &&
                state.status.loggedIn == true
              ) {
                commit("setLogoutTrigger", true);
              }
              return true;
            }
          })
          .catch((err) => {
            commit("setAuthLoaded", true);
            commit("loginFailure");
            return err;
          });
      } else if (userToken != "" && state.status.loggedIn) {
        commit("setAuthLoaded", true);
        return true;
      } else {
        commit("setAuthLoaded", true);
        return false;
      }
    },
  },
};
