<template>
  <div class="container-home">
    <NavbarTab />
    <b-container
      fluid
      id="content-home"
      class="content-home"
      :class="{ galleryPage: scrollPosition > 144 }"
    >
      <router-view :key="$route.path" />
    </b-container>
  </div>
</template>

<script>
import NavbarTab from "@/components/navigation/NavbarTab.vue";
export default {
  name: "Home",
  components: {
    NavbarTab,
  },
  data() {
    return {
      scrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScrollHome);
  },
  methods: {
    updateScrollHome: function() {
      this.scrollPosition = window.scrollY;
      // this.$root.$emit("handleScrollHome",  this.scrollPosition);
    },
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>
