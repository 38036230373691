export const countryList = [
  {
    text: "World",
    dialCode: "---",
    value: "WW",
  },
  {
    text: "Afghanistan",
    dialCode: "+93",
    value: "AF",
  },
  {
    text: "Aland Islands",
    dialCode: "+358",
    value: "AX",
  },
  {
    text: "Albania",
    dialCode: "+355",
    value: "AL",
  },
  {
    text: "Algeria",
    dialCode: "+213",
    value: "DZ",
  },
  {
    text: "AmericanSamoa",
    dialCode: "+1684",
    value: "AS",
  },
  {
    text: "Andorra",
    dialCode: "+376",
    value: "AD",
  },
  {
    text: "Angola",
    dialCode: "+244",
    value: "AO",
  },
  {
    text: "Anguilla",
    dialCode: "+1264",
    value: "AI",
  },
  {
    text: "Antarctica",
    dialCode: "+672",
    value: "AQ",
  },
  {
    text: "Antigua and Barbuda",
    dialCode: "+1268",
    value: "AG",
  },
  {
    text: "Argentina",
    dialCode: "+54",
    value: "AR",
  },
  {
    text: "Armenia",
    dialCode: "+374",
    value: "AM",
  },
  {
    text: "Aruba",
    dialCode: "+297",
    value: "AW",
  },
  {
    text: "Australia",
    dialCode: "+61",
    value: "AU",
  },
  {
    text: "Austria",
    dialCode: "+43",
    value: "AT",
  },
  {
    text: "Azerbaijan",
    dialCode: "+994",
    value: "AZ",
  },
  {
    text: "Bahamas",
    dialCode: "+1242",
    value: "BS",
  },
  {
    text: "Bahrain",
    dialCode: "+973",
    value: "BH",
  },
  {
    text: "Bangladesh",
    dialCode: "+880",
    value: "BD",
  },
  {
    text: "Barbados",
    dialCode: "+1246",
    value: "BB",
  },
  {
    text: "Belarus",
    dialCode: "+375",
    value: "BY",
  },
  {
    text: "Belgium",
    dialCode: "+32",
    value: "BE",
  },
  {
    text: "Belize",
    dialCode: "+501",
    value: "BZ",
  },
  {
    text: "Benin",
    dialCode: "+229",
    value: "BJ",
  },
  {
    text: "Bermuda",
    dialCode: "+1441",
    value: "BM",
  },
  {
    text: "Bhutan",
    dialCode: "+975",
    value: "BT",
  },
  {
    text: "Bolivia, Plurinational State of",
    dialCode: "+591",
    value: "BO",
  },
  {
    text: "Bosnia and Herzegovina",
    dialCode: "+387",
    value: "BA",
  },
  {
    text: "Botswana",
    dialCode: "+267",
    value: "BW",
  },
  {
    text: "Brazil",
    dialCode: "+55",
    value: "BR",
  },
  {
    text: "British Indian Ocean Territory",
    dialCode: "+246",
    value: "IO",
  },
  {
    text: "Brunei Darussalam",
    dialCode: "+673",
    value: "BN",
  },
  {
    text: "Bulgaria",
    dialCode: "+359",
    value: "BG",
  },
  {
    text: "Burkina Faso",
    dialCode: "+226",
    value: "BF",
  },
  {
    text: "Burundi",
    dialCode: "+257",
    value: "BI",
  },
  {
    text: "Cambodia",
    dialCode: "+855",
    value: "KH",
  },
  {
    text: "Cameroon",
    dialCode: "+237",
    value: "CM",
  },
  {
    text: "Canada",
    dialCode: "+1",
    value: "CA",
  },
  {
    text: "Cape Verde",
    dialCode: "+238",
    value: "CV",
  },
  {
    text: "Cayman Islands",
    dialCode: "+1345",
    value: "KY",
  },
  {
    text: "Central African Republic",
    dialCode: "+236",
    value: "CF",
  },
  {
    text: "Chad",
    dialCode: "+235",
    value: "TD",
  },
  {
    text: "Chile",
    dialCode: "+56",
    value: "CL",
  },
  {
    text: "China",
    dialCode: "+86",
    value: "CN",
  },
  {
    text: "Christmas Island",
    dialCode: "+61",
    value: "CX",
  },
  {
    text: "Cocos (Keeling) Islands",
    dialCode: "+61",
    value: "CC",
  },
  {
    text: "Colombia",
    dialCode: "+57",
    value: "CO",
  },
  {
    text: "Comoros",
    dialCode: "+269",
    value: "KM",
  },
  {
    text: "Congo",
    dialCode: "+242",
    value: "CG",
  },
  {
    text: "Congo, The Democratic Republic of the Congo",
    dialCode: "+243",
    value: "CD",
  },
  {
    text: "Cook Islands",
    dialCode: "+682",
    value: "CK",
  },
  {
    text: "Costa Rica",
    dialCode: "+506",
    value: "CR",
  },
  {
    text: "Cote d'Ivoire",
    dialCode: "+225",
    value: "CI",
  },
  {
    text: "Croatia",
    dialCode: "+385",
    value: "HR",
  },
  {
    text: "Cuba",
    dialCode: "+53",
    value: "CU",
  },
  {
    text: "Cyprus",
    dialCode: "+357",
    value: "CY",
  },
  {
    text: "Czech Republic",
    dialCode: "+420",
    value: "CZ",
  },
  {
    text: "Denmark",
    dialCode: "+45",
    value: "DK",
  },
  {
    text: "Djibouti",
    dialCode: "+253",
    value: "DJ",
  },
  {
    text: "Dominica",
    dialCode: "+1767",
    value: "DM",
  },
  {
    text: "Dominican Republic",
    dialCode: "+1849",
    value: "DO",
  },
  {
    text: "Ecuador",
    dialCode: "+593",
    value: "EC",
  },
  {
    text: "Egypt",
    dialCode: "+20",
    value: "EG",
  },
  {
    text: "El Salvador",
    dialCode: "+503",
    value: "SV",
  },
  {
    text: "Equatorial Guinea",
    dialCode: "+240",
    value: "GQ",
  },
  {
    text: "Eritrea",
    dialCode: "+291",
    value: "ER",
  },
  {
    text: "Estonia",
    dialCode: "+372",
    value: "EE",
  },
  {
    text: "Ethiopia",
    dialCode: "+251",
    value: "ET",
  },
  {
    text: "Falkland Islands (Malvinas)",
    dialCode: "+500",
    value: "FK",
  },
  {
    text: "Faroe Islands",
    dialCode: "+298",
    value: "FO",
  },
  {
    text: "Fiji",
    dialCode: "+679",
    value: "FJ",
  },
  {
    text: "Finland",
    dialCode: "+358",
    value: "FI",
  },
  {
    text: "France",
    dialCode: "+33",
    value: "FR",
  },
  {
    text: "French Guiana",
    dialCode: "+594",
    value: "GF",
  },
  {
    text: "French Polynesia",
    dialCode: "+689",
    value: "PF",
  },
  {
    text: "Gabon",
    dialCode: "+241",
    value: "GA",
  },
  {
    text: "Gambia",
    dialCode: "+220",
    value: "GM",
  },
  {
    text: "Georgia",
    dialCode: "+995",
    value: "GE",
  },
  {
    text: "Germany",
    dialCode: "+49",
    value: "DE",
  },
  {
    text: "Ghana",
    dialCode: "+233",
    value: "GH",
  },
  {
    text: "Gibraltar",
    dialCode: "+350",
    value: "GI",
  },
  {
    text: "Greece",
    dialCode: "+30",
    value: "GR",
  },
  {
    text: "Greenland",
    dialCode: "+299",
    value: "GL",
  },
  {
    text: "Grenada",
    dialCode: "+1473",
    value: "GD",
  },
  {
    text: "Guadeloupe",
    dialCode: "+590",
    value: "GP",
  },
  {
    text: "Guam",
    dialCode: "+1671",
    value: "GU",
  },
  {
    text: "Guatemala",
    dialCode: "+502",
    value: "GT",
  },
  {
    text: "Guernsey",
    dialCode: "+44",
    value: "GG",
  },
  {
    text: "Guinea",
    dialCode: "+224",
    value: "GN",
  },
  {
    text: "Guinea-Bissau",
    dialCode: "+245",
    value: "GW",
  },
  {
    text: "Guyana",
    dialCode: "+595",
    value: "GY",
  },
  {
    text: "Haiti",
    dialCode: "+509",
    value: "HT",
  },
  {
    text: "Holy See (Vatican City State)",
    dialCode: "+379",
    value: "VA",
  },
  {
    text: "Honduras",
    dialCode: "+504",
    value: "HN",
  },
  {
    text: "Hong Kong",
    dialCode: "+852",
    value: "HK",
  },
  {
    text: "Hungary",
    dialCode: "+36",
    value: "HU",
  },
  {
    text: "Iceland",
    dialCode: "+354",
    value: "IS",
  },
  {
    text: "India",
    dialCode: "+91",
    value: "IN",
  },
  {
    text: "Indonesia",
    dialCode: "+62",
    value: "ID",
  },
  {
    text: "Iran, Islamic Republic of Persian Gulf",
    dialCode: "+98",
    value: "IR",
  },
  {
    text: "Iraq",
    dialCode: "+964",
    value: "IQ",
  },
  {
    text: "Ireland",
    dialCode: "+353",
    value: "IE",
  },
  {
    text: "Isle of Man",
    dialCode: "+44",
    value: "IM",
  },
  {
    text: "Israel",
    dialCode: "+972",
    value: "IL",
  },
  {
    text: "Italy",
    dialCode: "+39",
    value: "IT",
  },
  {
    text: "Jamaica",
    dialCode: "+1876",
    value: "JM",
  },
  {
    text: "Japan",
    dialCode: "+81",
    value: "JP",
  },
  {
    text: "Jersey",
    dialCode: "+44",
    value: "JE",
  },
  {
    text: "Jordan",
    dialCode: "+962",
    value: "JO",
  },
  {
    text: "Kazakhstan",
    dialCode: "+77",
    value: "KZ",
  },
  {
    text: "Kenya",
    dialCode: "+254",
    value: "KE",
  },
  {
    text: "Kiribati",
    dialCode: "+686",
    value: "KI",
  },
  {
    text: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    value: "KP",
  },
  {
    text: "Korea, Republic of South Korea",
    dialCode: "+82",
    value: "KR",
  },
  {
    text: "Kuwait",
    dialCode: "+965",
    value: "KW",
  },
  {
    text: "Kyrgyzstan",
    dialCode: "+996",
    value: "KG",
  },
  {
    text: "Laos",
    dialCode: "+856",
    value: "LA",
  },
  {
    text: "Latvia",
    dialCode: "+371",
    value: "LV",
  },
  {
    text: "Lebanon",
    dialCode: "+961",
    value: "LB",
  },
  {
    text: "Lesotho",
    dialCode: "+266",
    value: "LS",
  },
  {
    text: "Liberia",
    dialCode: "+231",
    value: "LR",
  },
  {
    text: "Libyan Arab Jamahiriya",
    dialCode: "+218",
    value: "LY",
  },
  {
    text: "Liechtenstein",
    dialCode: "+423",
    value: "LI",
  },
  {
    text: "Lithuania",
    dialCode: "+370",
    value: "LT",
  },
  {
    text: "Luxembourg",
    dialCode: "+352",
    value: "LU",
  },
  {
    text: "Macao",
    dialCode: "+853",
    value: "MO",
  },
  {
    text: "Macedonia",
    dialCode: "+389",
    value: "MK",
  },
  {
    text: "Madagascar",
    dialCode: "+261",
    value: "MG",
  },
  {
    text: "Malawi",
    dialCode: "+265",
    value: "MW",
  },
  {
    text: "Malaysia",
    dialCode: "+60",
    value: "MY",
  },
  {
    text: "Maldives",
    dialCode: "+960",
    value: "MV",
  },
  {
    text: "Mali",
    dialCode: "+223",
    value: "ML",
  },
  {
    text: "Malta",
    dialCode: "+356",
    value: "MT",
  },
  {
    text: "Marshall Islands",
    dialCode: "+692",
    value: "MH",
  },
  {
    text: "Martinique",
    dialCode: "+596",
    value: "MQ",
  },
  {
    text: "Mauritania",
    dialCode: "+222",
    value: "MR",
  },
  {
    text: "Mauritius",
    dialCode: "+230",
    value: "MU",
  },
  {
    text: "Mayotte",
    dialCode: "+262",
    value: "YT",
  },
  {
    text: "Mexico",
    dialCode: "+52",
    value: "MX",
  },
  {
    text: "Micronesia, Federated States of Micronesia",
    dialCode: "+691",
    value: "FM",
  },
  {
    text: "Moldova",
    dialCode: "+373",
    value: "MD",
  },
  {
    text: "Monaco",
    dialCode: "+377",
    value: "MC",
  },
  {
    text: "Mongolia",
    dialCode: "+976",
    value: "MN",
  },
  {
    text: "Montenegro",
    dialCode: "+382",
    value: "ME",
  },
  {
    text: "Montserrat",
    dialCode: "+1664",
    value: "MS",
  },
  {
    text: "Morocco",
    dialCode: "+212",
    value: "MA",
  },
  {
    text: "Mozambique",
    dialCode: "+258",
    value: "MZ",
  },
  {
    text: "Myanmar",
    dialCode: "+95",
    value: "MM",
  },
  {
    text: "Namibia",
    dialCode: "+264",
    value: "NA",
  },
  {
    text: "Nauru",
    dialCode: "+674",
    value: "NR",
  },
  {
    text: "Nepal",
    dialCode: "+977",
    value: "NP",
  },
  {
    text: "Netherlands",
    dialCode: "+31",
    value: "NL",
  },
  {
    text: "Netherlands Antilles",
    dialCode: "+599",
    value: "AN",
  },
  {
    text: "New Caledonia",
    dialCode: "+687",
    value: "NC",
  },
  {
    text: "New Zealand",
    dialCode: "+64",
    value: "NZ",
  },
  {
    text: "Nicaragua",
    dialCode: "+505",
    value: "NI",
  },
  {
    text: "Niger",
    dialCode: "+227",
    value: "NE",
  },
  {
    text: "Nigeria",
    dialCode: "+234",
    value: "NG",
  },
  {
    text: "Niue",
    dialCode: "+683",
    value: "NU",
  },
  {
    text: "Norfolk Island",
    dialCode: "+672",
    value: "NF",
  },
  {
    text: "Northern Mariana Islands",
    dialCode: "+1670",
    value: "MP",
  },
  {
    text: "Norway",
    dialCode: "+47",
    value: "NO",
  },
  {
    text: "Oman",
    dialCode: "+968",
    value: "OM",
  },
  {
    text: "Pakistan",
    dialCode: "+92",
    value: "PK",
  },
  {
    text: "Palau",
    dialCode: "+680",
    value: "PW",
  },
  {
    text: "Palestinian Territory, Occupied",
    dialCode: "+970",
    value: "PS",
  },
  {
    text: "Panama",
    dialCode: "+507",
    value: "PA",
  },
  {
    text: "Papua New Guinea",
    dialCode: "+675",
    value: "PG",
  },
  {
    text: "Paraguay",
    dialCode: "+595",
    value: "PY",
  },
  {
    text: "Peru",
    dialCode: "+51",
    value: "PE",
  },
  {
    text: "Philippines",
    dialCode: "+63",
    value: "PH",
  },
  {
    text: "Pitcairn",
    dialCode: "+872",
    value: "PN",
  },
  {
    text: "Poland",
    dialCode: "+48",
    value: "PL",
  },
  {
    text: "Portugal",
    dialCode: "+351",
    value: "PT",
  },
  {
    text: "Puerto Rico",
    dialCode: "+1939",
    value: "PR",
  },
  {
    text: "Qatar",
    dialCode: "+974",
    value: "QA",
  },
  {
    text: "Romania",
    dialCode: "+40",
    value: "RO",
  },
  {
    text: "Russia",
    dialCode: "+7",
    value: "RU",
  },
  {
    text: "Rwanda",
    dialCode: "+250",
    value: "RW",
  },
  {
    text: "Reunion",
    dialCode: "+262",
    value: "RE",
  },
  {
    text: "Saint Barthelemy",
    dialCode: "+590",
    value: "BL",
  },
  {
    text: "Saint Helena, Ascension and Tristan Da Cunha",
    dialCode: "+290",
    value: "SH",
  },
  {
    text: "Saint Kitts and Nevis",
    dialCode: "+1869",
    value: "KN",
  },
  {
    text: "Saint Lucia",
    dialCode: "+1758",
    value: "LC",
  },
  {
    text: "Saint Martin",
    dialCode: "+590",
    value: "MF",
  },
  {
    text: "Saint Pierre and Miquelon",
    dialCode: "+508",
    value: "PM",
  },
  {
    text: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    value: "VC",
  },
  {
    text: "Samoa",
    dialCode: "+685",
    value: "WS",
  },
  {
    text: "San Marino",
    dialCode: "+378",
    value: "SM",
  },
  {
    text: "Sao Tome and Principe",
    dialCode: "+239",
    value: "ST",
  },
  {
    text: "Saudi Arabia",
    dialCode: "+966",
    value: "SA",
  },
  {
    text: "Senegal",
    dialCode: "+221",
    value: "SN",
  },
  {
    text: "Serbia",
    dialCode: "+381",
    value: "RS",
  },
  {
    text: "Seychelles",
    dialCode: "+248",
    value: "SC",
  },
  {
    text: "Sierra Leone",
    dialCode: "+232",
    value: "SL",
  },
  {
    text: "Singapore",
    dialCode: "+65",
    value: "SG",
  },
  {
    text: "Slovakia",
    dialCode: "+421",
    value: "SK",
  },
  {
    text: "Slovenia",
    dialCode: "+386",
    value: "SI",
  },
  {
    text: "Solomon Islands",
    dialCode: "+677",
    value: "SB",
  },
  {
    text: "Somalia",
    dialCode: "+252",
    value: "SO",
  },
  {
    text: "South Africa",
    dialCode: "+27",
    value: "ZA",
  },
  {
    text: "South Sudan",
    dialCode: "+211",
    value: "SS",
  },
  {
    text: "South Georgia and the South Sandwich Islands",
    dialCode: "+500",
    value: "GS",
  },
  {
    text: "Spain",
    dialCode: "+34",
    value: "ES",
  },
  {
    text: "Sri Lanka",
    dialCode: "+94",
    value: "LK",
  },
  {
    text: "Sudan",
    dialCode: "+249",
    value: "SD",
  },
  {
    text: "Suriname",
    dialCode: "+597",
    value: "SR",
  },
  {
    text: "Svalbard and Jan Mayen",
    dialCode: "+47",
    value: "SJ",
  },
  {
    text: "Swaziland",
    dialCode: "+268",
    value: "SZ",
  },
  {
    text: "Sweden",
    dialCode: "+46",
    value: "SE",
  },
  {
    text: "Switzerland",
    dialCode: "+41",
    value: "CH",
  },
  {
    text: "Syrian Arab Republic",
    dialCode: "+963",
    value: "SY",
  },
  {
    text: "Taiwan",
    dialCode: "+886",
    value: "TW",
  },
  {
    text: "Tajikistan",
    dialCode: "+992",
    value: "TJ",
  },
  {
    text: "Tanzania, United Republic of Tanzania",
    dialCode: "+255",
    value: "TZ",
  },
  {
    text: "Thailand",
    dialCode: "+66",
    value: "TH",
  },
  {
    text: "Timor-Leste",
    dialCode: "+670",
    value: "TL",
  },
  {
    text: "Togo",
    dialCode: "+228",
    value: "TG",
  },
  {
    text: "Tokelau",
    dialCode: "+690",
    value: "TK",
  },
  {
    text: "Tonga",
    dialCode: "+676",
    value: "TO",
  },
  {
    text: "Trinidad and Tobago",
    dialCode: "+1868",
    value: "TT",
  },
  {
    text: "Tunisia",
    dialCode: "+216",
    value: "TN",
  },
  {
    text: "Turkey",
    dialCode: "+90",
    value: "TR",
  },
  {
    text: "Turkmenistan",
    dialCode: "+993",
    value: "TM",
  },
  {
    text: "Turks and Caicos Islands",
    dialCode: "+1649",
    value: "TC",
  },
  {
    text: "Tuvalu",
    dialCode: "+688",
    value: "TV",
  },
  {
    text: "Uganda",
    dialCode: "+256",
    value: "UG",
  },
  {
    text: "Ukraine",
    dialCode: "+380",
    value: "UA",
  },
  {
    text: "United Arab Emirates",
    dialCode: "+971",
    value: "AE",
  },
  {
    text: "United Kingdom",
    dialCode: "+44",
    value: "GB",
  },
  {
    text: "United States",
    dialCode: "+1",
    value: "US",
  },
  {
    text: "Uruguay",
    dialCode: "+598",
    value: "UY",
  },
  {
    text: "Uzbekistan",
    dialCode: "+998",
    value: "UZ",
  },
  {
    text: "Vanuatu",
    dialCode: "+678",
    value: "VU",
  },
  {
    text: "Venezuela, Bolivarian Republic of Venezuela",
    dialCode: "+58",
    value: "VE",
  },
  {
    text: "Vietnam",
    dialCode: "+84",
    value: "VN",
  },
  {
    text: "Virgin Islands, British",
    dialCode: "+1284",
    value: "VG",
  },
  {
    text: "Virgin Islands, U.S.",
    dialCode: "+1340",
    value: "VI",
  },
  {
    text: "Wallis and Futuna",
    dialCode: "+681",
    value: "WF",
  },
  {
    text: "Yemen",
    dialCode: "+967",
    value: "YE",
  },
  {
    text: "Zambia",
    dialCode: "+260",
    value: "ZM",
  },
  {
    text: "Zimbabwe",
    dialCode: "+263",
    value: "ZW",
  },
];

export const countryListMap = countryList.map((el) => {
  return { text: el.text, value: el.text };
});
