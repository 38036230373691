import axiosInstance, {
  axiosMediaApi,
  axiosDefault,
} from "@/services/instanceService";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    statusLoad: false,
    statusUpdate: false,
    blockStatus: 0,
    connectionFilter: "",
    allMessageRead: true,
    reloadHeader: false,
    profileData: {},
    selfProfileData: {},
    redrawAbout: true,
    loginCheckAuth: false,
  },
  actions: {
    doRegister(commit, payload) {
      axiosDefault
        .post(`auth/register`, payload)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return err;
        });
    },
    async notificationDataSumLoad() {
      return await axiosInstance({
        method: "POST",
        url: `notification/notificationDataSumLoad`,
      }).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getInterest() {
      return axiosDefault.post(`public/getInterest`).then((response) => {
        try {
          return Promise.resolve(response);
        } catch (error) {
          return Promise.reject(error);
        }
      });
    },

    async addToListDeletedAccount(commit, payload) {
      let response = await axiosInstance.post(
        "user/addToListDeletedAccount",
        payload
      );
      // console.log(response);
      return response;
    },

    async findUserAccountStatus(commit, payload) {
      try {
        let response = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASE_API}user/findUserAccountStatus`,
          headers: {
            authorization: `Bearer ${payload.token}`,
            "content-type": "application/json",
          },
        });
        return response;
      } catch (err) {
        return err.response.data;
      }
    },

    async checkUsername(commit, payload) {
      let response = "";
      response = await axiosDefault.post(`public/checkUsername`, payload);

      return response.status;
    },

    async checkEmail(commit, payload) {
      let response = "";
      response = await axiosDefault.post(`public/checkEmail`, payload);
      return response;
    },

    profileLoad({ rootState }) {
      if (rootState.auth.status.loggedIn) {
        return axiosInstance({
          method: "POST",
          url: `user/profileload`,
        }).then((response) => {
          return response.data;
        });
      }
    },

    updateProfile({ commit }, data) {
      return axiosInstance({
        method: "POST",
        url: `user/updateProfile`,
        data: data,
      }).then((response) => {
        if (response.data) {
          const newUser = response.data.user;

          commit(
            "renewStateUser",
            {
              resource: "auth",
              newuser: newUser,
            },
            {
              root: true,
            }
          );
          return newUser;
        }
        return response.data;
      });
    },

    async updateAbout({ commit }, data) {
      var aboutme = "";
      if (data.aboutme != "") {
        aboutme = data.aboutme;
      }
      var interest = "";
      if (data.interest != "") {
        interest = data.interest;
      }
      var birthday = "";
      if (data.birthday != "") {
        birthday = data.birthday;
      }
      var email = "";
      if (data.email != "") {
        email = data.email;
      }
      var phone = "";
      if (data.phone != "") {
        phone = data.phone;
      }
      var country = "";
      var region = "";
      var place = "";
      var locality = "";
      var lng = "";
      var lat = "";
      if (data.country != "") {
        country = data.country;
      }
      if (data.region != "") {
        region = data.region;
      }
      if (data.place != "") {
        place = data.place;
      }
      if (data.locality != "") {
        locality = data.locality;
      }
      if (data.long != "") {
        lng = data.lng;
      }
      if (data.lat != "") {
        lat = data.lat;
      }
      return axiosInstance
        .post(`user/aboutUpdate/`, {
          aboutme: aboutme,
          interest: interest,
          birthday: birthday,
          email: email,
          phone: phone,
          location: {
            country: country,
            region: region,
            place: place,
            locality: locality,
            coordinates: [lng, lat],
          },
        })
        .then((response) => {
          if (response.data.interestUpdate) {
            const interest = response.data.dataUserUpdate;
            commit(
              "renewInterestUser",
              {
                resource: "auth",
                interest: interest,
              },
              {
                root: true,
              }
            );
          }
          return response.data;
        });
    },

    //EDITProfilePicture
    async userEditProfilePicture({ commit }, formData) {
      return await axiosMediaApi.post(`user/uploadPhoto`, formData).then(
        (resp) => {
          const response = resp.data;

          const newImgProfile = response.imgProfile;
          try {
            commit(
              "renewProfilePictUser",
              {
                resource: "auth",
                imgProfile: newImgProfile,
              },
              {
                root: true,
              }
            );
            return Promise.resolve(response);
          } catch (error) {
            return error;
          }
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },
    async deletePictureProfile({ commit }, { imgProfile }) {
      return await axiosMediaApi.post(`user/deletePhoto`, imgProfile).then(
        (response) => {
          try {
            commit(
              "renewProfilePictUser",
              {
                resource: "auth",
                imgProfile: "",
              },
              {
                root: true,
              }
            );
            return response;
          } catch (error) {
            return error;
          }
        },
        (error) => {
          commit("updateFailure");
          return error;
        }
      );
    },

    loadProfileData({ commit }, data) {
      let APIpath = "public/loadProfileData/";
      const userId = data.userId;
      if (userId) APIpath = "user/loadProfileData/";
      return axiosInstance({
        method: "POST",
        url: APIpath,
        data: data,
      })
        .then((data) => {
          if (data.data.message == "User not found") {
            return "User not found";
          }
          // console.log("load other");
          commit("setProfileData", data.data.profileData);
          return data.data.profileData;
        })
        .catch(() => {
          // console.log("load profile data error");
          // console.log(error);
        });
    },

    loadSelfProfileData({ state, commit }, data) {
      const APIpath = "user/loadProfileData/";
      if (!state.selfProfileData.username) {
        return axiosInstance({
          method: "POST",
          url: APIpath,
          data: data,
        })
          .then((data) => {
            if (data.data.message == "User not found") {
              return "User not found";
            }
            commit("setSelfProfileData", data.data.profileData);
            commit("setProfileData", data.data.profileData);
            // console.log("load self");
            return data.data.profileData;
          })
          .catch(() => {
            // console.log("load profile data error");
            // console.log(error);
          });
      } else {
        // console.log("skip load self");
        commit("setProfileData", state.selfProfileData);
        return state.selfProfileData;
      }
    },

    updateImageHeader({ commit, state }, { formData }) {
      return axiosMediaApi.post(`user/updateImageHeader`, formData).then(
        (response) => {
          const imgHeader = response.data.imgHeader;
          try {
            commit("setHeaderImageNew", {
              imgHeader,
            });
            return state.profileData;
          } catch (error) {
            return error;
          }
        },
        (error) => {
          commit("updateFailure");
          return error;
        }
      );
    },
    deleteImageHeader({ commit }, { imgHeader }) {
      return axiosMediaApi.post(`user/deleteImageHeader`, imgHeader).then(
        (response) => {
          const imgHeaderData = "";
          try {
            commit("setHeaderImage", {
              imgHeaderData,
            });
            return response;
          } catch (error) {
            return error;
          }
        },
        (error) => {
          commit("updateFailure");
          return error;
        }
      );
    },

    forgotPassword(context, email) {
      return axiosDefault({
        method: "POST",
        url: "auth/forgotPassword",
        data: {
          email: email,
        },
      });
    },
    privacySettingLoad({ commit }) {
      return axiosInstance.post(`user/privacysettingload`).then((response) => {
        try {
          commit("loadDataSuccess");
          return Promise.resolve(response);
        } catch (error) {
          commit("loadDataFailure");
          return Promise.reject(error);
        }
      });
    },
    privacySettingUpdate({ commit }, data) {
      return axiosInstance
        .post(`user/privacysetting`, {
          privacy_setting: {
            name_show: data.name_show,
            email_show: data.email_show,
            phone_show: data.phone_show,
            birthday_show: data.birthday_show,
            posts_show: data.posts_show,
          },
        })
        .then(
          (response) => {
            try {
              commit(
                "renewPrivacyUser",
                {
                  resource: "auth",
                  userData: response.data,
                },
                {
                  root: true,
                }
              );
              return Promise.resolve(response);
            } catch (error) {
              return error;
            }
          },
          (error) => {
            commit("updateFailure");
            return Promise.reject(error);
          }
        );
    },

    updatePassword(context, data) {
      return axiosInstance
        .post(`user/updatepassword`, {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        })
        .then((response) => {
          return Promise.resolve(response.data);
        });
    },
    notificationSettingLoad() {
      return axiosInstance.post("user/notificationsettingload").then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    notificationSettingUpdate(context, data) {
      return axiosInstance
        .post("user/notificationsetting", {
          notification_setting: {
            activity_status: data.activityStatus,
            activity_videos: data.activityVideos,
            activity_photos: data.activityPhotos,
            activity_connections: data.activityConnections,
            replies_comments: data.repliesComments,
            event_invitation: data.eventInvitation,
            content_sharing: data.contentSharing,
            follow: data.follow,
          },
        })
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },

    /*update contact*/
    contactSettingLoad() {
      return axiosInstance.post("user/contactsettingload").then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    contactSettingEmail(contex, setData) {
      return axiosInstance
        .post("user/contactemailsetting", {
          id: setData.id,
          email: setData.email,
          emailChangeCounter: setData.emailChangeCounter,
        })
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    contactSettingPhone(contex, setData) {
      return axiosInstance
        .post("user/contactphonesetting", {
          id: setData.id,
          phone: setData.phone,
        })
        .then(
          (response) => {
            return Promise.resolve(response.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    /*End update contact*/

    loadUserByDistance(context, payload) {
      return axiosInstance({
        method: "POST",
        url: "user/loadUserByDistance/",
        data: payload,
      });
    },

    loadUserByInterest(context, payload) {
      return axiosInstance({
        method: "POST",
        url: "user/loadUserByInterest/",
        data: payload,
      });
    },

    //Store loadUserLanding for load, filter, search Worldwide
    async loadAllUsers({ commit, rootState }, data) {
      const {
        numberOfLoad,
        countryCode,
        keyword,
        userId,
        countryCodeForSearch,
      } = data;

      //get latest pagin state
      var getStartNum = rootState.exploreLocation
        ? rootState.exploreUser[rootState.exploreLocation].startLoad
        : 1;
      //get latest status  load state
      var oldStatusLoad = rootState.exploreLocation
        ? rootState.exploreUser[rootState.exploreLocation].isAllUserLoaded
        : false;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        countryCode,
        keyword,
        countryCodeForSearch,
      };
      let getUsers = "";
      if (oldStatusLoad != true) {
        if (userId) {
          getUsers = await axiosInstance.post(`user/loadAllUsers`, payload);
        } else {
          getUsers = await axiosDefault.post(
            `public/usersLandingPublic`,
            payload
          );
        }
        if (getUsers) {
          const newgetUser = getUsers.data.data;
          const isAllUserLoaded = getUsers.data.isAllDataLoaded;
          commit(
            "mergeUserExplore",
            {
              newgetUser: newgetUser,
              isAllUserLoaded: isAllUserLoaded,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(true);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },

    //Store loadUsersAllOnTheGo for load, filter, search
    async loadUserOnTheGo({ commit, rootState }, data) {
      const { numberOfLoad, location, range, keyword, interest } = data;
      let listType = keyword ? "search" : "default";
      //initilalize new item state
      if (
        !rootState.onthegoUser[listType].userList[range] ||
        !rootState.onthegoUser[listType].userList[range].items[interest]
      ) {
        commit(
          "setNewRootState",
          {
            rootName: "onthegoUser",
            listType: listType,
            contentType: "userList",
            filter: range,
            setState: {
              isAllUserLoaded: {
                [interest]: false,
              },
              startLoad: {
                [interest]: 1,
              },
              items: {
                [interest]: [],
              },
            },
          },
          {
            root: true,
          }
        );
      }
      commit(
        "setStateType",
        {
          resource: "onthegoUser",
          listType: listType,
        },
        {
          root: true,
        }
      );
      commit(
        "setCurrentState",
        {
          resource: "onthegoUser",
          usernameTarget: range,
        },
        {
          root: true,
        }
      );
      commit(
        "stateFilterCurrent",
        {
          resource: "onthegoUser",
          setFilter: interest,
        },
        {
          root: true,
        }
      );
      //get latest pagin state
      var getStartNum =
        rootState.onthegoUser[listType].userList[range].startLoad[interest];
      //get latest status  load state
      var oldStatusLoad =
        rootState.onthegoUser[listType].userList[range].isAllUserLoaded[
          interest
        ];
      // const oldStatusLoad =false;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        range,
        keyword,
        interest,
        location,
      };

      let getPosts = "";
      if (oldStatusLoad != true) {
        getPosts = await axiosInstance.post(`user/loadUserByOnTheGo`, payload);
        if (getPosts) {
          const newgetUser = getPosts.data.data;
          const isAllUserLoaded = getPosts.data.isAllDataLoaded;
          commit(
            "mergeRootStateUser",
            {
              rootName: "onthegoUser",
              listType: listType,
              filter: range,
              interest: interest,
              newgetUser: newgetUser,
              isAllUserLoaded: isAllUserLoaded,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetUser);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },

    //Store loadUsersHometown for load, filter, search
    async loadUserHometown({ commit, rootState }, data) {
      const {
        stateLocation,
        numberOfLoad,
        range,
        checkhometown,
        keyword,
        interest,
        coorLocation,
        currentPlace,
      } = data;
      let listType = keyword ? "search" : "default";
      //initilalize new item state
      if (
        Object.keys(rootState.hometownUser[stateLocation]).length === 0 ||
        !rootState.hometownUser[stateLocation][listType].dataList ||
        !rootState.hometownUser[stateLocation][listType].dataList[range] ||
        !rootState.hometownUser[stateLocation][listType].dataList[range].items[
          interest
        ]
      ) {
        commit(
          "setNewHometownState",
          {
            rootName: "hometownUser",
            stateLocation: stateLocation,
            listType: listType,
            filter: range,
            setState: {
              isAllDataLoaded: {
                [interest]: false,
              },
              startLoad: {
                [interest]: 1,
              },
              items: {
                [interest]: [],
              },
            },
          },
          {
            root: true,
          }
        );
      }
      commit(
        "hometownConfigState",
        {
          resource: "hometownUser",
          stateLocation: stateLocation,
          listType: listType,
          currentRage: range,
          setFilter: interest,
        },
        {
          root: true,
        }
      );

      //get latest pagin state
      var getStartNum =
        rootState.hometownUser[stateLocation][listType].dataList[range]
          .startLoad[interest];

      //get latest status  load state
      var oldStatusLoad =
        rootState.hometownUser[stateLocation][listType].dataList[range]
          .isAllDataLoaded[interest];

      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        checkhometown: checkhometown,
        range,
        keyword,
        interest,
        stateLocation,
        coorLocation,
        currentPlace,
      };

      let getPosts = "";
      if (oldStatusLoad != true) {
        getPosts = await axiosInstance.post(`user/loadUsersHometown`, payload);
        if (getPosts) {
          const newgetUser = getPosts.data.data;
          const isAllDataLoaded = getPosts.data.isAllDataLoaded;
          commit(
            "mergeHometownState",
            {
              rootName: "hometownUser",
              stateLocation: stateLocation,
              listType: listType,
              filter: range,
              interest: interest,
              mergeState: newgetUser,
              isAllDataLoaded: isAllDataLoaded,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetUser);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },
  },

  mutations: {
    setProfileData(state, data) {
      state.profileData = data;
      state.profileData = {
        ...state.profileData,
      };
    },
    setSelfProfileData(state, data) {
      state.selfProfileData = data;
      state.selfProfileData = data;
      state.selfProfileData = {
        ...state.selfProfileData,
      };
    },
    setRedrawAbout(state, data) {
      state.redrawAbout = data;
    },
    setHeaderImage(state, { imgHeaderData }) {
      state.profileData.imgHeader = imgHeaderData;
      /*   state.profileData = imgHeaderData;
      state.profileData = {
        ...state.profileData,
      }; */
    },
    setHeaderImageNew(state, { imgHeader }) {
      state.profileData.imgHeader = imgHeader;
    },
    updateSuccess(state) {
      state.statusUpdate = true;
    },
    updateFailure(state) {
      state.statusLoad = false;
    },
    loadDataSuccess(state) {
      state.statusLoad = true;
    },
    loadDataFailure(state) {
      state.statusLoad = false;
    },
    setBlockStatus(state, payload) {
      state.blockStatus = payload;
    },
    setConnectionFilter(state, payload) {
      state.connectionFilter = payload;
    },
    setAllMessageRead(state, payload) {
      state.allMessageRead = payload;
    },
    setContentAboutData(state, payload) {
      state.contentAboutData = payload;
    },
    setReloadHeader(state, payload) {
      state.reloadHeader = payload;
    },
    resetUserState(state) {
      state.profileData = {};
      state.selfProfileData = {};
      state.statusLoad = false;
      state.statusUpdate = false;
      state.blockStatus = 0;
      state.connectionFilter = "";
      state.allMessageRead = true;
      state.contentAboutData = {};
      state.reloadHeader = false;
    },
    resetUserStateHeader(state) {
      state.selfProfileData = {};
      state.statusLoad = false;
      state.statusUpdate = false;
      state.blockStatus = 0;
      state.connectionFilter = "";
      state.allMessageRead = true;
      state.contentAboutData = {};
      state.reloadHeader = false;
    },

    setLoginCheckAuth(state, payload) {
      state.loginCheckAuth = payload;
    },
  },
};
