import { uniqBy } from "lodash";
import axiosInstance, {
  axiosMediaApi,
  axiosDefault,
} from "@/services/instanceService";

export default {
  namespaced: true,
  state: {
    photos: [],
    startLoadPhoto: 1,
    isAllPhotoLoaded: false,
    videos: [],
    startLoadVideo: 1,
    isAllVideoLoaded: false,
    uploadNewPhotos: [],
    uploadNewVideos: [],
    numberOfLoad: 24,

    photosForAlbum: {
      all: {
        albumId: "all",
        photos: [],
        allLoaded: false,
      },
    },
    videosForAlbum: {
      all: {
        albumId: "all",
        videos: [],
        allLoaded: false,
      },
    },
    isUploadMediaIntouch: false,
    uploadMediaLength: 0,
  },
  getters: {
    photosData: (state) => {
      return uniqBy(state.photos, "id");
    },
    isAllPhotoLoaded: (state) => {
      return state.isAllPhotoLoaded;
    },
    uploadNewPhotos: (state) => {
      return uniqBy(state.uploadNewPhotos, "id");
    },

    videosData: (state) => {
      return uniqBy(state.videos, "id");
    },
    isAllVideoLoaded: (state) => {
      return state.isAllVideoLoaded;
    },
    uploadNewVideos: (state) => {
      return uniqBy(state.uploadNewVideos, "id");
    },
  },
  mutations: {
    createState(state, { items, statusName, startNumName }) {
      state[items];
      if (state[items].length == 0) {
        state[items] = [];
        state[statusName] = false;
        state[startNumName] = 1;
      }
    },
    mergeMedia(
      state,
      { itemName, itemList, statusName, statusValue, startNumName }
    ) {
      state[itemName] = [...state[itemName], ...itemList];
      state[statusName] = statusValue;
      if (!statusValue) {
        state[startNumName]++;
      }
    },
    resetNewMedia(state) {
      state.uploadNewPhotos = [];
      state.uploadNewVideos = [];
    },
    resetMedia(state) {
      state.photos = [];
      state.startLoadPhoto = 1;
      state.videos = [];
      state.startLoadVideo = 1;
      state.isAllPhotoLoaded = false;
      state.isAllVideoLoaded = false;
    },
    mergeUploadPhoto(state, photos) {
      // state.uploadNewPhotos = [];
      state.uploadMediaLength = photos.length;
      state.uploadNewPhotos = [...state.uploadNewPhotos, ...photos];
    },
    mergeUploadVideo(state, videos) {
      // state.uploadNewVideos = [];
      state.uploadNewVideos = [...state.uploadNewVideos, ...videos];
    },
    setPhotosForAlbum(state, payload) {
      const { albumId, photos, allLoaded } = payload;
      state.photosForAlbum[albumId].photos = [
        ...state.photosForAlbum[albumId].photos,
        ...photos,
      ];
      state.photosForAlbum[albumId].allLoaded = allLoaded;
      state.photosForAlbum = { ...state.photosForAlbum };
    },
    setInitPhotosForAlbum(state, payload) {
      const { albumId, photos, allLoaded } = payload;

      state.photosForAlbum[albumId] = {
        albumId,
        photos,
        allLoaded,
      };
    },
    setVideosForAlbum(state, payload) {
      const { albumId, videos, allLoaded } = payload;
      state.videosForAlbum[albumId].videos = [
        ...state.videosForAlbum[albumId].videos,
        ...videos,
      ];
      state.videosForAlbum[albumId].allLoaded = allLoaded;
      state.videosForAlbum = { ...state.videosForAlbum };
    },
    setInitVideosForAlbum(state, payload) {
      const { albumId, videos, allLoaded } = payload;

      state.videosForAlbum[albumId] = {
        albumId,
        videos,
        allLoaded,
      };
    },
    setIsUploadMediaIntouch(state, payload) {
      state.isUploadMediaIntouch = payload;
    },
  },
  actions: {
    loadPhotoForAlbum({ commit, state }, { filter, albumId }) {
      let getStartNum = 0;
      let allLoaded = false;

      if (!state.photosForAlbum[albumId]) {
        const initialData = { albumId: albumId, photos: [], allLoaded: false };
        commit("setInitPhotosForAlbum", initialData);
      } else {
        getStartNum = state.photosForAlbum[albumId].photos.length;
        allLoaded = state.photosForAlbum[albumId].allLoaded;
      }

      const data = {
        pagePhotoNum: getStartNum,
        pagePhotoShow: state.numberOfLoad,
        ninMedia: filter.ninMedia,
      };

      if (allLoaded) {
        return;
      } else {
        return axiosInstance({
          method: "POST",
          url: "media/getPhotoForAlbum",
          data,
        })
          .then((res) => {
            const { photos, isAllDataPhoto } = res.data;
            if (albumId == "all") {
              commit("setPhotosForAlbum", {
                albumId: "all",
                photos,
                allLoaded: isAllDataPhoto,
              });
            } else {
              commit("setPhotosForAlbum", {
                albumId,
                photos,
                allLoaded: isAllDataPhoto,
              });
            }
            return true;
          })
          .catch(() => {
            // console.log(error);
          });
      }
    },
    loadVideoForAlbum({ commit, state }, { filter, albumId }) {
      let getStartNum = 0;
      let allLoaded = false;

      if (!state.videosForAlbum[albumId]) {
        const initialData = { albumId, videos: [], allLoaded: false };
        commit("setInitVideosForAlbum", initialData);
      } else {
        getStartNum = state.videosForAlbum[albumId].videos.length;
        allLoaded = state.videosForAlbum[albumId].allLoaded;
      }

      const data = {
        pageVideoNum: getStartNum,
        pageVideoShow: state.numberOfLoad,
        ninMedia: filter.ninMedia,
      };

      if (allLoaded) {
        return;
      } else {
        return axiosInstance({
          method: "POST",
          url: "media/getVideoForAlbum",
          data,
        })
          .then((res) => {
            const { videos, isAllDataVideo } = res.data;
            if (albumId == "all") {
              commit("setVideosForAlbum", {
                albumId: "all",
                videos,
                allLoaded: isAllDataVideo,
              });
            } else {
              commit("setVideosForAlbum", {
                albumId,
                videos,
                allLoaded: isAllDataVideo,
              });
            }
            return true;
          })
          .catch(() => {
            // console.log(error);
          });
      }
    },

    mediaViewsCounter(context, payload) {
      return axiosDefault
        .post("public/mediaViewCounter", {
          id: payload.id,
        })
        .then((response) => {
          return response.data;
        });
    },
    async getMediaPhoto({ commit, state }, { filter }) {
      var getStartNum = state.startLoadPhoto;

      const bodyReq = {
        pagePhotoNum: getStartNum,
        pagePhotoShow: filter.numberOfLoad,
        ninMedia: filter.ninMedia,
      };

      if (!state.isAllPhotoLoaded)
        return axiosInstance.post(`media/getPhoto`, bodyReq).then((res) => {
          const { photos, isAllDataPhoto } = res.data;

          commit("createState", {
            items: "photos",
            statusName: "isAllPhotoLoaded",
            startNumName: "startLoadPhoto",
          });

          commit("mergeMedia", {
            itemName: "photos",
            itemList: photos,
            statusName: "isAllPhotoLoaded",
            statusValue: isAllDataPhoto,
            startNumName: "startLoadPhoto",
          });
          return true;
        });
    },
    //function get video
    async getMediaVideo({ commit, state }, { filter }) {
      if (!state.isAllVideoLoaded)
        if (!state.isAllPhotoLoaded) var getStartNum = state.startLoadVideo;
      const bodyReq = {
        pageVideoNum: getStartNum,
        pageVideoShow: filter.numberOfLoad,
        ninMedia: filter.ninMedia,
      };

      return axiosInstance.post(`media/getVideo`, bodyReq).then((res) => {
        const { videos, isAllDataVideo } = res.data;
        commit("createState", {
          items: "videos",
          statusName: "isAllVideoLoaded",
          startNumName: "startLoadVideo",
        });

        commit("mergeMedia", {
          itemName: "videos",
          itemList: videos,
          statusName: "isAllVideoLoaded",
          statusValue: isAllDataVideo,
          startNumName: "startLoadVideo",
        });

        return true;
      });
    },
    async pushMediaPhoto({ state, commit }, photos) {
      commit("mergeUploadPhoto", photos);
      return state.uploadNewPhotos;
    },
    async pushMediaVideo({ state, commit }, videos) {
      commit("mergeUploadVideo", videos);
      return state.uploadNewVideos;
    },
    async removeUploadPhoto({ state }, { photoId: photoId, index: index }) {
      return axiosMediaApi
        .post(`photo/deletePhotoData`, {
          photoId: photoId,
        })
        .then(() => {
          state.uploadNewPhotos.splice(index, 1);
          return state.uploadNewPhotos;
        });
    },
    async removeUploadVideo({ state }, payload) {
      var id = payload.id;
      var index = payload.index;
      return axiosMediaApi
        .post("video/deleteVideoData", {
          id: id,
        })
        .then(() => {
          state.uploadNewVideos.splice(index, 1);
          return state.uploadNewVideos;
        });
    },

    resetMediaState({ commit }) {
      commit("resetMedia");
    },
    resetMediaNewState({ commit }) {
      commit("resetNewMedia");
    },
  },
};
