<template>
  <div>
    <b-container fluid class="iq-navbar-custom">
      <b-navbar
        class="navbar navbar-expand-lg"
        :class="headerPosition == 'fixed' ? 'fixed-top' : 'position-relative'"
      >
        <!-- LOGO -->
        <b-col class="col-header-logo" lg="4" md="4" sm="4">
          <b-navbar-brand class="iq-navbar-logo d-flex justify-content-between">
            <b-link @click="loadHome">
              <b-img src="/images/mindcaster_logo_big.png" />
            </b-link>
          </b-navbar-brand>
        </b-col>
        <!-- LOGO -->

        <!-- SEARCH BAR -->
        <b-col v-if="authLoaded" class="iq-search-bar" lg="4" md="6" sm="6">
          <b-form @submit.prevent="searchResult" class="searchbox">
            <b-input-group>
              <b-form-input
                id="header-search"
                type="text"
                :placeholder="setPlaceholder"
                class="search-input"
                :class="currentSearch ? 'search-active' : ''"
                v-model="currentSearch"
              ></b-form-input>
              <b-input-group-append class="header-btn-search">
                <b-button type="submit" variant="none">
                  <b-icon
                    icon="search"
                    variant="secondary"
                    class="rounded-circle"
                  ></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
        <!-- SEARCH BAR -->

        <!-- nav bar if login-->
        <b-col
          v-if="loggedIn & authLoaded"
          class="user-active-navbar"
          lg="4"
          md="4"
          sm="4"
        >
          <b-row>
            <b-col cols="8" md="8" sm="9" class="notif-bar">
              <b-nav
                class="navbar-nav ml-auto navbar-list ul-user-active-navbar"
              >
                <b-nav-item
                  v-if="showToggelBar"
                  class="d-block d-sm-block d-md-block d-lg-none icon-header-bar mt-2"
                  v-b-toggle.sidebar-left-menu
                >
                  <b-icon icon="list" animation="none" font-scale="2"></b-icon>
                </b-nav-item>
                <b-nav-item-dropdown
                  v-if="loggedIn"
                  id="icon-upload"
                  right
                  class="icon-upload mt-2"
                >
                  <template slot="button-content">
                    <span class="iq-waves-effect">
                      <lottie
                        :option="
                          require('../../../public/images/small/lottie-camera')
                        "
                        id="lottie-camera"
                      />
                    </span>
                  </template>
                  <b-dropdown-group class="group-notif-header">
                    <b-dropdown-header class="dropdown-notif-header text-left">
                      Upload media
                    </b-dropdown-header>
                    <div class="d-flex flex-column justify-content-center">
                      <b-dropdown-item-button
                        class="py-3 pl-3"
                        @click="handlePhotoModal"
                        >Photo</b-dropdown-item-button
                      >
                      <div class="line-between-upload-dropdown mx-4" />
                      <b-dropdown-item-button
                        class="py-3 pl-3 r"
                        @click="handleVideoModal"
                        >Video</b-dropdown-item-button
                      >
                    </div>
                  </b-dropdown-group>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown
                  v-if="loggedIn"
                  right
                  class="mt-2 icon-notif"
                >
                  <template slot="button-content">
                    <span class="iq-waves-effect" v-if="countNotif > 0">
                      <lottie
                        :option="
                          require('../../../public/images/small/lottie-bell')
                        "
                        id="lottie-beil"
                      />
                      <span class="bg-danger dots"></span>
                    </span>
                    <span class="iq-waves-effect not-login" v-else>
                      <b-icon
                        icon="bell"
                        class="none"
                        font-scale="1.5"
                      ></b-icon>
                    </span>
                  </template>
                  <b-dropdown-group
                    class="group-notif-header b-drop-notif"
                    v-if="loggedIn"
                  >
                    <b-dropdown-header class="dropdown-notif-header text-left">
                      Your Notifications
                      <small
                        v-if="countNotif > 0"
                        class="badge badge-light float-right pt-1"
                        >{{ this.countNotif }}</small
                      >
                    </b-dropdown-header>
                    <b-dropdown-item-button
                      class="pt-2"
                      v-for="(notif, index) in notifHeader"
                      v-bind:key="index"
                    >
                      <b-media right-align vertical-align="center">
                        <template #aside>
                          <div
                            class="media-body ml-1 media-notif"
                            v-if="
                              notif.moduleType == 'video' ||
                              notif.moduleType == 'photo'
                            "
                          >
                            <div
                              v-if="
                                notif.notifData &&
                                notif.notifData.mediaThumbnail
                              "
                            >
                              <img
                                class="avatar-40 img thumbnail"
                                :src="
                                  notif.notifData.mediaThumbnail
                                    ? pathThumb +
                                      notif.notifData.ownerId +
                                      '/' +
                                      thumbSize +
                                      notif.notifData.mediaThumbnail
                                    : ''
                                "
                                alt=""
                              />
                              <b-icon
                                v-if="notif.moduleType == 'video'"
                                class="centered text-white play-video-button"
                                icon="play-circle"
                                font-scale="1.5"
                                aria-hidden="true"
                              ></b-icon>
                            </div>
                            <div v-else class="text-center">
                              <b-icon
                                variant="dark"
                                icon="file-earmark-image-fill"
                                font-scale="2"
                                aria-hidden="true"
                              ></b-icon>
                            </div>
                          </div>
                        </template>

                        <div class="mb-0">
                          <b-link :href="'/' + notif.notifUser.username">
                            <b-avatar
                              variant="primary"
                              size="40px"
                              :text="
                                notif.notifUser.firstname.charAt(0) +
                                notif.notifUser.lastname.charAt(0)
                              "
                              :src="
                                notif.notifUser.imgProfile
                                  ? pathThumb +
                                    notif.notifUser._id +
                                    profilePathSmall +
                                    notif.notifUser.imgProfile
                                  : ''
                              "
                              :alt="notif.notifUser.firstname"
                            ></b-avatar>
                            <span class="mb-0 ml-2 medium"
                              >{{
                                capitalizeFirstLetter(
                                  stringLimit(notif.notifUser.firstname, 15)
                                )
                              }}
                            </span>
                          </b-link>

                          <a
                            href=""
                            class="iq-sub-card"
                            @click.prevent="
                              readNotification(
                                notif.moduleType,
                                notif.notification_type,
                                notif.action_type,
                                notif._id,
                                notif.slug,
                                notif.relatedId,
                                notif.count_notif
                              )
                            "
                          >
                            <span class="mb-0 medium">{{ notif.text }} </span>
                          </a>
                        </div>
                      </b-media>
                    </b-dropdown-item-button>
                    <b-dropdown-text v-if="countNotif <= 0" class="pt-1 pl-3">
                      You have no new notification
                    </b-dropdown-text>
                    <b-dropdown-divider></b-dropdown-divider>
                    <li role="presentation" v-if="countNotif > 0">
                      <b-button-group class="col-12 row" align-h="between">
                        <b-button
                          size="md"
                          @click.prevent="loadNotifPage()"
                          variant="none"
                          class="float-left text-primary pl-0"
                          >Show all notifications</b-button
                        >
                        <b-button
                          size="md"
                          @click.prevent="marksAllRead()"
                          variant="none"
                          class="float-right text-primary pr-0"
                          >Mark all as read</b-button
                        >
                      </b-button-group>
                    </li>
                    <b-dropdown-item-button v-if="countNotif == 0">
                      <div class="iq-sub-card pl-3">
                        <div class="media align-items-center">
                          <div class="media-body">
                            <a
                              href="#"
                              @click.prevent="loadNotifPage()"
                              class="iq-sub-card"
                              >show all notifications</a
                            >
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item-button>
                  </b-dropdown-group>
                </b-nav-item-dropdown>
                <b-nav-item
                  v-if="loggedIn"
                  right
                  @click.prevent="handleRouting('Message')"
                  class="mt-2 icon-message"
                >
                  <span v-if="!allMessageRead" class="iq-waves-effect">
                    <lottie
                      :option="
                        require('../../../public/images/small/lottie-mail')
                      "
                      id="lottie-mail"
                    />
                    <span class="bg-danger dots"></span>
                  </span>
                  <span v-if="allMessageRead" class="iq-waves-effect not-login">
                    <b-icon
                      icon="envelope"
                      class="none"
                      font-scale="1.5"
                    ></b-icon>
                  </span>
                </b-nav-item>
              </b-nav>
            </b-col>
            <!--  nav bar if login-->
            <b-col v-if="loggedIn" cols="4" md="4" sm="3" class="user-icon">
              <b-navbar variant="faded" type="light" class="float-left pl-0">
                <b-navbar-brand
                  v-if="firstname"
                  v-b-toggle.sidebar-right
                  @click.prevent="isLoggedIn"
                  href="#"
                >
                  <b-avatar
                    variant="primary"
                    size="4rem"
                    class="img-profile-nav d-inline-block align-top"
                    :text="firstname.charAt(0) + lastname.charAt(0)"
                    :src="profilePicture ? profilePictureMedium : ''"
                    :alt="firstname"
                  ></b-avatar>

                  <span class="profile-title-nav ml-2">{{
                    stringLimit(firstname, 15)
                  }}</span>
                </b-navbar-brand>
              </b-navbar>
            </b-col>
            <!--  End nav bar if login-->
          </b-row>
        </b-col>
        <!-- nav bar if not login-->
        <b-col
          v-if="!loggedIn & authLoaded"
          lg="4"
          md="4"
          sm="4"
          class="user-navbar head-sign"
        >
          <b-nav
            pills
            class="navbar-nav ml-auto navbar-notlogin ul-user-navbar"
          >
            <b-nav-item class="signup text-primary" @click="showModal" right>
              <b-icon
                icon="person-fill"
                variant="primary"
                aria-hidden="true"
              ></b-icon>
              Signin
            </b-nav-item>

            <b-nav-item class="signup text-primary" :to="'/signup'" right>
              <b-icon
                icon="person-plus-fill"
                variant="primary"
                aria-hidden="true"
              ></b-icon>
              Signup
            </b-nav-item>
          </b-nav>
        </b-col>
        <!--  End nav bar if not login-->
      </b-navbar>
    </b-container>

    <RightSidebar
      v-if="loggedIn"
      :userId="userId"
      :profilePicture="profilePicture"
      :profilePictureMedium="profilePictureMedium"
      :firstname="firstname"
      :lastname="lastname"
      :username="username"
      :urlMedia="urlMedia"
    />
    <div v-if="loggedIn">
      <UploadPhotoModal />
      <UploadVideoModal />
    </div>

    <!-- alert if no internet connection -->
    <b-alert
      v-if="!isInternetConnected"
      v-model="showAlert"
      class="position-fixed fixed-top m-0 rounded-0 py-2"
      style="z-index: 2000"
      variant="danger"
    >
      No internet connection. Please check again your internet connection.
    </b-alert>

    <!-- alert if user changing email but not verify it yet -->
    <b-alert
      v-if="loggedIn && newEmail"
      v-model="showAlert"
      class="position-fixed fixed-top m-0 rounded-0 py-2"
      style="z-index: 2000"
      variant="danger"
      dismissible
    >
      You recently changed your email address. Please verify your new email to
      complete the changes.
    </b-alert>

    <!-- alert if maintenance coming up -->
    <b-alert
      v-if="status == 1"
      v-model="showAlert"
      class="position-fixed fixed-top m-0 rounded-0 py-2"
      style="z-index: 2000"
      variant="warning"
      dismissible
    >
      Server is scheduled for maintenance on
      {{ new Date(maintenanceTime).toLocaleString() }}. Estimated down time:
      {{ downtime }}. We apologized for any inconvenience.
    </b-alert>

    <!----------------- modal for sign in ----------------->
    <b-modal
      :id="`signinModal`"
      hide-footer
      size="l"
      centered
      lazy
      modal-class="modalDialogSignin"
      dialog-class="modalDialogSignin"
      content-class="modalDialogSigninContent"
      header-class="modalDialogSigninHeader"
      title="Sign In"
    >
      <b-form
        class="py-2 px-4 form-signin-header"
        v-on:submit.prevent="submitFormLogin"
      >
        <b-input-group class="pt-4 mb-2" id="input-email-header">
          <b-input-group-prepend
            :class="$v.form.email.$error ? 'error-icon-input' : ''"
            is-text
          >
            <b-icon
              :icon="
                !$v.form.email.$error
                  ? 'person-fill'
                  : 'exclamation-circle-fill'
              "
              :variant="$v.form.email.$error ? 'white' : ''"
            ></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="email-input"
            name="email-input"
            placeholder=""
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-feedback"
            class="input-form"
          >
          </b-form-input>
          <label class="label-placeholder" for="email-input"
            >Username or email</label
          >
          <b-tooltip
            variant="danger"
            placement="rightbottom"
            :show="validateTooltipState('email')"
            :triggers="'manual'"
            target="input-email-header"
            custom-class="validation-tooltip"
          >
            <div class="error" v-if="!$v.form.email.required">
              Username or email <br />
              is required
            </div>
          </b-tooltip>
        </b-input-group>

        <b-input-group class="pt-4 mb-2" id="input-password-header">
          <b-input-group-prepend
            :class="$v.form.password.$error ? 'error-icon-input' : ''"
            is-text
          >
            <b-icon
              :icon="
                !$v.form.password.$error
                  ? 'lock-fill'
                  : 'exclamation-circle-fill'
              "
              :variant="$v.form.password.$error ? 'white' : ''"
            ></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-if="showPassword == false"
            id="password-input"
            name="password-input"
            type="password"
            placeholder=""
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="password-feedback"
            class="input-form"
          ></b-form-input>
          <b-form-input
            v-if="showPassword == true"
            id="password-input"
            name="password-input"
            type="text"
            placeholder=""
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="password-feedback"
            class="input-form"
          ></b-form-input>
          <label class="label-placeholder" for="password-input">Password</label>
          <b-tooltip
            variant="danger"
            placement="rightbottom"
            :show="validateTooltipState('password')"
            :triggers="'manual'"
            target="input-password-header"
            custom-class="validation-tooltip"
          >
            <div class="error" v-if="!$v.form.password.required">
              Password is required.
            </div>
          </b-tooltip>
        </b-input-group>
        <b-input-group class="d-flex flex-column">
          <b-form-checkbox
            v-model="checkBoxStatus"
            name="checkbox"
            value="accepted"
            unchecked-value="not_accepted"
            class="showPasswordStyle"
            @change="handleShowPassword"
            >Show password</b-form-checkbox
          >
        </b-input-group>
        <b-input-group class="pt-3">
          <b-button
            variant="primary"
            block
            size=""
            class=""
            :disabled="buttonPressed"
            type="submit"
          >
            Sign In
          </b-button>
        </b-input-group>

        <div class="pt-2" v-if="loginFailed">
          <strong class="text-danger"> {{ loginError }}</strong>
        </div>

        <!--hide temporary soicial media login  d-none  instead d-none-flex -->
        <!-- <b-input-group class="pt-2 d-none flex-row-reverse">
            <b-button variant="none">
              <b-icon
                class="position-relative"
                icon="google"
                animation="none"
                variant="primary"
                font-scale="1.5"
              >
              </b-icon>
            </b-button>
            <b-button variant="none">
              <b-icon
                class="position-relative"
                icon="facebook"
                animation="none"
                variant="primary"
                font-scale="1.5"
              >
              </b-icon>
            </b-button>
          </b-input-group>
        </b-dropdown-form> -->
      </b-form>
      <hr />
      <div class="px-4 pb-3">
        <div class="py-2 hover-blue" @click="forgotPassword">
          Forgot Password?
        </div>
        <div class="hover-blue" @click="signUp">New around here? Sign up</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "../../store/index.js";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import Lottie from "@/components/tools/Lottie.vue";
import RightSidebar from "@/components/navigation/RightSidebar.vue";
import UploadPhotoModal from "@/components/photo/UploadPhotoModal.vue";
import UploadVideoModal from "@/components/video/UploadVideoModal.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions, mapMutations } from "vuex";
import { THUMB_SMALL, FILE_PATH, THUMB_MEDIUM } from "../../variables.js";

function initialDataHeader() {
  return {
    showToggelBar: true,
    headerPosition: "fixed",
    showDD: false,
    isLogin: false,
    currentSearch: "",
    userId: "",
    profilePicture: "",
    firstname: "",
    lastname: "",
    username: "",
    imgProfile: "",
    loginFailed: false,
    loginError: "",
    setPlaceholder: "",
    form: {
      email: null,
      password: null,
    },
    noNotif: true,
    conReq: false,
    connectionRequestNumber: 0,
    totalNotification: 0,
    notif: [],
    mediaDescription: "",
    people_in_pict: "",
    showLocation: 1,
    photo_permission: 0,
    photo_permission_options: [
      { value: 0, text: "Everyone" },
      { value: 1, text: "Acquaintances" },
      { value: 2, text: "Friends" },
      { value: 3, text: "Best Friends" },
    ],
    uploadPhotoBox: true,

    videoTitle: "",
    videoDescription: "",
    cast: [],
    tag: [],
    mediaAccess: 0,
    tagInvalid: false,
    set_as_album: 0,
    albumTitle: "",
    albumStatus: false,
    file: [],
    photoDataform: [],
    videoDataform: [],
    videoThumbnailData: [],
    tabIndexPhoto: 0,
    tabIndexForm: 0,
    photoPost: [],
    videoPost: [],
    uploadPhoto: 0,
    uploadVideo: 0,
    uploadThumbnail: 0,
    navWrapperClass: "d-none",
    connectionBoxClass: "conHeightMin",
    formType: "",
    peopleCast: [{ _userIdCon: "", username_con: "" }],
    limit: 15,
    connections: [],
    connectionsVid: [],
    userLongitude: "",
    userLatitude: "",
    userLocation: "",
    poi: "",
    neighborhood: "",
    postcode: "",
    locality: "",
    place: "",
    region: "",
    country: "",

    urlMedia: "",
    pathThumb: "",
    profilePathSmall: "",
    profilePictureMedium: "",

    showAlert: true,
    newEmail: "",
    thumbSize: "thumbSm/",
    joinRoom: false,

    isInternetConnected: true,
    showPassword: false,
    checkBoxStatus: "not_accepted",
    buttonPressed: false,
    accountBanned: 0,
    loggedIn: false,
  };
}
export default {
  name: "Header",
  components: {
    // vueDropzone: vue2Dropzone,
    UploadPhotoModal,
    UploadVideoModal,
    Lottie,
    RightSidebar,
  },

  mixins: [validationMixin],
  data: function () {
    return initialDataHeader();
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      // loggedIn: (state) => state.auth.status.loggedIn,
      dataLogin: (state) => state.auth.user,
      authLoaded: (state) => state.auth.status.authLoaded,
      countNotif: (state) => state.notifModule.countNotif,
      notifHeader: (state) => uniqBy(state.notifModule.notifHeader, "_id"),
      allMessageRead: (state) => state.user.allMessageRead,
      loginCheckAuth: (state) => state.user.loginCheckAuth,
      noMessage: (state) => state.message.noMessage,
      allMessages: (state) => state.message.allMessages,
      activeMessage: (state) => state.message.activeMessage,
      status: (state) => state.publicAccess.maintenance.status,
      maintenanceTime: (state) =>
        state.publicAccess.maintenance.maintenanceTime,
      // profilePicture: (state) => state.auth.user.user.imgProfile,
    }),
    downtime() {
      let tempHour = this.$store.state.publicAccess.maintenance.downtime.hours;
      let tempMin = this.$store.state.publicAccess.maintenance.downtime.minutes;
      let hourString = "hour";
      let minString = "minute";
      if (tempHour > 1) hourString = " hours";
      let tempDowntime = `${tempHour} ${hourString}`;
      if (tempMin > 0) {
        if (tempMin > 1) minString = "minutes";
        tempDowntime = `${tempDowntime}, ${tempMin} ${minString}`;
      }
      return tempDowntime;
    },
    currentExploreFilterState() {
      return this.$store.state.exploreLocation;
    },
    lastCountry() {
      return this.$store.state.exploreLocationLast;
    },
    keywordSearch() {
      let keywordSearch = "";
      if (this.$route.query && this.$route.query.search) {
        keywordSearch = this.$route.query.search;
      }
      return keywordSearch;
    },
    latestKeyword() {
      return this.$store.state.latestKeyword || "";
    },
    logoutTrigger() {
      return this.$store.state.auth.logoutTrigger;
    },
  },

  watch: {
    async $route() {
      /*  if (!this.loggedIn) {
            this.checkAuth();
          }
      */
      if (!localStorage.getItem("userToken")) {
        this.$store.dispatch("auth/logout").then(() => {
          this.$store.commit("user/resetUserStateHeader");
          this.$store.commit("resetAllMessageState");
          this.$store.commit("auth/setAuthLoaded", true);
          this.loggedIn = false;
        });
      } else if (
        (localStorage.getItem("userToken") && this.loggedIn == false) ||
        localStorage
          .getItem("userToken")
          .substring(1, localStorage.getItem("userToken").length - 1) !=
          this.$store.state.auth.userToken
      ) {
        this.$store.commit("resetAllMessageState");
        // await this.$store.dispatch("auth/getdataLogin");
        this.loggedIn = this.$store.state.auth.status.loggedIn;
        this.checkAuth();
      }
      if (this.$route.query.search != "")
        this.currentSearch = this.$route.query.search
          ? this.$route.query.search
          : "";
      const paramsPage = this.$route.name;
      this.showToggelBar = this.checkToggleMenu(this.$route.name);

      switch (paramsPage) {
        case "Signup":
        case "Signin":
        case "ForgotPassword":
          // case "FriendRequest":
          this.headerPosition = "default";
          break;
        default:
          this.headerPosition = "fixed";
          break;
      }

      switch (this.$route.name) {
        case "HometownVideos":
        case "HometownVideosInterest":
        case "HometownUser":
        case "HometownUserInterest":
        case "HometownPhotos":
          if (this.$route.query.location == "current") {
            this.setPlaceholder =
              "Search your hobby in " + this.$store.state.currentPlace;
          } else {
            this.setPlaceholder =
              "Search your hobby in " + this.dataLogin.location.place;
          }
          break;
        case "CoterieVideosFilter":
          this.setPlaceholder = "Explore user's video in Coterie";
          break;
        case "CoteriePhotoFilter":
          this.setPlaceholder = "Explore user's photo in Coterie";
          break;
        case "CoterieIntouchFilter":
          this.setPlaceholder = "Explore user's intouch in Coterie";
          break;
        case "OnTheGoVideos":
        case "OnTheGoVideosInterest":
        case "OnTheGoUser":
        case "OnTheGoUserInterest":
          this.setPlaceholder =
            "Search your hobby in " + this.$store.state.currentPlace;
          break;
        default:
          this.setPlaceholder = "Search your hobby around the world";
          break;
      }
    },

    logoutTrigger() {
      if (this.logoutTrigger) this.logOut();
    },

    loginCheckAuth() {
      if (this.loginCheckAuth) {
        this.$store.commit("user/setLoginCheckAuth", false);
        this.checkAuth();
      }
    },
    // authLoaded() {
    //   if (this.authLoaded) {
    //     this.checkAuth();
    //   }
    // },
  },
  async created() {
    //this.showToggelBar = this.checkToggleMenu(this.$route.name);
    await store.restored;
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    if (localStorage.getItem("userToken")) {
      this.loggedIn = this.$store.state.auth.status.loggedIn;
      this.checkAuth();
    }
  },
  mounted() {
    this.$socket.on(
      "user/logoutAllDevices",
      this.loggedIn == true ? this.logOut : null
    );
    // window.addEventListener("scroll", this.updateScroll);
    // window.addEventListener("storage", function (event) {
    //   if (event.key === "userToken" && !event.newValue) {
    //     // do whatever to another active tabs you need after logout
    //     // location.reload();
    //   }
    //   if (event.key === "userToken" && event.newValue) {
    //     // do whatever to another active tabs you need after login
    //     // location.reload();
    //   }
    // });

    if (!localStorage.getItem("userToken")) {
      this.resetAllHomeState();
    }

    this.$socket.on(
      "admin/serverMaintenanceOut",
      this.handleServerMaintenanceStatus
    );

    this.loadServerMaintenanceStatus();

    //this for trigger to edit Profile picture on header
    this.$root.$on("newImgProfile", (newImgProfile) => {
      this.profilePictureMedium = this.urlMedia + THUMB_MEDIUM + newImgProfile;
    });
    this.$root.$on("renewName", (newFirstname, newLastname) => {
      this.firstname = newFirstname;
      this.lastname = newLastname;
    });
    this.$root.$on("triggerLogout", () => {
      this.logOut();
    });
    this.$root.$on("triggerLogin", () => {
      if (!this.showDD) this.$refs.dropdownSignin.show();
      else this.$refs.dropdownSignin.hide();
    });
    //Get emit from notificationList page
    this.$root.$on(
      "triggerReadNotif",
      (
        moduleType,
        notifType,
        actionType,
        notifId,
        slug,
        relatedId,
        countNotif
      ) => {
        this.readNotification(
          moduleType,
          notifType,
          actionType,
          notifId,
          slug,
          relatedId,
          countNotif
        );
      }
    );
    this.$root.$on("triggerPostComment", (comments) => {
      this.doCreateCommentPost(comments);
    });
    //this for trigger reply
    this.$root.$on("triggerPostReply", (index, replyComments) => {
      this.replyCommentPost(index, replyComments);
    });
    this.$root.$on("setOnthegoPlaceholder", (getPlace) => {
      this.setPlaceholder = "Search your hobby in " + getPlace;
    });
    this.$root.$on("setHometownPlaceholder", (currentPlace) => {
      //  this.$root.$emit("setNavbar", currentPlace);
      this.setPlaceholder = "Search your hobby in " + currentPlace;
    });
    this.$root.$on("clickTag", (empty) => {
      this.currentSearch = empty;
    });
    this.checkIfUserAccountListedInDeletedAccount();
  },
  destroyed() {
    this.leaveSocketOwnRoom();
    this.rootListenerOff();
    window.removeEventListener("scroll", this.updateScroll);
  },

  methods: {
    ...mapMutations(["resetPersistState", "resetAllHomeState"]),
    ...mapActions("notifModule", [
      "fetchNotifications",
      "readNotificationHead",
      "marksAllReadNotif",
    ]),

    rootListenerOff() {
      this.$root.$off("newImgProfile");
      this.$root.$off("renewName");
      this.$root.$off("triggerLogout");
      this.$root.$off("triggerLogin");
      this.$root.$off("triggerReadNotif");
      this.$root.$off("triggerPostComment");
      this.$root.$off("triggerPostReply");
      this.$root.$off("setOnthegoPlaceholder");
      this.$root.$off("setHometownPlaceholder");
      this.$root.$off("clickTag");
    },

    isLoggedIn() {
      if (!localStorage.getItem("userToken")) this.logOut();
    },
    handleShowPassword() {
      if (this.checkBoxStatus == "not_accepted") {
        this.showPassword = false;
      } else {
        this.showPassword = true;
      }
    },
    updateOnlineStatus(event) {
      this.isInternetConnected = event.type == "offline" ? false : true;
    },

    showModal() {
      this.$bvModal.show("signinModal");
    },

    loadServerMaintenanceStatus() {
      this.$store.dispatch("loadServerMaintenanceStatus");
    },

    checkAuth() {
      if (this.loggedIn) {
        this.$store.dispatch("checkUnreadMessageIndicator");

        (this.isLogin = true),
          (this.userId = this.dataLogin.id),
          (this.firstname = this.dataLogin.firstname),
          (this.lastname = this.dataLogin.lastname),
          (this.username = this.dataLogin.username);
        this.profilePicture = this.dataLogin.imgProfile;
        this.urlMedia = FILE_PATH + this.userId;
        this.pathThumb = FILE_PATH;
        this.profilePathSmall = THUMB_SMALL;
        this.profilePictureMedium =
          this.urlMedia + THUMB_MEDIUM + this.profilePicture;
        this.fetchNotificationsHandler(this.userId);
        // Get newEmail field from state
        this.newEmail = this.dataLogin.newEmail;

        if (!this.joinRoom) {
          this.joinSocketOwnRoom();
        }
      }
      localStorage.currentURL = this.$route.path;
    },

    handleRouting(name) {
      if (name == "Message") {
        this.$store.commit("user/setAllMessageRead", true);
      }
      if (name !== this.$route.name) {
        this.$router.push({ name });
      }
    },

    doCreateCommentPost: debounce(async function (comments) {
      let dispatchAction = "commentList/postComment";
      if (comments.content == "Intouch") {
        dispatchAction = "intouchList/postComment";
      }

      this.$store
        .dispatch(dispatchAction, { comments })
        .then(async () => {
          return;
        })
        .catch(() => {
          // console.log(err);
        });
    }, 300),
    /* Do reply  comment*/
    replyCommentPost: debounce(async function (index, replyComments) {
      const commentId = replyComments.commentId;
      const replyTo = replyComments.replyTo;
      let dispatchAction = "commentList/postReply";
      if (replyComments.content == "Intouch") {
        dispatchAction = "intouchList/postReply";
      }
      if (replyComments.replyText != null && replyComments.replyText != " ") {
        await this.$store
          .dispatch(dispatchAction, { replyComments, index: index })
          .then(() => {
            this.$root.$emit(
              "returnAfterReply",
              length,
              commentId,
              replyTo,
              index
            );
          })
          .catch(() => {});
      } else {
        this.errorReply = "Please enter a comment to save";
      }
    }, 300),

    albumTrigger() {
      if (this.albumStatus) {
        this.albumStatus = false;
      } else {
        this.albumStatus = true;
      }
    },
    changeConnectionClass() {
      this.connectionBoxClass = "conHeightMax";
    },

    checkIfUserAccountListedInDeletedAccount() {
      if (this.$store.state.auth && this.$store.state.auth.userToken) {
        this.$store
          .dispatch("user/findUserAccountStatus", {
            token: this.$store.state.auth.userToken,
          })
          .then((res) => {
            if (res["message"] == "Unauthorized Access - No Token Provided!") {
              this.logOut();
            }
            if (res.data.user.account_status == 2) {
              this.logOut();
            }
          });
      }
    },

    async fetchNotificationsHandler(userId) {
      const userNotif = {
        userId: userId,
      };
      await this.fetchNotifications({
        userNotif,
      })
        .then(() => {
          return;
          //  console.log(notif);
        })
        .catch(() => {
          // console.log(err);
        });
    },
    async marksAllRead() {
      await this.marksAllReadNotif({})
        .then(() => {})
        .catch(() => {
          // console.log(err);
        });
    },

    readNotification(
      moduleType,
      notifType,
      actionType,
      notifId,
      slug,
      relatedId,
      countNotif
    ) {
      var currentUrl = this.$route.path;
      const dataNotif = {
        moduleType: moduleType,
        notifType: notifType,
        actionType: actionType,
        relatedId: relatedId,
        userId: this.userId,
        currentUrl: currentUrl,
        slug: slug,
        countNotif: countNotif,
      };
      this.readNotificationHead({
        notifId: notifId,
        dataNotif,
      })
        .then(() => {
          this.$router.push(slug);
          /*    const notifStatus = resp.data.nModified;
          if (notifStatus != 0 && slug != currentUrl) {
            this.$router.push(slug);
          } */
        })
        .catch(() => {
          // console.log(err);
        });
    },

    submitFormLogin() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      var user = { email: this.form.email, password: this.form.password };
      if (this.form.email && this.form.password) {
        this.buttonPressed = true;
        this.$store
          .dispatch("auth/login", user)
          .then(() => {
            this.$bvModal.hide("signinModal");
            this.loggedIn = this.$store.state.auth.status.loggedIn;
            this.checkAuth();
            this.$root.$emit("setNavbar");
            this.$store.commit("resetConnectionStore");
            this.$store.commit("resetFollowState");
            this.$store.dispatch("checkUnreadMessageIndicator");
            return true;
          })
          .then(() => {
            this.accountBanned = 0;

            if (
              this.$route.name == "ExploreVideos" ||
              this.$route.name == "Signin" ||
              this.$route.name == "Signup" ||
              this.$route.name == "ExploreUsers"
            ) {
              this.$router.push({
                name: "HometownVideos",
              });
            } else if (this.$route.name == "VideoDetail") {
              this.$root.$emit("refetchVideoDetail");
            } else if (this.$route.name == "PostPhotoDetail") {
              this.$root.$emit("refetchPhotoDetail");
            } else if (this.$route.name == "FilterAlbum") {
              this.$store.commit("albumList/resetAlbum");
              this.$root.$emit("loadAlbum");
            } else if (this.$route.name == "FilterMedia") {
              // console.log("FilterMedia");
            } else if (this.$route.name == "Intouch") {
              this.$store.commit("intouchList/resetIntouchUserTarget", {
                usernameTarget: this.$route.params.username,
              });
              this.$root.$emit("refetchIntouch");
            } else {
              // location.reload();
            }
          })
          .then(() => {
            this.loginFailed = false;
            this.form = {
              email: "",
              password: "",
            };
          })
          .catch((err) => {
            // console.log(err.response.data);
            this.loginFailed = true;
            const errorData = err.response.data;

            switch (errorData.errMessage) {
              // case "not_exist":
              //   this.loginError =
              //     'The email address or username: "' +
              //     this.form.email +
              //     '" is not associated with any account. Double-check your login and try again.';
              //   break;
              case "account_inactive":
                this.loginError = "Your account has not yet been activated";
                break;
              case "account_banned":
                switch (errorData.account_status) {
                  case 3:
                    this.loginError =
                      "Your account has been suspended for 3 days";
                    break;
                  case 4:
                    this.loginError =
                      "Your account has been suspended for 7 days";
                    break;
                  default:
                    // error 5
                    this.loginError =
                      "Your account has been suspended for violating our terms. You may appeal by contacting support@mindcaster.tv";
                    break;
                }
                break;
              default:
                this.loginError =
                  "Please make sure your email/username and password are correct";
                break;
            }
            // console.log(error);
          })
          .finally(() => {
            this.buttonPressed = false;
          });
      }
    },

    validateState(email) {
      const { $dirty, $error } = this.$v.form[email];
      return $dirty ? !$error : null;
    },

    logOut: function () {
      this.$store.commit("resetAllMessageState");
      // var req = indexedDB.deleteDatabase("Mindcaster");
      // req.onsuccess = function () {
      //   return;
      // };
      // req.onerror = function () {
      //   return;
      // };
      this.$socket.removeListener("user/logoutAllDevices");
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.leaveSocketOwnRoom();
          this.resetInitialData();
          this.resetAllHomeState();
          this.resetPersistState();

          this.$store.commit("photoList/resetStatePhoto");
          this.$store.commit("videoList/resetStateVideo");
          this.$store.commit("albumList/resetAlbum");
          this.$store.commit("user/resetUserState");
          this.$store.commit("auth/setAuthLoaded", true);
          this.loggedIn = false;
        })
        .then(() => {
          this.$root.$emit("setNavbar", "");
          this.$root.$emit("renewCountry", this.lastCountry);
        })
        .then(() => {
          // window.location.href = process.env.VUE_APP_BASE_URL;
          this.$router.push({ name: "ExploreVideos" });
        });
    },
    resetInitialData: function () {
      Object.assign(this.$data, initialDataHeader());
    },
    loadNotifPage() {
      this.$router.push("/notifications");
    },
    signUp() {
      this.$router.push("/signup");
      this.$bvModal.hide("signinModal");
    },
    forgotPassword() {
      this.$router.push("/forgot_password");
      this.$bvModal.hide("signinModal");
    },
    searchResult() {
      const routeName = this.$route.name;
      let query = {};
      if (
        typeof this.currentSearch !== "undefined" &&
        this.currentSearch !== ""
      ) {
        if (
          routeName == "ExploreUsers" ||
          routeName == "ExploreVideos" ||
          routeName == "ExplorePhotos"
        ) {
          if (this.currentSearch != this.keywordSearch) {
            this.$store.commit("setExplorerStateSearch", {
              payload: "Search",
              routeName: this.$route.name,
            });
          } else if (
            this.keywordSearch == this.currentSearch &&
            this.$store.state.isSpecificCountrySearch !=
              this.$store.state.latestIsSpecificCountrySearch
          ) {
            this.$store.commit("setExplorerStateSearch", {
              payload: "Search",
              routeName: this.$route.name,
            });
            switch (routeName) {
              case "ExploreUsers":
                this.$root.$emit("loadHomeUsers");
                break;
              case "ExplorePhotos":
                this.$root.$emit("loadHomePhotos");
                break;
              case "ExploreVideos":
                this.$root.$emit("loadHomeVideos");
                break;
            }
          }

          this.$store.commit(
            "setLatestIsSpecificCountrySearch",
            this.$store.state.isSpecificCountrySearch
          );

          this.$store.commit("setExplorerLocation", {
            payload: "Search",
            routeName: this.$route.name,
          });
        }
        setTimeout(() => {
          this.$store.commit("setLatestKeyword", {
            payload: this.currentSearch,
          });
          query = { search: decodeURI(this.currentSearch) };
          this.gotToRoute(routeName, query);
        }, 200);
      } else {
        this.$store.commit("setLatestKeyword", {
          payload: "",
        });

        switch (routeName) {
          case "ExploreUsers":
          case "ExplorePhotos":
          case "ExploreVideos":
            // emite renewCountry on contentHome
            this.$router.replace({ query: null });
            this.$store.commit("setExplorerStateSearch", {
              payload: "Search",
              routeName: this.$route.name,
            });
            this.$root.$emit("renewCountry", this.lastCountry);
            break;
          default:
            if (this.currentSearch && this.currentSearch != "") {
              this.$router
                .replace({ path: this.$route.path, query: query })
                .catch(() => {});
            } else {
              this.$router.replace({ path: this.$route.path }).catch(() => {});
            }
            return false;
        }
      }
    },

    gotToRoute(routeName, query) {
      switch (routeName) {
        case "HometownVideos":
        case "HometownUser":
        case "HometownPhotos":
        case "CoterieVideosFilter":
        case "CoteriePhotoFilter":
        case "CoterieIntouchFilter":
        case "ExplorePhotos":
        case "ExploreUsers":
          if (this.currentSearch && this.currentSearch != "") {
            this.$router
              .replace({ path: this.$route.path, query: query })
              .catch(() => {});
          } else {
            this.$router.replace({ path: this.$route.path }).catch(() => {});
          }
          break;
        default:
          if (this.currentSearch && this.currentSearch != "") {
            this.$router.replace({ path: "/", query: query }).catch(() => {});
            break;
          } else {
            this.$router.replace({ path: "/" }).catch(() => {});
          }
      }
    },

    loadHome() {
      if (this.loggedIn) {
        this.$router
          .push({
            name: "HometownVideos",
          })
          .catch(() => {});
      } else {
        this.$store.commit("setExplorerLocation", {
          payload: "WW",
          routeName: "ExploreVideos",
        });
        this.$store.commit("setLastExplorerLocation", {
          payload: "WW",
        });
        this.$router
          .push({
            name: "ExploreVideos",
          })
          .catch(() => {});
      }
    },

    notificationSumLoader() {
      this.$store.dispatch("user/notificationDataSumLoad").then(
        (data) => {
          if (data.notifData !== undefined) {
            this.connectionRequestNumber =
              data.notifData.connectionRequestNumber;

            if (this.connectionRequestNumber > 0) {
              this.conReq = true;
              this.noNotif = false;
              this.totalNotification += this.connectionRequestNumber;
            }
          } else {
            this.conReq = false;
            this.noNotif = true;
          }
        },
        () => {
          // console.log(error);
        }
      );
    },
    handlePhotoModal() {
      this.$bvModal.show("upload-photo");
    },
    handleVideoModal() {
      this.$bvModal.show("upload-video");
    },

    // =============================== Socket Related =============================== //
    handleServerMaintenanceStatus(payload) {
      this.$store.commit("setIsServerMaintenance", payload);
    },
    joinSocketOwnRoom() {
      this.$socket.emit("userRoom/join", this.userId);

      this.$socket.on("userRoom/messageSent", this.handleIncomingMessage);
      this.$socket.on(
        "userRoom/deleteMessageActionSent",
        this.handleDeleteMessageAction
      );
      this.$socket.on(
        "userRoom/connectionTypeChanged",
        this.handleConnectionChanges
      );
      this.$socket.on(
        "userRoom/messageRead",
        this.handleReadMessageToUserState
      );
      this.$socket.on("userRoom/unreadCountChanged", this.handleUnreadCount);
      this.$socket.on("userRoom/banned", this.handleBan);
      this.joinRoom = true;
    },
    leaveSocketOwnRoom() {
      if (this.joinRoom) {
        this.$socket.emit("userRoom/leave", this.userId);
        this.$socket.removeListener(
          "userRoom/messageSent",
          this.handleIncomingMessage
        );
        this.$socket.removeListener(
          "userRoom/deleteMessageActionSent",
          this.handleDeleteMessageAction
        );
      }
      this.$socket.removeListener(
        "userRoom/connectionTypeChanged",
        this.handleConnectionChanges
      );
      this.$socket.removeListener(
        "userRoom/messageRead",
        this.handleReadMessageToUserState
      );
      this.$socket.removeListener(
        "userRoom/unreadCountChanged",
        this.handleUnreadCount
      );
      this.$socket.removeListener("userRoom/banned", this.handleBan);
      this.joinRoom = false;
    },

    handleReadMessageToUserState(payload) {
      this.$store.commit("setLiveMessageAsRead", payload.messageId);
      if (
        payload.senderId.toString() == this.$store.state.auth.user.id.toString()
      ) {
        this.$store.commit("setLiveMessageReadDecreaseCount", payload);
      }
    },
    handleIncomingMessage(data) {
      const { senderId, targetId, messageData, messageRoomData } = data;

      let index = this.allMessages.findIndex(
        (messageRoom) => messageRoom._id == messageRoomData._id
      );

      // ================== For live message ================== //
      // chat active = sender
      if (index > -1) {
        if (
          this.allMessages[index].countUnread == 0 &&
          senderId.toString() == this.activeMessage.userId.toString()
        ) {
          this.$store.dispatch("markOneAsRead", messageData).then(() => {
            this.$store.commit("setScrollBottom", true);
            this.$store.commit("setLiveMessageAsRead", messageData._id);
            this.$socket.emit("userRoom/readMessage", {
              senderId: targetId,
              targetId: senderId,
              messageId: messageData._id,
            });
          });
        } else if (
          senderId.toString() == this.userId.toString() &&
          targetId.toString() == this.activeMessage.userId.toString()
        ) {
          this.$store
            .dispatch("addNewMessageToUserStateAction", [messageData])
            .then(() => {
              this.$store.commit("setScrollBottom", true);
            });
        }
      }

      // ================== For messageRoom ================== //

      // Case if new message, no message room before
      if (index == -1) {
        this.$store.dispatch("loadMessageRoom").then(() => {
          let index = this.allMessages.findIndex(
            (messageRoom) => messageRoom._id == messageRoomData._id
          );
          if (
            index > -1 &&
            senderId.toString() == this.activeMessage.userId.toString()
          ) {
            const payloadForDispatch = {
              username: this.activeMessage.username,
              targetId: this.activeMessage.userId,
              type: "all",
              messageRoomId: this.activeMessage.messageRoomId,
            };
            this.$store.dispatch("loadMessage", payloadForDispatch);
          }
        });
      } else {
        // Case if message room exist
        const payload = {
          senderId,
          userId: this.userId,
          messageRoomData,
          index,
        };
        this.$store.dispatch("updateNewMessageRoom", payload);
      }

      // for new message indicator
      if (this.userId.toString() !== senderId.toString()) {
        this.$store.commit("user/setAllMessageRead", false);
      }
    },
    handleDeleteMessageAction(messageId) {
      this.$store.commit("deleteAMessageFromState", messageId);
    },
    handleConnectionChanges(payload) {
      payload.userId = this.userId;
      this.$store.dispatch("updateMessageRoomConType", payload);
    },

    handleUnreadCount(payload) {
      this.$store.commit("changeUnreadMsgCount", payload);
    },

    handleBan(payload) {
      if (payload == true) this.logOut();
    },
    // =============================== Socket Related =============================== //
  },
};
</script>
