<template>
  <div>
    <b-modal
      centered
      lazy
      size="lg"
      class="col-12"
      id="upload-video"
      dialog-class="modalUploadMedia"
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-variant="none"
      ok-title="Cancel"
    >
      <template #modal-header-close>
        <b-button size="sm" variant="none"
          ><b-icon
            icon="x"
            animation="none"
            font-scale="2"
            class="ml-2"
          ></b-icon>
        </b-button>
      </template>
      <template #modal-title>
        <h5>Video Upload</h5>
      </template>

      <template v-if="showConvertLoading" #modal-footer>
        <b-button variant="none" disabled>
          <b-spinner class="mr-1" small></b-spinner>
          Processing...
        </b-button>
      </template>

      <vue-dropzone
        class="col-12"
        id="dropzone"
        ref="vidDropzone"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        @vdropzone-sending="beforeSendVideo"
        @vdropzone-file-added="beforeUploadVideo"
        @vdropzone-canceled="uploadCanceledVideo"
        @vdropzone-upload-progress="onProgress"
        @vdropzone-success="afterCompleteVideo"
        @vdropzone-error="afterErrorVideo"
      >
        <div class="dropzone-custom-content">
          <b-icon
            icon="cloud-arrow-down"
            animation="none"
            font-scale="10"
            class="mr-2"
          ></b-icon>
          <h6 class="dropzone-custom-title">Drag and drop your video here</h6>
          <div class="subtitle">or click to browse your computer</div>
          <br />
          <span
            >We recommend compressing your video before uploading since we
            currently only support up to 350MB per file upload.</span
          >
          <br />
          <span> Currently our supported format are:</span>
          <br />
          <span> mov, mp4, WebM, avi</span>
        </div>
      </vue-dropzone>
    </b-modal>

    <b-modal
      lazy
      size="sm"
      class="col-12"
      id="detail-video"
      dialog-class="modalUploadMedia form-video-input"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-title>
        <h5>Video Upload</h5>
      </template>
      <template #modal-header-close>
        <b-button size="sm" variant="none" @click="deletePostVideo"
          ><b-icon
            icon="x"
            animation="none"
            font-scale="2"
            class="ml-2"
          ></b-icon>
        </b-button>
      </template>
      <b-card no-body>
        <form-wizard
          stepSize="xs"
          title=""
          no-header
          :subtitle="null"
          color="#31a3e0"
        >
          <template slot="footer" slot-scope="props">
            <b-row>
              <b-col class="col-sm-12 col-md-10 offset-md-1 pt-2 px-0">
                <div class="wizard-footer-left">
                  <wizard-button
                    v-if="props.activeTabIndex > 0"
                    @click.native="
                      checkPage(props, props.activeTabIndex, 'previous')
                    "
                    :style="props.fillButtonStyle"
                    >Previous</wizard-button
                  >
                </div>
                <div class="wizard-footer-right">
                  <wizard-button
                    v-if="!props.isLastStep"
                    @click.native="
                      checkPage(props, props.activeTabIndex + 2, 'next')
                    "
                    class="wizard-footer-right"
                    :style="props.fillButtonStyle"
                    >Next</wizard-button
                  >
                </div>
              </b-col>
            </b-row>
          </template>
          <tab-content
            title="Step 1"
            :before-change="() => validateStep()"
            @click.native="checkPage(null, 1, 'tab')"
          >
            <div style="text-align: center">
              <label for="title">Video Preview :</label>
            </div>
            <b-row class="justify-content-center">
              <b-col class="col-sm-12 col-md-6">
                <transition
                  v-if="showSkeletonVid && dataForm.mediaFile"
                  name="fade"
                >
                  <b-skeleton-img no-aspect height="300px"></b-skeleton-img>
                </transition>
                <video v-else class="container" controls id="mediaFile">
                  <source
                    :src="videopath + dataForm.mediaFile"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </b-col></b-row
            >
            <b-form-group>
              <b-col class="col-sm-12 col-md-10 offset-md-1 pt-2 mb-2">
                <label for="mediaTitle">Video Title :</label>
                <b-form-input
                  id="video-title"
                  name="mediaTitle"
                  v-model="dataForm.mediaTitle"
                  placeholder="Your Video Title"
                  :state="validateState('mediaTitle')"
                ></b-form-input>
                <div
                  class="error text-danger"
                  v-if="$v.dataForm.mediaTitle.$error"
                >
                  Video title is required
                </div>
                <div
                  class="error text-danger"
                  v-if="!$v.dataForm.mediaTitle.maxLength"
                >
                  Title cannot exceed
                  {{ $v.dataForm.mediaTitle.$params.maxLength.max }}
                  characters.
                </div>
              </b-col>
            </b-form-group>
            <b-form-group>
              <b-col
                class="
                  col-sm-12 col-md-10
                  offset-md-1
                  pt-2
                  mb-2
                  mediaDescription
                "
              >
                <label for="description">Video Description :</label>
                <div
                  :class="{
                    'is-invalid': $v.dataForm.mediaDescription.$error,
                  }"
                  id="texteditor"
                  ref="texteditor"
                  v-auto-expand
                  v-html="dataForm.mediaDescription"
                  :state="validateState('mediaDescription')"
                  class="
                    col-md-12
                    editme
                    texteditor-desc texteditor-upload-video
                    pl-2
                  "
                  contenteditable
                  :placeholder="newPlaceholder == true ? textPlaceholder : ''"
                  @blur="onEdit"
                  @keydown.enter="endEdit"
                  @click="getCursorPosDesc"
                  @focusin="returnPlaceholderDesc(false)"
                  @focusout="returnPlaceholderDesc(true)"
                ></div>
                <div
                  class="invalid-feedback"
                  v-if="$v.dataForm.mediaDescription.$error"
                >
                  <div
                    class="error"
                    v-if="!$v.dataForm.mediaDescription.maxLength"
                  >
                    Description cannot exceed
                    {{ $v.dataForm.mediaDescription.$params.maxLength.max }}
                    characters.
                  </div>
                </div>
              </b-col>
            </b-form-group>
          </tab-content>
          <tab-content
            title="Step 2"
            :before-change="() => validateStep2()"
            @click.native="checkPage(null, 2, 'tab')"
          >
            <div style="text-align: center">
              <label for="thumbnail">Thumbnail :</label>
            </div>
            <b-row class="justify-content-center">
              <b-col lg="6" md="12" class="text-center">
                <vue-dropzone
                  id="dropzone-vid-thumb"
                  ref="vidThumbDrozone"
                  :options="dropzoneOptionsThumb"
                  :useCustomSlot="true"
                  @vdropzone-removed-file="changeThumbnail"
                  @vdropzone-success="afterCompleteThumbnail"
                  @vdropzone-error="afterErrorThumbnail"
                >
                  <div class="dropzone-custom-content">
                    <transition
                      v-if="showSkeletonImg && dataForm.mediaThumbnail"
                      name="fade"
                    >
                      <b-skeleton-img no-aspect height="220px"></b-skeleton-img>
                    </transition>
                    <b-img-lazy
                      v-else
                      thumbnail
                      :src="
                        dataForm.mediaThumbnail
                          ? `${filepath}/thumbMd/${dataForm.mediaThumbnail}`
                          : ''
                      "
                    ></b-img-lazy>
                    <div class="subtitle">
                      Drag and drop or click to change custom thumbnail
                    </div>
                  </div>
                </vue-dropzone>
                <div
                  class="error text-danger"
                  v-if="$v.dataForm.mediaThumbnail.$error"
                >
                  Thumbnail is required
                </div>
              </b-col>
            </b-row>
            <b-form-group>
              <b-row>
                <b-col class="col-sm-12 col-md-10 offset-md-1 py-1 tags-input">
                  <label class="typo__label"
                    >Tag what's this video about:</label
                  >
                  <div class="borderPeopleInPicture rounded">
                    <multiselect
                      id="tags-vid-new"
                      v-model="dataForm.tags"
                      tag-placeholder="Add this as new tag"
                      placeholder="Search or add a tag here..."
                      :hideSelected="true"
                      :allow-empty="true"
                      :options="listTags"
                      :multiple="true"
                      :max="7"
                      :taggable="true"
                      @tag="onCloseTags"
                      @close="onCloseTags"
                      :close-on-select="false"
                      @search-change="searchTags"
                      :max-height="0"
                      :class="{
                        'is-invalid': $v.dataForm.tags.$error,
                      }"
                    >
                    </multiselect>
                  </div>
                  <div v-if="this.tagLimit" class="error text-danger">
                    Tags can't be more than 7
                  </div>
                  <div class="invalid-feedback">
                    <div class="error" v-if="$v.dataForm.tags.$error">
                      Tag is required
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-row>
              <b-col cols="col-sm-12 col-md-10 offset-md-1 mt-2">
                <span style="color: green"
                  >Tags in your videos are crucial in helping you connect with
                  others that share your interest. Make sure your tags are
                  specific. Misspelling your tags can affect how you get exposed
                  / recognized by the public.
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-sm-12 col-md-5 offset-md-1 pt-2 pb-2 mb-2">
                <label for="location">Where was this video recorded?</label>
                <MapBoxComponent
                  mapboxPlaceholder="Enter video location"
                  :i="0"
                  @resulted="loadDataMapVideo"
                  @resetMap="resetDataMap"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-sm-12 col-md-10 offset-md-1 pt-1 pb-0">
                <b-form-group>
                  <b-form-checkbox
                    :disabled="disableCheckLocation"
                    v-model="dataForm.showLocation"
                    name="showLocation"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Show location
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Step 3" @click.native="checkPage(null, 3, 'tab')">
            <!--AUTOCOMPLETE HERE-->
            <b-form-group>
              <b-row>
                <b-col class="col-sm-12 col-md-10 offset-md-1 pt-1">
                  <b-col class="row mt-2">
                    <label>Cast</label>
                    <b-form-checkbox
                      v-model="dataForm.castMe"
                      @change="checkedMePopup($event)"
                      name="tag-me"
                      role="button"
                      class="ml-2"
                      switch
                    >
                      Include myself
                    </b-form-checkbox>
                  </b-col>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-sm-12 col-md-9 offset-md-1 pt-2 mb-2">
                  <div
                    :class="'iq-card ' + connectionBoxClass"
                    class="borderPeopleInPicture"
                  >
                    <div class="iq-card-body">
                      <div class="row">
                        <div class="col-sm-6 ml-n1">
                          <autocomplete
                            aria-label="Search Connections"
                            placeholder="Search Connections"
                            :search="searchConnection"
                            :get-result-value="getResultValueCon"
                            @submit="handleSubmitCast"
                            :debounce-time="500"
                          >
                            <template #result="{ result, props }">
                              <li
                                v-bind="props"
                                class="
                                  autocomplete-result
                                  search-result
                                  pt-2
                                  pb-2
                                "
                              >
                                <b-avatar
                                  variant="primary"
                                  size="30px"
                                  class="ml-3"
                                  :text="
                                    result.firstname_con.charAt(0) +
                                    result.lastname_con.charAt(0)
                                  "
                                  :src="
                                    result.imgProfile
                                      ? `${pure_filepath}${result._userIdCon}${thumbPP}${result.imgProfile}`
                                      : ''
                                  "
                                  :alt="result.firstname_con"
                                ></b-avatar>
                                {{
                                  result.firstname_con +
                                  " " +
                                  result.lastname_con
                                }}
                              </li>
                            </template>
                          </autocomplete>
                        </div>
                      </div>
                      <div class="py-2">
                        <pim-slider
                          v-bind:key="0"
                          :peopleInMedia="dataForm.cast"
                          :filePath="pure_filepath"
                          :thumbPP="thumbPP"
                          :allowRemove="true"
                          @removeFromMedia="removeCaster"
                          :bullets="true"
                        />
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-sm-12 col-md-9 offset-md-1 pt-2 mb-2">
                  <div v-if="limitCast" class="error text-danger">
                    You can't tag more than 25 people
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row>
                <b-col class="col-sm-12 col-md-5 offset-md-1 pt-2 mb-2">
                  <label for="videoaccess"> Who can see my Video? </label>

                  <b-form-select
                    v-model="dataForm.mediaAccess"
                    class="borderPeopleInPicture"
                  >
                    <template #first>
                      <b-form-select-option selected :value="0" default
                        >Everyone</b-form-select-option
                      ></template
                    >
                    <b-form-select-option :value="1"
                      >Acquaintances</b-form-select-option
                    >
                    <b-form-select-option :value="2"
                      >Friend</b-form-select-option
                    >
                    <b-form-select-option :value="3"
                      >Best Friend</b-form-select-option
                    >
                  </b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row>
                <b-col class="col-sm-12 col-md-10 offset-md-1 pt-2 mb-2">
                  <b-form-group
                    label="Is this video safe for kids ?"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio
                      class="pt-2"
                      v-model="dataForm.safeForKids"
                      :aria-describedby="ariaDescribedby"
                      name="savekids"
                      :value="1"
                      >Yes, it is</b-form-radio
                    >
                    <b-form-radio
                      class="pt-2"
                      v-model="dataForm.safeForKids"
                      :aria-describedby="ariaDescribedby"
                      name="savekids"
                      :value="0"
                      >No, it is for young adults over 18</b-form-radio
                    >
                    <div
                      class="error text-danger"
                      style="position: absolute"
                      v-if="$v.dataForm.safeForKids.$error"
                    >
                      Video access by age is required
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </tab-content>
        </form-wizard>
      </b-card>
      <template #modal-footer>
        <b-button
          v-if="uploadPage == 3"
          size="sm"
          variant="none"
          @click="handleDelete"
        >
          cancel
        </b-button>

        <!--
          <b-button size="sm" variant="secondary" disabled v-if="uploadPage !== 3"
          ><b-icon
            icon="inbox-fill"
            animation="none"
            font-scale="1"
            class="mr-2"
          ></b-icon>
          Save
        </b-button> -->
        <b-button
          size="sm"
          :variant="!disableSubmitVideo ? 'primary' : 'secondary'"
          v-if="uploadPage == 3"
          :disabled="disableSubmitVideo"
          @click.prevent="submit"
        >
          <i
            class="fa"
            :class="
              !disableSubmitVideo ? 'fa-inbox mr-2' : 'fa-spinner fa-spin mr-2'
            "
            aria-hidden="true"
            font-scale="1"
          ></i>
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal
      centered
      id="del-form-video-all"
      size="sm"
      title="Delete Form"
      lazy
      ok-only
    >
      <p>Are you sure you want to delete post ?</p>

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="none" @click="cancel"> Cancel </b-button>
        <b-button size="sm" variant="primary" @click="deletePostVideo">
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PimSlider from "@/components/tools/PimSlider.vue";
import autoExpand from "@/directives/autoExpand";
import Multiselect from "vue-multiselect";
import vue2Dropzone from "vue2-dropzone";
import MapBoxComponent from "@/components/tools/MapBoxComponent.vue";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { validationMixin } from "vuelidate";
import { uniqBy, debounce } from "lodash";
import { getCaretCharOffset } from "@/helpers/getCaretCharOffset.js";
import { required, between, maxLength } from "vuelidate/lib/validators";
import { rejectDirty, disallowSpecialChar } from "@/helpers/sanitizeString";
import {
  FILE_PATH,
  EIGHTTEEN_TIMESTAMP,
  CURRENT_DATE,
  VIDEO_UPLOAD_LIMIT,
  PHOTO_UPLOAD_LIMIT,
  TITLE_LIMIT,
  DESCRIPTION_LIMIT,
  WORDING_DISALLOW_SPECIAL_CHAR_MODAL_TITLE,
  WORDING_DISALLOW_SPECIAL_CHAR_MODAL_MESSAGE,
} from "../../variables";

export default {
  name: "UploadVideoModal",
  components: {
    VueDropzone: vue2Dropzone,
    MapBoxComponent,
    Multiselect,
    PimSlider,
  },
  directives: { autoExpand },
  mixins: [validationMixin],
  data() {
    return {
      pure_filepath: FILE_PATH,
      newPlaceholder: true,
      uploadComplete: false,
      showSkeletonVid: false,
      showSkeletonImg: false,
      disableSubmitVideo: false,
      showConvertLoading: false,
      triggerIntouchVideo: false,
      disableCheckLocation: true,
      uploadPage: 1,
      currentCursorDesc: 0,
      listTags: [],
      dataForm: [],
      connections: [],
      dropzoneOptions: {},
      dropzoneOptionsThumb: {},
      thumbPP: "/80x80/",
      filepath: "",
      videopath: "",
      currentFilename: "",
      currentProcessId: "",
      textPlaceholder: "Create description here..",
      connectionBoxClass: "conHeightMin",
      tagLimit: false,
      limitCast: false,
    };
  },
  validations: {
    dataForm: {
      mediaTitle: {
        required,
        maxLength: maxLength(TITLE_LIMIT),
      },
      mediaDescription: {
        maxLength: maxLength(DESCRIPTION_LIMIT),
      },
      safeForKids: {
        required,
        between: between(0, 1),
      },
      mediaThumbnail: {
        required,
      },
      tags: {
        required,
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    dataLogin() {
      return this.$store.state.auth.user;
    },
    userToken() {
      return this.$store.state.auth.userToken;
    },
    rejectAge() {
      const userBirthday = this.dataLogin.birthday;
      return CURRENT_DATE - userBirthday < EIGHTTEEN_TIMESTAMP ? true : false;
    },
    isUploadingVideo() {
      return this.$store.state.isUploadingVideo;
    },
  },

  created() {
    this.filepath = FILE_PATH + this.dataLogin.id;
    this.videopath = FILE_PATH + "video/" + this.dataLogin.id + "/";
    this.dropzoneOptions = {
      url: process.env.VUE_APP_STORAGE_API + "video/uploadVideo",
      timeout: null,
      addRemoveLinks: true,
      uploadMultiple: false,
      thumbnailWidth: 400,
      thumbnailHeight: 400,
      dictRemoveFile: "Change File",
      maxFiles: 1,
      maxFilesize: VIDEO_UPLOAD_LIMIT,
      acceptedFiles: ".mov,.MP4,.WebM,.AVI",
      headers: {
        Authorization: "Bearer " + this.userToken,
      },
    };

    this.dropzoneOptionsThumb = {
      url: process.env.VUE_APP_STORAGE_API + "video/uploadVidThumbnail",
      timeout: null,
      addRemoveLinks: true,
      uploadMultiple: false,
      thumbnailWidth: 400,
      thumbnailHeight: 400,
      dictRemoveFile: "Change File",
      maxFiles: 1,
      maxFilesize: PHOTO_UPLOAD_LIMIT,
      acceptedFiles: "image/*",
      headers: {
        Authorization: "Bearer " + this.userToken,
      },
    };
    // this.listTags = this.dataLogin.interest.map((item) => {
    //   return item.interest_title;
    // });
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleCloseVideo);
    this.$root.$on("bv::modal::show", () => {});

    this.$root.$on("bv::modal::shown", (modalId) => {
      if (modalId.componentId == "intouch-gallery-select") {
        this.triggerIntouchVideo = true;
      }
    });
    this.$root.$on("bv::modal::shown", (modalId) => {
      if (modalId.componentId == "intouch-gallery-edit") {
        this.triggerIntouchVideo = true;
      }
    });
  },

  beforeDestroy() {
    this.$root.$off("bv::modal::show");
    this.$root.$off("bv::modal::shown");
    window.removeEventListener("beforeunload", this.handleCloseVideo);
  },

  methods: {
    handleCloseVideo(e) {
      if (this.isUploadingVideo) {
        e.preventDefault();
        e.returnValue = "Upload is on progress. Cancel upload?";
        return "Upload is on progress. Cancel upload?";
      }
    },

    returnPlaceholderDesc(value) {
      this.newPlaceholder = value;
      if (this.dataForm.mediaDescription == "<br>") {
        this.dataForm.mediaDescription = "";
      }
    },
    getCursorPosDesc() {
      var elm = document.querySelector(".texteditor-desc");
      this.currentCursorDesc = getCaretCharOffset(elm);
    },
    endEdit() {
      this.dataForm.mediaDescription;
    },
    onEdit(evt) {
      var src = evt.target.innerHTML;
      this.dataForm.mediaDescription = src;
    },

    validateState(values) {
      const { $dirty, $error } = this.$v.dataForm[values];
      return $dirty ? !$error : null;
    },

    checkedMePopup: function (event) {
      if (event) {
        const dataMe = {
          _userIdCon: this.dataLogin.id,
          firstname_con: this.dataLogin.firstname,
          lastname_con: this.dataLogin.lastname,
          username_con: this.dataLogin.username,
          imgProfile: this.dataLogin.imgProfile,
        };
        this.handleSubmitCast(dataMe);
      } else {
        this.removeCaster(this.dataLogin.id);
      }
    },
    handleSubmitCast(result) {
      const userIdCon = result._userIdCon;
      if (!this.dataForm.cast || this.dataForm.cast.length == 0) {
        this.dataForm.cast = [];
        this.dataForm.cast.push(result);
        this.limitCast = false;
      } else if (this.dataForm.cast.length < 25) {
        this.limitCast = false;
        this.dataForm.cast.forEach((el) => {
          if (el._userIdCon != userIdCon) {
            this.dataForm.cast.push(result);
          }
        });
      } else if (this.dataForm.cast.length >= 25) {
        this.limitCast = true;
      }
      this.dataForm.cast = uniqBy(this.dataForm.cast, "_userIdCon");
      this.$forceUpdate();
    },
    removeCaster(userIdCon) {
      if (userIdCon == this.dataLogin.id) {
        this.dataForm.castMe = false;
      }
      let newCaster = [];
      this.dataForm.cast.forEach((el) => {
        if (el._userIdCon != userIdCon) {
          newCaster.push(el);
        }
      });
      this.dataForm.cast = newCaster;
      this.$forceUpdate();
    },

    // ==================== dropzone video related events ====================
    // eslint-disable-next-line
    beforeSendVideo(file, xhr, formData) {
      let splitFilename = file.name.split(".");
      let fileExt = splitFilename[splitFilename.length - 1];
      let filename = `${Date.now()}_${this.dataLogin.id}.${fileExt}`;
      let processid = `${Date.now()}_${this.dataLogin.id}`;
      xhr.setRequestHeader("filename", filename);
      xhr.setRequestHeader("processid", processid);
      formData.append("source", "browser");
      this.currentFilename = filename;
      this.currentProcessId = processid;

      this.$socket.emit("upload/videoToggle", {
        videoName: this.currentFilename,
        processid: this.currentProcessId,
        auth: "Bearer " + this.userToken,
        progress: "upload",
      });
    },

    beforeUploadVideo() {
      this.$store.commit("setIsUploadingVideo", true);
      localStorage.setItem("isUploading", 0);
    },

    uploadCanceledVideo() {
      // this.$refs.vidDropzone.removeAllFiles(true);
      // setTimeout(() => {
      this.$store
        .dispatch("videoList/deleteCanceledUploadVideo", {
          videoName: this.currentFilename,
          processid: this.currentProcessId,
        })
        .then(() => {
          this.currentFilename = "";
          this.currentProcessId = "";
          this.showConvertLoading = false;
          this.$store.commit("setIsUploadingVideo", false);
          this.uploadComplete = false;
          localStorage.setItem("isUploading", 0);
        });
      // }, 2000);
    },

    onProgress() {
      this.showConvertLoading = true;
    },

    afterCompleteVideo(_, response) {
      this.uploadComplete = true;

      this.dataForm = {
        mediaTitle: "",
        mediaDescription: "",
        mediaAccess: this.dataLogin.privacy_setting.posts_show,
        tags: [],
        qualityOption: response.data.qualityOption,
        mediaFile: response.data.filenameUpload,
        duration: response.data.duration,
        name: response.data.name,
        showLocation: 0,
        safeForKids: "",
        mediaThumbnail: response.data.imgThumbnail,
      };
      this.uploadPage = 1;
      this.showSkeletonVid = true;
      this.$bvModal.hide("upload-video");
      if (response.status) {
        this.showConvertLoading = false;
        this.$bvModal.show("detail-video");
      }

      this.$socket.emit("upload/videoToggle", {
        videoName: response.data.filenameUpload,
        processid: this.currentProcessId,
        auth: "Bearer " + this.userToken,
        progress: "finish",
      });

      localStorage.setItem("isUploading", 1);

      setTimeout(() => {
        if (response.data.filenameUpload) {
          this.showSkeletonVid = false;
        }
      }, 500);
    },

    afterErrorVideo(_file) {
      let textError = "";
      var fileSize = _file.size;
      var fileType = _file.type;
      var checkLImit = VIDEO_UPLOAD_LIMIT * 1024 * 1024;
      this.showConvertLoading = false;

      if (!fileType.startsWith("video")) {
        textError = `Upload Error - No video file found`;
      } else if (checkLImit < fileSize) {
        textError = `File size exceeds ${VIDEO_UPLOAD_LIMIT} MB limit`;
      } else {
        textError = `There’s a problem with the connection`;
      }
      _file.previewElement.querySelectorAll(
        "#dropzone .dz-error-message span"
      )[0].textContent = textError;

      this.$store.dispatch("videoList/deleteCanceledUploadVideo", {
        videoName: this.currentFilename,
        processid: this.currentProcessId,
      });
      localStorage.setItem("isUploading", 0);
    },
    // ==================== dropzone video related events ====================

    // ==================== dropzone thumbnail video related events ====================
    changeThumbnail: debounce(function () {
      let file = [];
      file.push({
        filenameUpload: this.dataForm.mediaThumbnail,
      });
      this.$store
        .dispatch("videoList/cancelUploadVidThumbnail", file)
        .then(() => {
          this.dataForm.mediaThumbnail = "";
        })
        .catch(() => {
          // console.log(err)
        });
    }, 300),

    afterCompleteThumbnail: debounce(function (_, response) {
      if (this.dataForm.mediaThumbnail) {
        let file = [];
        file.push({
          filenameUpload: this.dataForm.mediaThumbnail,
        });
        this.showSkeletonImg = true;
        this.$store
          .dispatch("videoList/cancelUploadVidThumbnail", file)
          .then(() => {
            this.dataForm.mediaThumbnail = "";
          })
          .then(() => {
            this.dataForm.mediaThumbnail = response.data.filenameUpload;
            setTimeout(() => {
              if (response.data.filenameUpload) {
                this.showSkeletonImg = false;
              }
            }, 300);
          })
          .catch(() => {
            // console.log(err);
            this.showSkeletonImg = false;
          });
      } else {
        this.dataForm.mediaThumbnail = response.data.filenameUpload;
        this.showSkeletonImg = false;
      }
    }, 300),

    afterErrorThumbnail(_file) {
      let textError = "";
      var fileSize = _file.size;
      var fileType = _file.type;
      var checkLImit = PHOTO_UPLOAD_LIMIT * 1024 * 1024;
      if (!fileType.startsWith("image")) {
        textError = `Upload Error - No image file found`;
      } else if (checkLImit < fileSize) {
        textError = `File size exceeds ${PHOTO_UPLOAD_LIMIT} MB limit`;
      } else {
        textError = `There’s a problem with the connection`;
      }

      _file.previewElement.querySelectorAll(
        "#dropzone-vid-thumb .dz-error-message span"
      )[0].textContent = textError;
    },
    // ==================== dropzone thumbnail video related events ====================
    changeConnectionClass() {
      this.connectionBoxClass = "conHeightMax";
    },
    loadDataMapVideo(data) {
      this.dataForm["location"] = {
        type: "Point",
        country: data.country,
        coordinates: [data.lng, data.lat],
        locality: data.locality,
        place: data.place,
        region: data.region,
      };
      if (this.dataForm["location"]) this.disableCheckLocation = false;
    },
    resetDataMap(data) {
      this.dataForm.showLocation = 0;
      this.dataForm["location"] = data;
      this.disableCheckLocation = true;
    },
    stateReset() {
      this.dataForm = [];
      this.listTags = [];
      this.file = [];
      this.album_access = 0;
      this.album_title = "";
      this.set_as_album = 0;
    },

    searchTags: debounce(function () {
      this.listTags = [...this.dataForm.tags];
    }, 500),

    /*
    searchTags: debounce(function (text) {
      if (text.length > 0) {
        this.$store.dispatch("tags/searchTags", text).then((response) => {
          let tagFromDB = [];
          response.data.result.forEach((tag) => {
            tagFromDB.push(tag.tagTitle);
          });
          this.listTags = [...tagFromDB];
        });
      } else {
        this.listTags = [...this.dataForm.tags];
      }
    }, 500), */
    onCloseTags() {
      var tagEl = document.getElementById("tags-vid-new");
      if (
        this.dataForm.tags.length + tagEl.value.split(" ").length > 7 &&
        tagEl.value.trim() != ""
      ) {
        // console.log(tagEl.value.split(" ").length + this.dataForm.tags.length);
        this.tagLimit = true;
      } else {
        this.tagLimit = false;
      }
      if (tagEl.value !== "") {
        this.addTags(tagEl.value);
      }
    },

    addTags(inputedTag) {
      if (disallowSpecialChar(inputedTag)) {
        return this.handleModal();
      }

      var splitTags = inputedTag.toLowerCase().split(" ");
      //to split inputed tag into array, to remove 'space' characters
      if (splitTags.length + this.dataForm.tags.length > 7) {
        let num = 7 - this.currentTags.length;
        splitTags = splitTags.slice(0, num);
      }
      for (var index = 0; index < splitTags.length; index++) {
        //to capitalize the first character in array of strings
        splitTags[index] =
          splitTags[index].charAt(0).toUpperCase() + splitTags[index].substr(1);
      }

      for (var i = 0; i < this.dataForm.tags.length; i++) {
        //to filter the same string
        splitTags = splitTags.filter(
          (item) =>
            item.toLowerCase() !== this.dataForm.tags[i].toLowerCase() &&
            item !== ""
        );
      }
      splitTags = [...new Set(splitTags)];
      for (var j = 0; j < splitTags.length; j++) {
        //to filter null string
        splitTags = splitTags.filter((item) => item !== "");
      }

      splitTags.forEach(async (tag) => {
        const response = await rejectDirty(tag);
        if (response) {
          this.dataForm.tags.push(tag);
          this.listTags.push(tag);
        }
      });
    },
    handleModal() {
      this.$bvModal
        .msgBoxOk(WORDING_DISALLOW_SPECIAL_CHAR_MODAL_MESSAGE, {
          title: WORDING_DISALLOW_SPECIAL_CHAR_MODAL_TITLE,
          okTitle: "OK",
          cancelDisabled: true,
          buttonSize: "sm",
          bodyClass: "ml-4 py-4",
          hideHeaderClose: false,
          centered: true,
        })
        .then(() => {
          return;
        })
        .catch(() => {
          // console.log(err);
        });
    },

    handleDelete() {
      this.$bvModal.show("del-form-video-all");
    },
    submit() {
      this.$v.dataForm.$touch();

      if (this.dataForm.cast) {
        this.dataForm.cast.forEach((el) => {
          if (
            this.dataForm.safeForKids === 0 &&
            CURRENT_DATE - el.birthday_con < EIGHTTEEN_TIMESTAMP
          ) {
            //  this.disableSubmitVideo = true;
            this.toastCast("b-toaster-bottom-right", el.username_con);
            this.$v.dataForm.$anyError = true;
            return;
          }
        });
      }

      if (this.$v.dataForm.$anyError) {
        return;
      }

      if (this.dataForm.safeForKids === 0 && this.rejectAge) {
        //   this.deletePostVideo();
        this.toastAge("b-toaster-bottom-right");
        this.$v.dataForm.$anyError = true;
        return;
      }
      this.dataForm.username = this.dataLogin.username;
      let payload = this.dataForm;
      if (this.$route.params.filter) {
        payload.filter = this.$route.params.filter;
      }
      this.disableSubmitVideo = true;
      this.$socket.emit("upload/videoToggle", {
        videoName: "",
        processid: "",
        auth: "",
        progress: "",
      });
      this.$store
        .dispatch("videoList/submitVideo", payload)
        .then((response) => {
          this.disableSubmitVideo = false;
          sessionStorage.clear();
          this.$bvModal.hide("detail-video");
          this.$bvModal.hide("upload-video");
          this.stateReset();
          if (this.triggerIntouchVideo) {
            const videos = response;

            // emit to intouch wall if upload from create / edit intouch
            this.$root.$emit("pushToVideo", videos);
          }
          this.disableSubmitVideo = false;

          this.uploadPage = 1;
          this.$store.commit("setIsUploadingVideo", false);
          this.uploadComplete = false;
          localStorage.setItem("isUploading", 0);
        })
        .catch(() => {
          this.disableSubmitVideo = false;
          // console.log(err);
        });
    },
    deletePostVideo() {
      const payload = this.dataForm;
      this.$store
        .dispatch("videoList/cancelUploadVideo", payload)
        .then(() => {
          this.$bvModal.hide("detail-video");
          this.$bvModal.hide("upload-video");
          this.$bvModal.hide("del-form-video-all");
          this.stateReset();
          this.$store.commit("setIsUploadingVideo", false);
          this.uploadComplete = false;
          localStorage.setItem("isUploading", 0);
        })
        .catch(() => {
          // console.log(err)
        });
    },

    validateStep() {
      this.$v.dataForm.mediaTitle.$touch();
      this.$v.dataForm.mediaDescription.$touch();
      const isValid =
        !this.$v.dataForm.mediaTitle.$invalid &&
        !this.$v.dataForm.mediaDescription.$invalid;
      return isValid;
    },
    validateStep2() {
      this.$v.dataForm.mediaThumbnail.$touch();
      this.$v.dataForm.tags.$touch();
      let isValid = false;
      if (
        !this.$v.dataForm.mediaThumbnail.$invalid &&
        !this.$v.dataForm.tags.$invalid
      ) {
        isValid = true;
        this.uploadPage = 3;
      }
      return isValid;
    },
    checkPage(props, activeTab, action) {
      this.uploadPage = activeTab;
      if (action == "tab") {
        return;
      } else if (action == "next") {
        props.nextTab();
      } else {
        props.prevTab();
      }
    },
  },
};
</script>

<style scoped>
#dropzone-vid-thumb {
  padding: 0;
  border: none;
}
#mediaFile {
  max-height: 280px;
}

.vue-dropzone > .dz-preview .dz-details {
  bottom: 0;
  top: 0;
  color: #fff;
  background-color: var(--iq-secondary);
  transition: opacity 0.2s linear;
  text-align: left;
}
.vue-dropzone > .dz-preview .dz-image,
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
}
.dropzone .dz-preview {
  position: relative;
  display: contents;
  vertical-align: baseline;
  margin: 16px;
  min-height: 300px;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
  width: 90%;
}
.dropzone .dz-preview .dz-image img {
  display: inline-block;
  max-height: 220px;
  width: 340px;
  object-fit: cover;
}
.vue-dropzone {
  font-family: "Libre Franklin", sans-serif !important;
}
.texteditor-upload-video {
  min-height: 11em !important;
}
.vue-form-wizard .wizard-header {
  padding: 4px 15px 15px 15px;
  position: relative;
  border-radius: 3px 3px 0 0;
  text-align: center;
}
.wizard-tab-container {
  min-height: 62vh;
}
</style>
