import io from "socket.io-client";

const AppSocket = {
  install(Vue, options) {
    Vue.prototype.$socket = io(options.connection, {
      closeOnBeforeunload: false,
      withCredentials: true,
      transports: ["websocket"],
    });
  },
};

export default AppSocket;
