import { axiosDefault } from "@/services/instanceService";

export default {
  state: {
    maintenance: {},
    maintenanceTemplate: {
      _id: "",
      status: 0,
      maintenanceTime: "",
      notes: "",
      downtime: {
        hours: 0,
        minutes: 0,
      },
    },
  },
  mutations: {
    setIsServerMaintenance(state, payload) {
      if (!payload)
        return (state.maintenance = { ...state.maintenanceTemplate });
      payload.status == 1
        ? (state.maintenance = payload)
        : (state.maintenance = { ...state.maintenanceTemplate });
    },
  },
  actions: {
    checkForgotPassToken(context, data) {
      return axiosDefault({
        method: "POST",
        url: `auth/checkTokenForgotPassword`,
        data,
      });
    },

    changePasswordForgot(context, data) {
      return axiosDefault({
        method: "POST",
        url: `auth/changePasswordForgot/`,
        data: {
          email: data.email,
          userId: data.userId,
          token: data.token,
          password: data.password,
        },
      });
    },

    emailVerification(context, payload) {
      const { userId, verifyToken } = payload;
      return axiosDefault({
        method: "POST",
        url: `verification/`,
        data: {
          userId: userId,
          verifyToken: verifyToken,
        },
      });
    },

    resendToken(context, userId) {
      return axiosDefault({
        method: "POST",
        url: `verification/resendToken`,
        data: {
          userId: userId,
        },
      });
    },

    loadServerMaintenanceStatus({ commit }) {
      return axiosDefault({
        method: "POST",
        url: `maintenance/loadServerMaintenanceStatus`,
      })
        .then((data) => {
          commit("setIsServerMaintenance", data.data.data);
        })
        .catch(() => {
          // console.log(error);
        });
    },
  },
};
