import axiosInstance, { axiosDefault } from "@/services/instanceService";
import { uniqBy } from "lodash";
export default {
  namespaced: true,
  state: {
    stateCurrent: "",
    stateFilterCurrent: "",
    stateList: {},
  },
  getters: {
    currentPostState: (state) => {
      return uniqBy(
        state.stateList[state.stateCurrent].items[state.stateFilterCurrent],
        "_id"
      );
    },
    currentPostStatusLoad: (state) => {
      return state.stateList[state.stateCurrent].isAllPostLoaded[
        state.stateFilterCurrent
      ];
    },
  },
  actions: {
    //All videos gallery (video grid, all filter, loved & featured)
    async showcaseLoadAll({ state, commit }, data) {
      const usernameTarget = data.usernameTarget;
      const numberOfLoaded = data.numberOfLoaded;
      const refetchVideoDetail = data.refetchVideoDetail;
      const filter = data.videoFilter;
      const userId = data.userId;
      commit(
        "setNewPostState",
        {
          resource: "showcaseList",
          usernameTarget: usernameTarget,
          refetchVideoDetail: refetchVideoDetail,
          setState: {
            isAllPostLoaded: {
              newest: false,
              oldest: false,
              mostview: false,
              mostcomment: false,
            },
            startLoad: {
              newest: 1,
              oldest: 1,
              mostview: 1,
              mostcomment: 1,
            },
            filter: "",
            owner: "",
            items: {
              newest: [],
              oldest: [],
              mostview: [],
              mostcomment: [],
            },
          },
        },
        {
          root: true,
        }
      );
      commit(
        "setConfigPostState",
        {
          resource: "showcaseList",
          usernameTarget: usernameTarget,
          setFilter: filter,
        },
        {
          root: true,
        }
      );

      var getStartNum = state.stateList[usernameTarget].startLoad[filter];
      var oldStatusLoad =
        state.stateList[usernameTarget].isAllPostLoaded[filter];

      if (oldStatusLoad !== true) {
        const payload = {
          usernameTarget: usernameTarget,
          startNum: getStartNum,
          numberOfLoad: numberOfLoaded,
          filter,
          userId,
        };
        let getPosts = "";
        if (localStorage.getItem("userToken")) {
          getPosts = await axiosInstance.post(`video/showcaseLoad`, payload);
        } else {
          getPosts = await axiosDefault.post(`public/showcaseLoad`, payload);
        }

        if (getPosts) {
          const datavid = getPosts.data.data;
          var isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = datavid.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaTitle: item.mediaTitle,
              duration: item.duration,
              mediaType: "video",
              like: item.like,
              location: item.location,
              countComment: item.countComment,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              countViews: item.countViews,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              slugUrl: "video/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: "",
              tags: item.tag,
            };
          });

          commit(
            "mergePosts",
            {
              resource: "showcaseList",
              newgetPosts: newgetPosts,
              isAllPostLoaded: isAllPostLoaded,
              usernameTarget: usernameTarget,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts.length);
        } else {
          return Promise.reject("error");
        }
      }
    },
  },

  mutations: {
    setUpdateVideo(state, { postId, mediaThumbnail, mediaTitle }) {
      const indexNew = state.stateList[
        state.stateCurrent
      ].items.newest.findIndex((vid) => vid._id === postId);
      if (indexNew >= 0) {
        state.stateList[state.stateCurrent].items.newest[
          indexNew
        ].mediaThumbnail = mediaThumbnail;
        state.stateList[state.stateCurrent].items.newest[
          indexNew
        ].mediaTitle = mediaTitle;
      }
      const indexOld = state.stateList[
        state.stateCurrent
      ].items.oldest.findIndex((vid) => vid._id === postId);
      if (indexOld >= 0) {
        state.stateList[state.stateCurrent].items.oldest[
          indexOld
        ].mediaThumbnail = mediaThumbnail;
        state.stateList[state.stateCurrent].items.oldest[
          indexOld
        ].mediaTitle = mediaTitle;
      }
      const indexView = state.stateList[
        state.stateCurrent
      ].items.mostview.findIndex((vid) => vid._id === postId);
      if (indexView >= 0) {
        state.stateList[state.stateCurrent].items.mostview[
          indexView
        ].mediaThumbnail = mediaThumbnail;
        state.stateList[state.stateCurrent].items.mostview[
          indexView
        ].mediaTitle = mediaTitle;
      }
      const indexComment = state.stateList[
        state.stateCurrent
      ].items.mostcomment.findIndex((vid) => vid._id === postId);
      if (indexComment >= 0) {
        state.stateList[state.stateCurrent].items.mostcomment[
          indexComment
        ].mediaThumbnail = mediaThumbnail;
        state.stateList[state.stateCurrent].items.mostcomment[
          indexComment
        ].mediaTitle = mediaTitle;
      }
    },

    reducePost(state, { postId }) {
      const indexNew = state.stateList[
        state.stateCurrent
      ].items.newest.findIndex((vid) => vid._id === postId);
      if (indexNew >= 0) {
        state.stateList[state.stateCurrent].items.newest.splice(indexNew, 1);
      }
      const indexOld = state.stateList[
        state.stateCurrent
      ].items.oldest.findIndex((vid) => vid._id === postId);
      if (indexOld >= 0) {
        state.stateList[state.stateCurrent].items.oldest.splice(indexOld, 1);
      }
      const indexView = state.stateList[
        state.stateCurrent
      ].items.mostview.findIndex((vid) => vid._id === postId);
      if (indexView >= 0) {
        state.stateList[state.stateCurrent].items.mostview.splice(indexView, 1);
      }
      const indexComment = state.stateList[
        state.stateCurrent
      ].items.mostcomment.findIndex((vid) => vid._id === postId);
      if (indexComment >= 0) {
        state.stateList[state.stateCurrent].items.mostcomment.splice(
          indexComment,
          1
        );
      }
    },
  },
};
