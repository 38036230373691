import axiosInstance, {
  axiosMediaApi,
  axiosDefault,
} from "@/services/instanceService";
import { uniqBy } from "lodash";
//var  setState
export default {
  namespaced: true,
  state: {
    stateCurrent: "",
    stateFilterCurrent: "",
    stateList: {},
  },
  getters: {
    currentPostState: (state) => {
      return uniqBy(
        state.stateList[state.stateCurrent].items[state.stateFilterCurrent],
        "_id"
      );
    },
    currentPostStatusLoad: (state) => {
      return state.stateList[state.stateCurrent].isAllPostLoaded[
        state.stateFilterCurrent
      ];
    },
  },
  actions: {
    //this function to load tagged & photo all

    async photosLoadAll({ state, commit, dispatch }, data) {
      const usernameTarget = data.usernameTarget;
      const numberOfLoaded = data.numberOfLoaded;
      const filter = data.photoFilter;
      dispatch("generateState", usernameTarget);
      commit(
        "setConfigPostState",
        {
          resource: "photoList",
          usernameTarget: usernameTarget,
          setFilter: filter,
        },
        {
          root: true,
        }
      );

      //this desition for define load API tagged or photo
      let publicAPI = "";
      let privateAPI = "";
      switch (filter) {
        case "tagged":
          privateAPI = "tagphoto";
          publicAPI = "public/taggedPhotoPublic";
          break;
        default:
          privateAPI = "photo/photosLoadAll";
          publicAPI = "public/photosLoadAllPublic";
          break;
      }

      var postId = data.postId || 0;
      var getStartNum = state.stateList[usernameTarget].startLoad[filter];
      var oldStatusLoad =
        state.stateList[usernameTarget].isAllPostLoaded[filter];

      if (oldStatusLoad != true) {
        const payload = {
          username: usernameTarget,
          startNum: getStartNum,
          numberOfLoad: numberOfLoaded,
          filter: filter,
          postId,
        };

        let getPosts = "";
        if (localStorage.getItem("userToken")) {
          getPosts = await axiosInstance.post(privateAPI, payload);
        } else {
          getPosts = await axiosDefault.post(publicAPI, payload);
        }

        if (getPosts) {
          var isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = getPosts.data.data;
          newgetPosts = newgetPosts.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              duration: item.duration,
              mediaType: 2,
              like: item.like,
              allowAccessLevel: item.allowAccessLevel,
              allowAccessAge: item.allowAccessAge,
              countComment: item.countComment,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              mediaDescription: item.mediaDescription,
              slugUrl: "photo/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.photoOwner,
              location: item.location,
              showLocation: item.showLocation,
              tags: item.tag,
            };
          });
          //commit('mergePhotos', { newgetPosts:newgetPosts, isAllPostLoaded:isAllPostLoaded, usernameTarget:usernameTarget})
          commit(
            "mergePosts",
            {
              resource: "photoList",
              newgetPosts: newgetPosts,
              isAllPostLoaded: isAllPostLoaded,
              usernameTarget: usernameTarget,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts.length);
        } else {
          return Promise.reject("error");
        }
      }
    },

    deleteTagged({ commit, state }, payload) {
      const photoId = payload.id;

      return axiosInstance({
        method: "POST",
        url: "tagphoto/deleteTagged",
        data: {
          photoId,
        },
      })
        .then(() => {
          let filter = ["tagged"];
          commit(
            "reducePost",
            {
              resource: "photoList",
              postId: photoId,
              stateCurrent: state.stateCurrent,
              filter: filter,
            },
            {
              root: true,
            }
          );
        })
        .catch(() => {});
    },

    async photoLoadDetail(contex, data) {
      if (data.userId || localStorage.getItem("userToken")) {
        // var suffixURL = data.idPhoto + '/' + data.source;
        return axiosInstance({
          url: "photo/photoLoad/",
          method: "POST",
          data: {
            id: data.idPhoto,
            source: data.source,
          },
        }).then((response) => {
          return response.data;
        });
      } else {
        return axiosDefault({
          url: "public/photoLoadPublic",
          method: "POST",
          data: {
            id: data.idPhoto,
          },
        }).then((response) => {
          return response.data;
        });
      }
    },
    photoUpload({ commit }, data) {
      var API_URL_TARGET;
      if (data.set_as_album == 0) {
        API_URL_TARGET = "photo/uploadPhotoData/";
      }

      if (data.set_as_album == 1) {
        API_URL_TARGET = "photo/uploadPhotoAlbumData/";
      }

      return axiosInstance
        .post(API_URL_TARGET, {
          photo_description: data.mediaDescription,
          mediaAccess: data.mediaAccess,
          mediaThumbnail: data.mediaThumbnail,
          cast: data.cast,
          country: data.country,
          region: data.region,
          place: data.place,
          locality: data.locality,
          lat: data.lat,
          lng: data.lng,
          showLocation: data.showLocation,
          set_as_album: data.set_as_album,
          // show_distance: data.show_distance,
        })
        .then(
          (response) => {
            commit("postSuccess");
            return Promise.resolve(response.data);
          },
          (error) => {
            commit("postFail");
            return Promise.reject(error);
          }
        );
    },
    cancelUploadPhoto(context, payload) {
      return axiosMediaApi({
        method: "POST",
        url: "photo/uploadPhotoCancel",
        data: {
          photoData: payload,
        },
      });
    },
    photoCountShare(context, id) {
      return axiosDefault
        .post("public/photoCountSharePublic", {
          id: id,
        })
        .then((response) => {
          return Promise.resolve(response.data);
        });
    },

    submitPhoto({ commit, dispatch }, { payload, photoUser, filter }) {
      const usernameTarget = photoUser.username;
      dispatch("generateState", usernameTarget);
      return axiosInstance({
        method: "POST",
        url: "photo/uploadPhotoData",
        data: {
          data: payload,
        },
      }).then((resp) => {
        // let filter = ["newest", "oldest"];
        const dataPost = resp.data.photos;
        for (let index = 0; index < dataPost.length; index++) {
          dataPost[index].slugUrl = "photo/" + dataPost[index]._id;
        }
        let resource = "photoList";
        if (filter) {
          commit(
            "pushToPostState",
            { resource, filter, usernameTarget, dataPost },
            { root: true }
          );
        }
        return dataPost;
      });
    },

    submitPhotoAlbum(context, payload) {
      return axiosInstance({
        method: "POST",
        url: "photo/uploadPhotoAlbumData",
        data: {
          data: payload,
        },
      });
    },

    updatePhoto(context, payload) {
      return axiosInstance({
        method: "POST",
        url: "photo/updatePhotoData",
        data: {
          photoId: payload.photoId,
          data: payload,
        },
      });
    },
    editInlinePhoto(contex, payload) {
      return axiosInstance({
        method: "POST",
        url: "photo/updatePhotoInline",
        data: {
          postId: payload.postId,
          data: payload.dataEdit,
        },
      });
    },
    photoDelete({ commit, state }, payload) {
      return axiosMediaApi({
        method: "POST",
        url: "photo/deletePhotoData",
        data: {
          photoId: payload.id,
        },
      }).then((response) => {
        let filter = ["newest", "oldest", "tagged"];
        commit(
          "reducePost",
          {
            resource: "photoList",
            postId: payload.id,
            stateCurrent: state.stateCurrent,
            filter: filter,
          },
          {
            root: true,
          }
        );
        return response.data;
      });
    },

    // Sotre photoLanding for load, filter, search (Worldwide)
    async photosLanding({ commit, rootState }, payload) {
      const {
        numberOfLoad,
        countryCode,
        keyword,
        countryCodeForSearch,
      } = payload;
      //get latest pagin state
      var getStartNum = rootState.exploreLocation
        ? rootState.explorePhoto[rootState.exploreLocation].startLoad
        : 1;
      //get latest status  load state
      var oldStatusLoad = rootState.exploreLocation
        ? rootState.explorePhoto[rootState.exploreLocation].isAllPhotoLoaded
        : false;
      const data = {
        startNum: getStartNum,
        numberOfLoad,
        countryCode,
        keyword,
        countryCodeForSearch,
      };
      let getPosts;
      if (oldStatusLoad != true) {
        if (localStorage.getItem("userToken")) {
          getPosts = await axiosInstance({
            method: "POST",
            url: "photo/photosLanding",
            data: data,
          });
        } else {
          getPosts = await axiosInstance({
            method: "POST",
            url: "public/photosLandingPublic",
            data: data,
          });
        }
        if (getPosts) {
          const dataPhoto = getPosts.data.data;
          const isAllPhotoLoaded = getPosts.data.isAllDataLoaded;
          const newgetPhoto = dataPhoto.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaDescription: item.mediaDescription,
              mediaType: 2,
              like: item.like,
              countComment: item.countComment,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              slugUrl: "photo/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.photoOwner,
              location: item.location,
              showLocation: item.showLocation,
              tags: item.tag,
            };
          });
          commit(
            "mergePhotoExplore",
            {
              newgetPhoto,
              isAllPhotoLoaded,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPhoto.length);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },

    //Store loadVideosAllHometown for load, filter, search
    async loadPhotosAllHometown({ commit, rootState }, data) {
      const {
        stateLocation,
        numberOfLoad,
        range,
        checkhometown,
        keyword,
        interest,
        coorLocation,
        currentPlace,
      } = data;
      let listType = keyword ? "search" : "default";
      //initilize new item state

      if (
        //   Object.keys(rootState.hometownPhoto[stateLocation]).length === 0 ||
        !rootState.hometownPhoto[stateLocation][listType].dataList ||
        !rootState.hometownPhoto[stateLocation][listType].dataList[range] ||
        !rootState.hometownPhoto[stateLocation][listType].dataList[range]
          .items ||
        !rootState.hometownPhoto[stateLocation][listType].dataList[range].items[
          interest
        ]
      ) {
        commit(
          "setNewHometownState",
          {
            rootName: "hometownPhoto",
            stateLocation: stateLocation,
            listType: listType,
            filter: range,
            setState: {
              isAllDataLoaded: {
                [interest]: false,
              },
              startLoad: {
                [interest]: 1,
              },
              items: {
                [interest]: [],
              },
            },
          },
          {
            root: true,
          }
        );
      }
      commit(
        "hometownConfigState",
        {
          resource: "hometownPhoto",
          stateLocation: stateLocation,
          listType: listType,
          currentRage: range,
          setFilter: interest,
        },
        {
          root: true,
        }
      );

      //get latest pagin state
      var getStartNum =
        rootState.hometownPhoto[stateLocation][listType].dataList[range]
          .startLoad[interest];
      //get latest status  load state
      var oldStatusLoad =
        rootState.hometownPhoto[stateLocation][listType].dataList[range]
          .isAllDataLoaded[interest];
      // const oldStatusLoad =false;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        checkhometown: checkhometown,
        range,
        keyword,
        interest,
        stateLocation,
        coorLocation,
        currentPlace,
      };

      let getPosts = "";
      if (oldStatusLoad != true) {
        getPosts = await axiosInstance.post(
          `${"photo/photosLoadAllHometown"}`,
          payload
        );
        if (getPosts) {
          const dataPhoto = getPosts.data.data;
          var isAllDataLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = dataPhoto.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              duration: item.duration,
              mediaType: 2,
              like: item.like,
              countComment: item.countComment,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              mediaDescription: item.mediaDescription,
              slugUrl: "photo/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.photoOwner,
              location: item.location,
              showLocation: item.showLocation,
              tags: item.tag,
            };
          });
          commit(
            "mergeHometownState",
            {
              rootName: "hometownPhoto",
              stateLocation: stateLocation,
              listType: listType,
              filter: range,
              interest: interest,
              mergeState: newgetPosts,
              isAllDataLoaded: isAllDataLoaded,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts.length);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },

    //Store loadPhotosAllCoterie for load, filter, search
    async loadPhotosAllCoterie({ commit, rootState }, data) {
      const { numberOfLoad, filter, keyword } = data;
      let listType = keyword ? "search" : "default";
      //initilalize new item state
      if (
        !rootState.coteriePhoto[listType].stateList[filter] ||
        !rootState.coteriePhoto[listType].stateList[filter].items
      ) {
        commit(
          "setCoterieState",
          {
            rootName: "coteriePhoto",
            listType: listType,
            contentType: "stateList",
            filter: filter,
            setState: {
              isAllPostLoaded: false,
              startLoad: 1,
              items: [],
            },
          },
          {
            root: true,
          }
        );
      }

      commit(
        "setStateType",
        {
          resource: "coteriePhoto",
          listType: listType,
        },
        {
          root: true,
        }
      );
      commit(
        "setCurrentState",
        {
          resource: "coteriePhoto",
          usernameTarget: filter,
        },
        {
          root: true,
        }
      );

      //get latest pagin state
      var getStartNum =
        rootState.coteriePhoto[listType].stateList[filter].startLoad;
      //get latest status  load state
      let isAllPostLoaded =
        rootState.coteriePhoto[listType].stateList[filter].isAllPostLoaded;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        filter,
        keyword,
      };

      let getPosts = "";
      if (isAllPostLoaded != true) {
        getPosts = await axiosInstance.post(
          `photo/photosLoadAllCoterie`,
          payload
        );

        if (getPosts) {
          const dataPhoto = getPosts.data.data;
          isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = dataPhoto.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              mediaTitle: item.mediaDescription ? item.mediaDescription : " ",
              mediaType: "photo",
              like: item.like,
              disLike: item.disLike,
              hasLike: item.hasLike,
              hasDislike: item.hasDislike,
              countComment: item.countComment,
              countViews: item.countViews,
              contentAccess: item.mediaAccess,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              slugUrl: "photo/" + item._id,
              mediaOwner: item.photoOwner,
              location: item.location,
              showLocation: item.showLocation,
              allowAccessPost: item.allowAccessPost,
              tags: item.tag,
            };
          });
        }
        commit(
          "mergeCoterietState",
          {
            rootName: "coteriePhoto",
            listType: listType,
            contentType: "stateList",
            filter: filter,
            newgetPosts: newgetPosts,
            isAllPostLoaded: isAllPostLoaded,
          },
          {
            root: true,
          }
        );
        return Promise.resolve(newgetPosts.length);
      } else {
        return Promise.reject("error");
      }
    },

    async loadRelatedPhotos({ commit, rootState }, data) {
      const {
        numberOfLoad,
        tagPhotos,
        keyword,
        recentPhotos,
        userId,
        checkhometown,
      } = data;
      var getStartNum = rootState.relatedPhotos.startLoad;
      var oldStatusLoad = rootState.relatedPhotos.isAllPostLoaded;
      const payload = {
        startNum: getStartNum,
        numberOfLoad: numberOfLoad,
        keyword: keyword,
        tagPhotos,
        recentPhotos,
        checkhometown,
      };
      let getPosts;
      if (oldStatusLoad != true) {
        if (userId) {
          getPosts = await axiosInstance.post(
            `photo/loadRelatedPhotos`,
            payload
          );
        } else {
          getPosts = await axiosInstance.post(
            `public/relatedPhotosPublic`,
            payload
          );
        }

        if (getPosts) {
          const dataPhoto = getPosts.data.data;
          var isAllPostLoaded = getPosts.data.isAllDataLoaded;
          var newgetPosts = dataPhoto.map((item) => {
            return {
              _id: item._id,
              _userId: item._userId,
              duration: item.duration,
              mediaType: 2,
              like: item.like,
              countComment: item.countComment,
              countShare: item.countShare,
              mediaSrc: item.mediaThumbnail,
              safeForKids: item.safeForKids,
              contentAccess: item.mediaAccess,
              mediaDescription: item.mediaDescription,
              slugUrl: "photo/" + item._id,
              mediaThumbnail: item.mediaThumbnail,
              createdAt: item.createdAt,
              mediaOwner: item.photoOwner,
              location: item.location,
              showLocation: item.showLocation,
              tags: item.tag,
            };
          });
          commit(
            "mergeRelatedPhotoState",
            {
              newgetPosts,
              isAllPostLoaded,
              latestId: recentPhotos,
            },
            {
              root: true,
            }
          );
          return Promise.resolve(newgetPosts.length);
        } else {
          return Promise.reject("error");
        }
      } else {
        return Promise.resolve(true);
      }
    },

    generateState({ commit }, usernameTarget) {
      commit(
        "setNewPostState",
        {
          resource: "photoList",
          usernameTarget: usernameTarget,
          setState: {
            isAllPostLoaded: {
              newest: false,
              oldest: false,
              tagged: false,
            },
            startLoad: {
              newest: 1,
              oldest: 1,
              tagged: 1,
            },
            filter: "",
            owner: "",
            items: {
              newest: [],
              oldest: [],
              tagged: [],
            },
          },
        },
        {
          root: true,
        }
      );
    },
  },
  mutations: {
    resetStatePhoto(state) {
      state.stateCurrent = "";
      state.stateFilterCurrent = "";
      state.stateList = {};
      // state.isAllPostLoaded["newest"] = false;
      // state.isAllPostLoaded["oldest"] = false;
      // state.isAllPostLoaded["tagged"] = false;
      // state.startLoad["newest"] = 1;
      // state.startLoad["oldest"] = 1;
      // state.startLoad["tagged"] = 1;
      // state.items["newest"] = [];
      // state.items["oldest"] = [];
      // state.items["tagged"] = [];
    },

    //this mutation mergePhoto photo or tagged
    mergePhotos(state, { newgetPosts, isAllPostLoaded, usernameTarget }) {
      var setFilter = state.stateList[usernameTarget].filter;
      state.stateList[usernameTarget].isAllPostLoaded[
        setFilter
      ] = isAllPostLoaded;
      state.stateList[usernameTarget].items[setFilter] = [
        ...state.stateList[usernameTarget].items[setFilter],
        ...newgetPosts,
      ];
      if (!isAllPostLoaded) {
        state.stateList[usernameTarget].startLoad[setFilter]++;
      }
    },
    reducePhoto(state, { postId }) {
      let filter = ["newest", "oldest", "tagged"];
      filter.forEach((element) => {
        if (
          Object.keys(state.stateList[state.stateCurrent].items[element]).length
        ) {
          let index = state.stateList[state.stateCurrent].items[
            element
          ].findIndex((album) => album._id === postId);
          if (index >= 0) {
            state.stateList[state.stateCurrent].items[element].splice(index, 1);
          }
        }
      });
    },
  },
};
