import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import store from "../store";
import Home from "../views/Home.vue";
import { isMobile } from "mobile-device-detect";

Vue.use(VueRouter);
Vue.use(VueMeta);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/mobile",
    name: "MobileLink",
    component: () => import("../views/MobileLink.vue"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
  },
  {
    path: "/profile_edit",
    name: "ProfileEdit",
    component: () => import("../views/ProfileEdit.vue"),
  },
  {
    path: "/password_edit",
    name: "PasswordEdit",
    component: () => import("../views/PasswordEdit.vue"),
  },
  {
    path: "/privacy_setting",
    name: "PrivacySetting",
    component: () => import("../views/PrivacySetting.vue"),
  },
  {
    path: "/contact_setting",
    name: "ContactSetting",
    component: () => import("../views/ContactSetting.vue"),
  },
  {
    path: "/notification_setting",
    name: "NotificationSetting",
    component: () => import("../views/NotificationSetting.vue"),
  },
  {
    path: "/video/",
    component: () => import("../views/PostVideoDetail.vue"),
    children: [
      {
        path: ":id",
        name: "VideoDetail",
        component: () => import("../components/video/VideoDetail.vue"),
      },
      {
        path: "",
        redirect: "/",
      },
    ],
  },
  {
    path: "/photo/",
    component: () => import("../views/PostPhoto.vue"),
    children: [
      {
        path: ":id",
        name: "PostPhotoDetail",
        component: () => import("../components/photo/PostPhotoDetail.vue"),
      },
      {
        path: "",
        redirect: "/",
      },
    ],
  },
  {
    path: "/terms_of_use",
    name: "TermsOfUse",
    component: () => import("../views/TermsOfUse.vue"),
  },
  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },

  {
    path: "/signin",
    name: "Signin",
    component: () => import("../views/Signin.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/Signup.vue"),
  },
  {
    path: "/emailverification/:userId/:verifyToken",
    name: "EmailVerification",
    component: () => import("../views/EmailVerification.vue"),
  },
  {
    path: "/resetPassMail/:userId/:token",
    name: "ResetPassMail",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/forgot_password",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/friend_request",
    name: "FriendRequest",
    component: () => import("../views/FriendRequest.vue"),
  },
  {
    path: "/page_restricted",
    name: "PageRestricted",
    component: () => import("../views/PageRestricted.vue"),
  },
  {
    path: "/media_not_found",
    name: "MediaNotFound",
    component: () => import("../views/MediaNotFound.vue"),
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../views/PageNotFound.vue"),
  },
  {
    path: "/user_not_found",
    name: "UserNotFound",
    component: () => import("../views/UserNotFound.vue"),
  },
  {
    path: "/block_users",
    name: "Blocks",
    component: () => import("../views/BlockUsers.vue"),
  },
  {
    path: "/message",
    name: "Message",
    component: () => import("../views/Message.vue"),
  },
  {
    path: "",
    component: Home,
    children: [
      {
        path: "/hometown",
        component: () => import("../components/hometown/ContentHometown.vue"),
        children: [
          {
            path: "video",
            name: "HometownVideos",
            component: () =>
              import("../components/hometown/HometownVideos.vue"),
          },
          {
            path: "photo",
            name: "HometownPhotos",
            component: () => import("../components/hometown/HometownPhoto.vue"),
          },
          {
            path: "user",
            name: "HometownUser",
            component: () => import("../components/hometown/HometownUser.vue"),
          },
          {
            path: "",
            name: "Hometown",
            redirect: "video",
            component: () =>
              import("../components/hometown/HometownVideos.vue"),
          },
        ],
      },
      {
        path: "/coterie",
        component: () => import("../components/coterie/ContentCoterie.vue"),
        children: [
          {
            path: "video",
            name: "CoterieVideos",
            redirect: "video/followed",
            component: () => import("../components/coterie/CoterieVideos.vue"),
          },
          {
            path: "video/:filter",
            name: "CoterieVideosFilter",
            component: () => import("../components/coterie/CoterieVideos.vue"),
          },
          {
            path: "video/:filter/:interest",
            name: "CoterieVideosInterest",
            component: () => import("../components/coterie/CoterieVideos.vue"),
          },

          //route intouch coterie
          {
            path: "intouch",
            name: "CoterieIntouch",
            redirect: "intouch/followed",
            component: () => import("../components/coterie/CoterieIntouch.vue"),
          },
          {
            path: "intouch/:filter",
            name: "CoterieIntouchFilter",
            component: () => import("../components/coterie/CoterieIntouch.vue"),
          },
          {
            path: "intouch:filter/:interest",
            name: "CoterieIntouchInterest",
            component: () => import("../components/coterie/CoterieIntouch.vue"),
          },
          //route Photo coterie
          {
            path: "photo",
            name: "CoteriePhoto",
            redirect: "photo/followed",
            component: () => import("../components/coterie/CoteriePhoto.vue"),
          },
          {
            path: "photo/:filter",
            name: "CoteriePhotoFilter",
            component: () => import("../components/coterie/CoteriePhoto.vue"),
          },
          {
            path: "photo:filter/:interest",
            name: "CoteriePhotoInterest",
            component: () => import("../components/coterie/CoteriePhoto.vue"),
          },
          {
            path: "",
            name: "Coterie",
            redirect: "video",
            component: () => import("../components/coterie/CoterieVideos.vue"),
          },
        ],
      },
      {
        path: "/onthego/",
        component: () => import("../components/onthego/ContentOnTheGo.vue"),
        children: [
          {
            path: "",
            name: "OnTheGoVideos",
            component: () => import("../components/onthego/OnTheGoVideos.vue"),
          },
          {
            path: "user",
            name: "OnTheGoUser",
            component: () => import("../components/onthego/OnTheGoUser.vue"),
          },
          {
            path: "user/:interest",
            name: "OnTheGoUserInterest",
            component: () => import("../components/onthego/OnTheGoUser.vue"),
          },
          {
            path: ":interest",
            name: "OnTheGoVideosInterest",
            component: () => import("../components/onthego/OnTheGoVideos.vue"),
          },
        ],
      },
      {
        path: "",
        component: () => import("../components/hometown/ContentHome.vue"),
        children: [
          {
            path: "",
            name: "ExploreVideos",
            component: () => import("../components/hometown/HomeVideos.vue"),
          },
          {
            path: "user",
            name: "ExploreUsers",
            component: () => import("../components/hometown/HomeUsers.vue"),
          },
          {
            path: "photos",
            name: "ExplorePhotos",
            component: () => import("../components/hometown/HomePhotos.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:username",
    component: () => import("../views/TimelineUser.vue"),
    children: [
      {
        path: "about",
        name: "About",
        component: () => import("../views/About.vue"),
      },
      {
        path: "friends",
        name: "Friend",
        component: () => import("../views/Friend.vue"),
      },
      {
        path: "follow",
        name: "Follow",
        component: () => import("../views/Follow.vue"),
      },
      // {
      //   path: "group",
      //   name: "Group",
      //   component: () => import("../views/Group.vue"),
      // },
      {
        path: "showcase",
        name: "Showcase",
        redirect: "/:username/showcase/newest",
        component: () => import("../views/Showcase.vue"),
        children: [
          {
            path: ":filter",
            name: "FilterShowcase",
            component: () => import("../components/showcase/ShowcaseGrid.vue"),
          },
        ],
      },
      {
        path: "photos",
        redirect: "/:username/photos/newest",
        component: () => import("../views/Photos.vue"),
        children: [
          {
            path: "tagged",
            name: "TaggedPhotos",
            component: () => import("../components/photo/TaggedPhotoGrid.vue"),
          },
          {
            path: ":filter",
            name: "FilterPhotos",
            component: () => import("../components/photo/PhotosGrid.vue"),
          },
        ],
      },
      {
        path: "videos",
        redirect: "/:username/videos/newest",
        component: () => import("../views/Videos.vue"),
        children: [
          {
            path: "loved",
            name: "FavoriteVideos",
            component: () =>
              import("../components/video/FavoriteVideoGrid.vue"),
          },
          {
            path: "featured",
            name: "FeaturedVideos",
            component: () =>
              import("../components/video/FeaturedVideoGrid.vue"),
          },
          {
            path: ":filter",
            name: "FilterVideos",
            component: () => import("../components/video/VideosGrid.vue"),
          },
        ],
      },
      {
        path: "albums",
        name: "Albums",
        redirect: "/:username/albums/newest",
        component: () => import("../views/Albums.vue"),
        children: [
          {
            path: ":filter",
            name: "FilterAlbum",
            component: () => import("../components/album/AlbumsGrid.vue"),
          },
        ],
      },
      {
        path: "album",
        redirect: "/:username/album",
        component: () => import("../views/Album.vue"),
        children: [
          {
            path: ":albumId/photo/:postId",
            name: "Album photo",
            components: {
              PostAlbumDetail: () =>
                import("../components/album/AlbumDetail.vue"),
              detailMediaAlbum: () =>
                import("../components/album/AlbumDetailPhoto.vue"),
            },
            meta: {
              showModal: true,
            },
          },
          {
            path: ":albumId/video/:postId",
            name: "Album video",
            components: {
              listAlbum: () => import("../components/album/AlbumDetail.vue"),
              detailMediaAlbum: () =>
                import("../components/album/AlbumDetailVideo.vue"),
            },
            meta: {
              showModal: true,
            },
          },
          {
            path: ":albumId",
            component: () => import("../components/album/AlbumDetail.vue"),
            meta: {
              showModal: false,
            },
            children: [
              {
                path: "",
                name: "FilterMedia",
                component: () => import("../components/album/AlbumDetail.vue"),
                meta: {
                  showModal: false,
                },
              },
            ],
          },
        ],
      },
      {
        path: "intouch",
        component: () => import("../views/InTouch.vue"),
        children: [
          {
            path: ":intouchId",
            name: "IntouchDetail",
            components: {
              listIntouch: () =>
                import("../components/intouch/InTouchWall.vue"),
            },
            meta: {
              showModal: false,
            },
          },
          {
            path: ":intouchId/photo/:postId",
            name: "Intouch photo",
            components: {
              listIntouch: () =>
                import("../components/intouch/InTouchWall.vue"),
              details: () =>
                import("../components/intouch/DetailIntouchPhoto.vue"),
            },
            meta: {
              showModal: true,
            },
          },
          {
            path: ":intouchId/video/:postId",
            name: "Intouch video",
            components: {
              listIntouch: () =>
                import("../components/intouch/InTouchWall.vue"),
              details: () =>
                import("../components/intouch/DetailIntouchVideo.vue"),
            },
            meta: {
              showModal: true,
            },
          },
          {
            path: "",
            name: "Intouch",
            components: {
              listIntouch: () =>
                import("../components/intouch/InTouchWall.vue"),
            },
            meta: {
              showModal: false,
            },
          },
        ],
      },
      {
        path: "",
        redirect: "/:username/Showcase",
      },
    ],
  },
];

const router = new VueRouter({ mode: "history", routes });

// ========== vuex-persist + localForage (for indexedDB) ==========
const waitForStorageToBeReady = async (to, from, next) => {
  if (localStorage.getItem("userToken")) {
    await store.restored;
  }
  // await store.dispatch("auth/getdataLogin");
  next();
};
router.beforeEach(waitForStorageToBeReady);
// ========== vuex-persist + localForage (for indexedDB) ==========

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("userToken");
  let isMobileData = store.state.isMobile;

  if (
    (isMobileData &&
      to.name !== "MobileLink" &&
      to.name !== "EmailVerification" &&
      to.name !== "ResetPassMail") ||
    (isMobile &&
      to.name !== "MobileLink" &&
      to.name !== "EmailVerification" &&
      to.name !== "ResetPassMail")
  ) {
    next({ name: "MobileLink" });
  } else {
    if (
      (to.name === "Signin" ||
        to.name === "Signup" ||
        to.name === "ResetPassMail" ||
        to.name === "ForgotPassword" ||
        to.name === "EmailVerification") &&
      token
    ) {
      next({ name: "HometownVideos" });
    } else if (
      (to.name === "Message" ||
        to.name === "FriendRequest" ||
        to.name === "Blocks" ||
        to.name === "ProfileEdit" ||
        to.name === "PasswordEdit" ||
        to.name === "PrivacySetting" ||
        to.name === "ContactSetting" ||
        to.name === "NotificationSetting" ||
        to.name === "Notifications" ||
        to.name === "VideoUpload" ||
        to.name === "PhotoUpload" ||
        to.name === "PhotoUploadOptions") &&
      !token
    ) {
      next({ name: "Signin" });
    } else {
      if (to.name === "VideoDetail") {
        if (from.name === "HometownVideos") {
          store.commit("videoList/setToVideoDetailFromHometown", true);
          next();
        } else {
          store.commit("videoList/setToVideoDetailFromHometown", false);
          next();
        }
      } else {
        next();
      }
    }
  }
});

export default router;
