import axiosInstance from "@/services/instanceService";
export default {
  namespaced: true,
  state: {
    groupList: {},
    groupDetail: {},
  },
  getters: {},
  mutations: {
    setGroupList(state, payload) {
      if (!state.groupList[payload.memberId]) {
        state.groupList[payload.memberId] = {};
      }
      state.groupList[payload.memberId].groups = payload.findGroups;
    },
  },
  actions: {
    async createGroup(context, payload) {
      try {
        const response = await axiosInstance({
          method: "POST",
          url: "group/createGroup",
          data: payload,
        });
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    async loadAllUserGroup({ commit }, payload) {
      try {
        console.log(payload);
        const response = await axiosInstance({
          method: "POST",
          url: "group/loadAllUserGroup",
          data: payload,
        });
        commit("setGroupList", {
          memberId: payload.memberId,
          findGroups: response.data.findGroups,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
