<template>
  <div>
    <b-input-group>
      <mapbox-geocoder
        :class="'mapbox-geojson mapbox-' + i"
        @mb-created="(instance) => (control = instance)"
        :access-token="accesstoken"
        :types="types"
        :limit="limit"
        language="en"
        externalGeocoder="GeoJSON"
        :reverseGeocode="reverseGeocode"
        :placeholder="mapboxPlaceholder"
        @mb-result="onLoadMap"
        @mb-reset="onResetMap"
      />
      <b-input-group-prepend is-text class="btn-icon-mapbox">
        <div v-if="loadingMap" class="loading text-center">
          <span class="fa fa-spinner fa-spin"></span>
        </div>
        <b-button v-else v-on:click="getPosition" variant="none">
          <custom-icon name="iconmap" margin="m-auto" size="large" />
        </b-button>
      </b-input-group-prepend>
    </b-input-group>
    <div class="error text-danger" v-if="errorMapInput">
      {{ selectedMap }} is not a city
    </div>
    <b-toast
      toaster="b-toaster-bottom-right"
      v-model="showMapError"
      class="small mt-2"
      variant="danger"
      title="Error gps load"
      dismissible
    >
      {{ mapErrorText }}
    </b-toast>
  </div>
</template>

<script>
import { MapboxGeocoder } from "@studiometa/vue-mapbox-gl";

import { axiosDefault } from "@/services/instanceService";
import { MAPBOX_TOKEN } from "../../variables";
export default {
  name: "MapBoxComponent",
  components: {
    MapboxGeocoder,
  },
  props: ["i", "mapboxPlaceholder"],
  data() {
    return {
      form: {},
      userLocation: "",
      reverseGeocode: true,
      limit: 5,
      types: "locality,place,district,region",
      accesstoken: MAPBOX_TOKEN,
      lat: "",
      lng: "",
      showMapError: false,
      mapErrorText: "",
      loadingMap: false,
      errorMapInput: false,
      selectedMap: "",
    };
  },
  mounted() {
    // trigger event btn close on click
    var activeMap = ".mapbox-" + this.i;
    var geoClose = document.querySelector(
      activeMap + " button.mapboxgl-ctrl-geocoder--button"
    );
    geoClose.addEventListener("click", this.onResetMap);

    var inputMap = document.querySelector(activeMap + ">.mapboxgl-ctrl>input");
    inputMap.addEventListener("change", this.onDeleteMap);
  },
  methods: {
    onDeleteMap: function(e) {
      if (e.target.value.length <= 0) this.$emit("deleteMap");
    },
    onResetMap() {
      this.form = {};
      this.$emit("resetMap", this.form, this.i);
    },
    onLoadMap(result) {
      const respon = Object.keys(result).map((key) => result[key]);
      let countryGet,
        countryCodeGet,
        regionGet,
        placeGet,
        districtGet,
        localityGet = "";
      const responsMap = respon[0];
      // console.log(this.responsMap);
      const contexLoc = responsMap.context;
      const mapId = responsMap.id;
      this.selectedMap = responsMap.text;
      // console.log(this.selectedMap);
      var lat = responsMap.center[1];
      var lng = responsMap.center[0];
      if (mapId.startsWith("country.") || mapId.startsWith("region.")) {
        this.errorMapInput = true;
        var activeMap = ".mapbox-" + this.i;
        var ele = document.querySelector(activeMap + ">.mapboxgl-ctrl>input");
        ele.value = "";
        return;
      } else {
        this.errorMapInput = false;
        //if return place on top
        if (mapId.startsWith("place.")) {
          placeGet = responsMap.text;
          contexLoc.forEach((element) => {
            if (element.id.startsWith("country.")) {
              countryGet = element.text;
              countryCodeGet = element.short_code.toUpperCase();
            }
            if (element.id.startsWith("region.")) {
              regionGet = element.text;
            }
            if (element.id.startsWith("district.")) {
              districtGet = element.text;
            }
          });
        } //if return district on top
        if (mapId.startsWith("district.")) {
          districtGet = responsMap.text;
          localityGet = responsMap.text;
          contexLoc.forEach((element) => {
            if (element.id.startsWith("country.")) {
              countryGet = element.text;
              countryCodeGet = element.short_code.toUpperCase();
            }
            if (element.id.startsWith("region.")) {
              regionGet = element.text;
            }
          });
        } //if return district on top
        if (mapId.startsWith("locality.")) {
          localityGet = responsMap.text;
          contexLoc.forEach((element) => {
            if (element.id.startsWith("country.")) {
              countryGet = element.text;
              countryCodeGet = element.short_code.toUpperCase();
            }
            if (element.id.startsWith("region.")) {
              regionGet = element.text;
            }
            if (element.id.startsWith("district.")) {
              districtGet = element.text;
            }
            if (element.id.startsWith("place.")) {
              placeGet = element.text;
            }
          });
        }
      }

      if (
        placeGet == "" &&
        placeGet === undefined &&
        districtGet === undefined &&
        regionGet === undefined &&
        localityGet != ""
      ) {
        placeGet = localityGet;
      } else if (
        placeGet == "" &&
        placeGet === undefined &&
        regionGet === undefined &&
        districtGet != ""
      ) {
        placeGet = districtGet;
      } else if (regionGet == "" || regionGet === undefined) {
        regionGet = placeGet;
      }

      (this.form.country = countryGet),
        (this.form.country_code = countryCodeGet),
        (this.form.region = regionGet),
        (this.form.place = placeGet),
        (this.form.locality = "-"),
        (this.form.lng = lng),
        (this.form.lat = lat);

      this.$emit("resulted", this.form, this.i);
    },

    getPosition() {
      this.loadingMap = true;
      this.userLocation = "";
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 20000,
      };
      if (!navigator.geolocation) {
        this.mapErrorText = "Geolocation is not supported by your browser";
      } else {
        setTimeout(() => {
          navigator.geolocation.getCurrentPosition(
            this.successGetLocation,
            this.errorGetLocation,
            options
          );
        }, 300);
      }
    },
    errorGetLocation() {
      this.loadingMap = false;
      this.showMapError = true;
      this.mapErrorText =
        "Unable to retrieve your location please unblock location access";
    },
    successGetLocation(position) {
      this.loadingMap = false;
      let countryGet,
        countryCodeGet,
        regionGet,
        districtGet,
        placeGet,
        localityGet = "";

      this.showMapError = false;
      this.mapErrorText = "";
      this.showMapBox = true;
      this.loadingMap = false;
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      this.lat = latitude;
      this.lng = longitude;
      this.form.lat = latitude;
      this.form.lng = longitude;
      const url =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        longitude +
        "," +
        latitude +
        ".json?&types=" +
        this.types +
        "&language=en&access_token=" +
        MAPBOX_TOKEN;
      axiosDefault.get(url).then(({ data }) => {
        this.userLocation = data.features[0].place_name;
        const contexData = data.features[0].context;
        for (var i = 0; i < contexData.length; i++) {
          if (contexData[i].id.startsWith("country.")) {
            countryGet = contexData[i].text;
            countryCodeGet = contexData[i].short_code.toUpperCase();
          }
          if (contexData[i].id.startsWith("region.")) {
            regionGet = contexData[i].text;
          }
          if (contexData[i].id.startsWith("district.")) {
            districtGet = contexData[i].text;
          }
          if (contexData[i].id.startsWith("place.")) {
            placeGet = contexData[i].text;
          }
          if (contexData[i].id.startsWith("locality.")) {
            localityGet = contexData[i].text;
          }
        }

        if (
          placeGet == "" &&
          placeGet === undefined &&
          districtGet === undefined &&
          regionGet === undefined &&
          localityGet != ""
        ) {
          placeGet = localityGet;
        } else if (
          placeGet == "" &&
          placeGet === undefined &&
          regionGet === undefined &&
          districtGet != ""
        ) {
          placeGet = districtGet;
        } else if (regionGet == "" || regionGet === undefined) {
          regionGet = placeGet;
        }

        this.form.country = countryGet;
        this.form.country_code = countryCodeGet;
        this.form.region = regionGet;
        this.form.place = placeGet;
        this.form.locality = localityGet;

        setTimeout(() => {
          var activeMap = ".mapbox-" + this.i;
          var geoClose = document.querySelector(
            activeMap + " button.mapboxgl-ctrl-geocoder--button"
          );
          geoClose.style.display = "block";
          var ele = document.querySelector(activeMap + ">.mapboxgl-ctrl>input");
          ele.value = this.userLocation;
          this.$emit("resulted", this.form, this.i);
        }, 300);
      });
    },
  },
};
</script>

<style>
.loading {
  min-width: 3em;
}

.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 0px !important;
  margin-right: 3px !important;
}

.map-geocoder {
  height: 45px;
  line-height: 3em;
  background: transparent;
  border: 1px solid var(--iq-secondary);
  font-size: 1em;
  color: var(--iq-secondary);
  border-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.suggestions {
  display: block !important;
}
</style>
