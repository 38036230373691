import axios from "axios";
const API_URL = process.env.VUE_APP_BASE_API;
const API_STORAGE = process.env.VUE_APP_STORAGE_API;
//axios axiosInstance  : for api backend
const axiosInstance = axios.create({
  baseURL: API_URL,
});
//axios axiosMediaApi  : for api media
const axiosMedia = axios.create({
  baseURL: API_STORAGE,
});
// Agar semua axios tidak perlu menggunakan variable API_URL lagi
// Dimasukkan ke dalam parenthesis create => .create({baseurl})
// {
//     baseURL: 'http://ip:3000/api/'
// }
axiosInstance.interceptors.request.use(
  function(config) {
    var token = JSON.parse(localStorage.getItem("userToken"));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);
axiosMedia.interceptors.request.use(
  function(config) {
    var token = JSON.parse(localStorage.getItem("userToken"));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

export default axiosInstance;
export const axiosMediaApi = axiosMedia;
export const axiosDefault = axios.create({
  baseURL: API_URL,
});
