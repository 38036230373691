import axiosInstance from "@/services/instanceService";

export default {
  namespaced: true,

  actions: {
    searchTags(context, payload) {
      return axiosInstance({
        url: "tags/searchTags",
        method: "POST",
        data: {
          text: payload
        }
      })
    }
  }
}