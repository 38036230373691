import axiosInstance, { axiosDefault } from "@/services/instanceService";

// const initialState = {
//   followData: {},
//   followFilter: 'a',
//   username: '',
//   loadingFollowState: false,
//   loadingFetchFollow: false,
//   followBaseLoadLimit: 6,
//   userFollowSearched: '',
//   searchFollowStartLoad: '',
//   searchNotFound: false,
//   noMoreFollowSearch: false,
//   searchedFollowList: [],
//   refetchFollow: false,
// }
// const setFollowDataState = {
//   followList: { following: [], follower: []},
//   // for loadmore following and follower
//   startLoad: { following: 0, follower: 0},
//   noMoreData: { following: false, follower: false},
//   skipLimitFollowChanges: {following: 0, follower: 0},
// }

// Note State per user ada di function addNewUsernameFollow

export default {
  state: {},

  mutations: {
    initializeFollowState() {
      this.state.follow = {
        followData: {},
        followFilter: "",
        username: "",
        loadingFollowState: false,
        loadingFetchFollow: false,
        followBaseLoadLimit: 24,
        userFollowSearched: "",
        searchFollowStartLoad: 0,
        noMoreFollowSearch: false,
        searchedFollowList: [],
        refetchFollow: false,
      };
    },
    resetFollowState() {
      this.state.follow = {};
    },
    addNewUsernameFollow(state, username) {
      state.followData[username] = {
        ...{
          followList: { following: [], follower: [] },
          // for loadmore following and follower
          startLoad: { following: 0, follower: 0 },
          noMoreData: { following: false, follower: false },
          skipLimitFollowChanges: { following: 0, follower: 0 },
        },
      };
    },
    setTargetUsernameFollow(state, payload) {
      state.username = payload;
    },
    updateFollowState(state, payload) {
      const { index, data, filter } = payload;
      state.followData[state.username].followList[filter].splice(
        index,
        1,
        data
      );
    },
    setFollowData(state, data) {
      const { noMoreData, followData } = data;
      // set follower/following list
      state.followData[state.username].followList[state.followFilter] = [
        ...state.followData[state.username].followList[state.followFilter],
        ...followData,
      ];
      // set no more data true or false to follower/following filter
      state.followData[state.username].noMoreData[
        state.followFilter
      ] = noMoreData;
      // set start load / skip number
      state.followData[state.username].startLoad[state.followFilter] =
        state.followBaseLoadLimit +
        state.followData[state.username].startLoad[state.followFilter] +
        state.followData[state.username].skipLimitFollowChanges[
          state.followFilter
        ];
      // set no more data true or false to follower/following filter
      state.followData[state.username].skipLimitFollowChanges[
        state.followFilter
      ] = 0;
      // re-declare to make computed in component reactive
      state.followData = { ...state.followData };
    },
    resetFollowingData(state, payload) {
      const { username } = payload;
      // set follower/following list
      state.followData[username].followList.following = [];
      // set no more data true or false to follower/following filter
      state.followData[username].noMoreData.following = false;
      // set start load / skip number
      state.followData[username].startLoad.following = 0;
      // set no more data true or false to follower/following filter
      state.followData[username].skipLimitFollowChanges.following = 0;
      // re-declare to make computed in component reactive
      state.followData = { ...state.followData };
    },
    removeFromFollowState(state, payload) {
      const { filter, index } = payload;
      state.followData[state.username].followList[filter].splice(index, 1);
      // delete data from state, substract skip limit
      state.followData[state.username].skipLimitFollowChanges[filter] -= 1;
    },
    setLoadingFollowState(state, payload) {
      state.loadingFollowState = payload;
    },
    setLoadingFetchFollow(state, payload) {
      state.loadingFetchFollow = payload;
    },
    setFollowFilter(state, payload) {
      state.followFilter = payload;
    },
    setUserFollowSearched(state, payload) {
      state.userFollowSearched = payload;
    },
    setSearchFollowStartLoad(state) {
      state.searchFollowStartLoad =
        state.followBaseLoadLimit + state.searchFollowStartLoad;
    },
    setNoMoreFollowSearch(state, payload) {
      state.noMoreFollowSearch = payload;
    },
    setSearchedFollowList(state, payload) {
      state.searchedFollowList = [...state.searchedFollowList, ...payload];
    },
    resetFollowSearch(state) {
      state.searchFollowStartLoad = 0;
      state.noMoreFollowSearch = false;
      state.searchedFollowList = [];
    },
    setRefetchFollow(state, payload) {
      state.refetchFollow = payload;
    },
  },

  actions: {
    doFollow(context, payload) {
      const { followedPersonId, followedPersonUsername } = payload;
      return axiosInstance({
        method: "POST",
        url: `follow/doFollow/`,
        data: {
          followedPersonId,
          followedPersonUsername,
        },
      });
    },
    doUnfollow(context, payload) {
      const { id } = payload;
      return axiosInstance({
        method: "DELETE",
        url: `follow/doUnfollow/${id}`,
      });
    },
    loadFollow({ state }, data) {
      return axiosDefault({
        method: "POST",
        url: `public/loadFollow/`,
        data: data,
      })
        .then((data) => {
          setTimeout(() => {
            if (state.userFollowSearched == "") {
              this.commit("setFollowData", data.data);
            } else if (state.userFollowSearched !== "") {
              this.commit("setNoMoreFollowSearch", data.data.noMoreData);
              this.commit("setSearchFollowStartLoad");
              this.commit("setSearchedFollowList", data.data.followData);
            }
            this.commit("setLoadingFollowState", false);
            this.commit("setLoadingFetchFollow", false);
          }, 500);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    removeFollower(context, data) {
      return axiosInstance({
        method: "POST",
        url: `follow/removeFollower/`,
        data: data,
      });
    },
  },
};
