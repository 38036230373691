export const API_URL = process.env.VUE_APP_BASE_API;
export const FILE_PATH = process.env.VUE_APP_STORAGE;
export const THUMB_SMALL = process.env.VUE_APP_THUMB_SMALL;
export const THUMB_MEDIUM = process.env.VUE_APP_THUMB_MEDIUM;

export const CAPTION_LIMIT = process.env.VUE_APP_CAPTION_LIMIT;
export const TITLE_LIMIT = process.env.VUE_APP_TITLE_LIMIT;
export const DESCRIPTION_LIMIT = process.env.VUE_APP_DESCRIPTION_LIMIT;
export const GROUP_DESCRIPTION_LIMIT =
  process.env.VUE_APP_GROUP_DESCRIPTION_LIMIT;
export const VIDEO_UPLOAD_LIMIT = parseInt(
  process.env.VUE_APP_VIDEO_UPLOAD_LIMIT
);
export const PHOTO_UPLOAD_LIMIT = parseInt(
  process.env.VUE_APP_PHOTO_UPLOAD_LIMIT
);
export const MAPBOX_TOKEN = process.env.VUE_APP_MAPBOX_TOKEN;
export const EIGHTTEEN_TIMESTAMP = parseInt(
  process.env.VUE_APP_EIGHTTEEN_TIMESTAMP
);
export const CURRENT_DATE = Date.now();

export const WORDING_DISALLOW_SPECIAL_CHAR_MODAL_TITLE = "Not Allowed";
export const WORDING_DISALLOW_SPECIAL_CHAR_MODAL_MESSAGE =
  "Tags does not support special characters and emoticons";
export const WORDING_INSERT_CORRECT_LOCATION_TITLE = "Not Allowed";
export const WORDING_INSERT_CORRECT_LOCATION_MESSAGE =
  "Choose location from the location options";
